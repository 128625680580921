import { createStore } from 'easy-peasy'

import layout from './LayoutStore'
import user from './UserStore'
import global from './GlobalStore'
import ftp from './FtpStore'
import order from './OrderStore'
import template from './TemplateStore'
import invoice from './InvoiceStore'

export const Store = createStore({
  layout,
  user,
  global,
  ftp,
  order,
  template,
  invoice,
})
