import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'

import { DEFAULT_PAGE, LOGIN_PAGE, PX_TO_REM } from '../../helpers/Constants'
import { isAuthenticated } from '../../helpers/Utils'

import Typography from '../../components/Typography'
import Button from '../../components/Button'

import Footer from '../../layouts/Footer'

import './index.scss'

const Error = ({
  errorType, errorTitle, errorDescr, btnLabel,
}) => {
  const navigate = useNavigate()

  // init all component actions
  const isMobile = useStoreState((state) => state.layout.isMobile)

  const handleClick = () => {
    if (isAuthenticated()) {
      navigate(DEFAULT_PAGE)
    } else {
      navigate(LOGIN_PAGE)
    }
  }

  return (
    <div className="error-page">
      <div className="error--wrap">
        <div className="screen-center">
          <div className="width-100vw">
            <div className="error-type">
              <Typography
                font="semibold"
                label={errorType}
                fontSize={isMobile ? PX_TO_REM['130'] : PX_TO_REM['190']}
                lineHeight={isMobile ? PX_TO_REM['20'] : PX_TO_REM['34']}
              />
            </div>

            <div className={`${isMobile ? 'm-top-20' : 'm-top-24'} error-title`}>
              <Typography
                variant="h2"
                font="medium"
                label={errorTitle}
                fontSize={isMobile ? PX_TO_REM['35'] : null}
                lineHeight={isMobile ? PX_TO_REM['44'] : PX_TO_REM['72']}
              />
            </div>

            <div className={`${isMobile ? 'm-top-10' : 'm-top-24'} error-descr`}>
              <Typography
                variant="h6"
                label={errorDescr}
                fontSize={isMobile ? PX_TO_REM['16'] : null}
                lineHeight={isMobile ? PX_TO_REM['22'] : PX_TO_REM['25']}
              />
            </div>

            <div className={`${isMobile ? 'm-top-18' : 'm-top-24'}`}>
              <Button label={btnLabel} type="fit-content" onClick={handleClick} />
            </div>
          </div>
        </div>

        <div className="m-top-auto">
          <Footer isNewTab />
        </div>
      </div>
    </div>
  )
}

Error.propTypes = {
  errorType: PropTypes.string,
  errorTitle: PropTypes.string,
  errorDescr: PropTypes.string,
  btnLabel: PropTypes.string,
}

Error.defaultProps = {
  errorType: '',
  errorTitle: '',
  errorDescr: '',
  btnLabel: '',
}

export default Error
