import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../helpers/Translation'
import { isValidValue, removeInputError } from '../../../helpers/Utils'
import {
  FIELDS_MIN_LENGTH,
  FIELDS_PATTERNS,
  KEYS, OK_PASSWORD_SCORE,
  PX_TO_REM,
} from '../../../helpers/Constants'

import Input from '../../../components/Input'
import Button from '../../../components/Button'

import PasswordStrengthBar from '../../../layouts/Common/PasswordStrengthBar'
import TableTopInfo from '../../../layouts/Common/TableTopInfo'

import { ReactComponent as CheckSvg } from '../../../svg/check_curved.svg'

import './index.scss'

const Password = () => {
  const user = useStoreState((state) => state.user.user)
  const apiErrors = useStoreState((state) => state.user.apiErrors)
  const clearApiErrors = useStoreActions((state) => state.user.clearApiErrors)
  const updatePassword = useStoreActions((actions) => actions.user.updatePassword)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [isFormErrors, setIsFormErrors] = useState({})
  const [isAllValid, setIsAllValid] = useState(false)
  const [passwordScore, setPasswordScore] = useState(0)
  const [formErrors, setFormErrors] = useState({
    oldPassword: Translation.must_be_at_least_chars_long,
    password: Translation.must_be_at_least_chars_long,
    passwordScore: Translation.password_is_to_weak,
    repeatNewPassword: Translation.passwords_do_not_match,
  })

  useEffect(() => {
    if (oldPassword && newPassword && repeatNewPassword) setIsAllValid(true)
    else setIsAllValid(false)
    clearApiErrors()
  }, [oldPassword, newPassword, repeatNewPassword])

  useEffect(() => {
    if (apiErrors) {
      if (apiErrors.serverError) return

      let isValid = true

      const formErrorsUpdated = {}
      const isFormErrorsUpdated = {}

      if (apiErrors.required) {
        if (apiErrors.required['password-current']) {
          isValid = false
          formErrorsUpdated.oldPassword = apiErrors.required['password-current']
          isFormErrorsUpdated.oldPassword = !isValid
        }
        if (apiErrors.required.password) {
          isValid = false
          formErrorsUpdated.password = apiErrors.required.password
          isFormErrorsUpdated.password = !isValid
        }
        if (apiErrors.required['password-repeat']) {
          isValid = false
          formErrorsUpdated.repeatNewPassword = apiErrors.required['password-repeat']
          isFormErrorsUpdated.repeatNewPassword = !isValid
        }
      }

      if (!isValid) {
        setFormErrors({ ...formErrors, ...formErrorsUpdated })
        setIsFormErrors(isFormErrorsUpdated)
      }
    } else {
      // clear all form errors
      setIsFormErrors({})
    }
  }, [apiErrors])

  const isFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(newPassword, FIELDS_PATTERNS.simplePassword)) {
      isValid = false
      isFormErrorsUpdated.password = !isValid
    }

    if (passwordScore < OK_PASSWORD_SCORE) {
      isValid = false
      isFormErrorsUpdated.passwordScore = !isValid
    }

    if (!repeatNewPassword || newPassword !== repeatNewPassword) {
      isValid = false
      isFormErrorsUpdated.repeatNewPassword = !isValid
    }

    if (!isValidValue(oldPassword, FIELDS_PATTERNS.simplePassword)) {
      isValid = false
      isFormErrorsUpdated.oldPassword = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const handleOldPasswordChange = (e) => {
    const input = e.currentTarget
    const inputValue = input.value.trim()
    setOldPassword(inputValue)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handlePasswordChange = (e) => {
    const input = e.currentTarget
    const inputValue = input.value.trim()
    setNewPassword(inputValue)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handlePasswordRepeatChange = (e) => {
    const input = e.currentTarget
    const inputValue = input.value.trim()
    setRepeatNewPassword(inputValue)

    if (newPassword === inputValue) {
      setIsFormErrors({ ...isFormErrors, repeatNewPassword: false })
    }
  }

  const onSaveChanges = async () => {
    if (isFormValid()) {
      const res = await updatePassword({
        'password-current': oldPassword,
        password: newPassword,
        'password-repeat': repeatNewPassword,
        userId: user.user_id,
      })
      if (res) {
        updateSnackbarState({
          isOpen: true,
          message: Translation.password_changed_successfully,
          type: 'success',
        })
        setOldPassword('')
        setNewPassword('')
        setRepeatNewPassword('')
        setIsFormErrors({})
      }
    }
  }

  const handlePasswordRepeatKeyUp = (e) => {
    if (e.key === KEYS.enter && isFormValid()) {
      onSaveChanges().then(() => { })
    }
  }

  return (
    <div className="password-section">
      <div className="page-title">
        <TableTopInfo
          title={Translation.password_management}
          titleFontSize={PX_TO_REM['16']}
          description={Translation.change_your_account_password}
        />
      </div>
      <div className="form-control">
        <Input
          type="password"
          label={`${Translation.old_password}*`}
          placeholder={Translation.enter_old_password}
          minLength={FIELDS_MIN_LENGTH.password}
          pattern={FIELDS_PATTERNS.simplePassword}
          error={formErrors.oldPassword}
          isError={isFormErrors.oldPassword}
          value={oldPassword}
          onChange={handleOldPasswordChange}
          name="oldPassword"
          disableAutocomplete
        />
      </div>
      <div className="form-control">
        <Input
          type="password"
          label={`${Translation.new_password}*`}
          placeholder={Translation.enter_new_password}
          minLength={FIELDS_MIN_LENGTH.password}
          pattern={FIELDS_PATTERNS.simplePassword}
          // eslint-disable-next-line no-nested-ternary
          error={isFormErrors.password ? formErrors.password : isFormErrors.passwordScore ? formErrors.passwordScore : null}
          isError={isFormErrors.password || isFormErrors.passwordScore}
          value={newPassword}
          onChange={handlePasswordChange}
          name="password"
          disableAutocomplete
        />
        {
          (newPassword || isFormErrors.newPassword) ? (
            <PasswordStrengthBar
              password={newPassword}
              minLength={FIELDS_MIN_LENGTH.password}
              setPasswordScore={(score) => setPasswordScore(score)}
            />
          ) : null
        }
      </div>
      <div className="form-control">
        <Input
          type="password"
          label={`${Translation.repeat_new_password}*`}
          placeholder={Translation.repeat_new_password}
          value={repeatNewPassword}
          error={formErrors.repeatNewPassword}
          isError={isFormErrors.repeatNewPassword}
          minLength={FIELDS_MIN_LENGTH.password}
          pattern={FIELDS_PATTERNS.simplePassword}
          onChange={handlePasswordRepeatChange}
          onKeyUp={handlePasswordRepeatKeyUp}
          name="repeatNewPassword"
          {...(repeatNewPassword && (repeatNewPassword === newPassword)) && { endAdornment: <CheckSvg /> }}
          disableAutocomplete
        />
      </div>
      <div className="button-container">
        <Button
          disabled={isAllValid === false}
          label={Translation.save_changes}
          onClick={onSaveChanges}
          width="auto"
        />
      </div>
    </div>
  )
}

export default Password
