import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import { PX_TO_REM } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'

import { ReactComponent as StatusClose } from '../../../../svg/status_close.svg'
import { ReactComponent as StatusEye } from '../../../../svg/status_eye.svg'
import { ReactComponent as StatusScissors } from '../../../../svg/status_scissors.svg'
import { ReactComponent as StatusCheck } from '../../../../svg/status_check.svg'
import { ReactComponent as StatusArrows } from '../../../../svg/status_arrows.svg'
import { ReactComponent as StatusComment } from '../../../../svg/status_comment.svg'
import { ReactComponent as StatusClientComment } from '../../../../svg/status_client_comment.svg'
import { ReactComponent as StatusCreditCard } from '../../../../svg/status_credit_card.svg'
import { ReactComponent as StatusRecycle } from '../../../../svg/status_recycle.svg'
import { ReactComponent as StatusRecycleOval } from '../../../../svg/status_recycle_oval.svg'
import { ReactComponent as StatusSandWatch } from '../../../../svg/status_sand_watch.svg'
import { ReactComponent as StatusSettings } from '../../../../svg/status_settings.svg'

import './index.scss'

const icons = {
  cogs: {
    icon: <StatusSettings />,
    color: 'success-300',
  },
  eye: {
    icon: <StatusEye />,
    color: 'success-300',
  },
  comments: {
    icon: <StatusComment />,
    color: 'success-300',
  },
  client_comments: {
    icon: <StatusClientComment />,
    color: 'success-300',
  },
  creditcard: {
    icon: <StatusCreditCard />,
    color: 'orange-500',
  },
  refresh: {
    icon: <StatusRecycle />,
    color: 'success-400',
  },
  retweet: {
    icon: <StatusRecycleOval />,
    color: 'success-400',
  },
  exchange: {
    icon: <StatusArrows />,
    color: 'success-400',
  },
  check: {
    icon: <StatusCheck />,
    color: 'success-500',
  },
  hourglass: {
    icon: <StatusSandWatch />,
    color: 'gray',
  },
  times: {
    icon: <StatusClose />,
    color: 'gray',
  },
  cut: {
    icon: <StatusScissors />,
    color: 'success-400',
  },
}

const statusHover = (item) => (
  <div className="status-hover">
    {
      item.order_status_progress === 0 ? (
        <Typography
          variant="xs"
          lineHeight={PX_TO_REM['20']}
          font="medium"
          label={item.order_status_label}
          fontSize={PX_TO_REM['12']}
        />
      ) : (
        <>
          <div className={`status-hover-row ${item.order_status_progress === 1 ? 'active' : ''}`}>
            <div className="row-iconWrapper">
              <StatusEye />
            </div>
            <div className="row-text">
              <Typography variant="xs" lineHeight={PX_TO_REM['20']} font="medium" label={Translation.order_being_checked} />
            </div>
          </div>
          <div className={`status-hover-row ${item.order_status_progress === 2 ? 'active' : ''}`}>
            <div className="row-iconWrapper">
              <StatusScissors />
            </div>
            <div className="row-text">
              <Typography variant="xs" lineHeight={PX_TO_REM['20']} font="medium" label={Translation.order_being_processed} />
            </div>
          </div>
          {
            item.order_status_steps === 4 && (
              <div className={`status-hover-row ${item.order_status_progress === 3 ? 'active' : ''}`}>
                <div className="row-iconWrapper">
                  {item.order_status_progress === 3 ? icons[item.order_status_icon].icon : <StatusCheck />}
                </div>
                <div className="row-text">
                  <Typography
                    variant="xs"
                    lineHeight={PX_TO_REM['20']}
                    font="medium"
                    label={item.order_status_progress === 3 ? item.order_status_label : Translation.approval_required}
                  />
                </div>
              </div>
            )
          }
          <div className={`status-hover-row ${item.order_status_progress === 4 ? 'activeAll' : ''}`}>
            <div className="row-iconWrapper">
              <StatusCheck />
            </div>
            <div className="row-text">
              <Typography variant="xs" lineHeight={PX_TO_REM['20']} font="medium" label={Translation.order_delivered} />
            </div>
          </div>
        </>
      )
    }
  </div>
)

const OrderTableStatus = ({ item }) => (
  <Tooltip
    id={item.order_status_progress === 0 ? '' : 'status-tooltip'}
    className={item.order_status_progress === 0 ? 'info-tooltip' : ''}
    PopperProps={{
      disablePortal: true,
    }}
    title={statusHover(item)}
    placement="right"
    arrow={item.order_status_progress === 0}
  >
    <div
      className={`orderTable-status ${icons[item.order_status_icon].color} ${item.order_status_is_warning ? 'orange-500' : ''}`}
    >
      {icons[item.order_status_icon].icon}
    </div>
  </Tooltip>
)

OrderTableStatus.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
}

export default OrderTableStatus
