import { action } from 'easy-peasy'

import {
  COOKIE_NAMES,
  COOKIE_VALUES,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../helpers/Constants'
import { isLocal } from '../helpers/Cookie'
import { log, windowWidth } from '../helpers/Utils'

const LayoutStore = {
  isMobile: windowWidth() <= MOBILE_BREAKPOINT,
  isTablet: windowWidth() <= TABLET_BREAKPOINT,
  isSidebarMobile: false,
  isHorizontalScroll: false,
  isSidebarOpened: localStorage.getItem(COOKIE_NAMES.sidebar_state) !== COOKIE_VALUES.sidebar_state,
  isShowRating: true,
  isRatingExpanded: null,

  menuState: {
    lang: false,
    support: false,
    profile: false,
  },

  updateBreakpoints: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.isMobile = windowWidth() <= MOBILE_BREAKPOINT
    // eslint-disable-next-line no-param-reassign
    state.isTablet = windowWidth() > MOBILE_BREAKPOINT && windowWidth() <= TABLET_BREAKPOINT

    if (isLocal) { // for internal use only
      log('windowWidth', windowWidth())
    }
  }),

  updateSidebarMobile: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isSidebarMobile = payload
  }),

  updateHorizontalScroll: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isHorizontalScroll = payload
  }),

  updateSidebarOpened: action((state) => {
    if (!state.isSidebarOpened) localStorage.removeItem(COOKIE_NAMES.sidebar_state)
    else localStorage.setItem(COOKIE_NAMES.sidebar_state, COOKIE_VALUES.sidebar_state)
    // eslint-disable-next-line no-param-reassign
    state.isSidebarOpened = !state.isSidebarOpened
  }),

  updateMenuState: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.menuState = payload
  }),

  updateShowRating: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isShowRating = payload
  }),

  updateRatingExpanded: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isRatingExpanded = payload
  }),
}

export default LayoutStore
