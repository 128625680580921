import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'

import { Translation } from '../../helpers/Translation'
import { DEFAULT_PAGE, FOLDER_TYPES, PX_TO_REM } from '../../helpers/Constants'

import Typography from '../../components/Typography'
import Button from '../../components/Button'

import OrderSuccessfulAnimation from '../../animation/order_successful_animation.json'

import { ReactComponent as OrderPlacedSuccessfullyFilledSvg } from '../../svg/order_placed_successfully_filled.svg'
import { ReactComponent as OrderBeingCheckedFilledSvg } from '../../svg/order_being_checked_filled.svg'
import { ReactComponent as OrderBeingProcessedSvg } from '../../svg/order_being_processed_empty.svg'
import { ReactComponent as QualityAssuranceEmptySvg } from '../../svg/quality_assurance_empty.svg'
import { ReactComponent as OrderDeliveredEmptySvg } from '../../svg/order_delivered_empty.svg'
import { ReactComponent as SuccessfulCheckmarkSvg } from '../../svg/successful_checkmark.svg'

import './index.scss'

const splashDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: OrderSuccessfulAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Loader = () => <SuccessfulCheckmarkSvg className="loader-icon" />

const OrderSuccess = () => {
  const Lottie = React.lazy(() => import('lottie-react'))

  const orderId = useStoreState((state) => state.order.createdOrderId)
  const folderType = useStoreState((state) => state.order.folderType)

  const navigate = useNavigate()

  const orderSteps = [
    {
      key: 1,
      label: Translation.order_steps.order_placed_successfully,
      isActive: true,
      icon: <OrderPlacedSuccessfullyFilledSvg />,
      progressBarAnimation: 'ease-in',
      hasBar: true,
    },
    {
      key: 2,
      label: Translation.order_steps.order_being_checked,
      isActive: true,
      icon: <OrderBeingCheckedFilledSvg className="animate__shakeX" />,
      progressBarAnimation: 'ease-out',
      hasBar: true,
    },
    {
      key: 3,
      label: Translation.order_steps.order_being_processed,
      isActive: false,
      icon: <OrderBeingProcessedSvg />,
      progressBarAnimation: null,
      hasBar: true,
    },
    {
      key: 4,
      label: Translation.order_steps.quality_assurance,
      isActive: false,
      icon: <QualityAssuranceEmptySvg />,
      progressBarAnimation: null,
      hasBar: true,
    },
    {
      key: 5,
      label: Translation.order_steps.order_delivered,
      isActive: false,
      icon: <OrderDeliveredEmptySvg />,
      progressBarAnimation: null,
      hasBar: false,
    },
  ]

  const onWetransferClick = () => {
    window.open(process.env.REACT_APP_WETRANSFER_URL, '_blank')
  }

  useEffect(() => {
    if (!orderId) {
      navigate(DEFAULT_PAGE)
    }
  }, [])

  return (
    <div className="order-success-page">
      <div className="page-icon-container">
        <React.Suspense fallback={<Loader />}>
          <Lottie {...splashDefaultOptions} className="lottie-animation" />
        </React.Suspense>
      </div>
      <div className="title-container">
        <Typography variant="h5" theme="dark" font="semibold" label={Translation.order_was_placed_successfully} />
      </div>
      <div className="subtitle-container">
        <Typography variant="xs" theme="dark" font="medium">
          {`${Translation.your_order} `}
          <span>{`#${orderId}`}</span>
          {` ${Translation.will_now_be_checked}`}
        </Typography>
      </div>
      <div className="m-bottom-40 order-steps-container">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {orderSteps.map((step) => (
            <div key={step.key}>
              <div className={`progress-item ${step.isActive ? 'active' : ''}`}>
                {step.icon}
                {step.hasBar && (
                  <div className="progress-bar">
                    <div className={`progress-bar-inner ${step.progressBarAnimation}`} />
                  </div>
                )}
              </div>
              <Typography fontSize={PX_TO_REM['11']} lineHeight={PX_TO_REM['14']} label={step.label} />
            </div>
          ))}
        </div>
      </div>
      {
        (folderType === FOLDER_TYPES.WETRANSFER) ? (
          <div>
            <div className="m-bottom-26">
              <Typography variant="xs" theme="dark" font="medium" label={`${Translation.order_success_wetransfer_text_1}`} />
              <Typography variant="xs" theme="dark" font="medium" label={`${Translation.order_success_wetransfer_text_2}`} />
            </div>

            <div className="m-bottom-26">
              <Button
                size="xs"
                width="fit-content"
                label={Translation.upload_via_wetransfer.toUpperCase()}
                onClick={onWetransferClick}
              />
            </div>
          </div>
        ) : (
          <div className="button-container">
            <Button size="xs" label={Translation.go_to_dashboard} onClick={() => navigate(DEFAULT_PAGE)} />
          </div>
        )
      }

      <div className="m-bottom-26">
        <Typography variant="h6" theme="dark" font="semibold" label={`${Translation.what_happens_next}`} />
      </div>
      <div className="what-happens-next-items">
        <ol>
          {Object.keys(Translation.what_happens_items).map((item, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={key}>
              <Typography
                variant="xs"
                theme="dark"
                font="medium"
                lineHeight={PX_TO_REM['26']}
                label={Translation.what_happens_items[item]}
              />
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

export default OrderSuccess
