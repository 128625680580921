import React from 'react'
import { Tooltip } from '@mui/material'

import {
  DESKTOP_PX_TO_REM, DESKTOP_TABLE_WIDTH, ORDER_STATUS, PX_TO_REM,
} from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'

import Typography from '../../../components/Typography'

import OrdersTableStatus from './OrdersTableStatus'
import OrdersTableTiming from './OrdersTableTiming'

import { ReactComponent as ArrowUpCircle } from '../../../svg/arrow_up_circle.svg'
import { ReactComponent as CheckSvg } from '../../../svg/check.svg'

const getPaidStatus = (item, unpaidInvoiceIds, processingInvoiceIds) => {
  if (item.order_status_id <= ORDER_STATUS.ORDER_STATUS_NEW
    || item.order_status_id > ORDER_STATUS.ORDER_STATUS_COMPLETED) {
    return ' '
  }

  if (item.invoice_id !== null) {
    if (typeof unpaidInvoiceIds === 'undefined' && typeof processingInvoiceIds === 'undefined') {
      return ' '
    }

    if (typeof unpaidInvoiceIds !== 'undefined' && unpaidInvoiceIds !== null
      && unpaidInvoiceIds.includes(item.invoice_id)) {
      return 'unpaid'
    }

    if (typeof processingInvoiceIds !== 'undefined' && processingInvoiceIds !== null
      && processingInvoiceIds.includes(item.invoice_id)) {
      return 'processing'
    }
    return 'paid'
  }

  return 'unpaid'
}

const formatsMaxLength = 15

// eslint-disable-next-line arrow-body-style
const processFormats = (formats) => {
  if (!formats) {
    return (
      <div className="order-formats">
        <span>-</span>
      </div>
    )
  }

  // truncate formats if more than 15 characters
  if (formats?.length > formatsMaxLength) {
    return (
      <Tooltip
        className="info-tooltip"
        PopperProps={{
          disablePortal: true,
        }}
        title={formats.toUpperCase()}
        placement="top"
        arrow
      >
        <div className="order-formats">
          <span>{`${formats.substring(0, formatsMaxLength).toUpperCase()}...`}</span>
        </div>
      </Tooltip>
    )
  }
  return (
    <div className="order-formats">
      <span>{formats.toUpperCase()}</span>
    </div>
  )
}

// 102px is enough up to order id 999999
const colWidth = [94, 90, 74, 154, 390, 146, 64, 102]
colWidth.push(DESKTOP_TABLE_WIDTH - colWidth.reduce((a, b) => a + b))

export const columns = [
  {
    id: 'status',
    render: 'jsx',
    label: Translation.status,
    width: colWidth[0] / DESKTOP_PX_TO_REM,
  },
  {
    id: 'images',
    label: Translation.Images,
    width: colWidth[1] / DESKTOP_PX_TO_REM,
  },
  {
    id: 'time',
    label: Translation.time,
    width: colWidth[2] / DESKTOP_PX_TO_REM,
  },
  {
    id: 'formats',
    label: Translation.formats,
    width: colWidth[3] / DESKTOP_PX_TO_REM,
  },
  {
    id: 'name',
    label: Translation.order_name,
    sortable: true,
    width: colWidth[4] / DESKTOP_PX_TO_REM,
  },
  {
    id: 'timing',
    render: 'jsx',
    label: Translation.timing,
    width: colWidth[5] / DESKTOP_PX_TO_REM,
  },
  {
    id: 'isPrioritised',
    render: 'jsx',
    label: '',
    width: colWidth[6] / DESKTOP_PX_TO_REM,
  },
  {
    id: 'id',
    label: 'ID',
    sortable: true,
    width: colWidth[7] / DESKTOP_PX_TO_REM,
  },
  {
    id: 'debt',
    label: '',
    align: 'center',
    width: colWidth[8] / DESKTOP_PX_TO_REM,
  },
]

export const columnsMobile = [
  {
    id: 'status',
    render: 'jsx',
    label: Translation.status,
    width: 70 / DESKTOP_PX_TO_REM,
  },
  {
    id: 'images',
    label: Translation.images,
    width: 60 / DESKTOP_PX_TO_REM,
  },
  {
    id: 'name',
    label: Translation.order_name,
    width: 60 / DESKTOP_PX_TO_REM,
  },
]

export const transformedData = (orders, unpaidInvoiceIds, processingInvoiceIds, isAnyOrderPlaced) => {
  const tempData = []

  orders.map((item) => (
    tempData.push({
      status: <OrdersTableStatus item={item} />,
      images: item?.partial_delivery_confirmed_count ? (
        <div>
          <div>
            {item.image_count}
          </div>
          <div className="order-partial-delivery-count">
            <Tooltip
              className="info-tooltip"
              PopperProps={{
                disablePortal: true,
              }}
              title={Translation.confirmed_images}
              placement="left"
              arrow
            >
              <CheckSvg />
            </Tooltip>
            {item.partial_delivery_confirmed_count}
          </div>
        </div>
      ) : (
        item.image_count
      ),
      time: `${item.turnaround}h`,
      formats: processFormats(item.formats),
      name: (
        <div className="order-name">
          <div className="order-name-text">
            {
              item.template_name && (
                <div className="order-template-name">
                  <span>{item.template_name}</span>
                </div>
              )
            }
            <span>{item.name}</span>
          </div>
          {
            item.type === 0 && (
              <div className="order-name-badge">
                <div className="badge-arrow" />
                <div className="badge-text">
                  <Typography variant="xs" fontSize={PX_TO_REM['10']} font="medium" label={Translation.test} />
                </div>
              </div>
            )
          }
        </div>
      ),
      timing: <OrdersTableTiming
        date={item.timing}
        timingSeconds={item.timing_left_seconds}
        type={item.timing_type}
      />,
      isPrioritised: item.is_prioritised ? (
        <Tooltip
          className="info-tooltip"
          PopperProps={{
            disablePortal: true,
          }}
          title={Translation.prioritised}
          placement="top"
          arrow
        >
          <span className="order-prioritised-icon">
            <ArrowUpCircle />
          </span>
        </Tooltip>
      ) : ' ',
      id: item.id,
      debt: getPaidStatus(item, unpaidInvoiceIds, processingInvoiceIds),
    })
  ))

  if (!isAnyOrderPlaced) {
    tempData.push({
      id: 0,
      emptyDataText: Translation.not_placed_order,
    })
  }

  return tempData
}
