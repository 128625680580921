import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import { Translation } from '../../../../helpers/Translation'
import { localizeDate } from '../../../../helpers/Utils'

import Typography from '../../../../components/Typography'

import { ReactComponent as CircleInfoSvg } from '../../../../svg/circle-info.svg'

import './index.scss'

const OrdersTableTiming = ({ date, type, timingSeconds }) => {
  const [timerString, setTimerString] = useState('')
  const [timerWidth, setTimerWidth] = useState('')

  useEffect(() => {
    let interval
    let timeSeconds = timingSeconds.toString()
    timeSeconds = parseInt(timeSeconds.replace(/\./g, ''), 10)
    if (type === 'timer') {
      const calculateTimeLeft = () => {
        const months = Math.floor((timeSeconds % (60 * 60 * 24 * 365)) / (60 * 60 * 24 * 30))
        const days = Math.floor((timeSeconds % (60 * 60 * 24 * 30)) / (60 * 60 * 24))
        const hours = Math.floor((timeSeconds % (60 * 60 * 24)) / (60 * 60))
        const minutes = Math.floor((timeSeconds % (60 * 60)) / 60)
        const seconds = timeSeconds % 60

        let timerStr = ''

        if (months > 0) {
          timerStr += `${months}m `
          setTimerWidth('8.3846153rem')
        }

        if (days > 0) {
          timerStr += `${days}d `

          if (months < 1) {
            setTimerWidth('6.0000000rem')
          } else {
            setTimerWidth('8.4615384rem')
          }
        }

        if (months < 1 && days < 1) {
          setTimerWidth('4.7692307rem')
        }

        const timeLeftStr = `
        ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

        setTimerString(timerStr !== '' ? timerStr + timeLeftStr : timeLeftStr)
      }

      calculateTimeLeft()
      interval = setInterval(() => {
        if (timeSeconds > 0) {
          timeSeconds -= 1
          calculateTimeLeft()
        }
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [timingSeconds])

  if (type === 'warning') {
    return (
      <div className="orderTable-timing">
        <Typography variant="xs" label={`<i class="timing-orange">${date}</i>`} containsHtml />
      </div>
    )
  } if (type === 'timer') {
    return (
      <div className="orderTable-timing timer">
        <div style={{ width: `${timerWidth}` }}>
          <Typography
            variant="xs"
            label={timerString}
            noWrap
            containsHtml
          />
        </div>
        <Tooltip
          className="info-tooltip"
          PopperProps={{
            disablePortal: true,
          }}
          title={Translation.estimated_delivery}
          placement="top"
          arrow
        >
          <CircleInfoSvg />
        </Tooltip>
      </div>
    )
  } if (type === 'text') {
    return (
      <div className="orderTable-timing orderTable-timing-text">
        <Typography variant="xs" label={`<i>${date}</i>`} containsHtml />
      </div>
    )
  }
  return (
    <div className="orderTable-timing">
      {localizeDate(date)}
    </div>
  )
}

OrdersTableTiming.propTypes = {
  date: PropTypes.string,
  type: PropTypes.string,
  timingSeconds: PropTypes.number,
}

OrdersTableTiming.defaultProps = {
  date: '',
  type: '',
  timingSeconds: 0,
}

export default OrdersTableTiming
