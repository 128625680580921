import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import Tooltip from '@mui/material/Tooltip'

import {
  isEnterKey, isValidValue, removeInputError, validateInput, validateInputWithError,
} from '../../helpers/Utils'
import { Translation } from '../../helpers/Translation'
import {
  DOOPIC_SALES_NUMBER,
  DOOPIC_SUPPORT_NUMBER,
  FIELDS_MIN_LENGTH,
  FIELDS_PATTERNS,
  PX_TO_REM,
  SUPPORT_VALID_FORMATS,
} from '../../helpers/Constants'

import Typography from '../../components/Typography'
import Link from '../../components/Link'
import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Button from '../../components/Button'
import Select from '../../components/Select'

import Faq from './Faq'

import { ReactComponent as UploadSvg } from '../../svg/upload.svg'
import { ReactComponent as PhoneSvg } from '../../svg/phone.svg'
import { ReactComponent as PaperPlaneSvg } from '../../svg/paper_plane.svg'
import { ReactComponent as CloseSvg } from '../../svg/close.svg'

import './index.scss'

const Support = () => {
  // init all component actions
  const user = useStoreState((state) => state.user.user)
  const supportAction = useStoreActions((actions) => actions.user.support)
  const clearApiErrorsAction = useStoreActions((actions) => actions.user.clearApiErrors)
  const apiErrors = useStoreState((state) => state.user.apiErrors)

  const [searchParams] = useSearchParams()

  const topicOrder = 'order'
  const topicBilling = 'billing'

  // init all component states
  const [email, setEmail] = useState(user.email)
  const [firstname, setFirstname] = useState(user.firstname)
  const [lastname, setLastname] = useState(user.lastname)
  const [orderId, setOrderId] = useState('')
  const [invoiceId, setInvoiceId] = useState('')
  const [topic, setTopic] = useState(topicOrder)
  const [message, setMessage] = useState('')
  const [file, setFile] = useState(null)
  const [uploadFileName, setUploadFileName] = useState('')

  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors, setFormErrors] = useState({
    email: Translation.enter_valid_email,
    firstname: Translation.required_field,
    lastname: Translation.required_field,
    order_id: Translation.must_be_at_least_chars_long,
    invoice_id: Translation.must_be_at_least_chars_long,
    message: Translation.required_field,
  })

  const [isPhoneSupport, setIsPhoneSupport] = useState(false)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [isMessageSent, setIsMessageSent] = useState(false)

  // init all component references
  const fileUploadInputRef = useRef(null)

  // watch "user" change
  useEffect(() => {
    // check if user is not empty
    if (Object.keys(user).length !== 0) {
      setEmail(user.email)
      setFirstname(user.firstname)
      setLastname(user.lastname)
    }
  }, [user])

  // watch API errors change
  useEffect(() => {
    if (apiErrors) {
      // API server is down
      if (apiErrors.serverError) return

      if (apiErrors.required?.file) return

      setIsFormErrors({ ...isFormErrors, email: true })
    } else {
      // clear all form errors
      setIsFormErrors({})
    }
  }, [apiErrors])

  // executed only once on first page load
  useEffect(() => {
    clearApiErrorsAction()
    const oi = searchParams.get('orderId')
    if (oi) setOrderId(oi)
  }, [])

  // if coming from the ftp page
  useEffect(() => {
    const page = searchParams.get('page')
    if (page === 'ftp') setTopic('other')
  }, [])

  const handleToggleSupportType = (e) => {
    const { name } = e.currentTarget.dataset
    setIsPhoneSupport((name === 'phone'))
    setIsMessageSent(false)
  }

  const handleEmailChange = (e) => {
    const input = e.currentTarget
    setEmail(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleEmailBlur = (e) => {
    validateInputWithError(
      e.currentTarget,
      formErrors,
      setFormErrors,
      isFormErrors,
      setIsFormErrors,
      Translation.enter_valid_email,
    )
  }

  const handleFirstnameChange = (e) => {
    const input = e.currentTarget
    setFirstname(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleFirstnameBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const handleLastnameChange = (e) => {
    const input = e.currentTarget
    setLastname(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleLastnameBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const handleOrderIdChange = (e) => {
    const input = e.currentTarget
    setOrderId(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleOrderIdBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const handleInvoiceIdChange = (e) => {
    const input = e.currentTarget
    setInvoiceId(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleInvoiceIdBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const handleTopicChange = (e) => {
    setTopic(e.target.value)
  }

  const handleMessageChange = (e) => {
    const input = e.currentTarget
    setMessage(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleMessageBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const isFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(firstname, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.firstname = !isValid
    }

    if (!isValidValue(lastname, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.lastname = !isValid
    }

    if (!isValidValue(email, FIELDS_PATTERNS.email)) {
      isValid = false
      isFormErrorsUpdated.email = !isValid
    }

    if (orderId && !isValidValue(orderId, FIELDS_PATTERNS.order_id)) {
      isValid = false
      isFormErrorsUpdated.order_id = !isValid
    }

    if (invoiceId && !isValidValue(invoiceId, FIELDS_PATTERNS.invoice_id)) {
      isValid = false
      isFormErrorsUpdated.invoice_id = !isValid
    }

    if (message.length <= FIELDS_MIN_LENGTH.message) {
      isValid = false
      isFormErrorsUpdated.message = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const clearUploadFile = () => {
    setFile(null)
    setUploadFileName('')
    fileUploadInputRef.current.value = null
  }

  const clearFormFields = () => {
    setOrderId('')
    setInvoiceId('')
    setTopic(topicOrder)
    setMessage('')

    clearUploadFile()
  }

  const handleSubmitClick = async () => {
    if (!isFormValid()) return

    setIsSubmitButtonDisabled(true)

    const result = await supportAction({
      firstname,
      lastname,
      email,
      topic,
      order_id: (topic === topicOrder) ? orderId : 0,
      invoice_id: (topic === topicBilling) ? invoiceId : 0,
      message,
      file,
    })

    setIsSubmitButtonDisabled(false)

    if (result) {
      setIsMessageSent(true)

      clearFormFields()

      // clear all form errors
      setIsFormErrors({})
    }
  }

  const handleInputKeyUp = (e) => {
    if (isEnterKey(e.key)) {
      handleSubmitClick().then(() => { })
    }
  }

  const handleUploadClick = () => {
    fileUploadInputRef.current.click()
  }

  const handleFileUploadChange = (e) => {
    const input = e.currentTarget

    if (input?.files && input?.files[0] && typeof input?.files[0] !== 'undefined') {
      const { type, name } = input.files[0]
      const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase()

      if (SUPPORT_VALID_FORMATS.ext.includes(ext) && SUPPORT_VALID_FORMATS.mime.includes(type)) {
        setUploadFileName(name)
        setFile(input.files[0])
      }
    }
  }

  const handleDeleteUploadFile = (e) => {
    e.stopPropagation()

    clearUploadFile()
  }

  return (
    <div className="support-page">
      <div className="support-form m-bottom-20">
        <div className="support-form__form">
          <div>
            <Typography
              variant="xl"
              font="semibold"
              theme="dark"
              label={Translation.how_can_we_help_question}
            />
          </div>

          <div className="m-top-12">
            <Typography
              variant="s"
              label={Translation.team_love_to_here_you}
            />
          </div>

          <div className="flex m-top-12">
            <div
              onClick={handleToggleSupportType}
              data-name="email"
              className={`support-form__tab ${(!isPhoneSupport) ? 'support-form__tab--active' : ''}`}
            >
              <Typography
                variant="s"
                font={`${(!isPhoneSupport) ? 'semibold' : ''}`}
                theme="dark"
                label={Translation.email_support}
              />
            </div>

            <div
              onClick={handleToggleSupportType}
              data-name="phone"
              className={`support-form__tab ${(isPhoneSupport) ? 'support-form__tab--active' : ''}`}
            >
              <Typography
                variant="s"
                font={`${(isPhoneSupport) ? 'semibold' : ''}`}
                theme="dark"
                label={Translation.telephone_support}
              />
            </div>
          </div>
          {
            // eslint-disable-next-line no-nested-ternary
            (isMessageSent)
              ? (
                <>
                  <div className="m-top-35">
                    <Typography
                      variant="xl"
                      font="semibold"
                      theme="dark"
                      label={Translation.we_received_message}
                    />
                  </div>

                  <div className="m-top-20">
                    <Typography
                      variant="s"
                      label={Translation.team_will_contact_soon}
                    />
                  </div>

                  <div className="m-top-32">
                    <PaperPlaneSvg />
                  </div>
                </>
              )
              : (isPhoneSupport) ? (
                <>
                  <div className="support-form--phone--wrap m-top-25">
                    <PhoneSvg />

                    <div className="m-top-8">
                      <Typography
                        variant="h6"
                        font="semibold"
                        theme="dark"
                        label={Translation.contact_support}
                      />
                    </div>

                    <div className="m-top-8">
                      <Typography
                        variant="h6"
                        theme="dark"
                        label={Translation.contact_support_description}
                      />
                    </div>

                    <div className="m-top-20">
                      <Link
                        href={`tel: ${DOOPIC_SUPPORT_NUMBER}`}
                        variant="s"
                        underline
                        font="semibold"
                        theme="dark"
                        label={DOOPIC_SUPPORT_NUMBER}
                      />
                    </div>
                  </div>

                  <div className="support-form--phone--wrap m-top-31">
                    <PhoneSvg />

                    <div className="m-top-8">
                      <Typography
                        variant="h6"
                        font="semibold"
                        theme="dark"
                        label={Translation.contact_sales}
                      />
                    </div>

                    <div className="m-top-8">
                      <Typography
                        variant="h6"
                        theme="dark"
                        label={Translation.contact_sales_description}
                      />
                    </div>

                    <div className="m-top-20">
                      <Link
                        href={`tel: ${DOOPIC_SALES_NUMBER}`}
                        variant="s"
                        underline
                        font="semibold"
                        theme="dark"
                        label={DOOPIC_SALES_NUMBER}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="m-top-25 flex flex-column--gap-32">
                    <div className="column-half">
                      <Input
                        type="text"
                        name="firstname"
                        size="s"
                        width="100%"
                        label={`${Translation.firstname}*`}
                        error={formErrors.firstname}
                        isError={isFormErrors.firstname}
                        minLength={FIELDS_MIN_LENGTH.default}
                        pattern={FIELDS_PATTERNS.default}
                        placeholder={Translation.firstname}
                        value={firstname}
                        onChange={handleFirstnameChange}
                        onBlur={handleFirstnameBlur}
                        onKeyUp={handleInputKeyUp}
                      />
                    </div>

                    <div className="column-half m-top-20-mobile">
                      <Input
                        type="text"
                        name="lastname"
                        size="s"
                        width="100%"
                        label={`${Translation.lastname}*`}
                        error={formErrors.lastname}
                        isError={isFormErrors.lastname}
                        minLength={FIELDS_MIN_LENGTH.default}
                        pattern={FIELDS_PATTERNS.default}
                        placeholder={Translation.lastname}
                        value={lastname}
                        onChange={handleLastnameChange}
                        onBlur={handleLastnameBlur}
                        onKeyUp={handleInputKeyUp}
                      />
                    </div>
                  </div>

                  <div className="m-top-20">
                    <Input
                      type="text"
                      name="email"
                      size="s"
                      width="100%"
                      label={`${Translation.email}*`}
                      error={formErrors.email}
                      isError={isFormErrors.email}
                      pattern={FIELDS_PATTERNS.email}
                      placeholder={Translation.enter_your_email}
                      value={email}
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                      onKeyUp={handleInputKeyUp}
                    />
                  </div>

                  <div className="m-top-16 flex flex-column--gap-32">
                    <div className="column-half">
                      <Select
                        label={Translation.select_topic}
                        placeholder={Translation.select_option}
                        value={topic}
                        values={Translation.support_topics}
                        isEmptyOption={false}
                        onChange={handleTopicChange}
                      />
                    </div>

                    {
                      (topic === topicOrder) ? (
                        <div className="column-half m-top-20-mobile">
                          <Input
                            type="text"
                            name="order_id"
                            size="s"
                            width="100%"
                            label={`${Translation.order_id}`}
                            error={formErrors.order_id}
                            isError={isFormErrors.order_id}
                            minLength={FIELDS_MIN_LENGTH.order_id}
                            pattern={FIELDS_PATTERNS.order_id}
                            placeholder={Translation.enter_order_id}
                            value={orderId}
                            onChange={handleOrderIdChange}
                            onBlur={handleOrderIdBlur}
                            onKeyUp={handleInputKeyUp}
                          />
                        </div>
                      ) : null
                    }

                    {
                      (topic === topicBilling) ? (
                        <div className="column-half m-top-20-mobile">
                          <Input
                            type="text"
                            name="invoice_id"
                            size="s"
                            width="100%"
                            label={`${Translation.invoice_id}`}
                            error={formErrors.invoice_id}
                            isError={isFormErrors.invoice_id}
                            minLength={FIELDS_MIN_LENGTH.invoice_id}
                            pattern={FIELDS_PATTERNS.invoice_id}
                            placeholder={Translation.enter_invoice_id}
                            value={invoiceId}
                            onChange={handleInvoiceIdChange}
                            onBlur={handleInvoiceIdBlur}
                            onKeyUp={handleInputKeyUp}
                          />
                        </div>
                      ) : null
                    }
                  </div>

                  <div className="m-top-13">
                    <Textarea
                      name="message"
                      width="100%"
                      height={PX_TO_REM['114']}
                      label={`${Translation.message}*`}
                      error={formErrors.message}
                      isError={isFormErrors.message}
                      minLength={FIELDS_MIN_LENGTH.message}
                      pattern={FIELDS_PATTERNS.message}
                      placeholder={Translation.leave_us_message}
                      value={message}
                      onChange={handleMessageChange}
                      onBlur={handleMessageBlur}
                      onKeyUp={handleInputKeyUp}
                    />
                  </div>

                  <Tooltip
                    arrow
                    className="info-tooltip"
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    title={(
                      <div>
                        <Typography
                          variant="body1"
                          theme="lighter"
                          containsHtml
                          label={Translation.uploaded_file_format}
                        />
                        <Typography
                          variant="body1"
                          theme="lighter"
                          containsHtml
                          label={Translation.should_be_less_than}
                        />
                      </div>
                    )}
                  >
                    <div
                      onClick={handleUploadClick}
                      className={`m-top-20 support-form--upload--wrap 
                      ${uploadFileName ? 'support-form--upload--wrap__delete' : ''} overflow-ellipsis`}
                    >
                      {
                        (uploadFileName) ? (<CloseSvg onClick={handleDeleteUploadFile} />) : <UploadSvg />
                      }

                      <Typography
                        variant="s"
                        inline
                        theme="dark"
                        label={`${(uploadFileName) || Translation.upload_file}`}
                      />

                      <input
                        type="file"
                        name="file"
                        ref={fileUploadInputRef}
                        accept={SUPPORT_VALID_FORMATS.mime.join()}
                        onChange={handleFileUploadChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                  </Tooltip>

                  {
                    (apiErrors && apiErrors.serverError) ? (
                      <div className="m-top-4 align-left error">
                        <Typography
                          variant="xs"
                          theme="light"
                          inline
                          label={apiErrors.serverError}
                        />
                      </div>
                    ) : null
                  }

                  {
                    (apiErrors && apiErrors.required && apiErrors.required?.file) ? (
                      <div className="m-top-4 align-left error">
                        <Typography
                          variant="xs"
                          theme="light"
                          inline
                          label={apiErrors.required?.file}
                        />
                      </div>
                    ) : null
                  }

                  <div className="m-top-20">
                    <Button
                      size="s"
                      width={PX_TO_REM['360']}
                      label={Translation.send_message}
                      disabled={isSubmitButtonDisabled}
                      onClick={handleSubmitClick}
                    />
                  </div>
                </>
              )
          }
        </div>

        <div className="support-form__image" />
      </div>

      <div className="support-faq--tablet align-center m-top-37">
        <Typography
          variant="s"
          font="semibold"
          label={Translation.faq_only_on_desktop}
        />
      </div>

      <Faq />
    </div>
  )
}

export default Support
