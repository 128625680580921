import React from 'react'
import PropTypes from 'prop-types'

import { PX_TO_REM } from '../../../helpers/Constants'

import Typography from '../../../components/Typography'

import './index.scss'

const ProfilePicture = ({
  avatarUrl,
  firstname,
  lastname,
  width,
  height,
  showImage,
  fontSize,
  fontWeight,
  bgColor,
}) => {
  const [imageError, setImageError] = React.useState(false)

  const onImageError = () => {
    setImageError(true)
  }

  return (
    <div
      className="profile-picture-container"
      style={{ width: `${width}rem`, minWidth: `${width}rem`, height: `${height}rem` }}
    >
      {imageError || !avatarUrl || !showImage ? (
        <div className="name-wrapper" style={{ backgroundColor: bgColor ?? null }}>
          <Typography
            variant="xs"
            fontSize={fontSize}
            font={fontWeight}
            label={`${firstname?.charAt(0).toUpperCase()}${lastname?.charAt(0).toUpperCase()}`}
          />
        </div>
      ) : (
        <img src={avatarUrl} alt={firstname} className="profile-picture" onError={onImageError} />
      )}
    </div>
  )
}

ProfilePicture.propTypes = {
  avatarUrl: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  showImage: PropTypes.bool,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.string,
  bgColor: PropTypes.string,
}

ProfilePicture.defaultProps = {
  avatarUrl: '',
  firstname: '',
  lastname: '',
  width: 3.0769230, // 40px
  height: 3.0769230, // 40px
  showImage: true,
  fontSize: PX_TO_REM['14'], // 14px
  fontWeight: 'medium',
  bgColor: null,
}

export default ProfilePicture
