import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import { PX_TO_REM } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import Modal from '../../../../components/Modal'

import { ReactComponent as InfoSvg } from '../../../../svg/info.svg'

import './index.scss'

const InfoModal = ({ labelText, modalText }) => {
  const [showModal, setShowModal] = useState(false)

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      setShowModal(false)
    }
  }

  const openModal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowModal(true)
  }

  return (
    <div className="info-modal">
      <Tooltip
        className="info-tooltip"
        PopperProps={{
          disablePortal: true,
        }}
        title={Translation.click_to_view_explanation}
        placement="top"
        arrow
      >
        <button type="button" className="info-modal-icon" onClick={openModal}>
          <InfoSvg />
        </button>
      </Tooltip>
      <Modal
        isShown={showModal}
        size="xl"
        top="40%"
        onClickCloseIcon={() => { setShowModal(false) }}
        onClickEscClose={() => { setShowModal(false) }}
        onClickOutside={onClickOutside}
      >
        <div className="info-modal-title">
          <Typography variant="h2" fontSize={PX_TO_REM['24']} font="semibold" label={labelText} />
        </div>
        <div className="info-modal-text">
          <Typography variant="xs" label={modalText} containsHtml />
        </div>
      </Modal>
    </div>
  )
}

InfoModal.propTypes = {
  labelText: PropTypes.string,
  modalText: PropTypes.string,
}

InfoModal.defaultProps = {
  labelText: '',
  modalText: '',
}

export default InfoModal
