import React from 'react'
import PropTypes from 'prop-types'

import { getVariantClass, getFontClass, getThemeClass } from '../../helpers/Fonts'

import './index.scss'

const getClassName = (variant = '', theme = '', font = '', underline = '') => {
  let className = `a ${getVariantClass(variant)}`
  if (theme) className += ` ${getThemeClass(theme)}`
  if (font) className += ` ${getFontClass(font)}`
  if (underline) className += ' a--underline'
  return className
}

const Link = ({
  id,
  href,
  variant,
  theme,
  label,
  underline,
  font,
  lineHeight,
  isNewTab,
  onClick,
}) => (
  <a
    id={id}
    href={href}
    className={getClassName(variant, theme, font, underline)}
    style={{ lineHeight }}
    target={isNewTab ? '_blank' : null}
    rel={isNewTab ? 'noreferrer' : null}
    onClick={onClick}
  >
    {label}
  </a>
)

Link.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(['', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'caption', 'xs', 'subtitle', 's', 'title', 'xl']),
  theme: PropTypes.oneOf(['', 'light', 'dark']),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  underline: PropTypes.bool,
  font: PropTypes.oneOf(['', 'italic', 'medium', 'semibold']),
  lineHeight: PropTypes.number,
  isNewTab: PropTypes.bool,
  onClick: PropTypes.func,
}

Link.defaultProps = {
  id: '',
  href: '#',
  variant: '',
  theme: '',
  label: 'Link',
  underline: false,
  font: '',
  lineHeight: null,
  isNewTab: false,
  onClick: () => { },
}

export default Link
