import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import Typography from '../../../../components/Typography'
import Switch from '../../../../components/Switch'

import InfoModal from '../InfoModal'

import './index.scss'

const ColumnSwitch = ({
  id,
  labelText,
  modalText,
  onChange,
  badgeText,
  defaultChecked,
  disabled,
  tooltipText,
}) => {
  const handleChange = (actionId, value) => {
    onChange(actionId, value)
  }

  return (
    <div className="column-switch">
      {
        tooltipText ? (
          <Tooltip
            arrow
            className="info-tooltip w-max-314"
            PopperProps={{
              disablePortal: true,
            }}
            placement="top"
            disableFocusListener
            disableTouchListener
            title={tooltipText}
          >
            <label htmlFor={id} className="column-switch-label" style={{ cursor: disabled ? 'default' : 'pointer' }}>
              <Switch
                id={id}
                onChange={(value) => handleChange(id, value)}
                checked={defaultChecked}
                disabled={disabled}
              />
              <Typography variant="xs" label={labelText} />
              {
                badgeText && <span className="price-badge">{badgeText}</span>
              }
            </label>
          </Tooltip>
        ) : (
          <label htmlFor={id} className="column-switch-label" style={{ cursor: disabled ? 'default' : 'pointer' }}>
            <Switch
              id={id}
              onChange={(value) => handleChange(id, value)}
              checked={defaultChecked}
              disabled={disabled}
            />
            <Typography variant="xs" label={labelText} />
            {
              badgeText && <span className="price-badge">{badgeText}</span>
            }
          </label>
        )
      }
      {
        modalText && <InfoModal labelText={labelText} modalText={modalText} />
      }
    </div>
  )
}

ColumnSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  modalText: PropTypes.string,
  onChange: PropTypes.func,
  badgeText: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string,
}

ColumnSwitch.defaultProps = {
  labelText: '',
  modalText: '',
  onChange: () => { },
  badgeText: '',
  defaultChecked: false,
  disabled: false,
  tooltipText: '',
}

export default ColumnSwitch
