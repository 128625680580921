import React from 'react'
import { useStoreState } from 'easy-peasy'

import { Translation } from '../../helpers/Translation'
import { DEFAULT_PAGE, PX_TO_REM } from '../../helpers/Constants'
import { isAuthenticated } from '../../helpers/Utils'

import Typography from '../../components/Typography'

import Footer from '../../layouts/Footer'

import { ReactComponent as ErrorInfoIcon } from '../../svg/circle-info-js-error.svg'
import { ReactComponent as LogoSvg } from '../../svg/logo.svg'

import './index.scss'

const ErrorJs = () => {
  // init all component actions
  const isMobile = useStoreState((state) => state.layout.isMobile)

  const handleLogoClick = () => {
    window.location.href = DEFAULT_PAGE
  }

  return (
    <div className="error-page">
      <div className="error--wrap">
        {
          isAuthenticated && (
            <div className="header-logo--wrap">
              <div className="header-logo">
                <div
                  onClick={handleLogoClick}
                  id="logo-link"
                >
                  <LogoSvg className="header-logo--icon" />
                </div>
              </div>
            </div>
          )
        }
        <div className="screen-center">
          <div className="width-100vw">
            <div>
              <ErrorInfoIcon />
            </div>

            <div className={`${isMobile ? 'm-top-20' : 'm-top-24'} error-title`}>
              <Typography
                variant="h2"
                font="medium"
                label={Translation.error_js}
                fontSize={isMobile ? PX_TO_REM['35'] : null}
                lineHeight={isMobile ? PX_TO_REM['44'] : PX_TO_REM['72']}
              />
            </div>

            <div className={`${isMobile ? 'm-top-10' : 'm-top-24'} error-descr`}>
              <Typography
                variant="h6"
                label={Translation.error_js_description}
                fontSize={isMobile ? PX_TO_REM['16'] : null}
                lineHeight={isMobile ? PX_TO_REM['22'] : PX_TO_REM['25']}
              />
            </div>
          </div>
        </div>

        <div className="m-top-auto">
          <Footer isNewTab />
        </div>
      </div>
    </div>
  )
}

ErrorJs.propTypes = {}

ErrorJs.defaultProps = {}

export default ErrorJs
