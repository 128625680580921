import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const getTextareaClassName = (isError = false) => {
  let className = 'textarea'
  if (isError) className += ' textarea--error'
  return className
}

const Textarea = ({
  name,
  label,
  error,
  minLength,
  isError,
  pattern,
  placeholder,
  value,
  textareaRef,
  width,
  height,
  onChange,
  onBlur,
  onKeyUp,
}) => (
  <div
    className="textarea--wrap"
    style={{
      width: /^\d+(\.\d+)?$/.test(width) ? `${width}rem` : width,
    }}
  >
    {
      (label) ? (
        <div className="textarea__label">
          {label}
        </div>
      ) : null
    }
    <div
      style={{
        height: /^\d+(\.\d+)?$/.test(height) ? `${height}rem` : height,
      }}
    >
      <textarea
        name={name}
        value={`${value === null ? '' : value}`}
        pattern={pattern}
        placeholder={placeholder}
        className={getTextareaClassName(isError)}
        ref={textareaRef}
        style={{
          width: /^\d+(\.\d+)?$/.test(width) ? `${width}rem` : width,
          height: /^\d+(\.\d+)?$/.test(height) ? `${height}rem` : height,
        }}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
      />
    </div>
    {
      (isError && error) ? (
        <div className="textarea__error">
          {
              error.replace(/%MIN_LENGTH%/g, (minLength) ? `${minLength}` : '')
          }
        </div>
      ) : null
    }
  </div>
)

Textarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  minLength: PropTypes.number,
  isError: PropTypes.bool,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  textareaRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
}

Textarea.defaultProps = {
  name: '',
  label: '',
  error: '',
  minLength: 0,
  isError: false,
  pattern: '',
  placeholder: '',
  value: '',
  textareaRef: null,
  width: '',
  height: '',
  onChange: () => { },
  onBlur: () => { },
  onKeyUp: () => { },
}

export default Textarea
