import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'

import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

import { Translation } from '../../../helpers/Translation'
import {
  DESKTOP_PX_TO_REM, DESKTOP_TABLE_WIDTH,
  FIELDS_PATTERNS,
  PAYMENT_METHOD_STATUS,
  PAYMENT_MODAL_TYPE, PX_TO_REM,
  TABLES,
} from '../../../helpers/Constants'
import {
  getCardInfo,
  getPerPage,
  isValidValue,
  savePerPage,
  textDebounce,
  localizeDate,
  isClientAdminRole,
  clickToDownload,
} from '../../../helpers/Utils'

import Typography from '../../../components/Typography'
import Input from '../../../components/Input'
import Table from '../../../components/Table'

import TableTopInfo from '../../../layouts/Common/TableTopInfo'
import MobileView from '../../../layouts/Common/MobileView'
import ManagePaymentMethods from '../../../layouts/Common/ManagePaymentMethods'
import SepaInfoModal from '../../../layouts/Common/SepaInfoModal'
import BillingAddress from '../../../layouts/Common/BillingAddress'

import UnsavedChangesDialog from '../Common/UnsavedChangesDialog'

import { ReactComponent as HomeIcon } from '../../../svg/home.svg'
import { ReactComponent as EditIcon } from '../../../svg/edit_with_eraser.svg'
import { ReactComponent as PaymentIcon } from '../../../svg/payment.svg'
import { ReactComponent as SearchIcon } from '../../../svg/search-lg.svg'
import { ReactComponent as CheckIcon } from '../../../svg/check.svg'
import { ReactComponent as InfoIcon } from '../../../svg/info.svg'
import { ReactComponent as DownloadIcon } from '../../../svg/download.svg'
import { ReactComponent as WarningIcon } from '../../../svg/warning.svg'

import './index.scss'

const Billing = () => {
  const navigate = useNavigate()

  const user = useStoreState((state) => state.user.user)
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const unpaidDetails = useStoreState((state) => state.invoice.unpaidDetails)
  const invoices = useStoreState((state) => state.invoice.invoices)
  const invoiceCount = useStoreState((state) => state.invoice.invoiceCount)
  const paymentMethods = useStoreState((state) => state.invoice.paymentMethods)
  const userAddress = useStoreState((state) => state.user.userAddress)
  const countries = useStoreState((state) => state.user.countries)
  const getInvoices = useStoreActions((state) => state.invoice.getInvoices)
  const addInvoices = useStoreActions((state) => state.invoice.addInvoices)
  const getPaymentMethods = useStoreActions((state) => state.invoice.getPaymentMethods)
  const getUserAddress = useStoreActions((state) => state.user.getUserAddress)
  const getCountries = useStoreActions((state) => state.user.getCountries)
  const downloadInvoice = useStoreActions((actions) => actions.invoice.downloadInvoice)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)

  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false)
  const [navigateTo, setNavigateTo] = useState(null)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [primaryMethod, setPrimaryMethod] = useState(null)
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true)
  const [searchInvoiceNumber, setSearchInvoiceNumber] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(getPerPage(TABLES.BILLING))
  const [page, setPage] = useState(1)
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [isPaymentMethodsModalOpen, setIsPaymentMethodsModalOpen] = useState(false)
  const [paymentModalState, setPaymentModalState] = useState(PAYMENT_MODAL_TYPE.editList)
  const [isSepaInformationModalOpen, setIsSepaInformationModalOpen] = useState(false)
  const [sepaInfo, setSepaInfo] = useState(null)
  const debouncedNumber = textDebounce(searchInvoiceNumber, 500)

  const getTableData = () => {
    getInvoices({ page, limit: rowsPerPage, query: searchInvoiceNumber })
  }

  useEffect(() => {
    getUserAddress()
    getCountries()
    const fn = async () => {
      setPaymentMethodsLoading(true)
      await getPaymentMethods()
      setPaymentMethodsLoading(false)
    }
    fn().then(() => { })
  }, [])

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setPrimaryMethod(paymentMethods.find((m) => m.primary) || null)
    }
  }, [paymentMethods])

  useEffect(() => {
    if (debouncedNumber.length > 2 || debouncedNumber.length === 0) {
      getTableData()
      savePerPage(TABLES.BILLING, rowsPerPage)
    }
  }, [page, rowsPerPage, debouncedNumber])

  const handleInvoiceNumberChange = (e) => {
    const input = e.currentTarget
    if (!input.value) {
      addInvoices(null)
    }
    if (!input.value || isValidValue(input.value, FIELDS_PATTERNS.onlyNumber)) {
      setSearchInvoiceNumber(e.target.value)
    }
  }

  const colWidth = [160, 250, 250, 150, 180, 180]
  // last column width is calculated as the rest of available table width
  colWidth.push(DESKTOP_TABLE_WIDTH - colWidth.reduce((a, b) => a + b))

  const tableColumns = [
    {
      id: 'invoiceDate',
      label: Translation.billing_date,
      sortable: false,
      width: colWidth[0] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'invoice_number',
      render: 'jsx',
      label: Translation.invoice_number,
      sortable: false,
      width: colWidth[1] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'amount_formatted',
      render: 'jsx',
      label: Translation.amount,
      sortable: false,
      width: colWidth[2] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'imageCount',
      label: Translation.image_count,
      sortable: false,
      width: colWidth[3] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'orderCount',
      label: Translation.orders,
      sortable: false,
      width: colWidth[4] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'statusChip',
      render: 'jsx',
      label: null,
      sortable: false,
      align: 'center',
      width: colWidth[5] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'download_column',
      render: 'jsx',
      label: null,
      sortable: false,
      width: colWidth[6] / DESKTOP_PX_TO_REM,
    },
  ]

  const handleClickManagePayment = () => {
    setPaymentModalState(paymentMethods.length === 0 ? PAYMENT_MODAL_TYPE.form : PAYMENT_MODAL_TYPE.editList)
    setIsPaymentMethodsModalOpen(true)
  }

  const onRowsPerPageChange = (value) => {
    setRowsPerPage(value)
  }

  const onPageChange = (value) => {
    setPage(value)
  }

  const statusChip = (invoice) => (
    <Tooltip
      arrow
      className="info-tooltip"
      PopperProps={{
        disablePortal: true,
      }}
      placement="top"
      disableFocusListener
      {...(!invoice.ancestorId) && { disableHoverListener: true }}
      disableTouchListener
      title={`${Translation.corrected_invoice} ${invoice.ancestorId}`}
    >
      <Chip
        className={
          // eslint-disable-next-line no-nested-ternary
          `status-chip ${invoice.ancestorId ? 'chip-corrected'
            // eslint-disable-next-line no-nested-ternary
            : invoice.paid ? 'chip-paid' : invoice.processing ? 'chip-processing' : 'chip-unpaid'}`
        }
        label={(
          <>
            {invoice.paid && !invoice.ancestorId && (<CheckIcon />)}
            {invoice.ancestorId && (<InfoIcon />)}
            <Typography
              variant="body1"
              font="medium"
              label={
                // eslint-disable-next-line no-nested-ternary
                invoice.ancestorId
                  ? Translation.corrected
                  // eslint-disable-next-line no-nested-ternary
                  : (invoice.paid ? Translation.paid : invoice.processing ? Translation.processing : Translation.unpaid)
              }
            />
          </>
        )}
      />
    </Tooltip>
  )

  const handleDownload = async (invoiceId) => {
    const result = await downloadInvoice({ id: invoiceId })
    if (result === false) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.something_went_wrong,
        type: 'error',
      })
      return
    }

    if (result.success) {
      clickToDownload(result.invoice_url)
    }
  }

  const transformTableData = () => {
    const tempData = []

    invoices.forEach((item) => {
      tempData.push({
        ...item,
        invoiceDate: localizeDate(item.invoiceDate, false),
        invoice_number: (
          <Typography
            variant="xs-narrow"
            label={`${Translation.invoice} ${item.id}`}
            style={{ cursor: 'default' }}
          />
        ),
        amount_formatted: (
          <Typography
            variant="xs-narrow"
            label={`${item.total}${item.currencySign}`}
            style={{ cursor: 'default' }}
          />
        ),
        statusChip: statusChip(item),
        download_column: (
          item.download && (
            <Tooltip
              arrow
              className="info-tooltip"
              PopperProps={{
                disablePortal: true,
              }}
              placement="top"
              disableFocusListener
              disableTouchListener
              title={Translation.download_invoice}
            >
              <button type="button" className="table-row-cell-download" onClick={() => handleDownload(item.id)}>
                <DownloadIcon />
              </button>
            </Tooltip>
          )
        ),
      })
    })

    if (tempData.length === 0) {
      tempData.push({
        id: 0,
        emptyDataText: Translation.you_have_no_invoices,
      })
    }

    return tempData
  }

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      setIsSepaInformationModalOpen(false)
      setSepaInfo(null)
      setIsPaymentMethodsModalOpen(true)
    }
  }

  const handleCloseInfoModal = () => {
    setSepaInfo(null)
    setIsSepaInformationModalOpen(false)
    setIsPaymentMethodsModalOpen(true)
  }

  const handleSepaInfoModal = (sepa) => {
    if (sepa) {
      setSepaInfo(sepa)
      setIsPaymentMethodsModalOpen(false)
      setIsSepaInformationModalOpen(true)
    }
  }

  const handleSureToLeave = () => {
    if (navigateTo) {
      navigate(navigateTo)
      setNavigateTo(null)
    }
    setIsPaymentMethodsModalOpen(false)
    setLeaveDialogOpen(false)
    setIsFormChanged(false)
  }

  const handleClickCancel = () => {
    setLeaveDialogOpen(false)
    setNavigateTo(null)
    setIsPaymentMethodsModalOpen(true)
  }

  // eslint-disable-next-line no-nested-ternary
  return !isMobile ? (
    <div className="billing-section">
      <TableTopInfo
        chipText={`${invoiceCount} ${invoiceCount > 1 ? Translation.invoices : Translation.invoice}`}
        title={Translation.billing_settings_invoices}
        titleFontSize={PX_TO_REM['16']}
        description={Translation.below_you_can_edit_your_payment}
      />

      <div className="top-boxes">
        <div className="box flex-start">
          <div className="box-title">
            <HomeIcon className="box-icon" />
            <Typography
              variant="s"
              theme="dark"
              font="semibold"
              lineHeight={PX_TO_REM['20']}
              label={Translation.billing_address}
            />
          </div>
          {userAddress.id
            && (
              <>
                <Typography
                  variant="xs"
                  containsHtml
                  lineHeight={PX_TO_REM['18']}
                  label={userAddress.company}
                />
                <Typography
                  variant="xs"
                  containsHtml
                  lineHeight={PX_TO_REM['18']}
                  label={`${userAddress.address1} ${userAddress.address2}`}
                />
                <Typography
                  variant="xs"
                  containsHtml
                  lineHeight={PX_TO_REM['18']}
                  label={`${userAddress.zip} ${userAddress.city}`}
                />
                <Typography
                  variant="xs"
                  containsHtml
                  lineHeight={PX_TO_REM['18']}
                  label={countries?.[userAddress.country]?.name}
                />
                {
                  userAddress.ust_id && (
                  <Typography
                    variant="xs"
                    containsHtml
                    lineHeight={PX_TO_REM['18']}
                    label={userAddress.ust_id}
                  />
                  )
                }
              </>
            )}
          {isClientAdminRole(user?.role_after_login) && (
            <div className="edit-btn-container">
              <Tooltip
                arrow
                className="info-tooltip"
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                disableFocusListener
                disableTouchListener
                title={Translation.edit_billing_address}
              >
                <button
                  type="button"
                  className="edit-button"
                  onClick={() => setIsAddressModalOpen(true)}
                >
                  <EditIcon />
                </button>
              </Tooltip>
            </div>
          )}
        </div>

        <div className="box">
          <div className="box-title">
            <PaymentIcon className="box-icon" />
            <Typography
              variant="s"
              theme="dark"
              font="semibold"
              lineHeight={PX_TO_REM['20']}
              label={Translation.current_payment_method}
            />
            {isClientAdminRole(user?.role_after_login) && !paymentMethodsLoading && (
              <button
                type="button"
                className="payment-methods-button"
                onClick={handleClickManagePayment}
              >
                <Typography
                  variant="xs"
                  font="semibold"
                  theme="dark"
                  label={
                    `+ ${paymentMethods.length > 0
                      ? Translation.manage_payment_methods
                      : Translation.add_payment_methods}`
                  }
                />
              </button>
            )}
          </div>

          {!paymentMethodsLoading && (
            paymentMethods.length > 0 && primaryMethod && primaryMethod.id ? (
              <div className="payment-method-box">
                <div className="left">
                  <div className="icon-wrapper">
                    {getCardInfo(primaryMethod).icon}
                  </div>
                  <div className="card-info">
                    <Typography
                      variant="xs"
                      font="medium"
                      theme="dark"
                      lineHeight={PX_TO_REM['17']}
                      label={getCardInfo(primaryMethod).cardNameNumber}
                    />
                    {primaryMethod.iban && (
                      <Typography
                        variant="xs"
                        label={primaryMethod.iban}
                      />
                    )}
                  </div>
                </div>
                <div className="right">
                  <Typography
                    variant="xs"
                    font="semibold"
                    theme="dark"
                    lineHeight={PX_TO_REM['17']}
                    label={Translation.primary}
                  />

                  {primaryMethod.status === PAYMENT_METHOD_STATUS.deactivated && (
                  <Tooltip
                    arrow
                    className="info-tooltip deactivated"
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    title={(
                      <div>
                        {Translation.payment_method_deactivated.split('\n').map((line, key) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={key}>
                            <Typography
                              variant="body1"
                              label={line}
                              containsHtml
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  >
                    <WarningIcon className="warning-icon" />
                  </Tooltip>
                  )}

                </div>
              </div>
            ) : (
              <div className="no-method-box">
                <Typography
                  variant="s"
                  theme="dark"
                  font="semibold"
                  label={Translation.no_payment_method}
                />
              </div>
            )
          )}

        </div>
      </div>

      {(invoiceCount > 0 || searchInvoiceNumber) && (
        <div className="search-row">
          <div className="input-container">
            <Input
              type="text"
              name="search-invoice-number"
              onChange={handleInvoiceNumberChange}
              placeholder={Translation.invoice_number}
              value={searchInvoiceNumber}
              startAdornment={<SearchIcon />}
            />
          </div>
        </div>
      )}

      <div className="table-container">
        {invoices && (
          <Table
            columns={tableColumns}
            cellHeight="small"
            count={invoiceCount}
            data={transformTableData()}
            emptyDataText={searchInvoiceNumber ? 'no_invoices_with_number' : 'you_have_no_invoices'}
            onPageChange={onPageChange}
            rowsPerPageValue={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            scrollable={false}
          />
        )}
      </div>
      {isAddressModalOpen && (
        <div
          className="billing-address-modal"
          style={{ display: isAddressModalOpen ? 'block' : 'none' }}
        >
          <BillingAddress
            isOpen={isAddressModalOpen}
            closeModal={() => setIsAddressModalOpen(false)}
            modalTitle={Translation.update_billing_address}
          />
        </div>
      )}
      <div
        className="payment-methods-modal"
        style={{ display: isPaymentMethodsModalOpen ? 'block' : 'none' }}
      >
        <ManagePaymentMethods
          paymentModalState={paymentModalState}
          setPaymentModalState={setPaymentModalState}
          isOpen={isPaymentMethodsModalOpen}
          closeModal={() => setIsPaymentMethodsModalOpen(false)}
          openSepaInfoModal={handleSepaInfoModal}
          isFormChanged={isFormChanged}
          setIsFormChanged={setIsFormChanged}
          leaveDialogOpen={leaveDialogOpen}
          setLeaveDialogOpen={setLeaveDialogOpen}
          setNavigateTo={setNavigateTo}
        />
      </div>
      <div style={{ display: isSepaInformationModalOpen ? 'block' : 'none' }}>
        <SepaInfoModal
          handleCloseInfoModal={handleCloseInfoModal}
          isSepaInformationModalOpen={isSepaInformationModalOpen}
          onClickOutside={onClickOutside}
          sepaInfo={sepaInfo}
        />
      </div>
      <UnsavedChangesDialog
        leaveDialogOpen={leaveDialogOpen}
        handleLeaveApprove={handleSureToLeave}
        handleClickCancel={handleClickCancel}
      />
    </div>
  ) : (
    unpaidDetails?.final_price_no_format > 0 ? (
      <div className="mobile-view">
        <div className="balance-container">
          <Typography
            font="semibold"
            fontSize={PX_TO_REM['16']}
            theme="dark"
            label={Translation.outstanding_balance}
          />
          <Typography
            font="semibold"
            fontSize={PX_TO_REM['36']}
            lineHeight={PX_TO_REM['42']}
            theme="dark"
            label={`${unpaidDetails.final_price}${unpaidDetails.currency_sign}`}
          />
        </div>
        <Typography
          lineHeight={PX_TO_REM['20']}
          variant="s"
          label={Translation.to_access_billing_and_pay}
        />
      </div>
    ) : (
      <MobileView label={Translation.to_access_billing} />
    )
  )
}

export default Billing
