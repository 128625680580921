import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import { PX_TO_REM } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import { getGalleryUrl } from '../../../../helpers/Utils'

import Typography from '../../../../components/Typography'
import Button from '../../../../components/Button'
import Link from '../../../../components/Link'

import { ReactComponent as DownloadSvg } from '../../../../svg/download.svg'
import { ReactComponent as UploadArrowSvg } from '../../../../svg/upload_arrow.svg'

import './index.scss'

const OrderDetailsDownload = ({ orderDetails, isLargeView }) => (
  <div className="imagesDetail-download">
    <div className="imagesDetail-download-title">
      <Typography
        variant="xs-narrow"
        label={
          `${orderDetails.input_count + orderDetails.output_count === 1 ? Translation.Image : Translation.Images
          }: ${orderDetails.input_count + orderDetails.output_count}`
        }
        font="semibold"
      />
    </div>
    <div className="imagesDetail-download-subtitle">
      <DownloadSvg />
      <Typography
        label={orderDetails.input_count > 0 ? (
          <Link
            href={getGalleryUrl(orderDetails.id, false, isLargeView)}
            label={
              `${orderDetails.input_count} ${orderDetails.input_count === 1
                ? Translation.original_image
                : Translation.original_images}`
            }
            isNewTab
          />
        ) : `${orderDetails.input_count} ${orderDetails.input_count === 1
          ? Translation.original_image
          : Translation.original_images}`}
      />
    </div>
    <div className="imagesDetail-download-subtitle">
      <UploadArrowSvg />
      <Typography
        label={orderDetails.output_count > 0 ? (
          <Link
            href={getGalleryUrl(orderDetails.id, true, isLargeView)}
            label={
              `${orderDetails.output_count} ${orderDetails.output_count === 1
                ? Translation.edited_image
                : Translation.edited_images}`
            }
            isNewTab
          />
        ) : `${orderDetails.output_count} ${orderDetails.output_count === 1
          ? Translation.edited_image
          : Translation.edited_images}`}
      />
    </div>
    {
      (orderDetails?.test_image_status === 1 && orderDetails?.test_image_download_link) && (
        <div className="imagesDetail-download-testButton">
          <a href={orderDetails?.test_image_download_link} download>
            <Button
              width="auto"
              label={(
                <>
                  <DownloadSvg />
                  {Translation.download_test_image}
                </>
              )}
            />
          </a>
        </div>
      )
    }
    {
      orderDetails?.order_download_links?.length > 0 && (
        <div className="imagesDetail-download-buttons">
          {
            orderDetails?.order_download_links?.map((item, index) => (
              (orderDetails?.order_download_links?.length > 2) ? (
                <Tooltip
                  className="info-tooltip"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={`${index + 1}. ${Translation.download_images}`}
                  placement="top"
                  arrow
                >
                  <a key={item} href={item} download className="a-no-border">
                    <Button
                      width={(index === 0) ? '15.230769' : 'auto'}
                      label={(
                        <>
                          <DownloadSvg />
                          {
                            (index === 0) ? `${index + 1}. ${Translation.download_images}` : `${index + 1}.`
                          }
                        </>
                      )}
                    />
                  </a>
                </Tooltip>
              ) : (
                <a key={item} href={item} download className="a-no-border">
                  <Button
                    width="auto"
                    label={(
                      <>
                        <DownloadSvg />
                        {`${(orderDetails?.order_download_links?.length === 1) ? '' : `${index + 1}. `}
                        ${Translation.download_images}`}
                      </>
                    )}
                  />
                </a>
              )
            ))
          }
        </div>
      )
    }
    {
      orderDetails?.order_download_notice || orderDetails?.download_days_left ? (
        <div className="imagesDetail-download-infoText">
          <Typography
            variant="body1"
            lineHeight={PX_TO_REM['15']}
            label={
              (orderDetails?.order_download_notice === '' || orderDetails?.order_download_notice === null)
                ? `${orderDetails?.download_days_left} ${Translation.days_to_download_this_order}`
                : orderDetails?.order_download_notice
            }
            containsHtml
          />
        </div>
      ) : null
    }
    {
      orderDetails?.is_show_request_ftp_notice ? (
        <div className="imagesDetail-download-infoText">
          <Typography label={Translation.images_download_ftp_text} containsHtml />
        </div>
      ) : null
    }
  </div>
)

OrderDetailsDownload.propTypes = {
  orderDetails: PropTypes.shape({
    id: PropTypes.number,
    input_count: PropTypes.number,
    output_count: PropTypes.number,
    is_show_request_ftp_notice: PropTypes.bool,
    order_download_notice: PropTypes.string,
    download_days_left: PropTypes.number,
    image_test_s3: PropTypes.string,
    test_image_status: PropTypes.number,
    test_image_download_link: PropTypes.string,
    order_download_link: PropTypes.string,
    order_download_links: PropTypes.arrayOf(PropTypes.string),
  }),
  isLargeView: PropTypes.bool,
}

OrderDetailsDownload.defaultProps = {
  orderDetails: {},
  isLargeView: false,
}

export default OrderDetailsDownload
