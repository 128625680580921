import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { BILLING_PAGE } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import { isClientAdminRole } from '../../helpers/Utils'

import Button from '../../components/Button'

import MyDetails from './MyDetails'
import UserManagement from './UserManagement'
import Password from './Password'
import Billing from './Billing'
import PublicGalleryUsers from './PublicGalleryUsers'

import { ReactComponent as InfoIcon } from '../../svg/info_circled.svg'

import './index.scss'

const Profile = () => {
  const navigate = useNavigate()
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const user = useStoreState((state) => state.user.user)
  const unpaidDetails = useStoreState((state) => state.invoice.unpaidDetails)
  const updateHorizontalScroll = useStoreActions((actions) => actions.layout.updateHorizontalScroll)

  const [activeTab, setActiveTab] = React.useState(window.location.hash || '#my-details')

  const profilePageTabs = [
    {
      id: 1,
      name: Translation.profile_page_titles.my_details,
      href: '/profile#my-details',
      onlyAdmin: false,
      isBilling: false,
      isPublicGallery: true,
    },
    {
      id: 2,
      name: Translation.profile_page_titles.user_management,
      href: '/profile#user-management',
      onlyAdmin: true,
      isBilling: false,
      isPublicGallery: true,
    },
    {
      id: 3,
      name: Translation.profile_page_titles.public_gallery_users,
      href: '/profile#public-gallery-users',
      onlyAdmin: false,
      isBilling: false,
      isPublicGallery: user.is_public_gallery_client,
    },
    {
      id: 4,
      name: Translation.profile_page_titles.password,
      href: '/profile#password',
      onlyAdmin: false,
      isBilling: false,
      isPublicGallery: true,
    },
    {
      id: 5,
      name: Translation.profile_page_titles.billing,
      href: BILLING_PAGE,
      onlyAdmin: false,
      isBilling: true,
      isPublicGallery: true,
    },
  ]

  useEffect(() => {
    if (window.location.hash === '#user-management'
        && user && Object.keys(user).length !== 0 && !isClientAdminRole(user?.role_after_login)) {
      navigate('/profile#my-details')
    }
  }, [user])

  useEffect(() => {
    setActiveTab(window.location.hash || '#my-details')

    if (window.location.hash === '#user-management' || window.location.hash === '#billing') {
      updateHorizontalScroll(true)
    } else {
      updateHorizontalScroll(false)
    }

    if (window.location.hash === '#user-management'
        && user && Object.keys(user).length !== 0 && !isClientAdminRole(user?.role_after_login)) {
      navigate('/profile#my-details')
    }
    if (window.location.hash === '#billing'
        && user && Object.keys(user).length !== 0 && user.billing_info === 0) {
      navigate('/profile#my-details')
    }
  }, [window.location.hash])

  return Object.keys(user).length > 1 && (
    <>
      <div className="profile-page-tabs">
        {profilePageTabs
          .filter((tab) => !tab.onlyAdmin || isClientAdminRole(user?.role_after_login))
          .filter((tab) => !tab.isBilling || user.billing_info === 1)
          .filter((tab) => tab.isPublicGallery)
          .map((tab) => (
            <div
              key={tab.id}
              className={`button-container ${tab.href.includes(activeTab) ? 'active' : ''}`}
            >
              {
                isMobile
                && tab.isBilling
                && activeTab !== '#billing'
                && unpaidDetails?.final_price_no_format > 0
                && <InfoIcon className="info-icon" />
              }
              <Button
                size="xl"
                type="fit-content"
                label={tab.name}
                onClick={() => navigate(tab.href)}
              />
            </div>
          ))}
      </div>
      {activeTab === '#my-details' && <MyDetails />}
      {activeTab === '#user-management' && <UserManagement />}
      {activeTab === '#public-gallery-users' && <PublicGalleryUsers />}
      {activeTab === '#password' && <Password />}
      {activeTab === '#billing' && <Billing />}
    </>
  )
}

export default Profile
