export const defaultGalleryData = {
  template_id: null,
  name: 'Public gallery name',
  domain_path: null,
  password: null,
  logo_client: null,
  logo_public: null,
  theme: 'dark',
  filename: true,
  folder: true,
  sizes: true,
  colour_space: true,
  background_color: true,
  layers: true,
  paths: true,
  grids: true,
  comments: true,
  ps_guides: true,
  bg: true,
  border: true,
  compare: true,
  grid: true,
  small: true,
  middle: true,
  original: true,
  fullscreen: true,
  list: true,
  approve_button: true,
  redo_button: true,
  shapes: true,
  redos_to: 'qa',
  free_redos_counter: 3,
  approve_to: 'public',
  redo_notification: true,
  approve_notification: true,
  notification_log: true,
  separate_download_button: true,
  separate_download_availability: 'after',
  all_download_button: true,
  all_download_availability: 'after',
  ftp: true,
  ftp_host: null,
  ftp_username: null,
  ftp_password: null,
  ftp_port: 21,
  ftp_path: null,
}
