import React, { useEffect } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../helpers/Translation'
import { FTP_STATUS } from '../../helpers/Constants'

import MobileView from '../../layouts/Common/MobileView'

import NotRequestedView from './NotRequestedView'
import RequestRequestedView from './RequestRequestedView'
import FtpConnectionDenied from './FtpConnectionDenied'
import TableView from './TableView'

import './index.scss'

const Ftp = () => {
  const ftpInfo = useStoreState((state) => state.ftp.ftpInfo)
  const getFtpInfo = useStoreActions((actions) => actions.ftp.getFtpInfo)
  const isMobile = useStoreState((state) => state.layout.isMobile)

  useEffect(() => {
    getFtpInfo()
  }, [])

  const renderPageContent = () => {
    switch (ftpInfo.user_ftp_request_status) {
      case FTP_STATUS.requestDone:
      case FTP_STATUS.requestGranted:
        return <TableView />
      case FTP_STATUS.requestRequested:
        return <RequestRequestedView />
      case FTP_STATUS.notRequested:
        return <NotRequestedView />
      case FTP_STATUS.requestDenied:
        return <FtpConnectionDenied />
      default:
        return null
    }
  }

  return isMobile ? (
    <MobileView label={Translation.to_access_ftp_switch_desktop} />
  ) : (
    <div className="ftp-desktop-view">
      {renderPageContent()}
    </div>
  )
}

export default Ftp
