import { action, thunk } from 'easy-peasy'

import { Translation } from '../helpers/Translation'

const GlobalStore = {
  requestCount: 0,

  snackbarState: {
    isOpen: false,
    message: '',
    type: 'success',
  },

  backdropState: {
    isOpen: false,
    message: Translation.updating,
  },

  increaseCounter: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.requestCount += 1
  }),

  decreaseCounter: action((state) => {
    // eslint-disable-next-line no-param-reassign
    if (state.requestCount > 0) state.requestCount -= 1
  }),

  updateSnackbarState: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.snackbarState = { ...state.snackbarState, ...payload }
  }),

  updateBackdropState: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.backdropState = { ...state.backdropState, ...payload }
  }),

  fetchCurrentReleaseVersion: thunk(async () => {
    try {
      const response = await fetch(process.env.REACT_APP_VERSION_JSON)
      return await response.json()
    } catch (error) {
      return false
    }
  }),
}

export default GlobalStore
