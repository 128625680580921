import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

import Checkbox from '../../../components/Checkbox'

import { Translation } from '../../../helpers/Translation'

const ChecboxTooltip = ({ checked }) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={Translation.workflow_table_invoice_tooltip_text}
      disableHoverListener
      className="info-tooltip"
      PopperProps={{ disablePortal: true }}
      placement="top-start"
      arrow
    >
      <button type="button" className="btn-no-bg" onClick={handleOpen}>
        <Checkbox checked={checked} disabled />
      </button>
    </Tooltip>
  )
}

export default ChecboxTooltip

ChecboxTooltip.propTypes = {
  checked: PropTypes.bool.isRequired,
}
