import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions } from 'easy-peasy'
import { Tooltip } from '@mui/material'

import { PX_TO_REM, TEMPLATE_PAGE, WORKFLOW_PAGE } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import RadioGroup from '../../../../components/RadioGroup'
import Typography from '../../../../components/Typography'

import './index.scss'

const NewOrderTop = ({
  newOrderTemplate,
  isTestOrder,
  templateType,
  onOrderNameChange,
  isIframeTemplate,
}) => {
  const updateTemplateValue = useStoreActions((actions) => actions.order.updateTemplateValue)
  const getNewOrderPricing = useStoreActions((actions) => actions.order.getNewOrderPricing)
  const [orderName, setOrderName] = useState('')
  const [turnaroundTime, setTurnaroundTime] = useState('')

  const handleTurnaroundTimeChange = async (e) => {
    setTurnaroundTime(e.target.value)
    await updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'turnaround', value: e.target.value })
    if (!isIframeTemplate) {
      getNewOrderPricing({ template_id: newOrderTemplate?.template_id })
    }
  }

  useEffect(() => {
    setOrderName(newOrderTemplate?.name)
    onOrderNameChange(newOrderTemplate?.name)
    setTurnaroundTime(newOrderTemplate?.turnaround)
  }, [newOrderTemplate])

  const turnaroundValues = {
    8: (
      <>
        8h
        {
          newOrderTemplate?.turnaround_prices[8] && (
            <span className="price-badge">{newOrderTemplate?.turnaround_prices[8]}</span>
          )
        }
      </>
    ),
    12: (
      <>
        12h
        {
          newOrderTemplate?.turnaround_prices[12] && (
            <span className="price-badge">{newOrderTemplate?.turnaround_prices[12]}</span>
          )
        }
      </>
    ),
    24: (
      <>
        24h
        {
          newOrderTemplate?.turnaround_prices[24] && (
            <span className="price-badge">{newOrderTemplate?.turnaround_prices[24]}</span>
          )
        }
      </>
    ),
    48: (
      <>
        48h
        {
          newOrderTemplate?.turnaround_prices[48] && (
            <span className="price-badge">{newOrderTemplate?.turnaround_prices[48]}</span>
          )
        }
      </>
    ),
  }

  const transformData = (data) => {
    // if all disabled, we should wrap all values in the turnaroundValues object with a tooltip
    const allDisabled = ((templateType && newOrderTemplate?.is_template_disabled === true)
      || newOrderTemplate?.is_retail_ready === true)
    if (allDisabled && !isIframeTemplate) {
      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        data[key] = (
          <Tooltip
            arrow
            className="info-tooltip"
            PopperProps={{
              disablePortal: true,
            }}
            placement="left"
            disableFocusListener
            disableTouchListener
            title={Translation.disabled_for_retail}
          >
            <span className="disabled">{data[key]}</span>
          </Tooltip>
        )
      })
    } else if (isTestOrder) {
      Object.keys(data).forEach((key) => {
        if (key === '8' || key === '12') {
          // eslint-disable-next-line no-param-reassign
          data[key] = (
            <Tooltip
              arrow
              className="info-tooltip w-max-314"
              PopperProps={{
                disablePortal: true,
              }}
              placement="top"
              disableFocusListener
              disableTouchListener
              title={Translation.test_order_disabled_tooltip_text}
            >
              <span className="disabled">{data[key]}</span>
            </Tooltip>
          )
        }
      })
    }

    return data
  }

  return (
    <section className="top-info">
      <div className="top-info-box">
        <Typography
          variant="subtitle"
          font="semibold"
          lineHeight={PX_TO_REM['20']}
          label={
            // eslint-disable-next-line no-nested-ternary
            templateType
              ? (templateType === WORKFLOW_PAGE ? Translation.workflow_name : Translation.template_name)
              : Translation.order_name
          }
        />
        <input
          id="new-order-name"
          type="text"
          placeholder={Translation.new_order_name_input_placeholder}
          className="info-box-input"
          value={orderName}
          onChange={(e) => {
            setOrderName(e.target.value)
            onOrderNameChange(e.target.value)
          }}
          onBlur={() => {
            updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'name', value: orderName })
          }}
        />
        {
          !templateType && !isTestOrder && (
            <Typography variant="xs" label={Translation.new_order_name_input_bottom_text} />
          )
        }
      </div>
      <div className="top-info-box">
        <Typography variant="subtitle" font="semibold" lineHeight={PX_TO_REM['20']} label={Translation.turnaround_time} />
        <div className="info-box-turnaround">
          <RadioGroup
            name="info-box-turnaround"
            value={turnaroundTime}
            values={transformData(turnaroundValues)}
            color="gray"
            row
            disabledData={isTestOrder ? ['8', '12'] : []}
            onChange={(e) => handleTurnaroundTimeChange(e)}
            allDisabled={((templateType && newOrderTemplate?.is_template_disabled === true)
              || newOrderTemplate?.is_retail_ready === true) && !isIframeTemplate}
          />
        </div>
        <Typography
          variant="xs"
          containsHtml
          label={Translation.turnaround_time_text
            .replace('{start_h}', newOrderTemplate?.start_hour)
            .replace('{end_h}', newOrderTemplate?.eta_hours[turnaroundTime])}
        />
      </div>
    </section>
  )
}

NewOrderTop.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newOrderTemplate: PropTypes.object,
  isTestOrder: PropTypes.bool,
  templateType: PropTypes.oneOf(['', TEMPLATE_PAGE, WORKFLOW_PAGE]),
  onOrderNameChange: PropTypes.func.isRequired,
  isIframeTemplate: PropTypes.bool,
}

NewOrderTop.defaultProps = {
  newOrderTemplate: {},
  isTestOrder: false,
  templateType: '',
  isIframeTemplate: false,
}

export default NewOrderTop
