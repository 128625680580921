import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { RouterProvider } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'

import { Store } from './store/Store'
import { Router } from './router/Router'
import { enhanceAxiosInstance } from './helpers/Axios'

enhanceAxiosInstance(Store)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <StoreProvider store={Store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <RouterProvider router={Router} />
      </GoogleOAuthProvider>
    </StoreProvider>
  </React.StrictMode>,
)
