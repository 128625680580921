import { action, thunk } from 'easy-peasy'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS } from '../helpers/Urls'
import { Translation } from '../helpers/Translation'

const FtpStore = {
  apiErrors: '',
  ftpInfo: {},
  folders: null,
  folderCount: 0,
  isPasswordShown: false,
  orderFolders: null,
  isAnyOrderInPreparation: false,

  addApiErrors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = payload
  }),

  clearApiErrors: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = ''
  }),

  setFtpInfo: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.ftpInfo = payload
  }),

  setFolders: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.folders = payload
  }),

  setFolderCount: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.folderCount = payload
  }),

  setPasswordShown: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isPasswordShown = payload
  }),

  setOrderFolders: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.orderFolders = payload
  }),

  setIsAnyOrderInPreparation: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isAnyOrderInPreparation = payload
  }),

  getFtpInfo: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.ftp)

      if (response.data.result.success) {
        actions.setFtpInfo(response.data.result)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getFtpFolders: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.folders, { params: payload })

      if (response.data.result.success) {
        actions.setFolders(response.data.result.folders || [])
        actions.setFolderCount(response.data.result.folders_count)
        return true
      }

      return true
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  makeRequest: thunk(async (actions) => {
    try {
      const response = await axios.post(API_ENDPOINTS.ftp_request, { status: 1 })

      if (response.data.result.success) {
        actions.getFtpInfo()
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  resetPassword: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.ftp_password, payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  requestDone: thunk(async (actions) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.ftp_request_done)

      if (response.data.result.success) {
        actions.setPasswordShown(true)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getOrderFtpFolders: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.ftp_folders.replace('{referrer}', payload))

      if (response.data.result.success) {
        actions.setOrderFolders(response.data.result.ftp_folders || [])
        actions.setIsAnyOrderInPreparation(response.data.result.is_any_order_in_preparation)
        return true
      }

      return true
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  createOrderFtp: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.ftp_order_create, payload)

      if (response.data.result.success) {
        return response.data.result
      }

      if (response.data.result.error) {
        actions.addApiErrors(response.data.result.error)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),
}

export default FtpStore
