import React from 'react'
import PropTypes from 'prop-types'

import { PX_TO_REM } from '../../../helpers/Constants'

import Typography from '../../../components/Typography'

import { ReactComponent as DoopicIcon } from '../../../svg/logo_icon.svg'

import './index.scss'

const MobileView = ({ label }) => (
  <div className="mobile-view">
    <DoopicIcon className="doopic-logo" />
    <Typography
      theme="dark"
      lineHeight={PX_TO_REM['20']}
      variant="s"
      font="semibold"
      label={label}
    />
  </div>
)

MobileView.defaultProps = {
  label: '',
}

MobileView.propTypes = {
  label: PropTypes.string,
}

export default MobileView
