import React, { useContext } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import { ShepherdTourContext } from 'react-shepherd'
import { useNavigate } from 'react-router-dom'

// eslint-disable-next-line import/no-cycle
import { sidebarPages } from '../../router/Router'

import { windowWidth } from '../../helpers/Utils'
import { DEFAULT_PAGE, SIDEBAR_BREAKPOINT } from '../../helpers/Constants'

import Typography from '../../components/Typography'

import { ReactComponent as HamburgerSvg } from '../../svg/hamburger.svg'

import './index.scss'

const Sidebar = ({ pageName }) => {
  // init navigation
  const navigate = useNavigate()

  // init all component actions
  const updateSidebarMobile = useStoreActions((actions) => actions.layout.updateSidebarMobile)
  const updateSidebarOpened = useStoreActions((actions) => actions.layout.updateSidebarOpened)
  const switchToRealAccount = useStoreActions((actions) => actions.user.switchToRealAccount)
  const user = useStoreState((state) => state.user.user)
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const isSidebarMobile = useStoreState((state) => state.layout.isSidebarMobile)
  const isPendingConfirmation = useStoreState((state) => state.user.isPendingConfirmation)
  const isSidebarOpened = useStoreState((state) => state.layout.isSidebarOpened)

  const { isActive } = useContext(ShepherdTourContext)

  const handleLogoClick = (e) => {
    e.stopPropagation()

    // hide sidebar on mobile
    updateSidebarMobile(false)

    navigate(DEFAULT_PAGE)
  }

  const handleSidebarClick = (e) => {
    // ignore sidebar toggle on small devices
    if (windowWidth() < SIDEBAR_BREAKPOINT) return

    const collapse = e.currentTarget
    collapse.style.display = 'none'
    // timeout must correlate with opacity transition from CSS
    setTimeout(() => { collapse.style.display = '' }, 300)

    updateSidebarOpened()
  }

  const handleMenuItemClick = (e) => {
    if (isPendingConfirmation) return
    // hide sidebar on mobile
    updateSidebarMobile(false)

    navigate(e.currentTarget.dataset.url)
  }

  const handleHamburgerClick = () => {
    updateSidebarMobile(true)
  }

  const handleSidebarWrapClick = (e) => {
    // don't allow to close mobile sidebar while clicking within it
    e.stopPropagation()
  }

  const handleBackToMyUser = async () => {
    const result = await switchToRealAccount()
    if (result.success) {
      window.location.href = DEFAULT_PAGE
    }
  }

  return (
    <>
      {
        (!isSidebarMobile) ? (
          <div
            id="hamburger"
            onClick={handleHamburgerClick}
            className="hamburger"
          >
            <HamburgerSvg />
          </div>
        ) : null
      }

      <div
        onClick={handleSidebarWrapClick}
        id="sidebar--wrap"
        onMouseEnter={(e) => {
          if (isActive()) return
          e.currentTarget.classList.add('hovered')
        }}
        onMouseLeave={(e) => {
          e.currentTarget.classList.remove('hovered')
        }}
        className={`sidebar--wrap 
        ${isSidebarOpened ? 'sidebar--wrap--opened' : ''}
        ${isSidebarMobile ? 'sidebar--wrap--mobile' : ''}`}
      >
        <div className="sidebar">
          <div
            id="sidebar__logo--wrap"
            className="sidebar__logo--wrap"
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              id="sidebar__logo"
              src="/image/about_blank.gif"
              alt=""
              className="sidebar__logo"
              onClick={handleLogoClick}
            />

            <div
              onClick={handleSidebarClick}
              className="sidebar__collapse"
            />
          </div>

          <div className="sidebar__menu">
            {
              sidebarPages
                // eslint-disable-next-line no-confusing-arrow
                .filter((page) => page.pageName === 'api_order' ? (user?.is_live_ftp_order) : true)
                // eslint-disable-next-line no-confusing-arrow
                .filter((page) => page.pageName === 'back_to_my_user' ? (user.real_user_id) : true)
                // eslint-disable-next-line no-confusing-arrow
                .filter((page) => page.hideOnMobile ? !isMobile : true)
                .map((page) => (
                  <Tooltip
                    arrow
                    id="sidebar-menu-item-tooltip"
                    className="info-tooltip nowrap"
                    PopperProps={{
                      disablePortal: true,
                    }}
                    placement="right"
                    disableFocusListener
                    {...isSidebarOpened && { disableHoverListener: true }}
                    disableTouchListener
                    title={`${isSidebarOpened ? '' : page.label}`}
                    key={page.pageName}
                  >
                    <div
                      // eslint-disable-next-line no-eval
                      onClick={page.pageName === 'back_to_my_user' ? handleBackToMyUser : handleMenuItemClick}
                      id={`sidebar-menu-item-${page.pageName}`}
                      data-url={page.path}
                      className={`sidebar__menu-item 
                  ${(pageName === page.pageName) ? 'sidebar__menu-item--active' : ''} 
                  bg-color-transition`}
                    >
                      {
                        (pageName === page.pageName) ? page.iconWhite : page.icon
                      }
                      <Typography
                        variant="s"
                        inlineBlock
                        label={page.label}
                      />
                    </div>
                  </Tooltip>
                ))
            }
          </div>
        </div>
      </div>
    </>
  )
}

Sidebar.propTypes = {
  pageName: PropTypes.string,
}

Sidebar.defaultProps = {
  pageName: '',
}

export default Sidebar
