import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'

import { PX_TO_REM } from '../../../helpers/Constants'

import Typography from '../../../components/Typography'
import DotAnimation from '../../../layouts/Common/DotAnimation'

import './index.scss'

const DashboardInfoBox = ({
  title,
  content,
  containsHtml,
}) => {
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const isAnyOrderPlaced = useStoreState((state) => state.order.isAnyOrderPlaced)

  return (
    <div className={`dashboard-info-box ${isMobile ? 'mobile' : ''}`}>
      <div className="dashboard-info-box__title">
        <Typography variant="xs" noWrap font={isMobile ? '' : 'medium'} label={title} />
      </div>
      <div className={`dashboard-info-box__content ${!isAnyOrderPlaced ? 'no-order' : ''}`}>
        {
          content ? (
            <Typography
              variant="body1"
              font="semibold"
              fontSize={isMobile ? PX_TO_REM['17'] : PX_TO_REM['30']}
              lineHeight={isMobile ? PX_TO_REM['22'] : PX_TO_REM['38']}
              label={content}
              containsHtml={containsHtml}
            />
          ) : (
            <DotAnimation />
          )
        }
      </div>
    </div>
  )
}

DashboardInfoBox.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string || PropTypes.node,
  containsHtml: PropTypes.bool,
}

DashboardInfoBox.defaultProps = {
  title: '',
  content: '',
  containsHtml: false,
}

export default DashboardInfoBox
