import React from 'react'
import PropTypes from 'prop-types'

import { PX_TO_REM } from '../../../helpers/Constants'

import Typography from '../../../components/Typography'
import Button from '../../../components/Button'

import { ReactComponent as LogoIcon } from '../../../svg/logo_icon.svg'

import './index.scss'

const NewRegisterBox = ({
  title,
  text,
  leftButtonText,
  rightButtonText,
  leftButtonClick,
  rightButtonClick,
  isMobile,
  hideLogo,
  className,
}) => (
  <div className={`newRegister-box ${className}`}>
    {!hideLogo && (
      <div className="newRegister-box-icon">
        <LogoIcon />
      </div>
    )}
    <div className="newRegister-box-title">
      <Typography
        variant="h6"
        font="semibold"
        {...(isMobile && { fontSize: PX_TO_REM['16'] })}
        {...(isMobile && { lineHeight: PX_TO_REM['20'] })}
        label={title}
      />
    </div>
    <div className="newRegister-box-text">
      <Typography
        variant="xs"
        {...(isMobile && { fontSize: PX_TO_REM['13'] })}
        lineHeight={isMobile ? PX_TO_REM['16'] : PX_TO_REM['18']}
        label={text}
        containsHtml
      />
    </div>
    <div className="newRegister-box-buttons">
      {
        leftButtonText && <Button label={leftButtonText} type="contained" onClick={leftButtonClick} />
      }
      {
        rightButtonText && <Button label={rightButtonText} onClick={rightButtonClick} />
      }
    </div>
  </div>
)

NewRegisterBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.node,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  leftButtonClick: PropTypes.func,
  rightButtonClick: PropTypes.func,
  isMobile: PropTypes.bool,
  hideLogo: PropTypes.bool,
  className: PropTypes.string,
}

NewRegisterBox.defaultProps = {
  title: '',
  text: '',
  leftButtonText: '',
  rightButtonText: '',
  leftButtonClick: () => { },
  rightButtonClick: () => { },
  isMobile: false,
  hideLogo: false,
  className: '',
}

export default NewRegisterBox
