import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import Tooltip from '@mui/material/Tooltip'

import Typography from '../../../../components/Typography'
import Button from '../../../../components/Button'
import Dialog from '../../../../components/Dialog'
import Checkbox from '../../../../components/Checkbox'

import { Translation } from '../../../../helpers/Translation'
import { getCardInfo } from '../../../../helpers/Utils'
import {
  PAYMENT_METHOD_STATUS,
  PAYMENT_MODAL_TYPE,
  PX_TO_REM,
} from '../../../../helpers/Constants'

import { ReactComponent as InfoIcon } from '../../../../svg/info.svg'
import { ReactComponent as WarningIcon } from '../../../../svg/warning.svg'
import { ReactComponent as TrashIcon } from '../../../../svg/trash.svg'

import './index.scss'

const EditList = ({
  isOpen,
  openSepaInfoModal,
  setPaymentModalState,
  handleModalClose,
  checkModalClosable,
  isFormChanged,
  setIsFormChanged,
}) => {
  const userAddress = useStoreState((state) => state.user.userAddress)
  const paymentMethods = useStoreState((state) => state.invoice.paymentMethods)
  const addPaymentMethods = useStoreActions((state) => state.invoice.addPaymentMethods)
  const setPrimaryPayment = useStoreActions((state) => state.invoice.setPrimaryPayment)
  const getPaymentMethods = useStoreActions((state) => state.invoice.getPaymentMethods)
  const deletePaymentMethod = useStoreActions((state) => state.invoice.deletePaymentMethod)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const getUserAddress = useStoreActions((actions) => actions.user.getUserAddress)
  const updateUserAddress = useStoreActions((actions) => actions.user.updateUserAddress)
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false)
  const [methodToBeDeleted, setMethodToBeDeleted] = useState(null)
  const [primaryMethod, setPrimaryMethod] = useState(null)
  const [methodToBePrimary, setMethodToBePrimary] = useState(null)
  const [autoPayments, setAutoPayments] = useState(false)

  useEffect(() => {
    const primary = paymentMethods.find((m) => m.primary)
    setPrimaryMethod(primary)
    setMethodToBePrimary(isFormChanged ? methodToBePrimary : primary)
  }, [paymentMethods, isOpen])

  useEffect(() => {
    setAutoPayments(userAddress.is_creditcard_auto === 1)
  }, [JSON.stringify(userAddress)])

  const handleSetAsPrimary = async (e, payload) => {
    const methodEl = e.currentTarget.closest('.payment-method-item')
    methodEl.classList.add('primary')
    setTimeout(() => {
      methodEl.classList.remove('primary')
    }, 3000)
    setMethodToBePrimary(payload)
    setIsFormChanged(payload?.id !== primaryMethod.id)
  }

  const handleDelete = (payload) => {
    setMethodToBeDeleted(payload)
    setIsRemoveDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    const res = await deletePaymentMethod(methodToBeDeleted)
    if (res) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.payment_delete_success,
        type: 'success',
      })
      getUserAddress()
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.payment_delete_error,
        type: 'error',
      })
    }
    const methods = await getPaymentMethods()
    if (methods.length === 0) {
      setPaymentModalState(PAYMENT_MODAL_TYPE.form)
    }
    setIsRemoveDialogOpen(false)
  }

  const handleAutoPayToggle = (e) => {
    const input = e.currentTarget
    setAutoPayments(input.checked)
  }

  const handleUpdate = async () => {
    const reqList = []

    if (methodToBePrimary?.id !== primaryMethod?.id) {
      reqList.push(setPrimaryPayment(methodToBePrimary))
    }

    if (!!userAddress.is_creditcard_auto !== autoPayments) {
      reqList.push(updateUserAddress({
        company: userAddress.company,
        address1: userAddress.address1,
        address2: userAddress.address2,
        zip: userAddress.zip,
        city: userAddress.city,
        country: userAddress.country,
        'ust-id': userAddress.ust_id,
        'invoice-omit-name': userAddress.invoice_omit_name,
        is_creditcard_auto: autoPayments ? 1 : 0,
      }))
    }

    const res = await Promise.all(reqList)
    if (res.every((r) => r)) {
      setIsFormChanged(false)
      getPaymentMethods()
      addPaymentMethods(paymentMethods.map((method) => ({ ...method, primary: method.id === methodToBePrimary.id ? 1 : 0 })))
      getUserAddress()
      updateSnackbarState({
        isOpen: true,
        message: Translation.payment_set_primary_success,
        type: 'success',
      })
      handleModalClose()
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.payment_set_primary_error,
        type: 'error',
      })
    }
  }

  return (
    <>
      <div
        className={`payment-method-list scrollbar-overflow ${paymentMethods.length > 4 ? 'scrollable' : ''}`}
      >
        {paymentMethods.map((method) => (
          <div className="payment-method-item" key={method.id}>
            <div className="icon-wrapper">
              {getCardInfo(method).icon}
            </div>
            <div className="method-info">
              <Typography
                theme="dark"
                variant="xs"
                label={getCardInfo(method).name}
              />
              <Typography
                variant="xs"
                label={getCardInfo(method).cardNumber}
              />
            </div>
            <div className="method-actions">
              {method.iban && (
                <Tooltip
                  arrow
                  className="info-tooltip"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  title={Translation.view_direct_debit}
                >
                  <InfoIcon className="info-icon" onClick={() => openSepaInfoModal(method)} />
                </Tooltip>
              )}
              {methodToBePrimary?.id === method.id && (
                <div className="primary-container">
                  <Typography
                    theme="dark"
                    variant="xs"
                    font="semibold"
                    label={Translation.primary}
                  />
                </div>
              )}
              {method.status === PAYMENT_METHOD_STATUS.deactivated && (
                <Tooltip
                  arrow
                  className="info-tooltip deactivated"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  title={(
                    <div>
                      {Translation.payment_method_deactivated.split('\n').map((line, key) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={key}>
                          <Typography
                            variant="body1"
                            label={line}
                            containsHtml
                          />
                        </div>
                      ))}
                    </div>
                  )}
                >
                  <WarningIcon className="warning-icon" />
                </Tooltip>
              )}
              {methodToBePrimary?.id !== method.id && method.status !== PAYMENT_METHOD_STATUS.deactivated && (
                <div className="set-primary-btn">
                  <Button
                    label={Translation.set_as_primary}
                    onClick={
                      (e) => handleSetAsPrimary(e, { id: method.id, type: method.cardtype ? 'creditcard' : 'sepa' })
                    }
                    type="outlined"
                    width="auto"
                    size="s"
                  />
                </div>
              )}
              <Tooltip
                arrow
                className="info-tooltip deactivated"
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                disableFocusListener
                {...(method.primary !== 1 || paymentMethods.length === 1) && { disableHoverListener: true }}
                disableTouchListener
                title={Translation.set_another_method_as_primary}
              >
                <div className="flex">
                  <button
                    type="button"
                    disabled={method.primary === 1 && paymentMethods.length > 1}
                    onClick={
                      () => handleDelete(
                        { id: method.id, type: method.cardtype ? 'creditcard' : 'sepa' },
                      )
                    }
                  >
                    <TrashIcon />
                  </button>
                </div>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
      <div className="form-actions">

        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="terms-conditions-checkbox">
          <div className="align-left flex align-items-center">
            <div>
              <Checkbox
                id="terms-conditions-checkbox"
                checked={autoPayments}
                onChange={handleAutoPayToggle}
              />
            </div>
            <Typography
              variant="xs"
              theme="light"
              inline
              label={Translation.enable_automatic_payments}
            />
          </div>
        </label>

        <Button
          type="contained"
          onClick={checkModalClosable}
          label={Translation.cancel}
          width={PX_TO_REM['116']}
        />
        <Button
          onClick={handleUpdate}
          disabled={
            methodToBePrimary?.id === primaryMethod?.id
            && (!!userAddress.is_creditcard_auto === autoPayments)
          }
          label={Translation.update}
          width={PX_TO_REM['116']}
        />
      </div>
      {isRemoveDialogOpen && (
        <div id="delete-payment-method-dialog">
          <Dialog
            isShown={isRemoveDialogOpen}
            top={PX_TO_REM['300']}
            title={`${Translation.delete_method}?`}
            content={Translation.sure_to_delete_method}
            cancelLabel={Translation.cancel}
            yesLabel={Translation.delete}
            onClickCancel={() => setIsRemoveDialogOpen(false)}
            onClickEscClose={() => setIsRemoveDialogOpen(false)}
            onClickYes={handleDeleteConfirm}
            isWarning
          />
        </div>
      )}
    </>
  )
}

EditList.propTypes = {
  isOpen: PropTypes.bool,
  openSepaInfoModal: PropTypes.func,
  setPaymentModalState: PropTypes.func,
  handleModalClose: PropTypes.func,
  checkModalClosable: PropTypes.func,
  isFormChanged: PropTypes.bool,
  setIsFormChanged: PropTypes.func,
}

EditList.defaultProps = {
  isOpen: false,
  openSepaInfoModal: () => { },
  setPaymentModalState: () => { },
  handleModalClose: () => { },
  checkModalClosable: () => { },
  isFormChanged: false,
  setIsFormChanged: () => { },
}

export default EditList
