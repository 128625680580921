/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'

import Typography from '../../../components/Typography'
import Switch from '../../../components/Switch'
import RadioGroup from '../../../components/RadioGroup'
import Input from '../../../components/Input'

import './index.scss'

const RedosApproval = ({ galleryData, updateGalleryData }) => (
  <section className="public-gallery-settings-section redos-approval">
    <div className="section-title">
      <Typography
        variant="subtitle"
        font="semibold"
        label={Translation.redos_approval}
      />
    </div>
    <div className="switch-columns">
      <div className="switch-column">
        <div className="switch-column-title">
          <Typography
            label={Translation.marking}
          />
        </div>
        <div className="switch-group">
          <label htmlFor="switch-approve_button">
            <Switch
              id="switch-approve_button"
              checked={!!galleryData.approve_button}
              onChange={(value) => updateGalleryData('approve_button', value)}
            />
            <Typography label={Translation.approval_button} />
          </label>
          <label htmlFor="switch-comments">
            <Switch
              id="switch-comments"
              checked={!!galleryData.comments}
              onChange={(value) => updateGalleryData('comments', value)}
            />
            <Typography label={Translation.comments} />
          </label>
          <label htmlFor="switch-marking">
            <Switch
              id="switch-marking"
              checked={!!galleryData.shapes}
              onChange={(value) => updateGalleryData('shapes', value)}
            />
            <Typography label={Translation.incl_marking} />
          </label>
        </div>
      </div>
      <div className="check-column">
        <div className="check-column-title">
          <Typography
            label={Translation.flow}
          />
        </div>
        <div className="inputs-group">
          <div className="check-row">
            <Typography label={Translation.redos_by_client} />
            <RadioGroup
              name="redos-by-client"
              row
              color="gray"
              value={galleryData.redos_to}
              values={{
                client: Translation.to_us,
                qa: Translation.to_doopic,
              }}
              onChange={(e) => updateGalleryData('redos_to', e.target.value)}
            />
          </div>
          <div className="input-row">
            <label htmlFor="times-for-free">
              <Typography label={`${Translation.times_for_free}:`} />
            </label>
            <Input
              value={galleryData.free_redos_counter}
              onChange={(e) => updateGalleryData('free_redos_counter', e.target.value)}
            />
          </div>
          <div className="check-row">
            <Typography label={Translation.approval_by_doopic} />
            <RadioGroup
              name="approval-by-doopic"
              row
              color="gray"
              value={galleryData.approve_to}
              values={{
                client: Translation.to_us,
                public: Translation.directly_to_client,
              }}
              onChange={(e) => updateGalleryData('approve_to', e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="switch-column">
        <div className="switch-column-title">
          <Typography
            label={Translation.emails}
          />
        </div>
        <div className="switch-group">
          <label htmlFor="switch-redo-notification">
            <Switch
              id="switch-redo-notification"
              checked={!!galleryData.redo_notification}
              onChange={(value) => updateGalleryData('redo_notification', value)}
            />
            <Typography label={Translation.redo_notification} />
          </label>
          <label htmlFor="switch-approve-notification">
            <Switch
              id="switch-approve-notification"
              checked={!!galleryData.approve_notification}
              onChange={(value) => updateGalleryData('approve_notification', value)}
            />
            <Typography label={Translation.approval_notification} />
          </label>
          <label htmlFor="switch-email-approval">
            <Switch
              id="switch-email-approval"
              checked={!!galleryData.notification_log}
              onChange={(value) => updateGalleryData('notification_log', value)}
            />
            <Typography label={Translation.email_log_after_redo_approval} />
          </label>
        </div>
      </div>
    </div>
  </section>
)

export default RedosApproval

RedosApproval.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  galleryData: PropTypes.objectOf(PropTypes.any).isRequired,
  updateGalleryData: PropTypes.func.isRequired,
}
