import { action, thunk } from 'easy-peasy'
// eslint-disable-next-line import/no-named-default
import { default as customAxios } from 'axios'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS } from '../helpers/Urls'
import { Translation } from '../helpers/Translation'

const InvoiceStore = {
  apiErrors: '',
  invoices: null,
  invoiceCount: 0,
  unpaidDetails: null,
  paymentMethods: [],
  ibanValidationInfo: null,
  isPaymentInProcess: false,

  addApiErrors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = payload
  }),

  clearApiErrors: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = ''
  }),

  addInvoices: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.invoices = payload
  }),

  setInvoiceCount: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.invoiceCount = payload
  }),

  setUnpaidDetails: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.unpaidDetails = payload
  }),

  addPaymentMethods: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.paymentMethods = payload
  }),

  addIbanValidationInfo: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.ibanValidationInfo = payload
  }),

  setPaymentInProcess: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isPaymentInProcess = payload
  }),

  getUnpaidDetails: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.unpaid_details)

      if (response.data.result.success) {
        actions.setUnpaidDetails(response.data.result)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getUnpaidDetailsCheckout: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.unpaid_details_checkout)

      if (response.data.result.success) {
        actions.setUnpaidDetails(response.data.result)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getUnpaidDataDashboard: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.unpaid_details_dashboard)

      if (response.data.result.success) {
        actions.setUnpaidDetails(response.data.result)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getInvoices: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.invoices, { params: payload })

      if (response.data.result.success) {
        actions.addInvoices(response.data.result.invoices)
        actions.setInvoiceCount(response.data.result.invoices_count)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getPaymentMethods: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.payment_methods)

      if (response.data.result.success) {
        actions.addPaymentMethods([
          ...response.data.result.po_creditcards,
          ...response.data.result.po_iban_mandates,
        ].sort((a, b) => b.primary - a.primary))
        return [
          ...response.data.result.po_creditcards,
          ...response.data.result.po_iban_mandates,
        ]
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  savePaymentMethod: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.payment_method, payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  deletePaymentMethod: thunk(async (actions, { id, type }) => {
    try {
      const response = await axios.delete(`${API_ENDPOINTS.payment_method}/${id}/${type}`)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  setPrimaryPayment: thunk(async (actions, { id, type }) => {
    try {
      const response = await axios.patch(`${API_ENDPOINTS.paymentPrimary}/${id}/${type}`)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getIbanInfo: thunk(async (actions, payload) => {
    try {
      const response = await customAxios.get(`https://openiban.com/validate/${payload}?getBIC=true&validateBankCode=true`)

      if (response.data) {
        actions.addIbanValidationInfo(response.data)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  payWithCreditCard: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.payWithCreditCard, payload)

      return response.data.result
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  payWithSepa: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.payWithSepa, payload)

      return response.data.result
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  payWithGiropay: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.payWithGiropay, payload)

      if (response.data.result.success) {
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  payWithSofort: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.payWithSofort, payload)

      if (response.data.result.success) {
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  createInvoice: thunk(async (actions) => {
    try {
      const response = await axios.post(API_ENDPOINTS.createInvoice)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  downloadInvoice: thunk(async (actions, payload) => {
    try {
      const downloadInvoiceUrl = API_ENDPOINTS.downloadInvoice.replace('{id}', payload.id)
      const response = await axios.get(downloadInvoiceUrl)

      return response.data.result
    } catch (error) {
      return false
    }
  }),

}

export default InvoiceStore
