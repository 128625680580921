import React, { useState, useEffect, useRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

import {
  DESKTOP_PX_TO_REM,
  DESKTOP_TABLE_WIDTH,
  TABLES,
  WORKFLOW_PAGE,
  PX_TO_REM,
} from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import { getPerPage, localizeDate, savePerPage } from '../../helpers/Utils'

import Table from '../../components/Table'
import Typography from '../../components/Typography'

import TableTopInfo from '../../layouts/Common/TableTopInfo'
import MobileView from '../../layouts/Common/MobileView'
import TableContent from './TableContent'

import { ReactComponent as EyeSvg } from '../../svg/eye.svg'
import { ReactComponent as WorkflowsSvg } from '../../svg/workflows.svg'
import { ReactComponent as PlusBorder } from '../../svg/plus_border.svg'
import { ReactComponent as ThreeDots } from '../../svg/three_dots.svg'
import { ReactComponent as SettingsSvg } from '../../svg/settings.svg'

import './index.scss'

const processFormats = (value, maxLength) => {
  // truncate formats
  if (value.length > maxLength) {
    return (
      <Tooltip
        className="info-tooltip"
        PopperProps={{
          disablePortal: true,
        }}
        title={value}
        placement="top"
        arrow
      >
        <div className="order-formats">
          <span>{`${value.substring(0, maxLength)}...`}</span>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="order-formats">
      <span>{value}</span>
    </div>
  )
}

const processFormatsActions = (value, maxLength) => {
  // truncate actions
  if (value.length > maxLength) {
    return (
      <Tooltip
        className="info-tooltip w-max-314"
        PopperProps={{
          disablePortal: true,
        }}
        title={value}
        placement="left"
        arrow
      >
        <div className="order-actions">
          <span>{`${value.substring(0, maxLength)}...`}</span>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="order-actions">
      <span>{value}</span>
    </div>
  )
}

const IconTooltip = ({ children, text }) => (
  <Tooltip
    className="info-tooltip"
    PopperProps={{ disablePortal: true }}
    title={text}
    placement="left"
    arrow
  >
    {children}
  </Tooltip>
)

const Workflows = () => {
  const navigate = useNavigate()

  const isMobile = useStoreState((state) => state.layout.isMobile)
  const user = useStoreState((state) => state.user.user)
  // init all component actions
  const templatesAction = useStoreActions((actions) => actions.template.fetchTemplates)
  const setWorkflowList = useStoreActions((actions) => actions.template.setWorkflowList)
  const getTemplateDetail = useStoreActions((actions) => actions.template.getTemplateDetail)

  const workflowList = useStoreState((state) => state.template.workflowList)
  const workflowCount = useStoreState((state) => state.template.workflowCount)

  const [workflowPage, setWorkflowPage] = useState(1)
  const [workflowRowsPerPage, setWorkflowRowsPerPage] = useState(getPerPage(TABLES.WORKFLOW_TEMPLATES))
  const [isShowRowActions, setIsShowRowActions] = useState(false)
  const [workflowTableRowCollapseContent, setWorkflowTableRowCollapseContent] = useState({})

  const actionsBoxRef = useRef(null)

  const fetchTemplates = (payload) => {
    templatesAction(payload)
  }

  useEffect(() => {
    setWorkflowList({ templates: null })
  }, [])

  useEffect(() => {
    fetchTemplates({ page: workflowPage, limit: workflowRowsPerPage, type: 'workflow' })
    savePerPage(TABLES.WORKFLOW_TEMPLATES, workflowRowsPerPage)
  }, [workflowPage, workflowRowsPerPage])

  const formatActionsWorkflowsMaxLength = 40
  const publicGalleryColWidth = 75
  let inputPathColWidth = 180
  if (!user?.is_public_gallery_client) inputPathColWidth += publicGalleryColWidth
  const workflowsTableColWidth = [240, inputPathColWidth, 270, 60, 110, 150, 100]
  if (user?.is_public_gallery_client) workflowsTableColWidth.push(publicGalleryColWidth)
  // last column width is calculated as the rest of available table width
  workflowsTableColWidth.push(DESKTOP_TABLE_WIDTH - workflowsTableColWidth.reduce((a, b) => a + b))

  const workflowsTableColumns = [
    ...[
      { id: 'template_name', label: Translation.name, width: workflowsTableColWidth[0] / DESKTOP_PX_TO_REM },
      { id: 'input_path', label: Translation.input_folder, width: workflowsTableColWidth[1] / DESKTOP_PX_TO_REM },
      { id: 'formats', label: Translation.templates_formats, width: workflowsTableColWidth[2] / DESKTOP_PX_TO_REM },
      { id: 'turnaround', label: Translation.time, width: workflowsTableColWidth[3] / DESKTOP_PX_TO_REM },
      { id: 'trigger', label: Translation.trigger, width: workflowsTableColWidth[4] / DESKTOP_PX_TO_REM },
      { id: 'latest_order', label: Translation.last_order, width: workflowsTableColWidth[5] / DESKTOP_PX_TO_REM },
      { id: 'imagesEdited', label: Translation.edited_images, width: workflowsTableColWidth[6] / DESKTOP_PX_TO_REM },
    ],
    ...(user?.is_public_gallery_client ? [
      {
        id: 'filefront',
        label: Translation.filefront,
        render: 'jsx',
        width: workflowsTableColWidth[7] / DESKTOP_PX_TO_REM,
      },
    ] : []),
    ...[{
      id: 'warningEditDelete',
      label: null,
      render: 'jsx',
      width: (user?.is_public_gallery_client ? workflowsTableColWidth[8] : workflowsTableColWidth[7]) / DESKTOP_PX_TO_REM,
    }],
  ]

  const onEdit = (template) => {
    navigate(`${WORKFLOW_PAGE}/${template.id}`)
  }

  useEffect(() => {
    if (workflowList !== null && workflowList.length > 0) {
      const obj = {}
      workflowList.forEach((item) => {
        obj[item.id] = (
          <TableContent />
        )
      })

      Object.keys(workflowTableRowCollapseContent).forEach((key) => {
        if (obj[key]) {
          obj[key] = workflowTableRowCollapseContent[key]
        }
      })

      setWorkflowTableRowCollapseContent({ ...obj })
    }
  }, [workflowList, workflowList?.length])

  const handleRowClick = async (id, show, tableType) => {
    if (show) {
      const templateContentData = await getTemplateDetail({ id, tableType })

      if (templateContentData) {
        setWorkflowTableRowCollapseContent((prev) => ({
          ...prev,
          [id]: (
            <TableContent data={templateContentData} tableType="workflows" />
          ),
        }))
      }
    }
  }

  const toggleIsShowRowActions = (e, id) => {
    e.stopPropagation()
    setIsShowRowActions((prev) => (prev === id ? false : id))
  }

  const handleOnClickOutside = (e) => {
    if (actionsBoxRef.current && !actionsBoxRef.current.contains(e.target)) {
      setIsShowRowActions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOnClickOutside)
    return () => {
      document.removeEventListener('click', handleOnClickOutside)
    }
  })

  const transformWorkflowData = () => {
    const tempData = []
    let tableType = 'table-collapse'

    workflowList?.map((template) => (
      tempData.push({
        ...template,
        latest_order: template.latest_order ? localizeDate(template.latest_order) : '-',
        template_name: (
          <div className="word-break">
            <Typography
              variant="xs-narrow"
              label={template?.name}
              style={{ cursor: 'default' }}
            />
          </div>),
        input_path: (
          <div className="word-break">
            <Typography
              variant="body1"
              label={template.input_path}
              fontSize={PX_TO_REM['10']}
              lineHeight={PX_TO_REM['13']}
            />
          </div>
        ),
        formats: (
          <>
            {processFormats(template.formats, formatActionsWorkflowsMaxLength)}
            {processFormatsActions(template.actions, formatActionsWorkflowsMaxLength)}
          </>
        ),
        trigger: template.trigger,
        imagesEdited: template.images_edited,
        ...(user?.is_public_gallery_client ? {
          filefront: (
            <div className="table-row-cell-buttons filefront">
              <div className={`filefront-icon ${template.public_gallery_id ? 'active' : ''}`} />
            </div>
          ),
        } : {}),
        warningEditDelete: (
          <div className="more-actions" ref={actionsBoxRef}>
            <button
              type="button"
              className="more-actions-icon"
              onClick={(e) => toggleIsShowRowActions(e, template.id)}
            >
              <ThreeDots />
            </button>
            <div className="more-actions-content" style={{ display: isShowRowActions === template.id ? 'block' : 'none' }}>
              {
                user?.is_public_gallery_client && (
                  template.public_gallery_id ? (
                    <Link
                      to={`/public-gallery-settings/${template.public_gallery_id}`}
                      className="more-actions-content-icon setting-icon"
                    >
                      <SettingsSvg />
                      <Typography
                        variant="xs"
                        label={Translation.filefront_settings}
                      />
                    </Link>
                  ) : (
                    <Link
                      to={`/public-gallery-settings/new/${template.id}`}
                      className="more-actions-content-icon add-icon"
                    >
                      <PlusBorder />
                      <Typography
                        variant="xs"
                        label={Translation.add_filefront}
                      />
                    </Link>
                  )
                )
              }
              <button
                type="button"
                className="more-actions-content-icon view-icon"
                onClick={() => onEdit(template)}
              >
                <EyeSvg />
                <Typography
                  variant="xs"
                  label={Translation.view_workflow}
                />
              </button>
            </div>
          </div>
        ),
      })
    ))

    if (tempData.length === 0) {
      tempData.push({
        id: 0,
        emptyDataText: Translation.setting_up_workflows,
      })

      tableType = 'table-default'
    }

    return { data: tempData, tableType }
  }

  if (workflowList === null) return null

  return isMobile ? (
    <MobileView label={Translation.to_access_templates_switch_desktop} />
  ) : (
    <div className="workflows-page">
      <div
        id="workflow-table"
        className={transformWorkflowData()?.tableType === 'table-default' ? 'table-cursor-default' : ''}
      >
        <div>
          <div className="m-top-35 m-bottom-20">
            <TableTopInfo
              title={Translation.workflows}
              chipText={`${workflowCount} ${workflowCount === 1 ? Translation.workflow : Translation.workflows}`}
              description={Translation.workflows_help_meet}
              icon={<WorkflowsSvg className="table-top-icon" />}
            />
          </div>
        </div>
        <Table
          data={transformWorkflowData()?.data}
          columns={workflowsTableColumns}
          count={workflowCount}
          tableType={transformWorkflowData()?.tableType}
          collapseContents={workflowTableRowCollapseContent}
          onRowClick={(id, show) => handleRowClick(id, show, 'workflows')}
          onPageChange={(value) => setWorkflowPage(value)}
          rowsPerPageValue={workflowRowsPerPage}
          onRowsPerPageChange={(value) => setWorkflowRowsPerPage(value)}
          scrollable={false}
          tableContainerStyle={{ overflow: workflowList.length < 3 ? 'revert' : '' }}
        />
      </div>
    </div>
  )
}

export default Workflows

IconTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}
