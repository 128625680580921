import React from 'react'
import CheckboxMui from '@mui/material/Checkbox'
import PropTypes from 'prop-types'

import { ReactComponent as CheckboxCheckedSvg } from '../../svg/checkbox_checked.svg'
import { ReactComponent as CheckedUncheckedSvg } from '../../svg/checkbox_unchecked.svg'

import './index.scss'

const Checkbox = ({
  id,
  checked,
  onChange,
  disabled,
  error,
}) => (
  <CheckboxMui
    id={id}
    checked={checked}
    disabled={disabled}
    disableRipple
    className={`doo-checkbox ${error ? 'checkbox-error' : ''}`}
    onChange={onChange}
    icon={<CheckedUncheckedSvg />}
    checkedIcon={<CheckboxCheckedSvg />}
  />
)

Checkbox.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
}

Checkbox.defaultProps = {
  id: '',
  checked: false,
  onChange: () => { },
  disabled: false,
  error: false,
}

export default Checkbox
