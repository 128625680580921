import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate, useParams } from 'react-router-dom'

import {
  FIELDS_PATTERNS, FIELDS_MIN_LENGTH, OK_PASSWORD_SCORE, LOGIN_PAGE,
} from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import {
  isEnterKey, isValidValue, removeInputError,
} from '../../helpers/Utils'

import Button from '../../components/Button'
import Input from '../../components/Input'
import Typography from '../../components/Typography'
import Link from '../../components/Link'

import Footer from '../../layouts/Footer'
import PasswordStrengthBar from '../../layouts/Common/PasswordStrengthBar'

import ResetPasswordSuccess from './ResetPasswordSuccess'

import { ReactComponent as BackArrowSvg } from '../../svg/arrow_back_large.svg'
import { ReactComponent as CheckSvg } from '../../svg/check_curved.svg'

import './index.scss'

const ResetPassword = () => {
  // init navigation
  const navigate = useNavigate()
  const params = useParams()

  // init all component actions
  const resetPassword = useStoreActions((actions) => actions.user.resetPassword)
  const clearApiErrorsAction = useStoreActions((actions) => actions.user.clearApiErrors)
  const apiErrors = useStoreState((state) => state.user.apiErrors)
  const isResetPasswordToken = useStoreState((state) => state.user.isResetPasswordToken)
  const validateToken = useStoreActions((actions) => actions.user.validateToken)

  // init all component states
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [passwordScore, setPasswordScore] = useState(0)

  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors, setFormErrors] = useState({
    password: Translation.must_be_at_least_chars_long.replace('%MIN_LENGTH%', FIELDS_MIN_LENGTH.password),
    passwordRepeat: Translation.passwords_do_not_match,
    passwordScore: Translation.password_is_to_weak,
  })

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  useEffect(() => {
    validateToken({ id_code: params.token })
    clearApiErrorsAction()
  }, [])

  useEffect(() => {
    if (isResetPasswordToken) {
      navigate(LOGIN_PAGE)
    }
  }, [isResetPasswordToken])

  // watch API errors change
  useEffect(() => {
    if (apiErrors) {
      // API server is down
      if (apiErrors.serverError) return

      setFormErrors({
        ...formErrors,
        password: (apiErrors.password) ? apiErrors.password : Translation.nonce,
        passwordRepeat: (apiErrors.passwordRepeat) ? apiErrors.passwordRepeat : Translation.nonce,
      })

      setIsFormErrors({ ...isFormErrors, password: true, passwordRepeat: true })
    } else {
      // clear all form errors
      setIsFormErrors({})
    }
  }, [apiErrors])

  const handlePasswordChange = (e) => {
    const input = e.currentTarget
    setPassword(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handlePasswordRepeatChange = (e) => {
    const input = e.currentTarget
    const inputValue = input.value.trim()

    setPasswordRepeat(inputValue)

    if (password === inputValue) {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: false })
    }
  }

  const handlePasswordRepeatBlur = () => {
    if (!passwordRepeat || password === passwordRepeat) {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: false })
    } else {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: true })
    }
  }

  const isFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(password, FIELDS_PATTERNS.simplePassword)) {
      isValid = false
      isFormErrorsUpdated.password = !isValid
    }

    if (passwordScore < OK_PASSWORD_SCORE) {
      isValid = false
      isFormErrorsUpdated.passwordScore = !isValid
    }

    if (!passwordRepeat || password !== passwordRepeat) {
      isValid = false
      isFormErrorsUpdated.passwordRepeat = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const handleSubmitClick = async () => {
    if (!isFormValid()) return

    setIsSubmitButtonDisabled(true)

    const result = await resetPassword({
      id_code: params.token,
      password: password.trim(),
      'password-repeat': passwordRepeat.trim(),
    })

    setIsSubmitButtonDisabled(false)

    if (result) {
      setIsFormSubmitted(true)
    }
  }

  const handleInputKeyUp = (e) => {
    if (isEnterKey(e.key)) {
      handleSubmitClick().then(() => { })
    }
  }

  const handleLinkClick = (e) => {
    e.preventDefault()
    const url = new URL(e.currentTarget.href)
    navigate(url.pathname)
  }

  return (
    <div className="reset-page">
      <div className="reset-form--wrap">
        <div>
          {
            isFormSubmitted
              ? (
                <ResetPasswordSuccess isSubmitButtonDisabled={isSubmitButtonDisabled} />
              )
              : (
                <div className="screen-center-top">
                  <div className="reset-form__line">
                    <Typography
                      variant="xl"
                      theme="dark"
                      noWrap
                      font="semibold"
                      label={Translation.set_new_password}
                    />
                  </div>

                  <div className="reset-form__line m-top-12 m-bottom-32">
                    <Typography
                      variant="s"
                      theme="light"
                      label={Translation.new_password_text}
                    />
                  </div>

                  <div className="reset-form__line m-top-25">
                    <Input
                      type="password"
                      name="password"
                      size="s"
                      label={`${Translation.password}*`}
                      // eslint-disable-next-line max-len, no-nested-ternary
                      error={isFormErrors.password ? formErrors.password : isFormErrors.passwordScore ? formErrors.passwordScore : null}
                      isError={isFormErrors.password || isFormErrors.passwordScore}
                      pattern={FIELDS_PATTERNS.simplePassword}
                      placeholder={Translation.create_password}
                      value={password}
                      onChange={handlePasswordChange}
                      onKeyUp={handleInputKeyUp}
                      disableAutocomplete
                    />
                    {
                      (password || isFormErrors.password) ? (
                        <PasswordStrengthBar
                          password={password}
                          minLength={FIELDS_MIN_LENGTH.password}
                          setPasswordScore={(score) => setPasswordScore(score)}
                        />
                      ) : null
                    }
                  </div>

                  <div className="reset-form__line m-top-25">
                    <Input
                      type="password"
                      name="repeat-password"
                      size="s"
                      label={`${Translation.repeat_password}*`}
                      error={formErrors.passwordRepeat}
                      isError={isFormErrors.passwordRepeat}
                      pattern={FIELDS_PATTERNS.simplePassword}
                      placeholder={Translation.repeat_a_password}
                      value={passwordRepeat}
                      onChange={handlePasswordRepeatChange}
                      onBlur={handlePasswordRepeatBlur}
                      onKeyUp={handleInputKeyUp}
                      {...(passwordRepeat && (passwordRepeat === password)) && { endAdornment: <CheckSvg /> }}
                      disableAutocomplete
                    />
                  </div>

                  <div className="reset-form__line m-top-24">
                    <Button
                      size="s"
                      label={Translation.reset_password}
                      disabled={isSubmitButtonDisabled}
                      onClick={handleSubmitClick}
                      id="reset--button"
                    />
                  </div>

                  <div className="reset-form__line m-top-32">
                    <a
                      id="back-to-login--arrow"
                      href="/"
                      onClick={handleLinkClick}
                    >
                      <BackArrowSvg className="back-arrow" />
                    </a>

                    <Link
                      id="back-to-login"
                      href="/"
                      variant="xs"
                      theme="dark"
                      font="semibold"
                      label={Translation.back_to_login}
                      onClick={handleLinkClick}
                    />
                  </div>
                </div>
              )
          }
        </div>

        <div className="m-top-auto">
          <Footer isNewTab />
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
