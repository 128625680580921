import axios from 'axios'

import {
  LOGIN_PAGE, DEFAULT_PAGE, NEW_ORDER_PAGE, TEMPLATE_PAGE,
} from './Constants'
import { Translation } from './Translation'
import { Cookie } from './Cookie'
import { API_ENDPOINTS } from './Urls'
import { removePerPage } from './Utils'

// eslint-disable-next-line import/no-mutable-exports
export let axiosInstance = null

export const enhanceAxiosInstance = (store) => {
  // axios instance must exist only once
  if (axiosInstance !== null) return axiosInstance

  axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

  const excludedUrls = [
    API_ENDPOINTS.dashboard,
    API_ENDPOINTS.unpaid_details,
    API_ENDPOINTS.unpaid_details_dashboard,
  ]

  const pageUrl = window.location.pathname

  const isToShowLoader = (url, params) => {
    if (!excludedUrls.includes(url)
        && !url.includes('/order/template/pricing')
        && !url.includes('/format/actions/')
        && !url.includes('/order/details/')
        && !url.includes('/template/details/')
        && !url.includes('/order/ftp/folders/')) {
      if (pageUrl === DEFAULT_PAGE
          && url.includes(API_ENDPOINTS.me)
          && params !== 'undefined'
          && params
          && 'with_assigned_templates' in params) {
        return false
      } if (pageUrl === DEFAULT_PAGE && url.includes(API_ENDPOINTS.userTemplates)) {
        return false
      } if (pageUrl === NEW_ORDER_PAGE && !url.includes(API_ENDPOINTS.order_create)) {
        return true
      }
      return true
    }

    return false
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      if ((config.url.includes(TEMPLATE_PAGE) && config.method === 'patch')) {
        // eslint-disable-next-line no-param-reassign
        config.withCredentials = true
        return config
      }

      if (isToShowLoader(config.url, config.params)) {
        // this will trigger showing of page blocking loader
        store.getActions().global.increaseCounter()
      }

      if (config.url !== API_ENDPOINTS.login) {
        // eslint-disable-next-line no-param-reassign
        config.withCredentials = true
      }

      return config
    },
    (error) => Promise.reject(error),
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      if (store.getState().global.requestCount > 0) {
        if (isToShowLoader(response.config.url, response.config.params)) {
          store.getActions().global.decreaseCounter()
        }
      }
      return response
    },
    (error) => {
      if (store.getState().global.requestCount > 0) {
        store.getActions().global.decreaseCounter()
      }

      if (error.response && error.response.status === 401) {
        Cookie.deleteCookie('PHPSESSID')
        removePerPage()
        const redirectTo = `${LOGIN_PAGE}${window.location.pathname.slice(1)}${window.location.search}${window.location.hash}`
        window.location.href = `${LOGIN_PAGE}?redirect=${encodeURIComponent(redirectTo)}`
      }

      // if the status is 301 and the page url is not /dashboard
      // or contains "/email/confirmation", then redirect to "/dashboard"
      if (error.response && error.response.status === 301
        && window.location.pathname !== DEFAULT_PAGE && !window.location.pathname.includes('/email/confirmation')) {
        window.location.href = DEFAULT_PAGE
      }

      // if the status is "301" and we are on dashboard, show verify email popup
      if (error.response && error.response.status === 301 && window.location.pathname === DEFAULT_PAGE) {
        store.getActions().user.setIsPendingConfirmation(true)
      }

      // handle 500 errors and show the snackbar with error type
      if (error.response && error.response.status === 500) {
        store.getActions().global.updateSnackbarState({
          isOpen: true,
          message: Translation.nonce,
          type: 'error',
        })
      }
      return Promise.reject(error)
    },
  )

  return store
}
