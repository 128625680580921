import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'

import {
  INTERVAL_60_SECONDS,
  ORDER_STATUS,
  FIELDS_PATTERNS,
  NEW_ORDER_PAGE,
  TEMPLATE_PAGE,
  FOLDER_TYPES,
  TEMPLATES_PAGE,
  PX_TO_REM,
} from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'
import { clickToDownload, isValidValue } from '../../../helpers/Utils'

import Typography from '../../../components/Typography'
import Link from '../../../components/Link'
import Input from '../../../components/Input'
import Dialog from '../../../components/Dialog'

import DotAnimation from '../../../layouts/Common/DotAnimation'

import OrderDetailsInfo from './OrderDetailsInfo'
import OrderDetailsTop from './OrderDetailsTop'
import OrderDetailsDownload from './OrderDetailsDownload'
import OrderDetailsPricing from './OrderDetailsPricing'
import OrderDetailsRate from './OrderDetailsRate'
import OrderDetailsConfirm from './OrderDetailsConfirm'
import OrderDetailsAcceptPrice from './OrderDetailsAcceptPrice'
import OrderDetailsImages from './OrderDetailsImages'

import { ReactComponent as SaveSvg } from '../../../svg/save.svg'
import { ReactComponent as CopyOrderSvg } from '../../../svg/copy_order.svg'

import './index.scss'

const OrdersTableContent = ({ orderDetails, orderName, isLargeView }) => {
  const navigate = useNavigate()

  const getOrderDetails = useStoreActions((actions) => actions.order.getOrderDetails)

  const user = useStoreState((state) => state.user.user)
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const setIsOrderDetailDataAgain = useStoreActions((actions) => actions.order.setIsOrderDetailDataAgain)
  const downloadInvoice = useStoreActions((actions) => actions.invoice.downloadInvoice)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)
  const templateEqual = useStoreActions((state) => state.template.templateEqual)
  const templateOrderSave = useStoreActions((state) => state.template.templateOrderSave)
  const templateOrderApply = useStoreActions((state) => state.template.templateOrderApply)

  const [isAcceptPriceResult, setIsAcceptPriceResult] = useState(false)

  const [existTemplate, setExistTemplate] = useState(null)
  const [newTemplateName, setNewTemplateName] = useState(null)
  const [newTemplateNameError, setNewTemplateNameError] = useState(false)
  const [newTemplateDialogOpen, setNewTemplateDialogOpen] = useState(false)
  const [applyTemplateDialogOpen, setApplyTemplateDialogOpen] = useState(false)

  useEffect(() => {
    let orderDetailsInterval
    if (orderDetails?.id) {
      orderDetailsInterval = setInterval(async () => {
        await getOrderDetails(orderDetails?.id)
        setIsOrderDetailDataAgain(true)
      }, INTERVAL_60_SECONDS)
    }

    return () => {
      clearInterval(orderDetailsInterval)
    }
  }, [orderDetails.id])

  const fetchDataAgain = async () => {
    await getOrderDetails(orderDetails?.id)
    setIsOrderDetailDataAgain(true)
  }

  const handleDownload = async (e, invoiceId) => {
    e.preventDefault()
    e.stopPropagation()
    const result = await downloadInvoice({ id: invoiceId })
    if (result === false) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.something_went_wrong,
        type: 'error',
      })
      return
    }

    if (result.success) {
      clickToDownload(result.invoice_url)
    }
  }

  const handleSaveSettings = async () => {
    const res = await templateEqual(orderDetails?.id)

    if (res.template_id) {
      setExistTemplate({
        template_id: res.template_id,
        template_name: res.template_name,
      })
    } else {
      setExistTemplate(null)
    }

    setNewTemplateName(`${Translation.template}: ${orderName}`)
    setNewTemplateDialogOpen(true)
  }

  const isTemplateNameValid = () => {
    if (!isValidValue(newTemplateName, FIELDS_PATTERNS.default)) {
      setNewTemplateNameError(true)
      return false
    }
    return true
  }

  const handleSaveTemplateConfirm = async () => {
    if (isTemplateNameValid()) {
      const res = await templateOrderSave({
        order_id: orderDetails?.id,
        name: newTemplateName,
        turnaround: orderDetails?.turnaround,
      })

      if (res) {
        updateSnackbarState({
          isOpen: true,
          message: Translation.settings_saved_successfully,
          type: 'success',
        })
        navigate(TEMPLATES_PAGE)
      } else {
        updateSnackbarState({
          isOpen: true,
          message: Translation.nonce,
          type: 'error',
        })
      }
    }
  }

  const handleTemplateNameChange = (e) => {
    setNewTemplateName(e.target.value)
  }

  const handleApplyTemplate = async () => {
    setApplyTemplateDialogOpen(true)
  }

  const handleApplyTemplateConfirm = async () => {
    const res = await templateOrderApply({
      order_id: orderDetails?.id,
    })

    if (res) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.settings_applied_successfully,
        type: 'success',
      })
      navigate(NEW_ORDER_PAGE)
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.nonce,
        type: 'error',
      })
    }
  }

  const handleTemplateNameClick = (e) => {
    e.preventDefault()
    navigate(`${TEMPLATE_PAGE}/${existTemplate.template_id}`)
  }

  return (
    // eslint-disable-next-line no-nested-ternary
    orderDetails?.id ? (
      <div className="order-table-content">
        <OrderDetailsTop orderDetails={orderDetails} />
        <div>
          {
            isMobile && (
              <div className="order-detail-mobile-text">
                <Typography
                  variant="xs"
                  fontSize={PX_TO_REM['11']}
                  lineHeight={PX_TO_REM['15']}
                  label={Translation.order_details_slider_mobile_text}
                />
              </div>
            )
          }
          <div className="content-imagesDetail">
            {
              !isMobile && (
                <>
                  <OrderDetailsDownload orderDetails={orderDetails} isLargeView={user?.is_large_view} />
                  <OrderDetailsConfirm orderDetails={orderDetails} fetchAgainOrderDetail={fetchDataAgain} />
                </>
              )
            }

            <OrderDetailsImages isLargeView={isLargeView} orderDetails={orderDetails} />
          </div>
        </div>
        {
          !isMobile && (
            <>
              <div className="content-bottom">
                <div className="order-details">
                  <div className="order-details-top">
                    <Typography variant="h6" label={Translation.details} font="semibold" />
                    {orderDetails?.folder_type === FOLDER_TYPES.WEB && (
                      <div className="order-details-top-icons">
                        <Tooltip
                          className="info-tooltip"
                          PopperProps={{
                            disablePortal: true,
                          }}
                          title={
                            <Typography variant="body1" font="medium" label={Translation.save_the_settings} noWrap />
                          }
                          placement="left"
                          arrow
                        >
                          <button type="button" onClick={handleSaveSettings}>
                            <SaveSvg />
                          </button>
                        </Tooltip>
                        <Tooltip
                          className="info-tooltip"
                          PopperProps={{
                            disablePortal: true,
                          }}
                          title={
                            <Typography variant="body1" font="medium" label={Translation.apply_the_settings} noWrap />
                          }
                          placement="left"
                          arrow
                        >
                          <button type="button" onClick={handleApplyTemplate}>
                            <CopyOrderSvg />
                          </button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {
                    orderDetails.order_actions.map((orderDetailsItem, index) => (
                      <OrderDetailsInfo key={orderDetailsItem.label} orderDetailsItem={orderDetailsItem} index={index} />
                    ))
                  }
                </div>
                <div>
                  {
                    user.billing_info === 1 && orderDetails.order_prices?.order_final_price
                    && <OrderDetailsPricing orderDetails={orderDetails} handleInvoiceDownload={handleDownload} />
                  }
                  {
                    orderDetails?.order_status_id === ORDER_STATUS.ORDER_STATUS_PRICE_CHANGE
                    && !isAcceptPriceResult && user.billing_info === 1 && (
                      <OrderDetailsAcceptPrice
                        orderId={orderDetails.id}
                        isSuccess={(result) => setIsAcceptPriceResult(result)}
                      />
                    )
                  }
                </div>
              </div>
              <OrderDetailsRate orderDetails={orderDetails} fetchAgainOrderDetail={fetchDataAgain} />
            </>
          )
        }
        {
          isMobile && (orderDetails?.order_status_id === ORDER_STATUS.ORDER_STATUS_PRICE_CHANGE) && !isAcceptPriceResult && (
            <OrderDetailsAcceptPrice
              orderId={orderDetails.id}
              isSuccess={(result) => setIsAcceptPriceResult(result)}
            />
          )
        }
        {newTemplateDialogOpen && (
          <div className="new-template-dialog-container">
            <Dialog
              isShown={newTemplateDialogOpen}
              title={Translation.new_template}
              content={(
                <div className="new-template-dialog">
                  <Typography
                    variant="xs"
                    theme="dark"
                    containsHtml
                    label={Translation.about_to_save_settings.replace(/%TEMPLATE_NAME%/g, orderName)}
                  />
                  <Input
                    label={Translation.template_name}
                    onChange={handleTemplateNameChange}
                    value={newTemplateName}
                    isError={newTemplateNameError}
                    error={Translation.this_field_is_required}
                  />

                  {existTemplate && (
                    <div className="template-exist-warning">
                      <Typography
                        variant="xs"
                        theme="dark"
                        // containsHtml
                        inlineBlock
                      >
                        <b>
                          {Translation.attention}
                          !
                          {' '}
                        </b>
                        {Translation.you_already_have_a_template}
                        {' '}
                        <Link
                          variant="xs"
                          href="/"
                          onClick={handleTemplateNameClick}
                          label={existTemplate.template_name}
                        />
                      </Typography>
                    </div>
                  )}
                </div>
              )}
              cancelLabel={Translation.no}
              yesLabel={Translation.yes}
              onClickCancel={() => setNewTemplateDialogOpen(false)}
              onClickYes={handleSaveTemplateConfirm}
              onClickEscClose={() => setNewTemplateDialogOpen(false)}
            />
          </div>
        )}

        {applyTemplateDialogOpen && (
          <div className="new-template-dialog-container">

            <Dialog
              isShown={applyTemplateDialogOpen}
              title={`${Translation.attention}!`}
              content={(
                <div className="new-template-dialog">
                  <Typography
                    variant="xs"
                    theme="dark"
                    containsHtml
                    label={
                      Translation.about_to_apply_settings
                        .replace(/%TEMPLATE_NAME%/g, orderName)
                    }
                  />
                  <Typography
                    variant="xs"
                    theme="dark"
                    containsHtml
                    label={Translation.your_current_settings_warning}
                  />
                </div>
              )}
              cancelLabel={Translation.no}
              yesLabel={Translation.yes}
              onClickCancel={() => setApplyTemplateDialogOpen(false)}
              onClickYes={handleApplyTemplateConfirm}
              onClickEscClose={() => setApplyTemplateDialogOpen(false)}
            />
          </div>
        )}
      </div>
    ) : (
      <div className="dashboard-table-loading">
        <DotAnimation />
      </div>
    )
  )
}

OrdersTableContent.propTypes = {
  orderDetails: PropTypes.instanceOf(Object),
  orderName: PropTypes.string,
  isLargeView: PropTypes.bool,
}

OrdersTableContent.defaultProps = {
  orderDetails: {},
  orderName: '',
  isLargeView: false,
}

export default OrdersTableContent
