export const DOOPIC_SUPPORT_NUMBER = '+49 (0)30 220 120 855'
export const DOOPIC_SALES_NUMBER = '+49 (0)30 220 120 857'

export const MOBILE_BREAKPOINT = 430 // must correspond with CSS breakpoint
export const TABLET_BREAKPOINT = 880 // must correspond with CSS breakpoint
export const SIDEBAR_BREAKPOINT = 1441 // must correspond with CSS breakpoint

export const DESKTOP_PX_TO_REM = 13 // must correspond with CSS

export const DESKTOP_TABLE_WIDTH = 1248 - 1 // -1px guarantee no horizontal scrolling appear

export const BROWSER_SCROLLBAR_WIDTH = 23 // must be applied to tables with vertical scrolling

export const CENTER_TOP = '44%'

export const PX_TO_REM = { // all values are exact copy of values in "Reset.scss"
  7: 0.5384615,
  8: 0.6153846,
  9: 0.6923077,
  10: 0.7692307,
  11: 0.8461538,
  12: 0.9230769,
  13: 1.0000000,
  14: 1.0769231,
  15: 1.1538462,
  16: 1.2307692,
  17: 1.3076923,
  18: 1.3846153,
  19: 1.4615384,
  20: 1.5384615,
  21: 1.6153846,
  22: 1.6923077,
  23: 1.7692307,
  24: 1.8461538,
  25: 1.9230769,
  26: 2.0000000,
  27: 2.0769231,
  28: 2.1538461,
  30: 2.3076923,
  32: 2.4615384,
  34: 2.5384615,
  35: 2.6923076,
  36: 2.7692307,
  38: 2.9230769,
  42: 3.2307692,
  44: 3.3846153,
  62: 4.7692307,
  67: 5.1538461,
  72: 5.5384615,
  93: 7.1538461,
  113: 8.6923076,
  114: 8.7692307,
  116: 8.9230769,
  130: 10.000000,
  172: 13.230769,
  185: 14.230769,
  190: 14.615384,
  300: 23.076923,
  360: 27.692307,
  394: 30.307692,
  475: 36.538461,
}

export const HIDE_DROPDOWN_TIMEOUT = 750

export const HIDE_SNACKBAR_SUCCESS_TIMEOUT = 3000

export const HIDE_SNACKBAR_ERROR_TIMEOUT = 4000

export const INTERVAL_30_SECONDS = 30000
export const INTERVAL_60_SECONDS = 60000

export const MEMORY_BYTE_LIMIT_UPLOAD_DOWNLOAD = 734003200
// eslint-disable-next-line max-len
export const VALID_FILE_EXTENSIONS = 'ai,arw,bmp,cr2,cr3,dng,dxf,eps,gif,heic,idml,indd,jfif,jpeg,jpg,nef,pdf,pef,png,psb,psd,rw2,svg,tif,tiff,xml,webp'

export const MAX_FILES_UPLOAD_CNT = 1000
export const TEST_ORDER_MAX_FILES_UPLOAD_CNT = 3

export const OK_PASSWORD_SCORE = 2

export const FIELDS_MIN_LENGTH = {
  default: 1,
  cardName: 2,
  password: 6,
  ftp_password: 2,
  order_id: 6,
  invoice_id: 5,
  message: 1,
  bicCode: 8,
}

export const FIELDS_PATTERNS = {
  default: `.{${FIELDS_MIN_LENGTH.default},}`,
  email: '^(?!.{255})[_A-Za-z0-9\\-+]+(\\.[_A-Za-z0-9\\-+]+)*@[A-Za-z0-9\\-]+(\\.[A-Za-z0-9\\-]+)*(\\.[A-Za-z]{2,})$',
  simpleEmail: '.+@.+\\..{2,}',
  simplePassword: `.{${FIELDS_MIN_LENGTH.password},}`,
  order_id: `.{${FIELDS_MIN_LENGTH.order_id},}`,
  invoice_id: `.{${FIELDS_MIN_LENGTH.invoice_id},}`,
  message: `.{${FIELDS_MIN_LENGTH.message},}`,
  telephone: '[+\\(\\)0-9\\s]+',
  cardName: `.{${FIELDS_MIN_LENGTH.cardName},}`,
  cardNumber: '^[0-9 ]+$',
  onlyNumber: '^[0-9]+$',
  onlyChars: '^[a-zA-Z ]+$',
  bicCode: `.{${FIELDS_MIN_LENGTH.bicCode},}`,
}

export const COOKIE_NAMES = {
  info_cookie: '_gkvsd',
  sidebar_state: '_doo_sidebar',
  REFERRER_ID_COOKIE: '_ref',
  AFFILIATE_PARTNER_ID_COOKIE: '_aff',
  SALES_REP_ID_COOKIE: '_sal',
  AD_REFERRER_ID_COOKIE: '_dref',
}

export const COOKIE_VALUES = {
  info_cookie: '1',
  sidebar_state: '1',
}

export const INDUSTRY_VALUES = {
  en: {
    1: 'Automobile',
    2: 'Jewellery',
    3: 'Fashion - Clothing',
    4: 'Accessories',
    5: 'Cosmetics',
    6: 'Food',
    7: 'Furniture - Furnishings',
    8: 'Advertising & Marketing',
    9: 'Electronics',
    10: 'Agency',
    11: 'Other',
  },
  de: {
    1: 'Automobil',
    2: 'Schmuck',
    3: 'Fashion - Bekleidung',
    4: 'Accessoires',
    5: 'Kosmetik',
    6: 'Lebensmittel',
    7: 'Möbel - Einrichtung',
    8: 'Werbung & Marketing',
    9: 'Elektronik',
    10: 'Agentur',
    11: 'Sonstiges',
  },
  it: {
    1: 'Automobile',
    2: 'Gioielleria',
    3: 'Vestiti di moda',
    4: 'Accessori',
    5: 'Cosmetici',
    6: 'Cibo',
    7: 'Mobili - arredi',
    8: 'Pubblicità e marketing',
    9: 'Elettronica',
    10: 'Agenzia',
    11: 'Altro',
  },
}

export const SOURCE_VALUES = {
  en: {
    1: 'Online search engine',
    2: 'Social media',
    3: 'Amazon',
    4: 'Blogs / Press',
    5: 'Recommendation',
    6: 'Flyer',
    7: 'Fair',
    8: 'Sales staff',
    9: 'Other',
    10: 'Newsletter',
  },
  de: {
    1: 'Online-Suchmaschine',
    2: 'Soziale Medien',
    3: 'Amazon',
    4: 'Blogs / Presse',
    5: 'Empfehlung',
    6: 'Flyer',
    7: 'Messe',
    8: 'Vertriebsmitarbeiter',
    9: 'Sonstiges',
    10: 'Newsletter',
  },
  it: {
    1: 'Motore di ricerca Online',
    2: 'Social media',
    3: 'Amazon',
    4: 'Blogs / Stampa',
    5: 'Raccomandazione',
    6: 'Flyer',
    7: 'Fiera',
    8: 'Personale di vendita',
    9: 'Altro',
    10: 'Newsletter',
  },
}

export const KEYS = {
  enter: 'Enter',
}

export const SUPPORT_VALID_FORMATS = {
  ext: [
    'jpg',
    'jpeg',
    'png',
    'gif',
  ],
  mime: [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
  ],
}

// valid formats svg, png, jpg, jpeg, gif
export const VALID_PROFILE_IMAGE_FORMATS = {
  ext: [
    'jpg',
    'jpeg',
  ],
  mime: [
    'image/jpg',
    'image/jpeg',
  ],
}

export const FTP_GUIDE_LINKS = {
  en: 'https://my.doopic.com/pdf/ftp_guide_en.pdf',
  de: 'https://my.doopic.com/pdf/ftp_guide_de.pdf',
  it: 'https://my.doopic.com/pdf/ftp_guide_it.pdf',
}

export const FTP_STATUS = {
  notRequested: 0,
  requestRequested: 1,
  requestGranted: 2, // user allowed to see and copy FTP password
  requestDone: 10,
  requestDenied: 90,
}

export const FTP_PASSWORD_VIEWED = 5

export const FTP_MODAL_TYPE = {
  question: 1,
  show: 2,
  resetPassword: 3,
}

export const ORDER_STATUS = {
  ORDER_STATUS_NEW: 0,
  ORDER_STATUS_PRICE_CHANGE: 1,
  ORDER_STATUS_WAITING_PAYMENT: 2,
  ORDER_STATUS_CHOOSE_SP: 4,
  ORDER_STATUS_UPLOAD_TO_SP: 5,
  ORDER_STATUS_IN_PROCESS: 6,
  ORDER_STATUS_DOWNLOAD_FROM_SP: 7,
  ORDER_STATUS_EDITED: 8,
  ORDER_STATUS_REDO: 9,
  ORDER_STATUS_DELIVERY: 10,
  ORDER_STATUS_TEST_IMAGE_UPLOADED: 22,
  ORDER_STATUS_CLIENT_REDO: 80,
  ORDER_STATUS_PUBLIC_REDO: 81,
  ORDER_STATUS_CLIENT_PUBLIC_REDO: 82,
  ORDER_STATUS_CLIENT_APPROVAL_REQUIRED: 85,
  ORDER_STATUS_PUBLIC_APPROVAL_REQUIRED: 86,
  ORDER_STATUS_COMPLETED: 100,
  ORDER_STATUS_ON_HOLD: 900,
  ORDER_STATUS_CANCELED: 999,
}

export const IMAGE_GENERATED_OFFSET = 6

export const IMAGE_PREVIEW_PER_PAGE = 14
export const IMAGE_PREVIEW_VERTICAL_MAX_WIDTH = 200 // must correspond with CSS
export const IMAGE_PREVIEW_HORIZONTAL_MAX_HEIGHT = 120 // must correspond with CSS
export const IMAGE_PREVIEW_MAX_HEIGHT = 160 // must correspond with CSS
export const TABLE_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100]
export const TABLE_TRUNCATE_LENGTH = 40
export const TABLE_ROWS_PER_PAGE = 5

export const LOGIN_PAGE = '/'
export const DEFAULT_PAGE = '/dashboard'
export const BILLING_PAGE = '/profile#billing'
export const PAYMENT_SUCCESS_PAGE = '/payment-success'
export const CHECKOUT_PAGE = '/checkout'
export const NEW_ORDER_PAGE = '/new-order'
export const TEST_ORDER_PAGE = '/test-order'
export const TEMPLATE_PAGE = '/template'
export const WORKFLOW_PAGE = '/workflow'
export const TEMPLATES_PAGE = '/templates'
export const WORKFLOWS_PAGE = '/workflows'
export const ORDER_SUCCESS_PAGE = '/order-success'
export const IMAGE_SEARCH_PAGE = '/search'

export const HORIZONTAL_SCROLL_PAGES = [
  'dashboard',
  'new_order',
  'templates',
  'workflows',
  'ftp',
  'checkout',
  'profile',
]

export const PAYMENT_MODAL_TYPE = {
  editList: 1,
  paymentList: 2,
  form: 3,
  inProcess: 4,
  paymentError: 5,
}

export const PAYMENT_METHOD_STATUS = {
  deleted: 0,
  active: 1,
  deactivated: 9,
}

export const TABLES = {
  SAVED_TEMPLATES: 'saved_templates',
  WORKFLOW_TEMPLATES: 'workflow_templates',
  FTP_FOLDERS: 'ftp_folders',
  USER_MANAGEMENT: 'user_management',
  BILLING: 'billing',
  DASHBOARD_ORDERS: 'dashboard_orders',
}

export const QA_ROLES = [
  'admin',
  'admin-sp',
  'admin-light',
  'admin-freelancer',
  'admin-super',
  'manager',
  'manager-light',
  'sales',
  'marketing',
  'onboarding',
  'ext-junior',
  'ext-staff',
  'ext-senior',
  'doo-junior',
  'doo-staff',
  'doo-senior',
  'doo-staff-marketing',
  'doo-staff-sales',
]

export const USER_ROLE = 'user'
export const CLIENT_ADMIN_ROLE = 'client-admin' // not used yet

export const FOLDER_TYPES = {
  WEB: 'web',
  FTP: 'ftp',
  CSV: 'csv',
  WETRANSFER: 'wetransfer',
}

export const DEFAULT_TIMEZONE = 'Europe/Berlin'
export const DEFAULT_CURRENCY = 'EUR'

export const IMG_SIZE_LARGE = 900
export const IMAGE_TYPE_IN = 'in'
export const IMAGE_TYPE_OUT = 'out'

export const REMARK_CHARACTERS_LIMIT = 200

export const EKOMI_MIN_RATING = 4

export const ONLY_NUMBER_REGEX = /^[0-9]*\.?[0-9]*$/
