import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Slide } from 'react-slideshow-image'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-slideshow-image/dist/styles.css'

import { Cookie, isLocal } from '../../helpers/Cookie'
import {
  FIELDS_MIN_LENGTH,
  FIELDS_PATTERNS,
  OK_PASSWORD_SCORE,
  KEYS,
  DEFAULT_PAGE,
  COOKIE_NAMES,
  INTERVAL_60_SECONDS,
  DEFAULT_CURRENCY,
  PX_TO_REM,
} from '../../helpers/Constants'
import {
  Translation, DOOPIC_URLS, sources, industries, lang,
} from '../../helpers/Translation'
import {
  isEnterKey,
  isValidValue, removeInputError, validateInput, validateInputWithError,
} from '../../helpers/Utils'

import Button from '../../components/Button'
import Input from '../../components/Input'
import RadioGroup from '../../components/RadioGroup'
import Select from '../../components/Select'
import Checkbox from '../../components/Checkbox'
import Typography from '../../components/Typography'
import Link from '../../components/Link'

import Footer from '../../layouts/Footer'

import { ReactComponent as StarSvg } from '../../svg/star.svg'
import { ReactComponent as ArrowLeftSvg } from '../../svg/arrow_left.svg'
import { ReactComponent as ArrowRightSvg } from '../../svg/arrow_right.svg'
import { ReactComponent as CheckSvg } from '../../svg/check_curved.svg'
import { ReactComponent as GoogleSvg } from '../../svg/google.svg'

import './index.scss'

import PasswordStrengthBar from '../../layouts/Common/PasswordStrengthBar'

const Register = ({ isRegisterToTest }) => {
  // init navigation
  const navigate = useNavigate()

  // init all component actions
  const registerAction = useStoreActions((actions) => actions.user.register)
  const googleLogin = useStoreActions((actions) => actions.user.googleLogin)
  const clearApiErrorsAction = useStoreActions((actions) => actions.user.clearApiErrors)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const apiErrors = useStoreState((state) => state.user.apiErrors)
  const prefilledData = useStoreState((state) => state.user.registerPrefilledData)

  // init all component states
  const [company, setCompany] = useState(prefilledData.company || '')
  const [email, setEmail] = useState(prefilledData.email || '')
  const [password, setPassword] = useState(prefilledData.password || '')
  const [passwordScore, setPasswordScore] = useState(0)
  const [passwordRepeat, setPasswordRepeat] = useState(prefilledData.passwordRepeat || '')
  const [gender, setGender] = useState('male')
  const [firstname, setFirstname] = useState(prefilledData.firstname || '')
  const [lastname, setLastname] = useState(prefilledData.lastname || '')
  const [source, setSource] = useState('')
  const [industry, setIndustry] = useState('')
  const [isTerms, setIsTerms] = useState(false)
  const [isMailing, setIsMailing] = useState(false)

  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors, setFormErrors] = useState({
    company: Translation.required_field,
    email: Translation.enter_valid_email,
    password: Translation.must_be_at_least_chars_long,
    passwordRepeat: Translation.passwords_do_not_match,
    passwordScore: Translation.password_is_to_weak,
    firstname: Translation.required_field,
    lastname: Translation.required_field,
  })

  const [isFirstForm, setIsFirstForm] = useState(prefilledData.email === '')
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [recaptchaKey, setRecaptchaKey] = useState('')

  // init all component references
  const firstnameInputRef = useRef(null)
  const firstFormRef = useRef(null)

  // watch API errors change
  useEffect(() => {
    if (apiErrors) {
      // API server is down
      if (apiErrors.serverError) return

      let isValid = true

      const formErrorsUpdated = {}
      const isFormErrorsUpdated = {}
      if (apiErrors.required) {
        if (apiErrors.required.email) {
          isValid = false
          formErrorsUpdated.email = apiErrors.required.email
          isFormErrorsUpdated.email = !isValid
        }

        if (apiErrors.required.password) {
          isValid = false
          formErrorsUpdated.password = apiErrors.required.password
          isFormErrorsUpdated.password = !isValid
        }

        if (apiErrors.required.firstname) {
          isValid = false
          formErrorsUpdated.firstname = apiErrors.required.firstname
          isFormErrorsUpdated.firstname = !isValid
        }

        if (apiErrors.required.lastname) {
          isValid = false
          formErrorsUpdated.firstname = apiErrors.required.lastname
          isFormErrorsUpdated.firstname = !isValid
        }

        if (apiErrors.required.invalid_recaptcha_token) {
          isValid = false
          updateSnackbarState({
            isOpen: true,
            message: Translation.nonce,
            type: 'error',
          })
        }
      }

      if (!isValid) {
        setFormErrors(formErrorsUpdated)
        setIsFormErrors(isFormErrorsUpdated)
      }

      setIsFirstForm(true)
      firstFormRef.current.classList.remove('inactive')
      firstFormRef.current.classList.add('active')
    } else {
      // clear all form errors
      setIsFormErrors({})
    }
  }, [apiErrors])

  // watch "isFirstForm" change
  useEffect(() => {
    if (!isFirstForm && firstnameInputRef.current) firstnameInputRef.current.focus()
  }, [isFirstForm])

  useEffect(() => {
    if (prefilledData.email !== '') {
      setEmail(prefilledData.email)
      setFirstname(prefilledData.firstname)
      setLastname(prefilledData.lastname)
      setCompany(prefilledData.company)
      setPassword(prefilledData.password)
      setPasswordRepeat(prefilledData.passwordRepeat)
      setIsFirstForm(false)
      firstFormRef.current.classList.remove('active')
      firstFormRef.current.classList.add('inactive')
    }
  }, [prefilledData.email])

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY, { action: 'homepage' })
        .then((token) => {
          setRecaptchaKey(token)
        })
    })
  }

  // executed only once on first page load
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}`
    script.addEventListener('load', handleLoaded)
    document.body.appendChild(script)
    clearApiErrorsAction()
    const interval = setInterval(() => {
      handleLoaded()
    }, INTERVAL_60_SECONDS)

    return () => clearInterval(interval)
  }, [])

  const isFirstFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(company, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.company = !isValid
    }

    if (!isValidValue(email, FIELDS_PATTERNS.email)) {
      isValid = false
      isFormErrorsUpdated.email = !isValid
    }

    if (!isValidValue(password, FIELDS_PATTERNS.simplePassword)) {
      isValid = false
      isFormErrorsUpdated.password = !isValid
    }

    if (passwordScore < OK_PASSWORD_SCORE) {
      isValid = false
      isFormErrorsUpdated.passwordScore = !isValid
    }

    if (!passwordRepeat || password !== passwordRepeat) {
      isValid = false
      isFormErrorsUpdated.passwordRepeat = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const isSecondFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(firstname, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.firstname = !isValid
    }

    if (!isValidValue(lastname, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.lastname = !isValid
    }

    if (!isTerms) {
      isValid = false
      isFormErrorsUpdated.terms = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const handleRegisterForwardClick = () => {
    if (isFirstFormValid()) {
      firstFormRef.current.classList.add('inactive')
      firstFormRef.current.classList.remove('active')
      setIsFirstForm(false)
    }
  }

  const handleRegisterBackClick = () => {
    firstFormRef.current.classList.remove('inactive')
    firstFormRef.current.classList.add('active')
    setIsFirstForm(true)
  }

  const handleCompanyChange = (e) => {
    const input = e.currentTarget
    setCompany(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleCompanyBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const handleEmailChange = (e) => {
    const input = e.currentTarget
    setEmail(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleEmailBlur = (e) => {
    validateInputWithError(
      e.currentTarget,
      formErrors,
      setFormErrors,
      isFormErrors,
      setIsFormErrors,
      Translation.enter_valid_email,
    )
  }

  const handlePasswordChange = (e) => {
    const input = e.currentTarget
    setPassword(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handlePasswordRepeatChange = (e) => {
    const input = e.currentTarget
    const inputValue = input.value.trim()

    setPasswordRepeat(inputValue)

    if (password === inputValue) {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: false })
    }
  }

  const handlePasswordRepeatBlur = () => {
    if (!passwordRepeat || password === passwordRepeat) {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: false })
    } else {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: true })
    }
  }

  const handleGenderChange = (e) => {
    setGender(e.currentTarget.value)
  }

  const handleFirstnameChange = (e) => {
    const input = e.currentTarget
    setFirstname(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleFirstnameBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const handleLastnameChange = (e) => {
    const input = e.currentTarget
    setLastname(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleLastnameBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const handleSourceChange = (e) => {
    setSource(e.target.value)
  }

  const handleIndustryChange = (e) => {
    setIndustry(e.target.value)
  }

  const handleTermsChange = (e) => {
    const input = e.currentTarget
    setIsTerms(input.checked)

    setIsFormErrors({ ...isFormErrors, terms: !input.checked })
  }

  const handleMailingChange = (e) => {
    const input = e.currentTarget
    setIsMailing(input.checked)
  }

  const handleSubmitClick = async () => {
    if (!isFirstFormValid()) {
      setIsFirstForm(true)
      return
    }

    if (!isSecondFormValid()) {
      return
    }

    setIsSubmitButtonDisabled(true)

    const referrerId = Cookie.getCookie(COOKIE_NAMES.REFERRER_ID_COOKIE)
    const partnerId = Cookie.getCookie(COOKIE_NAMES.AFFILIATE_PARTNER_ID_COOKIE)
    const salesRepId = Cookie.getCookie(COOKIE_NAMES.SALES_REP_ID_COOKIE)
    const adReferrerId = Cookie.getCookie(COOKIE_NAMES.AD_REFERRER_ID_COOKIE)

    const result = await registerAction({
      company,
      email,
      password,
      'password-repeat': passwordRepeat,
      gender,
      firstname,
      lastname,
      'found-source': source,
      'industry-branch': industry,
      newsletter: isMailing,
      'recaptcha-token': recaptchaKey,
      ...(referrerId && { [COOKIE_NAMES.REFERRER_ID_COOKIE]: referrerId }),
      ...(partnerId && { [COOKIE_NAMES.AFFILIATE_PARTNER_ID_COOKIE]: partnerId }),
      ...(salesRepId && { [COOKIE_NAMES.SALES_REP_ID_COOKIE]: salesRepId }),
      ...(adReferrerId && { [COOKIE_NAMES.AD_REFERRER_ID_COOKIE]: adReferrerId }),
    })

    setIsSubmitButtonDisabled(false)

    if (result) {
      // send conversion to AdWords
      if (!isLocal && typeof gtag !== 'undefined') {
        // eslint-disable-next-line no-undef
        gtag(
          'event',
          'conversion',
          {
            send_to: process.env.REACT_APP_AW_REGISTRATION_CONVERSION,
            value: 0.0,
            currency: DEFAULT_CURRENCY,
          },
        )
      }

      navigate(DEFAULT_PAGE)
    }
  }

  const handleFirstFormInputKeyUp = (e) => {
    if (e.key === KEYS.enter) {
      handleRegisterForwardClick()
    }
  }

  const handleInputKeyUp = (e) => {
    if (isEnterKey(e.key)) {
      handleSubmitClick().then(() => { })
    }
  }

  const handleLinkClick = (e) => {
    e.preventDefault()
    const url = new URL(e.currentTarget.href)
    navigate(url.pathname)
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const accessToken = tokenResponse.access_token
      const result = await googleLogin({ id_token: accessToken })
      if (result.success) {
        navigate(result.redirect)
      }
    },
    onError: () => {
      updateSnackbarState({
        isOpen: true,
        message: Translation.nonce,
        type: 'error',
      })
    },
  })

  const indicators = (index) => (<div className="indicator">{index + 1}</div>)

  const arrowStyle = {
    background: 'none',
    border: 'none',
    width: '2.3076923rem',
    height: '2.3076923rem',
    bottom: '-5.2307692rem',
  }

  const arrowLeftStyle = {
    left: '11.923076rem',
  }

  const arrowRightStyle = {
    right: '11.923076rem',
  }

  const properties = {
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    prevArrow: <button style={{ ...arrowStyle, ...arrowLeftStyle }} type="button"><ArrowLeftSvg /></button>,
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    nextArrow: <button style={{ ...arrowStyle, ...arrowRightStyle }} type="button"><ArrowRightSvg /></button>,
  }

  const slides = [
    {
      id: '1',
      stars: 5,
      feedback: {
        en: '"After a brief adjustment period, we have become very accustomed to Doopic\'s way of working '
          + 'and have developed a high level of trust in their adaptability and flexibility."',
        de: '“Wir haben uns nach einer kurzen Anpassungsphase sehr gut an die Arbeitsweise von Doopic gewöhnt '
          + 'und mittlerweile ein großes Vertrauen in die Anpassungsfähigkeit und die Flexibilität ”',
      },
      avatar: 'feedback_avatar_2.png',
      name: 'Thorsten',
      title: {
        en: 'Head of Marketing - Betty Barclay Group',
        de: 'Head of Marketing - Betty Barclay Group',
      },
    },
    {
      id: '2',
      stars: 5,
      feedback: {
        en: '"For us, it was important to find an outsourcing partner who can grow with us, '
          + 'respond flexibly and quickly to our needs, '
          + 'and has positioned themselves to ensure good quality at fair prices, regardless of fluctuations in quantity."',
        de: '“Für uns war es wichtig einen Outsourcingpartner zu finden, der mit uns wachsen kann, '
          + 'der flexibel und kurzfristig auf unsere Bedürfnisse reagieren kann '
          + 'und der sich clever genug aufgestellt hat um unabhängig von Mengenschwankungen eine gute Qualität '
          + 'zu fairen Preisen gewährleisten zu können.“',
      },
      avatar: 'feedback_avatar_3.png',
      name: 'Philipp',
      title: {
        en: 'Managing Director – Zuumeo',
        de: 'Managing Director – Zuumeo',
      },
    },
    {
      id: '3',
      stars: 5,
      feedback: {
        en: '"The integration of the workflow has significantly reduced the time it takes to put the images online. '
          + 'The delivered images were of good quality, and the support team in Berlin was extremely helpful. '
          + 'We will definitely use the service again. Thank you very much!"',
        de: ' “Die Integration des Workflows hat dazu beigetragen, dass die Zeit, um die Bilder online zu stellen, '
          + 'deutlich verkürzt wurde. Die gelieferten Bilder waren von guter Qualität '
          + 'und das Support-Team in Berlin war äußerst hilfreich. '
          + 'Wir werden den Service auf jeden Fall wieder nutzen. Vielen Dank!”',
      },
      avatar: 'feedback_avatar_4.png',
      name: 'Christian',
      title: {
        en: 'Content Manager - Telefónica Germany Retail GmbH',
        de: 'Content Manager - Telefónica Germany Retail GmbH',
      },
    },
  ]

  const alreadyHaveAccountLink = () => (
    <div className="register-form__line m-top-30">
      <Typography
        variant="xs"
        theme="dark"
        inline
        label={Translation.already_have_account_question}
      />
      {' '}
      <Link
        href="/"
        variant="xs"
        theme="dark"
        font="semibold"
        label={Translation.login}
        onClick={handleLinkClick}
        id="login-link"
      />
    </div>
  )

  return (
    <div className="register-page">
      <div className="register-ad--wrap">
        <div>
          <div className="screen-center">
            <div className="register-ad-line--wrap">
              <Slide
                {...properties}
                autoplay
                transitionDuration={300}
                duration={6000}
                easing="ease-in"
                indicators={indicators}
              >
                {
                  slides.map((slide) => (
                    <div key={slide.id} className="register-ad-slide">
                      <div className="feedback-stars--wrap">
                        {
                          // eslint-disable-next-line react/no-array-index-key
                          [...Array(slide.stars)].map((star, index) => <StarSvg key={index} />)
                        }
                      </div>

                      <div className="m-top-32">
                        <Typography
                          variant="h5"
                          theme="dark"
                          font="italic"
                          fontSize={PX_TO_REM['25']}
                          label={(lang === 'de') ? slide.feedback.de : slide.feedback.en}
                        />
                      </div>

                      <div className="m-top-32">
                        <img src={`/image/${slide.avatar}`} alt="" className="feedback-avatar" />
                      </div>

                      <div className="m-top-16">
                        <Typography
                          variant="s"
                          theme="dark"
                          font="semibold"
                          label={slide.name}
                        />
                      </div>

                      <div className="m-top-4">
                        <Typography
                          variant="xs"
                          theme="dark"
                          font="medium"
                          label={(lang === 'de') ? slide.title.de : slide.title.en}
                        />
                      </div>
                    </div>
                  ))
                }
              </Slide>
            </div>
          </div>
        </div>

        <div className="m-top-auto">
          <Footer isNewTab />
        </div>
      </div>

      <div className="register-form--wrap">
        <div>
          <div className="screen-center">
            <div className="register-form__line align-left">
              <Typography
                variant="xl"
                theme="dark"
                font="semibold"
                label={isRegisterToTest ? Translation.register_for_free_test : Translation.register}
              />
            </div>

            <div className="register-form__line m-top-12 m-bottom-32 align-left">
              <Typography
                variant="s"
                theme="light"
                label={Translation.sing_up_subtitle}
              />
            </div>
            <div className="form-wrapper">
              {/* eslint-disable-next-line no-nested-ternary */}
              <div className="first-form" ref={firstFormRef}>
                <div className="register-form__line">
                  <Input
                    type="text"
                    name="company"
                    size="s"
                    label={`${Translation.company}*`}
                    error={formErrors.company}
                    isError={isFormErrors.company}
                    minLength={FIELDS_MIN_LENGTH.default}
                    pattern={FIELDS_PATTERNS.default}
                    placeholder={Translation.enter_your_company}
                    value={company}
                    onChange={handleCompanyChange}
                    onBlur={handleCompanyBlur}
                    onKeyUp={handleFirstFormInputKeyUp}
                  />
                </div>

                <div className="register-form__line m-top-20">
                  <Input
                    type="text"
                    name="email"
                    size="s"
                    label={`${Translation.email}*`}
                    error={formErrors.email}
                    isError={isFormErrors.email}
                    pattern={FIELDS_PATTERNS.email}
                    placeholder={Translation.enter_your_email}
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    onKeyUp={handleFirstFormInputKeyUp}
                  />
                </div>

                <div className="register-form__line m-top-20">
                  <Input
                    type="password"
                    name="password"
                    size="s"
                    label={`${Translation.password}*`}
                    // eslint-disable-next-line no-nested-ternary, max-len
                    error={isFormErrors.password ? formErrors.password : isFormErrors.passwordScore ? formErrors.passwordScore : null}
                    isError={isFormErrors.password || isFormErrors.passwordScore}
                    minLength={FIELDS_MIN_LENGTH.password}
                    pattern={FIELDS_PATTERNS.simplePassword}
                    placeholder={Translation.create_password}
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyUp={handleFirstFormInputKeyUp}
                    disableAutocomplete
                  />
                  {
                    (password || isFormErrors.password) ? (
                      <PasswordStrengthBar
                        password={password}
                        minLength={FIELDS_MIN_LENGTH.password}
                        setPasswordScore={(score) => setPasswordScore(score)}
                      />
                    ) : null
                  }
                </div>

                <div className={`register-form__line ${(password || isFormErrors.password) ? '' : 'm-top-20'}`}>
                  <Input
                    type="password"
                    name="passwordRepeat"
                    size="s"
                    label={`${Translation.repeat_password}*`}
                    error={formErrors.passwordRepeat}
                    isError={isFormErrors.passwordRepeat}
                    minLength={FIELDS_MIN_LENGTH.password}
                    pattern={FIELDS_PATTERNS.simplePassword}
                    placeholder={Translation.repeat_a_password}
                    value={passwordRepeat}
                    onChange={handlePasswordRepeatChange}
                    onBlur={handlePasswordRepeatBlur}
                    onKeyUp={handleFirstFormInputKeyUp}
                    {...(passwordRepeat && (passwordRepeat === password)) && { endAdornment: <CheckSvg /> }}
                    disableAutocomplete
                  />
                </div>

                <div className="register-form__line m-top-24">
                  <Button
                    size="s"
                    label={Translation.create_my_account}
                    onClick={handleRegisterForwardClick}
                    id="create-account-button"
                  />
                </div>

                <div className="login-form__line m-top-16 google-btn">
                  <Button
                    type="outlined"
                    size="s"
                    onClick={handleGoogleLogin}
                    label={(
                      <>
                        <GoogleSvg />
                        {Translation.register_with_google}
                      </>
                    )}
                  />
                </div>

                {alreadyHaveAccountLink()}
              </div>
              <div className={`second-form ${!isFirstForm ? 'active' : 'inactive'}`}>
                <div className="register-form__line">
                  <RadioGroup
                    name="gender"
                    value={gender}
                    values={Translation.genders}
                    row
                    color="#3A3183"
                    onChange={handleGenderChange}
                  />
                </div>

                <div id="first-name-container" className="register-form__line m-top-20">
                  <Input
                    type="text"
                    name="firstname"
                    size="s"
                    label={`${Translation.firstname}*`}
                    error={formErrors.firstname}
                    isError={isFormErrors.firstname}
                    minLength={FIELDS_MIN_LENGTH.default}
                    pattern={FIELDS_PATTERNS.default}
                    placeholder={Translation.firstname}
                    value={firstname}
                    inputRef={firstnameInputRef}
                    onChange={handleFirstnameChange}
                    onBlur={handleFirstnameBlur}
                    onKeyUp={handleInputKeyUp}
                  />
                </div>

                <div className="register-form__line m-top-20">
                  <Input
                    type="text"
                    name="lastname"
                    size="s"
                    label={`${Translation.lastname}*`}
                    error={formErrors.lastname}
                    isError={isFormErrors.lastname}
                    minLength={FIELDS_MIN_LENGTH.default}
                    pattern={FIELDS_PATTERNS.default}
                    placeholder={Translation.lastname}
                    value={lastname}
                    onChange={handleLastnameChange}
                    onBlur={handleLastnameBlur}
                    onKeyUp={handleInputKeyUp}
                  />
                </div>

                <div className="register-form__line m-top-20">
                  <Select
                    label={Translation.how_found_us_question}
                    placeholder={Translation.select_option}
                    value={source}
                    values={sources}
                    onChange={handleSourceChange}
                  />
                </div>

                <div className="register-form__line m-top-20">
                  <Select
                    label={Translation.which_industry_question}
                    placeholder={`${Translation.select_option}`}
                    value={industry}
                    values={industries}
                    onChange={handleIndustryChange}
                  />
                </div>

                <div className="register-form__line m-top-20">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="terms-conditions-checkbox">
                    <div className="align-left flex">
                      <div>
                        <Checkbox
                          id="terms-conditions-checkbox"
                          checked={isTerms}
                          error={isFormErrors.terms}
                          onChange={handleTermsChange}
                        />
                      </div>
                      <div className="min-width-330">
                        <Typography
                          variant="xs"
                          theme="light"
                          inline
                          label={Translation.register_terms_agree_1}
                        />
                        {' '}
                        <Link
                          href={DOOPIC_URLS.terms}
                          variant="xs"
                          theme="light"
                          underline
                          label={Translation.register_terms_agree_2}
                          isNewTab
                        />
                        {' '}
                        <Typography
                          variant="xs"
                          theme="light"
                          inline
                          label={Translation.register_terms_agree_3}
                        />
                        {' '}
                        <Link
                          href={DOOPIC_URLS.privacy}
                          variant="xs"
                          theme="light"
                          underline
                          label={Translation.register_terms_agree_4}
                          isNewTab
                        />
                        <Typography
                          variant="xs"
                          theme="light"
                          inline
                          label={Translation.register_terms_agree_5}
                        />
                      </div>
                    </div>
                  </label>

                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="is-mailing-checkbox">
                    <div className="align-left flex m-top-4">
                      <div>
                        <Checkbox
                          id="is-mailing-checkbox"
                          checked={isMailing}
                          onChange={handleMailingChange}
                        />
                      </div>
                      <div>
                        <Typography
                          variant="xs"
                          theme="light"
                          inline
                          label={Translation.register_mailing_agree}
                        />
                      </div>
                    </div>
                  </label>
                </div>
                {
                  (isFormErrors.terms) ? (
                    <div
                      id="terms-conditions-error"
                      className="terms-conditions-text register-form__line m-top-4 align-left error"
                    >
                      <Typography
                        variant="xs"
                        theme="light"
                        inline
                        label={Translation.accept_terms_conditions_1}
                      />
                      {' '}
                      <Typography
                        variant="xs"
                        theme="light"
                        inline
                        font="semibold"
                        label={Translation.accept_terms_conditions_2}
                      />
                      {' '}
                      <Typography
                        variant="xs"
                        theme="light"
                        inline
                        label={Translation.accept_terms_conditions_3}
                      />
                      {' '}
                      <Typography
                        variant="xs"
                        theme="light"
                        inline
                        font="semibold"
                        label={Translation.accept_terms_conditions_4}
                      />
                      .
                    </div>
                  ) : null
                }
                {
                  (apiErrors && apiErrors.serverError) ? (
                    <div className="register-form__line m-top-4 align-left error">
                      <Typography
                        variant="xs"
                        theme="light"
                        inline
                        label={apiErrors.serverError}
                      />
                    </div>
                  ) : null
                }
                <div className="register-form__line m-top-32 flex flex-column--gap-16 buttons-wrapper">
                  <div className="column-half">
                    <Button
                      type="contained"
                      size="s"
                      label={Translation.back}
                      onClick={handleRegisterBackClick}
                      id="back-button"
                    />
                  </div>

                  <div className="column-half">
                    <Button
                      size="s"
                      label={Translation.register}
                      disabled={isSubmitButtonDisabled}
                      onClick={handleSubmitClick}
                      id="register-button"
                    />
                  </div>
                </div>

                {alreadyHaveAccountLink()}
              </div>
            </div>
          </div>
        </div>

        <div className="register__footer m-top-auto">
          <Footer isNewTab />
        </div>
      </div>
    </div>
  )
}

Register.propTypes = {
  isRegisterToTest: PropTypes.bool,
}

Register.defaultProps = {
  isRegisterToTest: false,
}

export default Register
