/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import { PX_TO_REM } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import Link from '../../../../components/Link'

import { ReactComponent as CircleInfoSvg } from '../../../../svg/circle-info-price.svg'

import './index.scss'

const OrderDetailsPricing = ({ orderDetails, handleInvoiceDownload }) => (
  <div className="pricing-summary">
    <div className="pricing-summary-title">
      <Typography variant="h6" label={Translation.pricing_summary} font="semibold" />
    </div>
    <div className="pricing-summary-table">
      <table>
        <thead>
          <tr>
            <th>
              <Typography
                variant="subtitle-narrow"
                label={Translation.format}
                font="semibold"
              />
            </th>
            <th>
              <Typography
                variant="subtitle-narrow"
                label={Translation.Images}
                font="semibold"
              />
            </th>
            <th style={{ textAlign: 'right' }}>
              <Typography
                variant="subtitle-narrow"
                label={Translation.per_image}
                font="semibold"
                noWrap
              />
            </th>
            <th style={{ textAlign: 'center' }}>
              <Typography
                variant="subtitle-narrow"
                label={Translation.total}
                font="semibold"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {
            orderDetails.order_prices?.formats?.map((formatItem) => (
              <tr key={formatItem.format_label} className="format-row">
                <td style={{ textAlign: 'left', verticalAlign: 'top' }}>
                  <Typography
                    variant="xs-narrow"
                    label={formatItem.format_label}
                    noWrap
                  />
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  {
                    formatItem.format_image_count.map((formatImageCount, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index}>
                        <Typography
                          variant="xs-narrow"
                          label={formatImageCount}
                          noWrap
                        />
                      </div>
                    ))
                  }
                </td>
                <td style={{ textAlign: 'right', verticalAlign: 'top' }}>
                  <div>
                    {
                      formatItem.format_per_image_price.map((formatPerImagePrice, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className="summary-table-perImage">
                          <Typography
                            variant="xs-narrow"
                            label={formatPerImagePrice}
                            noWrap
                          />
                          {
                            index === 0 && (
                              <Tooltip
                                className="info-tooltip"
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                title={(
                                  <div className="dashboard-pricing-tooltip">
                                    <div className="perImage-title">
                                      <Typography variant="body1" label={Translation.price_per_image} />
                                    </div>
                                    <ul className="perImage-icon-tooltip">
                                      {
                                        formatItem?.format_actions_prices?.map((item) => (
                                          <li key={item.label} className="perImage-icon-tooltip-item">
                                            <Typography variant="body1" label={item.label} />
                                            <Typography variant="body1" label={item.value} />
                                          </li>
                                        ))
                                      }
                                      <li className="perImage-icon-tooltip-total">
                                        <Typography variant="body1" label={formatPerImagePrice} />
                                      </li>
                                    </ul>
                                  </div>
                                )}
                                placement="top"
                                arrow
                              >
                                <CircleInfoSvg className="height-20 width-18" />
                              </Tooltip>
                            )
                          }
                        </div>
                      ))
                    }
                  </div>
                </td>
                <td style={{ textAlign: 'right', verticalAlign: 'top' }}>
                  {
                    formatItem.format_subtotal_price.map((formatSubtotalPrice, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index}>
                        <Typography
                          variant="xs-narrow"
                          label={formatSubtotalPrice}
                          noWrap
                        />
                      </div>
                    ))
                  }
                </td>
              </tr>
            ))
          }
          <tr>
            <td colSpan={3} style={{ textAlign: 'right' }}>
              <Typography variant="body1" label={`${Translation.total_netto}:`} lineHeight={PX_TO_REM['24']} />
            </td>
            <td style={{ position: 'relative', textAlign: 'right' }}>
              <div className="table-cell-total-line" />
              <Typography
                variant="xs-narrow"
                label={orderDetails.order_prices?.order_total_price}
                noWrap
              />
            </td>
          </tr>
          {
            orderDetails.order_prices?.order_surcharge_minimum_difference && (
              <tr>
                <td colSpan={3} style={{ textAlign: 'right' }}>
                  <Typography
                    variant="body1"
                    label={`${Translation.surcharge_applied_price}:`}
                    lineHeight={PX_TO_REM['24']}
                    noWrap
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <Typography
                    variant="xs-narrow"
                    label={orderDetails.order_prices?.order_surcharge_minimum_difference}
                    noWrap
                  />
                </td>
              </tr>
            )
          }
          {
            orderDetails.order_prices?.order_discount_price && (
              <tr>
                <td colSpan={3} style={{ textAlign: 'right' }}>
                  <Typography variant="body1" label={`${Translation.discount}:`} lineHeight={PX_TO_REM['24']} />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <Typography
                    variant="xs-narrow"
                    label={orderDetails.order_prices?.order_discount_price}
                    noWrap
                  />
                </td>
              </tr>
            )
          }
          {
            orderDetails.order_prices?.order_vat_price && (
              <tr>
                <td colSpan={3} style={{ textAlign: 'right' }}>
                  <Typography
                    variant="body1"
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    label={`${Translation.vat}: (${(orderDetails.order_prices?.vat_percent).replace('0.', '')}%):`}
                    lineHeight={PX_TO_REM['24']}
                    noWrap
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <Typography
                    variant="xs-narrow"
                    label={orderDetails.order_prices?.order_vat_price}
                    noWrap
                  />
                </td>
              </tr>
            )
          }
          <tr>
            <td colSpan={2} style={{ textAlign: 'left' }}>
              {
                orderDetails.invoice_id && (
                  <Typography
                    variant="xs-narrow"
                    label={(
                      <>
                        {`${Translation.invoice} `}
                        <Link
                          href="#"
                          label={`#${orderDetails.invoice_id}`}
                          underline
                          onClick={(e) => handleInvoiceDownload(e, orderDetails.invoice_id)}
                        />
                      </>
                    )}
                  />
                )
              }
            </td>
            <td style={{ textAlign: 'right' }}>
              <Typography
                variant="xs-narrow"
                label={Translation.total_gross}
                font="semibold"
                noWrap
              />
            </td>
            <td style={{ position: 'relative', textAlign: 'right' }}>
              <div className="table-cell-gross-line" />
              <Typography
                variant="xs-narrow"
                label={orderDetails.order_prices?.order_final_price}
                font="semibold"
                noWrap
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

OrderDetailsPricing.propTypes = {
  orderDetails: PropTypes.shape({
    invoice_id: PropTypes.number,
    order_prices: PropTypes.shape({
      formats: PropTypes.arrayOf(PropTypes.shape({
        format_label: PropTypes.string,
        format_image_count: PropTypes.arrayOf(PropTypes.number),
        format_per_image_price: PropTypes.arrayOf(PropTypes.string),
        format_subtotal_price: PropTypes.arrayOf(PropTypes.string),
        format_actions_prices: PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })),
      })),
      order_total_price: PropTypes.string,
      order_vat_price: PropTypes.string,
      vat_percent: PropTypes.string,
      order_surcharge_minimum_difference: PropTypes.string,
      order_discount: PropTypes.string,
      order_discount_price: PropTypes.string,
      order_final_price: PropTypes.string,
    }),
  }).isRequired,
  handleInvoiceDownload: PropTypes.func.isRequired,
}

export default OrderDetailsPricing
