import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useParams, useNavigate } from 'react-router-dom'

import { Translation } from '../../helpers/Translation'
import { WORKFLOWS_PAGE } from '../../helpers/Constants'

import Typography from '../../components/Typography'
import Button from '../../components/Button'
import Dialog from '../../components/Dialog'

import General from './General'
import ViewsFeatures from './ViewsFeatures'
import RedosApproval from './RedosApproval'
import DataTransfer from './DataTransfer'
import { defaultGalleryData } from './DefaultGalleryData'

import './index.scss'

const PublicGallerySettings = () => {
  const createPublicGallery = useStoreActions((actions) => actions.user.createPublicGallery)
  const publicGalleryData = useStoreState((state) => state.user.publicGalleryData)
  const getPublicGalleryData = useStoreActions((actions) => actions.user.getPublicGalleryData)
  const updatePublicGallery = useStoreActions((actions) => actions.user.updatePublicGallery)
  const deletePublicGallery = useStoreActions((actions) => actions.user.deletePublicGallery)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [galleryData, setGalleryData] = useState({})
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    // scroll to top
    document.getElementsByClassName('layout')?.[0]?.scrollTo(0, 0)

    if (params.id) {
      getPublicGalleryData(params.id)
    } else if (params.template_id) {
      setGalleryData({
        ...defaultGalleryData,
        template_id: params.template_id,
      })
    }
  }, [params])

  useEffect(() => {
    if (Object.keys(publicGalleryData).length > 0 && params.id) {
      setGalleryData(publicGalleryData)
    }
  }, [publicGalleryData])

  const updateGalleryDataLocale = (key, value) => {
    setGalleryData({
      ...galleryData,
      [key]: value,
    })
  }

  const updateGalleryData = async () => {
    if (galleryData.name) {
      const result = await updatePublicGallery({
        id: params.id,
        data: galleryData,
      })

      if (result) {
        updateSnackbarState({
          isOpen: true,
          message: Translation.saved_successfully,
          type: 'success',
        })
      } else {
        updateSnackbarState({
          isOpen: true,
          message: Translation.something_went_wrong,
          type: 'error',
        })
      }
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.please_public_gallery_name,
        type: 'error',
      })
    }
  }

  const createGallery = async () => {
    if (galleryData.name) {
      const result = await createPublicGallery(galleryData)

      if (result) {
        updateSnackbarState({
          isOpen: true,
          message: Translation.saved_successfully,
          type: 'success',
        })
        navigate(`/public-gallery-settings/${result}`)
      } else {
        updateSnackbarState({
          isOpen: true,
          message: Translation.something_went_wrong,
          type: 'error',
        })
      }
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.please_public_gallery_name,
        type: 'error',
      })
    }
  }

  const deleteGallery = async () => {
    const result = await deletePublicGallery(params.id)

    if (result) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.deleted_successfully,
        type: 'success',
      })
      navigate(WORKFLOWS_PAGE)
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.something_went_wrong,
        type: 'error',
      })
    }

    setIsDeleteModalShown(false)
  }

  return (
    <div className="public-gallery-settings">
      <div>
        <General galleryData={galleryData} updateGalleryData={updateGalleryDataLocale} />
        <ViewsFeatures galleryData={galleryData} updateGalleryData={updateGalleryDataLocale} />
        <RedosApproval galleryData={galleryData} updateGalleryData={updateGalleryDataLocale} />
        <DataTransfer galleryData={galleryData} updateGalleryData={updateGalleryDataLocale} />
        <div className="bottom-action-buttons">
          <div>
            {' '}
          </div>
          <div>
            <Button
              label={Translation.back_to_workflows}
              type="contained"
              width="20"
              onClick={() => navigate(WORKFLOWS_PAGE)}
            />
            {
              params.id && (
                <Button
                  label={Translation.delete}
                  type="contained"
                  width="10.384615"
                  onClick={() => setIsDeleteModalShown(true)}
                />
              )
            }
            <Button
              label={Translation.save}
              width="10.384615"
              onClick={() => (params.id ? updateGalleryData() : createGallery())}
            />
          </div>
        </div>
      </div>
      <Dialog
        isShown={isDeleteModalShown}
        title={`${Translation.attention}!`}
        content={(<Typography variant="xs" label={Translation.public_gallery_delete_modal_text} />)}
        yesLabel={Translation.yes}
        onClickCancel={() => setIsDeleteModalShown(false)}
        onClickEscClose={() => setIsDeleteModalShown(false)}
        onClickYes={deleteGallery}
        isWarning
      />
    </div>
  )
}

export default PublicGallerySettings
