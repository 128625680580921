import React from 'react'
import { useStoreActions } from 'easy-peasy'

import { PX_TO_REM } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'

import Typography from '../../../components/Typography'
import Button from '../../../components/Button'

import { ReactComponent as PlusIcon } from '../../../svg/white_plus.svg'
import { ReactComponent as DoopicIcon } from '../../../svg/logo_icon.svg'

const NotRequestedView = () => {
  const makeRequest = useStoreActions((state) => state.ftp.makeRequest)

  return (
    <div className="not-requested-view">
      <DoopicIcon className="doopic-logo" />
      <div className="title-container">
        <Typography
          fontSize={PX_TO_REM['23']}
          lineHeight={PX_TO_REM['26']}
          font="semibold"
          label={Translation.ftp_connection}
        />
      </div>
      <div className="description-container">
        {Translation.ftp_folders_are_set_up.split('\n').map((label, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={key} className="description-paragraph">
            <Typography variant="s" lineHeight={1.5384615} containsHtml label={label} />
          </div>
        ))}
      </div>
      <div className="request-btn-container">
        <Button
          size="s"
          onClick={() => makeRequest()}
          label={(
            <>
              <PlusIcon />
              <Typography variant="xs" font="semibold" inlineBlock label={Translation.request_ftp_account} />
            </>
          )}
        />
      </div>
    </div>
  )
}

export default NotRequestedView
