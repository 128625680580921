import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { DEFAULT_PAGE } from '../../helpers/Constants'
import { isAdminRole } from '../../helpers/Utils'

const SwitchUser = () => {
  const user = useStoreState((actions) => actions.user.user)
  const switchAccount = useStoreActions((actions) => actions.user.switchAccount)
  const params = useParams()

  const handleSwitchAccount = async () => {
    const result = await switchAccount({
      user_account_id_code: params.id,
    })
    if (result.success) {
      window.location.href = DEFAULT_PAGE
    }
  }

  useEffect(() => {
    // check if the user is logged in and the role is one of the valid roles, then call the API to switch the user
    // if the user is not logged in, redirect to "/login" page
    // if the user is logged in but the role is not valid, redirect to "/dashboard"
    if (Object.keys(user).length > 0) {
      if (isAdminRole(user.role)) {
        handleSwitchAccount().then(() => { })
      } else {
        window.location.href = DEFAULT_PAGE
      }
    }
  }, [user])

  return null
}

export default SwitchUser
