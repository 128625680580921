import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { Translation, DOOPIC_URLS } from '../../helpers/Translation'
import { Cookie } from '../../helpers/Cookie'
import { DEFAULT_PAGE } from '../../helpers/Constants'

import Link from '../../components/Link'

import './index.scss'

const Footer = ({ isNewTab }) => {
  const location = useLocation()

  const isShowRating = useStoreState((state) => state.layout.isShowRating)
  const updateShowRating = useStoreActions((state) => state.layout.updateShowRating)
  const updateRatingExpanded = useStoreActions((state) => state.layout.updateRatingExpanded)
  const dashboardData = useStoreState((state) => state.order.dashboardData)
  const user = useStoreState((state) => state.user.user)

  const [showRateUsLink, setShowRateUsLink] = useState(location.pathname === DEFAULT_PAGE)

  useEffect(() => {
    setShowRateUsLink(location.pathname === DEFAULT_PAGE && dashboardData?.is_service_rated === false
      && Cookie.getCookie('no_review_footer') === '1')
  }, [dashboardData, isShowRating, location.pathname])

  const handleOpenCookieSettings = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const cookieSettingButton = document.querySelector('.ot-floating-button__open')
    if (cookieSettingButton) cookieSettingButton.click()
  }

  const handleShowRateUs = (e) => {
    e.preventDefault()
    e.stopPropagation()
    Cookie.deleteCookie('no_review_footer')
    updateShowRating(true)
    updateRatingExpanded(true)
    setShowRateUsLink(false)
  }

  return (
    <div className="footer--wrap">
      <div className="footer">
        <Link
          href={`${DOOPIC_URLS.home}`}
          variant="xs"
          theme="dark"
          label={DOOPIC_URLS.home.replace('https://', '')}
          isNewTab
        />

        <span className="footer-link__separator" />

        <Link
          href={Object.keys(user).length ? DOOPIC_URLS.support : DOOPIC_URLS.contact}
          variant="xs"
          theme="dark"
          label={Translation.support}
          isNewTab={!Object.keys(user).length}
        />

        <span className="footer-link__separator" />

        <Link
          href={DOOPIC_URLS.prices}
          variant="xs"
          theme="dark"
          label={Translation.prices}
          isNewTab={isNewTab}
        />

        <span className="footer-link__separator" />

        <Link
          href={DOOPIC_URLS.terms}
          variant="xs"
          theme="dark"
          label={Translation.terms}
          isNewTab={isNewTab}
        />

        <span className="footer-link__separator" />

        <Link
          href={DOOPIC_URLS.privacy}
          variant="xs"
          theme="dark"
          label={Translation.privacy}
          isNewTab={isNewTab}
        />

        <span className="footer-link__separator" />

        <Link
          href={DOOPIC_URLS.open_cookie_settings}
          variant="xs"
          theme="dark"
          label={Translation.open_cookie_settings}
          isNewTab={isNewTab}
          onClick={(e) => handleOpenCookieSettings(e)}
        />

        {showRateUsLink && (
          <div className="rate-us-container">
            <span className="footer-link__separator" />

            <Link
              href="/"
              variant="xs"
              theme="dark"
              label={Translation.rate_us}
              onClick={(e) => handleShowRateUs(e)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

Footer.propTypes = {
  isNewTab: PropTypes.bool,
}

Footer.defaultProps = {
  isNewTab: false,
}

export default Footer
