import React, { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  MenuItem,
  FormControl,
  Select,
  Pagination,
  PaginationItem,
  Tooltip,
  TableContainer,
  Collapse,
} from '@mui/material'

import { Translation } from '../../helpers/Translation'
import {
  TABLE_ROWS_PER_PAGE, TABLE_ROWS_PER_PAGE_OPTIONS, TABLE_TRUNCATE_LENGTH, PX_TO_REM,
} from '../../helpers/Constants'

import Typography from '../Typography'

import { ReactComponent as EditSvg } from '../../svg/edit.svg'
import { ReactComponent as TrashSvg } from '../../svg/trash.svg'
import { ReactComponent as PreviousSvg } from '../../svg/previous.svg'
import { ReactComponent as NextSvg } from '../../svg/next.svg'
import { ReactComponent as ChevronDownSvg } from '../../svg/chevron_down.svg'
import { ReactComponent as CheckSvg } from '../../svg/check.svg'
import { ReactComponent as InfoSvg } from '../../svg/info.svg'
import { ReactComponent as DownloadSvg } from '../../svg/download.svg'
import { ReactComponent as PlusAngularSvg } from '../../svg/plus-angular.svg'

import './index.scss'

const DynamicTable = ({
  data,
  count,
  columns,
  onEdit,
  onDelete,
  onSort,
  defaultSortColumn,
  defaultSortDirection,
  onRowsPerPageChange,
  rowsPerPageValue,
  pageValue,
  onPageChange,
  collapseContents,
  tableType,
  handleDownload,
  handleAdd,
  cellHeight,
  emptyDataText,
  showPagination,
  showRowsPerPage,
  scrollable,
  divRef,
  onRowClick,
  isAllRowClosed,
  tableContainerStyle,
}) => {
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const [sortColumn, setSortColumn] = useState(defaultSortColumn)
  const [sortDirection, setSortDirection] = useState(defaultSortDirection)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_PER_PAGE)
  const rowsPerPageOptions = TABLE_ROWS_PER_PAGE_OPTIONS

  const handleSort = (columnId, currentSortDirection) => {
    if (columnId === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(columnId)
      setSortDirection('asc')
    }
    // eslint-disable-next-line no-nested-ternary
    const newSortDirection = columnId === sortColumn ? currentSortDirection === 'asc' ? 'desc' : 'asc' : 'asc'
    onSort(columnId, newSortDirection)
  }

  const handleEdit = (row) => {
    onEdit(row)
  }

  const handleDelete = (row) => {
    onDelete(row)
  }

  const handleRowsPerPageChange = (e) => {
    onPageChange(1)
    setPage(1)
    onRowsPerPageChange(e.target.value)
    setRowsPerPage(e.target.value)
  }

  const handlePageChange = (event, value) => {
    onPageChange(value)
    setPage(value)
  }

  useEffect(() => {
    if (pageValue) {
      setPage(pageValue)
    }
  }, [pageValue])

  useEffect(() => {
    if (rowsPerPageValue) {
      setRowsPerPage(rowsPerPageValue)
    }
  }, [rowsPerPageValue])

  useEffect(() => {
    if (showPagination) {
      const targetElement = document.querySelector('.content')
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [page])

  const renderHeader = () => (
    <TableHead className={`table-head ${scrollable ? 'table-head-fixed' : ''}`}>
      <TableRow>
        {columns.map((column) => (
          (column.id === 'edit-delete') ? (
            <TableCell
              key={column.id}
              className="table-head-cell"
              style={{ width: `${column.width}rem`, minWidth: `${column.width}rem` }}
            />
          ) : (
            <TableCell
              key={column.id}
              sortDirection={column.id === sortColumn ? sortDirection : false}
              className="table-head-cell"
              style={{ width: `${column.width}rem`, minWidth: `${column.width}rem` }}
            >
              {column.sortable ? (
                <TableSortLabel
                  active={column.id === sortColumn}
                  direction={sortDirection}
                  onClick={() => handleSort(column.id, sortDirection)}
                  className="table-head-cell-label"
                  IconComponent={ChevronDownSvg}
                >
                  <Typography
                    variant="caption"
                    label={column.label}
                    font="medium"
                    fontSize={PX_TO_REM['13']}
                    lineHeight={PX_TO_REM['16']}
                  />
                </TableSortLabel>
              ) : (
                <Typography
                  variant="caption"
                  label={column.label}
                  font="medium"
                  fontSize={PX_TO_REM['13']}
                  lineHeight={PX_TO_REM['16']}
                />
              )}
            </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  )

  const renderCell = (row, column) => {
    if (row.emptyDataText) {
      return (
        <TableCell key={1} className="table-row-cell empty-cell" align={column.align} colSpan={columns.length}>
          <Typography
            variant="xs-narrow"
            isSpan
            font="italic"
            label={row.emptyDataText}
            containsHtml
            style={{ cursor: 'default' }}
            theme="dark"
          />
        </TableCell>
      )
    }

    // if the value is null or empty string, replace it with '-'
    if (row[column.id] === null || row[column.id] === '' || typeof row[column.id] === 'undefined') {
      return (
        <TableCell key={`${row.id}-${column.id}`} className="table-row-cell" align={column.align}>
          <Typography
            variant="xs-narrow"
            isSpan
            label="-"
            style={{ cursor: 'default' }}
          />
        </TableCell>
      )
    }

    if (column.id === 'edit-delete') {
      return (
        <TableCell key={column.id} className="table-row-cell" align={column.align}>
          <div className="table-row-cell-buttons">
            <button type="button" className="table-row-cell-buttons-edit" onClick={() => handleEdit(row)}>
              <EditSvg />
            </button>
            <button type="button" className="table-row-cell-buttons-delete" onClick={() => handleDelete(row)}>
              <TrashSvg />
            </button>
          </div>
        </TableCell>
      )
    }

    if (column.id === 'debt') {
      let label = ' '
      if (row[column.id] === 'paid') label = Translation.paid
      else if (row[column.id] === 'unpaid') label = Translation.unpaid
      else if (row[column.id] === 'corrected') label = Translation.corrected
      else if (row[column.id] === 'processing') label = Translation.processing

      return (
        <TableCell key={`${row.id}-${column.id}`} className="table-row-cell" align={column.align}>
          <span className={`table-row-cell-debtBox ${row[column.id]}`}>
            {row[column.id] === 'paid' && <CheckSvg />}
            {row[column.id] === 'corrected' && <InfoSvg />}
            <Typography
              variant="xs-narrow"
              isSpan
              label={label}
              font="medium"
              fontSize={PX_TO_REM['12']}
            />
          </span>
        </TableCell>
      )
    }

    if (column.id === 'download') {
      return (
        <TableCell key={`${row.id}-${column.id}`} className="table-row-cell" align={column.align}>
          <button type="button" className="table-row-cell-download" onClick={() => handleDownload(row.id)}>
            <DownloadSvg />
          </button>
        </TableCell>
      )
    }

    if (column.id === 'add') {
      return (
        <TableCell key={`${row.id}-${column.id}`} className="table-row-cell" align={column.align}>
          <button type="button" className="table-row-cell-add" onClick={() => handleAdd(row.id)}>
            <PlusAngularSvg />
          </button>
        </TableCell>
      )
    }

    if (column.id === 'turnaround') {
      return (
        <TableCell key={`${row.id}-${column.id}`} className="table-row-cell" align={column.align}>
          <Typography
            variant="xs-narrow"
            isSpan
            label={`${row[column.id]}h`}
            style={{ cursor: 'default' }}
          />
        </TableCell>
      )
    }

    if (column.render === 'jsx') {
      return (
        <TableCell key={`${row.id}-${column.id}`} className="table-row-cell" align={column.align}>
          <span>
            {row[column.id]}
          </span>
        </TableCell>
      )
    }

    if (row[column.id]?.length > TABLE_TRUNCATE_LENGTH || row[column.id]?.length > column.truncateLength) {
      return (
        <TableCell key={`${row.id}-${column.id}`} className="table-row-cell" align={column.align}>
          <Tooltip
            className="info-tooltip"
            PopperProps={{
              disablePortal: true,
            }}
            title={row[column.id]}
            arrow
          >
            <span style={{ cursor: 'pointer' }}>
              <Typography
                variant="xs-narrow"
                isSpan
                label={
                  `
                  ${row[column.id].substring(
                    0,
                    TABLE_TRUNCATE_LENGTH <= column.truncateLength ? TABLE_TRUNCATE_LENGTH : column.truncateLength,
                  )
                  }...
                  `
                }
              />
            </span>
          </Tooltip>
        </TableCell>
      )
    }

    return (
      <TableCell key={`${row.id}-${column.id}`} className="table-row-cell" align={column.align}>
        <Typography
          variant="xs-narrow"
          isSpan
          label={row[column.id]}
          style={{ cursor: 'default' }}
        />
      </TableCell>
    )
  }

  return (
    data.length > 0 ? (
      <div>
        <TableContainer
          className={`table-container scrollbar-overflow ${scrollable ? 'table-scroll' : ''}`}
          style={tableContainerStyle}
          ref={divRef}
        >
          <Table className={`table ${tableType} cell-${cellHeight}`}>
            {renderHeader()}
            <TableBody>
              {data.map((row, index) => (
                <Row
                  key={row.id ? row.id : index}
                  row={row}
                  columns={columns}
                  tableType={tableType}
                  renderCell={renderCell}
                  collapseContent={collapseContents ? collapseContents[row.id] : null}
                  onRowClick={onRowClick}
                  isAllRowClosed={isAllRowClosed}
                  count={count}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {
          (showPagination || showRowsPerPage) && count > 0 && (
            <div className="table-bottom">
              {
                showRowsPerPage && (
                  <FormControl className="table-bottom-perPageOption">
                    <Select
                      value={rowsPerPage}
                      onChange={handleRowsPerPageChange}
                      IconComponent={ChevronDownSvg}
                    >
                      {rowsPerPageOptions.map((option) => (
                        <MenuItem key={option} value={option} className="table-bottom-perPageOption-menuItem">
                          {option}
                          {' '}
                          {Translation.per_page}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }
              {
                showPagination && (
                  <Pagination
                    count={Math.ceil(count / rowsPerPage)}
                    page={page}
                    className={`table-bottom-pagination ${isMobile ? 'mobile' : ''}`}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{ previous: PreviousSvg, next: NextSvg }}
                        {...item}
                        disableRipple
                      />
                    )}
                  />
                )
              }
            </div>
          )
        }
      </div>
    ) : (
      <div className="table-empty">
        <Typography
          variant="h6"
          font="semibold"
          label={Translation[emptyDataText]}
          containsHtml
        />
      </div>
    )
  )
}

const Row = ({
  row,
  columns,
  renderCell,
  collapseContent,
  tableType,
  onRowClick,
  isAllRowClosed,
  count,
}) => {
  const [open, setOpen] = useState(false)

  const onClicked = () => {
    onRowClick(row.id, !open)
    setOpen(!open)
  }

  useEffect(() => {
    if (isAllRowClosed) {
      setOpen(false)
    }
  }, [isAllRowClosed])

  return (
    <>
      <TableRow
        className={`table-row ${count < 1 ? 'cursor-default' : ''} ${(open && tableType === 'table-collapse') && 'open'}`}
        onClick={onClicked}
      >
        {count > 0 && columns.map((column) => (
          renderCell(row, column)
        ))}
        {count === 0 && (
          renderCell(row, columns)
        )}
      </TableRow>
      {
        tableType === 'table-collapse' && (
          <TableRow className={`table-rowCollapse ${open && 'open'}`}>
            <TableCell className="table-rowCollapse-cell" colSpan={columns.length}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div>
                  {open ? collapseContent : null}
                </div>
              </Collapse>
            </TableCell>
          </TableRow>
        )
      }
    </>
  )
}

Row.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      sortable: PropTypes.bool,
    }),
  ).isRequired,
  tableType: PropTypes.oneOf(['table-default', 'table-collapse']).isRequired,
  renderCell: PropTypes.func.isRequired,
  collapseContent: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
  isAllRowClosed: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
}

Row.defaultProps = {
  collapseContent: null,
}

DynamicTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  count: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      sortable: PropTypes.bool,
      width: PropTypes.number,
      truncateLength: PropTypes.number,
    }),
  ).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSort: PropTypes.func,
  defaultSortColumn: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(['', 'asc', 'desc']),
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageValue: PropTypes.number,
  onPageChange: PropTypes.func,
  pageValue: PropTypes.number,
  collapseContents: PropTypes.instanceOf(Object),
  tableType: PropTypes.oneOf(['table-default', 'table-collapse']),
  handleDownload: PropTypes.func,
  handleAdd: PropTypes.func,
  cellHeight: PropTypes.oneOf(['small', 'default']),
  emptyDataText: PropTypes.string,
  showPagination: PropTypes.bool,
  showRowsPerPage: PropTypes.bool,
  scrollable: PropTypes.bool,
  divRef: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func,
  isAllRowClosed: PropTypes.bool,
  tableContainerStyle: PropTypes.instanceOf(Object),
}

DynamicTable.defaultProps = {
  data: [],
  count: 0,
  onEdit: () => { },
  onDelete: () => { },
  onSort: () => { },
  defaultSortColumn: '',
  defaultSortDirection: 'asc',
  onRowsPerPageChange: () => { },
  rowsPerPageValue: 5,
  onPageChange: () => { },
  pageValue: 1,
  collapseContents: null,
  tableType: 'table-default',
  handleDownload: () => { },
  handleAdd: () => { },
  cellHeight: 'default',
  emptyDataText: 'empty_table_data_text',
  showPagination: true,
  showRowsPerPage: true,
  scrollable: true,
  divRef: null,
  onRowClick: () => { },
  isAllRowClosed: false,
  tableContainerStyle: {},
}

export default DynamicTable
