/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'

import { Translation } from '../../helpers/Translation'
import { isAuthenticated } from '../../helpers/Utils'

import Typography from '../../components/Typography'
import Button from '../../components/Button'

import Footer from '../../layouts/Footer'

import { ReactComponent as LogoIcon } from '../../svg/logo_icon.svg'

import './index.scss'

const MailingUnsubscribe = () => {
  const params = useParams()
  const unsubscribe = useStoreActions((actions) => actions.user.unsubscribeFromMailing)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const [isUnsubscribe, setIsUnsubscribe] = useState(true)
  const handleUnsubscribe = async () => {
    const { lang, user_account_id_code, mailing_id_code } = params
    if (lang && user_account_id_code && mailing_id_code) {
      const result = await unsubscribe({
        lang,
        user_account_id_code,
        mailing_id_code,
        status: isUnsubscribe ? 1 : 0,
      })

      if (result) {
        updateSnackbarState({
          isOpen: true,
          message: isUnsubscribe ? Translation.unsubscribe_successful : Translation.subscribe_successful,
          type: 'success',
        })
        setIsUnsubscribe(!isUnsubscribe)
      } else {
        updateSnackbarState({
          isOpen: true,
          message: Translation.something_went_wrong,
          type: 'error',
        })
      }
    }
  }

  return (
    <div className="mailing-unsubscribe-page">
      <div className="mailing-unsubscribe-page--wrap">
        <div>
          <div className="screen-center-top">
            <div>
              <LogoIcon className="doopic-logo" />
            </div>

            <div className="m-top-20">
              <Typography
                variant="h6"
                font="semibold"
                label={isUnsubscribe ? Translation.unsubscribe_header : Translation.subscribe_header}
              />
            </div>

            <div className="m-top-8">
              <Typography
                variant="xs"
                label={isUnsubscribe ? Translation.unsubscribe_message : Translation.subscribe_message}
              />
            </div>

            <div className="m-top-32">
              <Button label={isUnsubscribe ? Translation.unsubscribe : Translation.subscribe} onClick={handleUnsubscribe} />
            </div>
          </div>
        </div>
        {!isAuthenticated() && (
        <div className="m-top-auto">
          <Footer isNewTab />
        </div>
        )}
      </div>
    </div>
  )
}

export default MailingUnsubscribe
