/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { Translation } from '../../../helpers/Translation'
import { clickToCopy } from '../../../helpers/Utils'

import Typography from '../../../components/Typography'
import Input from '../../../components/Input'
import Link from '../../../components/Link'
import RadioGroup from '../../../components/RadioGroup'
import Button from '../../../components/Button'

import { ReactComponent as CopySvg } from '../../../svg/copy.svg'
import { ReactComponent as NewTabSvg } from '../../../svg/new_tab.svg'

import './index.scss'

const General = ({ galleryData, updateGalleryData }) => {
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)

  const handleUrlCopy = (url) => {
    clickToCopy(url)
  }

  return (
    <section className="public-gallery-settings-section">
      <div className="name-row">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="gallery-name">
          <Typography
            label={`${Translation.name}:`}
          />
        </label>
        <Input type="text" value={galleryData.name} onChange={(e) => updateGalleryData('name', e.target.value)} />
      </div>
      <div className="detail-row">
        <div className="detail-row-column">
          <div className="column-input">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="input-doopic">
              <Typography
                label="Doopic:"
              />
            </label>
            <div className="column-input-icons">
              <Input
                value={galleryData.url || ''}
                onChange={(e) => updateGalleryData('domain_path', e.target.value)}
                disabled
              />
              <div className="column-input-buttons">
                <button type="button" className="copy-button" onClick={() => handleUrlCopy(galleryData.url)}>
                  <CopySvg />
                </button>
                <Link href={galleryData.url} className="new-tab-button" label={<NewTabSvg />} isNewTab />
              </div>
            </div>
          </div>

          <div>
            <Button
              label={Translation.public_gallery_users}
              type="contained"
              width="20"
              onClick={() => navigate('/profile#public-gallery-users')}
            />
          </div>

        </div>
        <div className="detail-row-column">
          <div className="column-input">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="input-password">
              <Typography
                label={`${Translation.password}:`}
              />
            </label>
            <div>
              <div className="column-input-icons">
                <div className="password-input-wrapper">
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={galleryData.password || ''}
                    onChange={(e) => updateGalleryData('password', e.target.value)}
                  />
                  <button type="button" className="show-password" onClick={() => setShowPassword(!showPassword)}>
                    <Typography label={showPassword ? Translation.hide_password : Translation.show_password} />
                  </button>
                </div>
                <div className="column-input-buttons">
                  <Link href={galleryData.url_password} className="new-tab-button" label={<NewTabSvg />} isNewTab />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detail-row-column multiple-inputs">
          <div className="column-input">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="input-logo-left">
              <Typography
                label={`Logo (${Translation.left}):`}
              />
            </label>
            <Input value={galleryData.logo_client || ''} onChange={(e) => updateGalleryData('logo_client', e.target.value)} />
          </div>
          <div className="column-input">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="input-logo-rigth">
              <Typography
                label={`Logo (${Translation.right}):`}
              />
            </label>
            <Input value={galleryData.logo_public || ''} onChange={(e) => updateGalleryData('logo_public', e.target.value)} />
          </div>
        </div>
        <div className="detail-row-column radio-group">
          <RadioGroup
            name="info-box-turnaround"
            value={galleryData.theme}
            values={{
              dark: Translation.dark,
              light: Translation.light,
            }}
            color="gray"
            onChange={(e) => updateGalleryData('theme', e.target.value)}
          />
        </div>
      </div>
    </section>
  )
}
export default General

General.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  galleryData: PropTypes.objectOf(PropTypes.any).isRequired,
  updateGalleryData: PropTypes.func.isRequired,
}
