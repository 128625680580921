import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../helpers/Translation'
import { CENTER_TOP } from '../../helpers/Constants'

import Button from '../Button'
import Typography from '../Typography'
import Modal from '../Modal'

import './index.scss'

const getClassName = (isCentered) => `${isCentered ? 'modal--center' : ''}`

const Dialog = ({
  id,
  title,
  content,
  cancelLabel,
  yesLabel,
  size,
  isShown,
  isWarning,
  isCentered,
  hasOnlyYes,
  hasCloseIcon,
  isDisabledYes,
  top,
  left,
  onClickCloseIcon,
  onClickYes,
  onClickCancel,
  onClickEscClose,
}) => (
  <Modal
    id={id}
    size={size}
    isShown={isShown}
    hasCloseIcon={hasCloseIcon}
    top={top}
    left={left}
    onClickCloseIcon={onClickCloseIcon}
    onClickEscClose={onClickEscClose}
  >
    <div className={getClassName(isCentered)}>
      <div>
        <Typography
          variant="h6"
          font="semibold"
          label={title}
        />
      </div>

      <div className="m-top-8 m-bottom-32">
        {content}
      </div>

      <div className="m-top-auto flex justify-center flex-column--gap-16">
        {
          (!hasOnlyYes)
            ? (
              <div className="column-half">
                <Button
                  type="contained"
                  label={cancelLabel}
                  onClick={onClickCancel}
                />
              </div>
            ) : null
        }

        <div className="column-half">
          <Button
            label={yesLabel}
            isWarning={isWarning}
            onClick={onClickYes}
            disabled={isDisabledYes}
          />
        </div>
      </div>
    </div>
  </Modal>
)

Dialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  cancelLabel: PropTypes.string,
  yesLabel: PropTypes.string,
  size: PropTypes.oneOf(['', 'small', 'medium', 'large', 'xl']),
  isShown: PropTypes.bool,
  isWarning: PropTypes.bool,
  isCentered: PropTypes.bool,
  hasOnlyYes: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,
  isDisabledYes: PropTypes.bool,
  top: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  left: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClickCloseIcon: PropTypes.func,
  onClickYes: PropTypes.func,
  onClickCancel: PropTypes.func,
  onClickEscClose: PropTypes.func,
}

Dialog.defaultProps = {
  id: 'modal',
  title: 'Dialog',
  content: (<Typography label={Translation.my_doopic} variant="xs" />),
  cancelLabel: 'Cancel',
  yesLabel: 'Yes',
  size: '',
  isShown: false,
  isWarning: false,
  isCentered: true,
  hasOnlyYes: false,
  hasCloseIcon: false,
  isDisabledYes: false,
  top: CENTER_TOP,
  left: '50%',
  onClickCloseIcon: () => { },
  onClickYes: () => { },
  onClickCancel: () => { },
  onClickEscClose: () => { },
}

export default Dialog
