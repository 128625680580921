import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  FIELDS_PATTERNS, FIELDS_MIN_LENGTH, OK_PASSWORD_SCORE, LOGIN_PAGE,
} from '../../helpers/Constants'
import { Translation, DOOPIC_URLS } from '../../helpers/Translation'
import {
  isEnterKey, isValidValue, removeInputError,
} from '../../helpers/Utils'

import Button from '../../components/Button'
import Input from '../../components/Input'
import Typography from '../../components/Typography'
import Checkbox from '../../components/Checkbox'
import Link from '../../components/Link'

import Footer from '../../layouts/Footer'
import PasswordStrengthBar from '../../layouts/Common/PasswordStrengthBar'

import { ReactComponent as CheckIcon } from '../../svg/check_curved.svg'

import './index.scss'

const Loader = () => (
  <div>Loading...</div>
)

const SetPassword = () => {
  // init navigation
  const navigate = useNavigate()
  const params = useParams()

  // init all component actions
  const setNewPassword = useStoreActions((actions) => actions.user.setNewPassword)
  const clearApiErrorsAction = useStoreActions((actions) => actions.user.clearApiErrors)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const apiErrors = useStoreState((state) => state.user.apiErrors)

  // init all component states
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [passwordScore, setPasswordScore] = useState(0)
  const [isTerms, setIsTerms] = useState(false)

  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors, setFormErrors] = useState({
    password: Translation.must_be_at_least_chars_long.replace('%MIN_LENGTH%', FIELDS_MIN_LENGTH.password),
    passwordRepeat: Translation.passwords_do_not_match,
  })

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)

  useEffect(() => {
    clearApiErrorsAction()
  }, [])

  // watch API errors change
  useEffect(() => {
    if (apiErrors) {
      // API server is down
      if (apiErrors.serverError) return

      setFormErrors({
        ...formErrors,
        password: (apiErrors.password) ? apiErrors.password : Translation.nonce,
        passwordRepeat: (apiErrors.passwordRepeat) ? apiErrors.passwordRepeat : Translation.nonce,
      })

      setIsFormErrors({ ...isFormErrors, password: true, passwordRepeat: true })
    } else {
      // clear all form errors
      setIsFormErrors({})
    }
  }, [apiErrors])

  const handlePasswordChange = (e) => {
    const input = e.currentTarget
    setPassword(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handlePasswordRepeatChange = (e) => {
    const input = e.currentTarget
    const inputValue = input.value.trim()

    setPasswordRepeat(inputValue)

    if (password === inputValue) {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: false })
    }
  }

  const handlePasswordRepeatBlur = () => {
    if (!passwordRepeat || password === passwordRepeat) {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: false })
    } else {
      setIsFormErrors({ ...isFormErrors, passwordRepeat: true })
    }
  }

  const handleTermsChange = (e) => {
    const input = e.currentTarget
    setIsTerms(input.checked)

    setIsFormErrors({ ...isFormErrors, terms: !input.checked })
  }

  const isFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(password, FIELDS_PATTERNS.simplePassword) || passwordScore < OK_PASSWORD_SCORE) {
      isValid = false
      isFormErrorsUpdated.password = !isValid
    }

    if (!passwordRepeat || password !== passwordRepeat) {
      isValid = false
      isFormErrorsUpdated.passwordRepeat = !isValid
    }

    if (!isTerms) {
      isValid = false
      isFormErrorsUpdated.terms = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const handleSubmitClick = async () => {
    if (!isFormValid()) return

    setIsSubmitButtonDisabled(true)

    const result = await setNewPassword({
      user_account_id_code: params.token,
      password: password.trim(),
      'password-repeat': passwordRepeat.trim(),
    })

    setIsSubmitButtonDisabled(false)

    if (result) {
      // show snackbar with success
      updateSnackbarState({
        isOpen: true,
        message: Translation.password_created_successfully,
        type: 'success',
      })
      navigate(LOGIN_PAGE)
    } else {
      // show snackbar with error
      updateSnackbarState({
        isOpen: true,
        message: Translation.nonce,
        type: 'error',
      })
    }
  }

  const handleInputKeyUp = (e) => {
    if (isEnterKey(e.key)) {
      handleSubmitClick().then(() => { })
    }
  }

  return (
    <div className="set-password-page">
      <div className="set-password-form--wrap">
        <div>

          <div className="screen-center-top">
            <div className="set-password-form__line">
              <Typography
                variant="xl"
                theme="dark"
                noWrap
                font="semibold"
                label={Translation.create_password}
              />
            </div>

            <div className="set-password-form__line m-top-25">
              <Input
                type="password"
                name="password"
                size="s"
                label={`${Translation.password}*`}
                error={formErrors.password}
                isError={isFormErrors.password}
                pattern={FIELDS_PATTERNS.simplePassword}
                placeholder={Translation.create_password}
                value={password}
                onChange={handlePasswordChange}
                onKeyUp={handleInputKeyUp}
                disableAutocomplete
              />
              {
                (password || isFormErrors.password) ? (
                  <React.Suspense fallback={<Loader />}>
                    <PasswordStrengthBar
                      password={password}
                      minLength={FIELDS_MIN_LENGTH.password}
                      setPasswordScore={(score) => setPasswordScore(score)}
                    />
                  </React.Suspense>
                ) : null
              }
            </div>

            <div className="set-password-form__line m-top-25">
              <Input
                type="password"
                name="repeat-password"
                size="s"
                label={`${Translation.repeat_password}*`}
                error={formErrors.passwordRepeat}
                isError={isFormErrors.passwordRepeat}
                pattern={FIELDS_PATTERNS.simplePassword}
                placeholder={Translation.repeat_a_password}
                value={passwordRepeat}
                onChange={handlePasswordRepeatChange}
                onBlur={handlePasswordRepeatBlur}
                onKeyUp={handleInputKeyUp}
                {...(passwordRepeat && (passwordRepeat === password)) && { endAdornment: <CheckIcon /> }}
                disableAutocomplete
              />
            </div>

            <div className="set-password-form__line m-top-20">
              <div className="align-left flex">
                <div>
                  <Checkbox
                    id="terms-conditions-checkbox"
                    checked={isTerms}
                    onChange={handleTermsChange}
                  />
                </div>
                <div className="min-width-330">
                  <Typography
                    variant="xs"
                    theme="light"
                    inline
                    label={Translation.register_terms_agree_1}
                  />
                  {' '}
                  <Link
                    href={DOOPIC_URLS.terms}
                    variant="xs"
                    theme="light"
                    underline
                    label={Translation.register_terms_agree_2}
                    isNewTab
                  />
                  {' '}
                  <Typography
                    variant="xs"
                    theme="light"
                    inline
                    label={Translation.register_terms_agree_3}
                  />
                  {' '}
                  <Link
                    href={DOOPIC_URLS.privacy}
                    variant="xs"
                    theme="light"
                    underline
                    label={Translation.register_terms_agree_4}
                    isNewTab
                  />
                  <Typography
                    variant="xs"
                    theme="light"
                    inline
                    label={Translation.register_terms_agree_5}
                  />
                </div>
              </div>
            </div>

            {isFormErrors.terms ? (
              <div id="terms-conditions-error" className="set-password-form__line m-top-4 align-left error">
                <Typography
                  variant="xs"
                  theme="error"
                  inline
                  label={Translation.accept_terms_conditions_1}
                />
                {' '}
                <Typography
                  variant="xs"
                  theme="error"
                  inline
                  font="semibold"
                  label={Translation.accept_terms_conditions_2}
                />
                {' '}
                <Typography
                  variant="xs"
                  theme="error"
                  inline
                  label={Translation.accept_terms_conditions_3}
                />
                {' '}
                <Typography
                  variant="xs"
                  theme="error"
                  inline
                  font="semibold"
                  label={Translation.accept_terms_conditions_4}
                />
                .
              </div>
            ) : null}

            <div className="set-password-form__line m-top-24">
              <Button
                size="s"
                label={Translation.set_password}
                disabled={isSubmitButtonDisabled}
                onClick={handleSubmitClick}
                id="set-password--button"
              />
            </div>
          </div>

        </div>

        <div className="m-top-auto">
          <Footer isNewTab />
        </div>
      </div>
    </div>
  )
}

export default SetPassword
