import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'

import { Translation } from '../../helpers/Translation'
import { DEFAULT_PAGE, LOGIN_PAGE } from '../../helpers/Constants'
import { isAuthenticated, debounce } from '../../helpers/Utils'
import CheckUTMParams from '../../helpers/CheckUTMParams'

import LangMenu from '../LangMenu'
import SnackBar from '../../components/SnackBar'
import Typography from '../../components/Typography'

import DotAnimation from '../Common/DotAnimation'

import { ReactComponent as LogoSvg } from '../../svg/logo.svg'

import './index.scss'

const LayoutNotLogged = ({
  title, isHeader, isHalf, isHalfRight, noAuth, children,
}) => {
  const setUTMTags = CheckUTMParams()
  const navigate = useNavigate()
  // init page title
  document.title = `${title} / ${Translation.my_doopic}`

  // init all component actions
  const updateBreakpoints = useStoreActions((actions) => actions.layout.updateBreakpoints)

  const menuState = useStoreState((state) => state.layout.menuState)
  const updateMenuState = useStoreActions((actions) => actions.layout.updateMenuState)

  const requestCount = useStoreState((state) => state.global.requestCount)
  const snackbarState = useStoreState((state) => state.global.snackbarState)
  const backdropState = useStoreState((state) => state.global.backdropState)

  useEffect(() => {
    if (isAuthenticated() && !noAuth) {
      window.location.href = DEFAULT_PAGE
    }
    setUTMTags()
    // listen to window resize
    window.addEventListener('resize', debounce(() => {
      updateBreakpoints()
    }), true)
  }, [])

  const handleLayoutClick = () => {
    if (menuState.lang) {
      updateMenuState({
        ...menuState,
        lang: false,
      })
    }
  }

  const handleLogoClick = (e) => {
    e.preventDefault()
    if (isAuthenticated()) {
      navigate(DEFAULT_PAGE)
    } else {
      navigate(LOGIN_PAGE)
    }
  }

  const renderLogo = () => (
    <div className="header-logo--wrap">
      <div className="header-logo">
        <div
          onClick={handleLogoClick}
          id="logo-link"
        >
          <LogoSvg className="header-logo--icon" />
        </div>
      </div>
    </div>
  )

  // show empty page, before redirect
  if (isAuthenticated() && !noAuth) return null

  return (
    <div
      className="not-logged-page"
      onClick={handleLayoutClick}
      id="layout"
    >
      <div className="layout--wrap">
        {
          isHeader ? renderLogo() : null
        }

        <LangMenu isWhite={(isHalf && !isHalfRight)} isNotLogged />

        {children}

        <SnackBar isOpen={snackbarState.isOpen} />

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropState.isOpen}
        >
          <div className="backdrop-content">
            <Typography variant="xs" label={backdropState.message} />
          </div>
        </Backdrop>
      </div>

      {requestCount > 0 && <div className="screen-center general-loading-icon"><DotAnimation /></div>}
    </div>
  )
}

LayoutNotLogged.propTypes = {
  title: PropTypes.string,
  isHeader: PropTypes.bool,
  isHalf: PropTypes.bool,
  isHalfRight: PropTypes.bool,
  children: PropTypes.node.isRequired,
  noAuth: PropTypes.bool,
}

LayoutNotLogged.defaultProps = {
  title: '',
  isHeader: false,
  isHalf: false,
  isHalfRight: false,
  noAuth: false,
}

export default LayoutNotLogged
