import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'
import { PAYMENT_MODAL_TYPE, PX_TO_REM } from '../../../../helpers/Constants'

import Typography from '../../../../components/Typography'
import Button from '../../../../components/Button'

import { ReactComponent as PaymentErrorIcon } from '../../../../svg/payment_error.svg'

const PaymentError = ({
  setPaymentModalState,
}) => {
  const paymentMethods = useStoreState((state) => state.invoice.paymentMethods)
  const setPaymentInProcess = useStoreActions((state) => state.invoice.setPaymentInProcess)

  const handleTryAgain = () => {
    if (paymentMethods.length > 0) {
      setPaymentModalState(PAYMENT_MODAL_TYPE.paymentList)
    } else {
      setPaymentModalState(PAYMENT_MODAL_TYPE.form)
    }
  }

  useEffect(() => {
    setPaymentInProcess(false)
  }, [])

  return (
    <div className="after-payment-status">
      <PaymentErrorIcon className="payment-error-icon" />
      <div className="title">
        <Typography
          variant="xl"
          font="semibold"
          theme="dark"
          label={Translation.payment_unsuccessful}
        />
      </div>
      <div className="description">
        <Typography
          variant="h6"
          lineHeight={PX_TO_REM['25']}
          label={Translation.payment_not_processed}
        />
      </div>
      <div className="try-again">
        <Button
          label={Translation.try_again}
          width="auto"
          onClick={handleTryAgain}
        />
      </div>
    </div>
  )
}

PaymentError.propTypes = {
  setPaymentModalState: PropTypes.func,
}

PaymentError.defaultProps = {
  setPaymentModalState: () => { },
}

export default PaymentError
