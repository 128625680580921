import React,
{
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { ShepherdTourContext } from 'react-shepherd'

import { Translation } from '../../helpers/Translation'
import {
  COOKIE_NAMES,
  DEFAULT_PAGE,
  NEW_ORDER_PAGE,
  TABLES,
  TEST_ORDER_PAGE,
  PX_TO_REM,
} from '../../helpers/Constants'
import {
  getDashboardFilters,
  getPerPage,
  saveDashboardFilters,
  savePerPage,
} from '../../helpers/Utils'
import { Cookie } from '../../helpers/Cookie'

import Typography from '../../components/Typography'
import Table from '../../components/Table'

import NewRegisterBox from './NewRegisterBox'
import DashboardInfoBox from './DashboardInfoBox'
import OrdersTableContent from './OrdersTableContent'
import OrdersTableTop from './OrdersTableTop'
import { columns, columnsMobile, transformedData } from './OrdersTableData'
import RateModal from './OrdersTableContent/OrderDetailsRate/RateModal'
import EkomiModal from './OrdersTableContent/OrderDetailsRate/EkomiModal'

import BillingAddress from '../../layouts/Common/BillingAddress'

import { ReactComponent as RateCloseIcon } from '../../svg/status_close.svg'
import { ReactComponent as StarFillSvg } from '../../svg/star_fill.svg'
import { ReactComponent as RateSvg } from '../../svg/rate-icon.svg'
import { ReactComponent as PlusSvg } from '../../svg/plus.svg'

import './index.scss'

const Dashboard = () => {
  // init all component actions
  const user = useStoreState((state) => state.user.user)
  const orders = useStoreState((state) => state.order.orders)
  const ordersCount = useStoreState((state) => state.order.ordersCount)
  const isAnyOrderPlaced = useStoreState((state) => state.order.isAnyOrderPlaced)
  const dashboardData = useStoreState((state) => state.order.dashboardData)
  const orderDetails = useStoreState((state) => state.order.orderDetails)
  const unpaidDetails = useStoreState((state) => state.invoice.unpaidDetails)
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const isOrderDetailDataAgain = useStoreState((state) => state.order.isOrderDetailDataAgain)
  const isPendingConfirmation = useStoreState((state) => state.user.isPendingConfirmation)
  const isShowRating = useStoreState((state) => state.layout.isShowRating)
  const isRatingExpanded = useStoreState((state) => state.layout.isRatingExpanded)
  const updateShowRating = useStoreActions((state) => state.layout.updateShowRating)
  const updateRatingExpanded = useStoreActions((state) => state.layout.updateRatingExpanded)
  const getOrders = useStoreActions((actions) => actions.order.getOrders)
  const logoutAction = useStoreActions((actions) => actions.user.logout)
  const confirmEmailAction = useStoreActions((actions) => actions.user.confirmEmail)
  const meAction = useStoreActions((actions) => actions.user.me)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const getUnpaidDataDashboard = useStoreActions((actions) => actions.invoice.getUnpaidDataDashboard)
  const getDashboardData = useStoreActions((actions) => actions.order.getDashboardData)
  const getOrderDetails = useStoreActions((actions) => actions.order.getOrderDetails)
  const deleteOrderDetails = useStoreActions((actions) => actions.order.deleteOrderDetails)
  const removeOrderDetailsState = useStoreActions((actions) => actions.order.removeOrderDetailsState)
  const setIsOrderDetailDataAgain = useStoreActions((actions) => actions.order.setIsOrderDetailDataAgain)
  const getOrderRating = useStoreActions((actions) => actions.order.getOrderRating)
  const setStepperShown = useStoreActions((actions) => actions.user.setStepperShown)
  const userAccountPendingConfirmation = useStoreActions((actions) => actions.user.userAccountPendingConfirmation)
  const getOrderTestImageConfirm = useStoreActions((actions) => actions.order.getOrderTestImageConfirm)
  const [unpaidInvoiceIds, setUnpaidInvoiceIds] = useState(null)
  const [processingInvoiceIds, setProcessingInvoiceIds] = useState(null)
  const [isDashboardLoading, setIsDashboardLoading] = useState(true)

  const params = useParams()
  const [searchParams] = useSearchParams()
  const orderQuery = searchParams.get('order')
  const acceptTestImageQuery = searchParams.get('acceptTestImage')
  const workflowQuery = searchParams.get('workflow')
  const navigate = useNavigate()

  const defaultFilterOptions = {
    filter_order_status: '',
    filter_date_from: null,
    filter_date_till: null,
    filter_tid: [],
  }

  const [isUser, setIsUser] = useState(false)
  const [isOrders, setIsOrders] = useState(false)
  const [rowCollapseContent, setRowCollapseContent] = useState({})
  const [rowsPerPage, setRowsPerPage] = useState(getPerPage(TABLES.DASHBOARD_ORDERS))
  const [page, setPage] = useState(1)
  const [filterOptions, setFilterOptions] = useState(null)
  const [searchValue, setSearchValue] = useState(orderQuery || '')
  const [isAllRowClosed, setIsAllRowClosed] = useState(true)
  const [lastValues, setLastValues] = useState({
    search: '',
    filterOptions,
  })
  const [sortBy, setSortBy] = useState('')
  const [sortType, setSortType] = useState('')
  const [hoveredStars, setHoveredStars] = useState(0)
  const [showRateModal, setShowRateModal] = useState(false)
  const [rate, setRate] = useState(0)
  let ratingTimeout = null
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false)
  const [tableData, setTableData] = useState([])
  const [emptyDataText, setEmptyDataText] = useState(Translation.no_orders_found)

  const [isSingleOrderPage, setIsSingleOrderPage] = useState()

  useEffect(() => {
    if (searchValue && Object.keys(filterOptions || {}).length > 0) {
      // eslint-disable-next-line max-len
      setIsSingleOrderPage(orderQuery && (searchValue === orderQuery) && JSON.stringify(filterOptions) === JSON.stringify(defaultFilterOptions))
    } else {
      setIsSingleOrderPage(true)
    }
  }, [searchValue, filterOptions])

  useEffect(() => {
    if (isSingleOrderPage === false) {
      navigate({ search: '' })
    }
  }, [isSingleOrderPage])

  const { show: showTour } = useContext(ShepherdTourContext)

  useEffect(() => {
    if (JSON.stringify(filterOptions) === JSON.stringify(defaultFilterOptions) && searchValue !== '') {
      // Searching with no results
      setEmptyDataText('dashboard_search_with_no_results')
    } else if (JSON.stringify(filterOptions) !== JSON.stringify(defaultFilterOptions) && searchValue === '') {
      // Filter with no results
      setEmptyDataText('dashboard_filter_with_no_results')
    } else if (JSON.stringify(filterOptions) !== JSON.stringify(defaultFilterOptions) && searchValue !== '') {
      // No filter and no search
      setEmptyDataText('dashboard_filter_and_search_with_no_results')
    } else {
      setEmptyDataText('empty_table_data_text')
    }
  }, [tableData])

  useEffect(() => {
    setTableData(transformedData(orders, unpaidInvoiceIds, processingInvoiceIds, isAnyOrderPlaced))
  }, [orders, unpaidInvoiceIds, processingInvoiceIds, orderQuery])

  const fetchDashboardRelatedData = useCallback(async () => {
    await getDashboardData()
    await getUnpaidDataDashboard()
  }, [])

  const fetchOrders = async (
    pageNumber = 1,
    limit = getPerPage(TABLES.DASHBOARD_ORDERS),
    filterOptionsData = null,
    nameSearch = null,
    tableSortBy = null,
    tableSortType = null,
    saveFilters = true,
  ) => {
    setIsDashboardLoading(true)
    setIsAllRowClosed(true)
    await getOrders({
      page: pageNumber,
      limit,
      ...filterOptionsData,
      name_search: nameSearch,
      sort_by: tableSortBy,
      sort_order: tableSortType,
    })
    setIsOrders(true)
    setIsAllRowClosed(false)
    setLastValues({
      search: searchValue,
      filterOptions: filterOptionsData,
      page: pageNumber,
      rowsPerPage: limit,
      sortBy: tableSortBy,
      sortType: tableSortType,
    })
    setIsDashboardLoading(false)

    // get unpaid details with delay, so it does not block other requests
    setTimeout(() => {
      fetchDashboardRelatedData().then(() => { })
    }, 200)

    if (saveFilters && Object.entries(user).length) {
      saveDashboardFilters({ filterOptionsData, nameSearch }, user.id)
    }
  }

  const fetchSingleOrder = async () => {
    if (acceptTestImageQuery) {
      const result = await getOrderTestImageConfirm({ id: orderQuery, status: true })
      updateSnackbarState({
        message: result ? Translation.test_image_confirmed_ok : Translation.test_image_confirmed_error,
        isOpen: true,
        type: result ? 'success' : 'error',
      })
    }
    fetchOrders(1, 1, filterOptions, orderQuery, sortBy, sortType, false).then(() => { })
  }

  useEffect(() => {
    if (Object.entries(user).length > 0) {
      if (workflowQuery) {
        setFilterOptions({
          ...defaultFilterOptions,
          filter_tid: [Number(workflowQuery)],
        })
      } else if (orderQuery) {
        setFilterOptions(defaultFilterOptions)
      } else {
        setFilterOptions(getDashboardFilters(user.id)?.filterOptionsData || defaultFilterOptions)
      }
      setSearchValue(
        orderQuery ?? (getDashboardFilters(user.id)?.nameSearch || ''),
      )
    }
  }, [user])

  useEffect(() => {
    if (Object.entries(user).length > 0 && filterOptions && searchValue !== null) {
      if (orderQuery && searchValue === '' && JSON.stringify(filterOptions) === JSON.stringify(defaultFilterOptions)) {
        fetchSingleOrder().then(() => { })
      } else if (lastValues.search !== searchValue
        || JSON.stringify(lastValues.filterOptions) !== JSON.stringify(filterOptions)) {
        fetchOrders(
          1,
          rowsPerPage,
          filterOptions,
          searchValue,
          sortBy,
          sortType,
        ).then(() => { })
        setPage(1)
      } else if (
        page !== lastValues.page
        || rowsPerPage !== lastValues.rowsPerPage
        || sortBy !== lastValues.sortBy
        || sortType !== lastValues.sortType
      ) {
        fetchOrders(
          page,
          rowsPerPage,
          filterOptions,
          searchValue,
          sortBy,
          sortType,
        ).then(() => { })
      }
      savePerPage(TABLES.DASHBOARD_ORDERS, rowsPerPage)
      removeOrderDetailsState()
      setIsSingleOrderPage(
        // eslint-disable-next-line
        (orderQuery && (searchValue === orderQuery) && JSON.stringify(filterOptions) === JSON.stringify(defaultFilterOptions)) || false,
      )
    }
  }, [page, rowsPerPage, filterOptions, orderQuery, searchValue, sortBy, sortType])

  useEffect(() => {
    const obj = {}
    orderDetails.forEach((item) => {
      obj[item.id] = (
        <OrdersTableContent
          orderDetails={{ ...item }}
          orderName={orders.find((order) => order.id === item.id)?.name}
          isLargeView={user?.is_large_view}
        />
      )
    })
    setRowCollapseContent({ ...obj })
    setIsOrderDetailDataAgain(false)
  }, [orderDetails, orderDetails.length, isOrderDetailDataAgain])

  const handleRowClick = async (id, show) => {
    if (id) {
      if (show) {
        if (orderDetails.some((item) => item.id === id)) {
          setRowCollapseContent((current) => ({
            ...current,
            [id]: <OrdersTableContent
              orderDetails={orderDetails.find((item) => item.id)}
              orderName={orders?.find((item) => item.id === id)?.name}
              isLargeView={user?.is_large_view}
            />,
          }))
        } else {
          setRowCollapseContent((current) => ({
            ...current,
            [id]: <OrdersTableContent
              orderName={orders?.find((item) => item.id === id)?.name}
              isLargeView={user?.is_large_view}
            />,
          }))
        }

        await getOrderDetails(id)
      } else {
        setRowCollapseContent((current) => ({
          ...current,
          [id]: null,
        }))
        deleteOrderDetails(id)
      }
    }
  }

  const confirmEmail = async () => {
    if (isUser && user.pending_confirmation === null) {
      return false
    }

    const result = await confirmEmailAction({ pending_confirmation: params.token, user_account_id_code: params.id })
    if (result.is_email_confirmed === true) {
      updateSnackbarState({
        message: Translation.email_confirmed,
        isOpen: true,
        type: 'success',
      })
      meAction()
      getDashboardData()
      fetchOrders().then(() => { })
    } else {
      updateSnackbarState({
        message: Translation.email_not_confirmed,
        isOpen: true,
        type: 'error',
      })
    }

    return true
  }

  useEffect(() => {
    // delete registration cookie, they were used only once to register user
    Cookie.deleteCookie(COOKIE_NAMES.AFFILIATE_PARTNER_ID_COOKIE)
    Cookie.deleteCookie(COOKIE_NAMES.REFERRER_ID_COOKIE)
    Cookie.deleteCookie(COOKIE_NAMES.SALES_REP_ID_COOKIE)

    // if the user lands on the email confirmation, call the api to verify the email
    if (params.token && params.id) {
      confirmEmail().then(() => { })
    }

    return () => {
      meAction()
    }
  }, [])

  useEffect(() => {
    if (Object.entries(user).length) {
      setIsUser(true)
      if (user.is_intro_shown === false) {
        showTour()
        setStepperShown({
          key: 'is_intro_shown',
          value: '1',
        })
      } else if (!user?.is_invoice_address && isAnyOrderPlaced) {
        setIsBillingModalOpen(true)
      }
    }
  }, [user, isAnyOrderPlaced])

  useEffect(() => {
    if (unpaidDetails !== null) {
      setUnpaidInvoiceIds(unpaidDetails?.unpaid_invoice_ids?.map((item) => parseInt(item, 10)))
      setProcessingInvoiceIds(unpaidDetails?.processing_invoices_ids?.map((item) => parseInt(item, 10)))
    }
  }, [unpaidDetails])

  useEffect(() => {
    updateShowRating(
      // eslint-disable-next-line no-nested-ternary
      dashboardData?.is_service_rated === true
        ? false
        : dashboardData?.is_service_rated === false
          ? Cookie.getCookie('no_review_footer') !== '1'
          : false,
    )
  }, [dashboardData])

  useEffect(() => {
    if (!isDashboardLoading && isSingleOrderPage && !ordersCount) {
      navigate(DEFAULT_PAGE)
    }
  }, [orders, isDashboardLoading])

  const handleLogOut = (e) => {
    e.preventDefault()
    logoutAction()
  }

  const onRefChange = useCallback((node) => {
    if (node !== null) {
      if (orderQuery && (orderQuery === searchValue)
        && JSON.stringify(filterOptions) === JSON.stringify(defaultFilterOptions)) {
        const tableRowElement = document.querySelector('.table-row')
        if (tableRowElement) {
          tableRowElement.click()
        }
      }
    }
  }, [orderQuery, searchValue, filterOptions])

  if (!isUser) {
    return false
  }

  const userHasConfirmedEmail = () => isUser && user.pending_confirmation === null

  const hideRating = (e) => {
    if (e) e.preventDefault()
    Cookie.setCookie('no_review_footer', 1)
    updateShowRating(false)
  }

  const handleStarHover = (index) => {
    setHoveredStars(index + 1)
  }

  const handleStarLeave = () => {
    setHoveredStars(0)
  }

  const handleSubmit = async (value) => {
    const result = await getOrderRating({ order_id: 0, rating: value })
    getDashboardData()
    setRate(value)
    if (result) {
      updateSnackbarState({
        message: Translation.success_rating_text,
        isOpen: true,
        type: 'success',
      })
      setShowRateModal(true)
      Cookie.setCookie('no_review_footer', 1)
      hideRating()
    } else {
      updateSnackbarState({
        message: Translation.error_rating_text,
        isOpen: true,
        type: 'error',
      })
    }
  }

  const mouseLeaveRating = () => {
    if (isRatingExpanded !== null) {
      ratingTimeout = setTimeout(() => {
        updateRatingExpanded(false)
      }, 1000)
    }
  }

  const handleMouseEnter = () => {
    clearTimeout(ratingTimeout)
  }

  const handleSendConfirmationEmail = async () => {
    const result = await userAccountPendingConfirmation()
    if (result) {
      updateSnackbarState({
        message: Translation.confirmation_email_sent,
        isOpen: true,
        type: 'success',
      })
    } else {
      updateSnackbarState({
        message: Translation.error_confirmation_email_sent,
        isOpen: true,
        type: 'error',
      })
    }
  }

  const processColumns = (columnsData) => {
    if (user.is_to_show_unpaid_orders === false) {
      return columnsData.filter((item) => item.id !== 'debt')
    }
    return columnsData
  }

  if (!userHasConfirmedEmail()) {
    return (
      <NewRegisterBox
        title={Translation.thank_you_for_registering}
        text={isMobile ? Translation.please_verify_email_text_mobile : Translation.please_verify_email_text}
        leftButtonText={Translation.logout}
        rightButtonText={Translation.resend_link}
        rightButtonClick={handleSendConfirmationEmail}
        leftButtonClick={handleLogOut}
        isMobile={isMobile}
        className="full-height"
      />
    )
  }

  if (userHasConfirmedEmail() && !isAnyOrderPlaced && isMobile) {
    return (
      <NewRegisterBox
        title={Translation.you_have_no_active_orders}
        text={Translation.welcome_to_doopic_text_mobile}
        isMobile={isMobile}
        className="full-height"
      />
    )
  }

  if ((!isUser || (isUser && !isOrders)) && !isPendingConfirmation) {
    return null
  }

  if (isSingleOrderPage && !ordersCount) {
    return null
  }

  return (
    <div className="dashboard-page">
      <div className={`dashboard-info-boxs ${isMobile ? 'mobile' : ''}`}>
        <DashboardInfoBox
          title={Translation.open_orders}
          content={!isAnyOrderPlaced ? '-' : dashboardData?.opened_orders_count}
        />
        <DashboardInfoBox
          title={Translation.completed_orders}
          content={!isAnyOrderPlaced ? '-' : dashboardData?.completed_orders_count}
        />
        <DashboardInfoBox
          title={Translation.edited_images}
          content={!isAnyOrderPlaced ? '-' : dashboardData?.completed_images_count}
        />
        <DashboardInfoBox
          title={Translation.next_delivery_eta}
          content={!isAnyOrderPlaced ? '-' : dashboardData?.orders_next_eta}
          containsHtml
        />
      </div>

      <OrdersTableTop
        handleFilterOptions={(values) => setFilterOptions(values)}
        filterOptions={filterOptions}
        setSearchValue={(value) => setSearchValue(value)}
        searchValue={searchValue}
      />

      <div className="orderTable">
        <Table
          columns={isMobile ? columnsMobile : processColumns(columns)}
          data={tableData}
          count={ordersCount}
          pageValue={page}
          onPageChange={(value) => setPage(value)}
          onRowsPerPageChange={(value) => setRowsPerPage(value)}
          rowsPerPageValue={rowsPerPage}
          tableType={isAnyOrderPlaced ? 'table-collapse' : 'table-default'}
          collapseContents={rowCollapseContent}
          onRowClick={(id, show) => handleRowClick(id, show)}
          scrollable={false}
          showRowsPerPage={!isMobile && !isSingleOrderPage}
          showPagination={!isSingleOrderPage}
          isAllRowClosed={isAllRowClosed}
          onSort={(tableSortBy, tableSortType) => { setSortBy(tableSortBy); setSortType(tableSortType) }}
          divRef={onRefChange}
          emptyDataText={emptyDataText}
        />
        {!isAnyOrderPlaced && (
          <NewRegisterBox
            title={Translation.welcome_to_doopic}
            text={Translation.welcome_to_doopic_text}
            leftButtonText={Translation.test_order}
            rightButtonText={(
              <div className="create-order-btn">
                <PlusSvg />
                {Translation.new_order}
              </div>
            )}
            rightButtonClick={() => navigate(NEW_ORDER_PAGE)}
            leftButtonClick={() => navigate(TEST_ORDER_PAGE)}
            hideLogo={!isMobile}
          />
        )}
      </div>

      {isShowRating && !isMobile && (
        <div
          // eslint-disable-next-line no-nested-ternary
          className={`rate-container ${isRatingExpanded === true ? 'open' : isRatingExpanded === false ? 'closed' : ''}`}
          onMouseLeave={mouseLeaveRating}
          onMouseEnter={handleMouseEnter}
        >
          <div className="rate-top" onClick={() => updateRatingExpanded(!isRatingExpanded)}>
            <RateSvg />
            <Typography
              font="medium"
              fontSize={PX_TO_REM['13']}
              lineHeight={PX_TO_REM['11']}
              label={Translation.rate_our_service}
            />
            <button type="button" className="close-button" onClick={hideRating}>
              <RateCloseIcon />
            </button>
          </div>
          <div className="rate-bottom">
            <div className="rate-stars">
              {
                [1, 2, 3, 4, 5].map((item, index) => (
                  <button
                    key={item}
                    type="button"
                    onClick={() => handleSubmit(item)}
                    className={
                      `rate-star ${index < hoveredStars ? 'hovered' : ''} 
                            ${index + 1 === hoveredStars ? 'last-child' : ''}`
                    }
                    onMouseEnter={() => handleStarHover(index)}
                    onMouseLeave={handleStarLeave}
                  >
                    <StarFillSvg />
                  </button>
                ))
              }
            </div>
          </div>
        </div>
      )}
      {
        showRateModal && rate < 3 && (
          <RateModal
            rate={rate}
            orderId={0}
            onCloseModal={(value) => setShowRateModal(value)}
            reviewOptions={orderDetails?.order_rating_review_options}
          />
        )
      }
      {
        showRateModal && rate > 2 && (
          <EkomiModal
            rate={rate}
            orderId={0}
            onCloseModal={(value) => setShowRateModal(value)}
          />
        )
      }
      {isBillingModalOpen && (
        <div
          className="billing-address-modal"
          style={{ display: isBillingModalOpen ? 'block' : 'none' }}
        >
          <BillingAddress
            isOpen={isBillingModalOpen}
            closeModal={() => setIsBillingModalOpen(false)}
            modalTitle={Translation.provide_billing_address_dashboard}
            isDashboard
          />
        </div>
      )}
      <div id={process.env.REACT_APP_EKOMI_CONTAINER_ID} />
    </div>
  )
}

export default Dashboard
