import React, { useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'

import { FIELDS_PATTERNS } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import {
  isEnterKey, isValidValue, removeInputError, validateInputWithError,
} from '../../helpers/Utils'

import Button from '../../components/Button'
import Input from '../../components/Input'
import Typography from '../../components/Typography'
import Link from '../../components/Link'

import Footer from '../../layouts/Footer'

import CheckYourEmail from './CheckYourEmail'

import { ReactComponent as BackArrowSvg } from '../../svg/arrow_back_large.svg'

import './index.scss'

const ForgotPassword = () => {
  // init navigation
  const navigate = useNavigate()

  // init all component actions
  const forgotAction = useStoreActions((actions) => actions.user.forgot)

  // init all component states
  const [email, setEmail] = useState('')

  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors, setFormErrors] = useState({
    email: Translation.enter_valid_email,
  })

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const handleEmailChange = (e) => {
    const input = e.currentTarget
    setEmail(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleEmailBlur = (e) => {
    validateInputWithError(
      e.currentTarget,
      formErrors,
      setFormErrors,
      isFormErrors,
      setIsFormErrors,
      Translation.enter_valid_email,
    )
  }

  const isFormValid = () => {
    let isValid = true

    if (!isValidValue(email, FIELDS_PATTERNS.email)) {
      isValid = false
      setIsFormErrors({ ...isFormErrors, email: !isValid })
      setFormErrors({ ...formErrors, email: Translation.enter_valid_email })
    }

    return isValid
  }

  const handleSubmitClick = async () => {
    if (!isFormValid()) return

    setIsSubmitButtonDisabled(true)

    await forgotAction({
      email,
    })

    setIsSubmitButtonDisabled(false)

    setIsFormSubmitted(true)
  }

  const handleInputKeyUp = (e) => {
    if (isEnterKey(e.key)) {
      handleSubmitClick().then(() => { })
    }
  }

  const handleLinkClick = (e) => {
    e.preventDefault()
    const url = new URL(e.currentTarget.href)
    navigate(url.pathname)
  }

  return (
    <div className="forgot-page">
      <div className="forgot-form--wrap">
        <div>
          {
            isFormSubmitted
              ? (
                <CheckYourEmail
                  email={email}
                  isSubmitButtonDisabled={isSubmitButtonDisabled}
                  handleSubmitClick={handleSubmitClick}
                  handleLinkClick={handleLinkClick}
                />
              )
              : (
                <div className="screen-center-top">
                  <div className="forgot-form__line">
                    <Typography
                      variant="xl"
                      theme="dark"
                      noWrap
                      font="semibold"
                      label={Translation.forgot_password_question}
                    />
                  </div>

                  <div className="forgot-form__line m-top-12 m-bottom-32">
                    <Typography
                      variant="s"
                      theme="light"
                      label={Translation.forgot_password_subtitle}
                    />
                  </div>

                  <div className="forgot-form__line m-top-25">
                    <Input
                      type="text"
                      name="email"
                      size="s"
                      label={Translation.email}
                      error={formErrors.email}
                      isError={isFormErrors.email}
                      pattern={FIELDS_PATTERNS.email}
                      placeholder={Translation.enter_your_email}
                      value={email}
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                      onKeyUp={handleInputKeyUp}
                    />
                  </div>

                  <div className="forgot-form__line m-top-24">
                    <Button
                      size="s"
                      label={Translation.reset_password}
                      disabled={isSubmitButtonDisabled}
                      onClick={handleSubmitClick}
                      id="forgot--button"
                    />
                  </div>

                  <div className="forgot-form__line m-top-32">
                    <a
                      id="back-to-login--arrow"
                      href="/"
                      onClick={handleLinkClick}
                    >
                      <BackArrowSvg className="back-arrow" />
                    </a>

                    <Link
                      id="back-to-login"
                      href="/"
                      variant="xs"
                      theme="dark"
                      font="semibold"
                      label={Translation.back_to_login}
                      onClick={handleLinkClick}
                    />
                  </div>
                </div>
              )
          }
        </div>

        <div className="m-top-auto">
          <Footer isNewTab />
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
