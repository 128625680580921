import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'

import { PX_TO_REM } from '../../../helpers/Constants'
import Typography from '../../../components/Typography'

import './index.scss'

const TableTopInfo = ({
  title,
  titleFontSize = PX_TO_REM['18'],
  chipText,
  description,
  icon,
}) => {
  const isMobile = useStoreState((state) => state.layout.isMobile)

  return (
    <div className={`tableTopInfo ${isMobile ? 'mobile' : ''}`}>
      <div className="tableTopInfo-top">
        {icon}
        <Typography
          variant="h2"
          font="semibold"
          fontSize={isMobile ? PX_TO_REM['14'] : titleFontSize}
          lineHeight={isMobile ? PX_TO_REM['18'] : PX_TO_REM['28']}
          label={title}
        />
        {
          chipText && (
            <span className="tableTopInfo-top-chip">
              <Typography
                variant="body1"
                font="medium"
                fontSize={isMobile ? PX_TO_REM['7'] : PX_TO_REM['12']}
                lineHeight={isMobile ? PX_TO_REM['10'] : PX_TO_REM['18']}
                label={chipText}
              />
            </span>
          )
        }
      </div>
      <div className="tableTopInfo-text">
        {
          (typeof description === 'string') ? (
            <Typography
              variant="xs"
              label={description}
              containsHtml
            />
          ) : (
            <div>
              {description}
            </div>
          )
        }
      </div>
    </div>
  )
}

TableTopInfo.defaultProps = {
  title: '',
  titleFontSize: PX_TO_REM['18'],
  chipText: '',
  description: '',
  icon: null,
}

TableTopInfo.propTypes = {
  title: PropTypes.string,
  titleFontSize: PropTypes.number,
  chipText: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  icon: PropTypes.instanceOf(Object),
}

export default TableTopInfo
