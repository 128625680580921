import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { CENTER_TOP } from '../../helpers/Constants'

import { ReactComponent as CloseSvg } from '../../svg/close.svg'

import './index.scss'

export const getSizeClass = (size = '') => {
  if (size === 'small') return 'modal-small'
  if (size === 'medium') return 'modal-medium'
  if (size === 'large') return 'modal-large'
  if (size === 'xl') return 'modal-xl'
  return ''
}

const getClassName = (size) => `modal ${getSizeClass(size)}`

const Modal = ({
  id,
  size,
  isShown,
  hasCloseIcon,
  top,
  left,
  height,
  onClickCloseIcon,
  onClickOutside,
  onClickEscClose,
  children,
}) => {
  useEffect(() => {
    if (isShown) {
      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          e.preventDefault()
          onClickEscClose()
        }
      }
      window.addEventListener('keydown', handleKeyDown)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }

    return () => { }
  }, [isShown])

  return (
    <div
      className="modal--wrap"
      onClick={onClickOutside}
      style={{ display: isShown ? '' : 'none' }}
    >
      <div
        id={id}
        className={getClassName(size)}
        style={{
          top: /^\d+(\.\d+)?$/.test(top) ? `${top}rem` : top,
          left: /^\d+(\.\d+)?$/.test(left) ? `${left}rem` : left,
          height: /^\d+(\.\d+)?$/.test(height) ? `${height}rem` : height,
        }}
      >
        <div>
          {children}
        </div>

        {
          (hasCloseIcon)
            ? (
              <div className="modal--close-icon" onClick={onClickCloseIcon}>
                <CloseSvg />
              </div>
            ) : null
        }
      </div>
    </div>
  )
}

Modal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['', 'small', 'medium', 'large', 'xl']),
  isShown: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hasCloseIcon: PropTypes.bool,
  top: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  left: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClickCloseIcon: PropTypes.func,
  onClickOutside: PropTypes.func,
  onClickEscClose: PropTypes.func,
}

Modal.defaultProps = {
  id: '',
  size: '',
  isShown: false,
  hasCloseIcon: true,
  top: CENTER_TOP,
  left: '50%',
  height: '',
  onClickCloseIcon: () => { },
  onClickOutside: () => { },
  onClickEscClose: () => { },
}

export default Modal
