import React from 'react'
import PropTypes from 'prop-types'
import SwitchMui from '@mui/material/Switch'

import './index.scss'

const Switch = ({
  id,
  checked,
  disabled,
  color,
  onChange,
  switchRef,
}) => (
  <div className={`switch ${color} ${disabled ? 'disabled' : ''}`}>
    <SwitchMui
      id={id}
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      disabled={disabled}
      size="small"
      ref={switchRef}
    />
  </div>
)

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  onChange: PropTypes.func,
  switchRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}

Switch.defaultProps = {
  checked: false,
  disabled: false,
  color: 'default' || 'white',
  onChange: () => { },
  switchRef: null,
}

export default Switch
