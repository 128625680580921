import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
} from 'react-router-dom'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import Slider from '@mui/material/Slider'
import AvatarEditor from 'react-avatar-editor'

import { Translation, lang } from '../../../helpers/Translation'
import { FIELDS_PATTERNS, VALID_PROFILE_IMAGE_FORMATS, PX_TO_REM } from '../../../helpers/Constants'
import {
  isClientAdminRole, isValidInput, isValidValue, removeInputError,
} from '../../../helpers/Utils'

import Typography from '../../../components/Typography'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'
import Modal from '../../../components/Modal'
import RadioGroup from '../../../components/RadioGroup'

import ProfilePicture from '../../../layouts/Common/ProfilePicture'

import UserInformation from '../Common/UserInformation'
import UnsavedChangesDialog from '../Common/UnsavedChangesDialog'

import { ReactComponent as RemoveIcon } from '../../../svg/close_rounded.svg'

import './index.scss'

const MyDetails = () => {
  const navigate = useNavigate()

  const isMobile = useStoreState((state) => state.layout.isMobile)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)
  const meAction = useStoreActions((actions) => actions.user.me)
  const updateMe = useStoreActions((actions) => actions.user.updateMe)
  const user = useStoreState((state) => state.user.user)
  const userAvatarUpdate = useStoreActions((state) => state.user.userAvatarUpdate)

  const [title, setTitle] = useState(user.gender)
  const [firstname, setFirstname] = useState(user.firstname)
  const [lastname, setLastname] = useState(user.lastname)
  const [telephone, setTelephone] = useState(user.telephone)
  const [avatarBg, setAvatarBg] = useState(user.avatar_bg)
  const [emailNotifications, setEmailNotifications] = useState(user['email-type'])
  const [newsletter, setNewsletter] = useState(user.newsletter ? 'subscribe' : 'unsubscribe')
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [isClientAdmin, setIsClientAdmin] = useState(isClientAdminRole(user?.role))
  const [pictureModalOpen, setPictureModalOpen] = useState(false)
  const [sizeDialogOpen, setSizeDialogOpen] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState(user.avatar_url)
  const [image, setImage] = useState(null)
  const [imageScale, setImageScale] = useState(1)
  const [thumbnail, setThumbnail] = useState(null)
  const [imageChange, setImageChange] = useState(false)
  const [removeImage, setRemoveImage] = useState(false)
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false)
  const [navigateTo, setNavigateTo] = useState(null)
  const [isShowColours, setIsShowColours] = useState(false)
  const colours = [
    '#CCCCCC', '#E2C044', '#839788', '#FEA82F', '#9ABCA7', '#54B9D1', '#BD6B73', '#C7F2A7', '#DCD6F7', '#D1B1CB',
  ]

  const editorRef = useRef(null)
  const fileUploadInputRef = useRef(null)

  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors] = useState({
    firstname: Translation.this_field_is_required,
    lastname: Translation.this_field_is_required,
    telephone: Translation.telephone_error,
  })

  const handleLeaveApprove = () => {
    setLeaveDialogOpen(false)
    navigate(navigateTo)
  }

  const handleClickCancel = () => {
    setLeaveDialogOpen(false)
  }

  useBlocker((e) => {
    if ((isFormChanged || imageChange) && !leaveDialogOpen) {
      setNavigateTo(`${e.nextLocation.pathname}${e.nextLocation.hash}`)
      setLeaveDialogOpen(true)
      return true
    }
    return false
  })

  useEffect(() => {
    meAction()
  }, [])

  useEffect(() => {
    const handleTabClose = (e) => {
      e.preventDefault()
    }
    if (isFormChanged || imageChange) {
      window.addEventListener('beforeunload', handleTabClose)
    } else {
      window.removeEventListener('beforeunload', handleTabClose)
    }

    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [isFormChanged, imageChange])

  useEffect(() => {
    setIsClientAdmin(isClientAdminRole(user?.role))
    setTitle(user.gender)
    setFirstname(user.firstname)
    setLastname(user.lastname)
    setTelephone(user.telephone)
    setAvatarUrl(user.avatar_url)
  }, [user])

  useEffect(() => {
    if (
      user.gender !== title
      || user.firstname !== firstname
      || user.lastname !== lastname
      || user.telephone !== telephone
      || user.avatar_bg !== avatarBg
      || user['email-type'] !== emailNotifications
      || user.newsletter !== (newsletter === 'subscribe' ? 1 : 0)
    ) {
      setIsFormChanged(true)
    } else {
      setIsFormChanged(false)
    }
  }, [user, title, firstname, lastname, telephone, avatarBg, emailNotifications, newsletter])

  const handleUploadClick = () => {
    fileUploadInputRef.current.click()
  }

  const handleFileUpload = () => {
    if (fileUploadInputRef.current.files[0].size > 2097152) {
      setSizeDialogOpen(true)
    } else {
      setImage(fileUploadInputRef.current.files[0])
      setPictureModalOpen(true)
    }
  }

  const getCroppedImg = () => {
    const url = editorRef.current.getImageScaledToCanvas().toDataURL('image/jpeg')
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `thumbnail_${fileUploadInputRef.current.files[0].name}`, { type: 'image/jpeg' })
        setThumbnail(file)
        setPictureModalOpen(false)
        setImageChange(true)
        setAvatarUrl(url)
        setRemoveImage(false)
        setIsShowColours(false)
      })
  }

  const handleRemoveImage = () => {
    setImageChange(true)
    setAvatarUrl(null)
    setRemoveImage(true)
    if (user.avatar_url) setRemoveImage(true)
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleFirstnameChange = (e) => {
    const input = e.currentTarget
    setFirstname(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleLastnameChange = (e) => {
    const input = e.currentTarget
    setLastname(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleTelephoneChange = (e) => {
    const input = e.currentTarget
    if (isValidInput(input)) setTelephone(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const isFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(firstname, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.firstname = !isValid
    }

    if (!isValidValue(lastname, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.lastname = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const updateMeRequest = async () => {
    const news = newsletter === 'subscribe'

    return updateMe({
      ...(title !== user.gender && { gender: title }),
      ...(firstname !== user.firstname && { firstname }),
      ...(lastname !== user.lastname && { lastname }),
      ...(telephone !== user.telephone && { telephone }),
      ...(avatarBg !== user.avatar_bg && { avatar_bg: avatarBg }),
      ...(emailNotifications !== user['email-type'] && { 'email-type': emailNotifications }),
      ...(news !== user.newsletter && { newsletter: news }),
      lang,
    })
  }

  const avatarRequest = async () => {
    if (!removeImage) {
      const data = new FormData()
      data.append('avatar', fileUploadInputRef.current.files[0])
      data.append('avatar_thumbnail', thumbnail)
      return userAvatarUpdate(data)
    }
    return userAvatarUpdate({ avatar: false })
  }

  const onSaveChanges = async () => {
    if (!isFormValid()) return

    const requestList = []
    if (isFormChanged) requestList.push(updateMeRequest())
    if (imageChange) requestList.push(avatarRequest())

    const res = await Promise.all(requestList)
    if (res.every((r) => r)) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.updated_successfully,
        type: 'success',
      })
      meAction()
      setImageChange(false)
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.update_unsuccessful,
        type: 'error',
      })
    }
    if (imageChange) meAction() // This is needed to update profile menu component
  }

  return (
    <div className="my-details">
      <div className="my-details-section">
        {!isMobile && (
          <div className="my-details-title">
            <Typography
              theme="dark"
              variant="s"
              font="semibold"
              lineHeight={PX_TO_REM['20']}
              label={Translation.my_details}
              inlineBlock
            />
            {isClientAdmin && (
              <Chip className="admin-user-chip" label={Translation.admin_user} />
            )}
          </div>
        )}

        <div className="my-details-description">
          {isMobile ? (
            <Typography
              variant="subtitle-narrow"
              label={Translation.manage_templates_on_desktop}
            />
          ) : (
            <Typography
              variant="xs-narrow"
              label={Translation.we_may_send_you_notifications}
            />
          )}
        </div>
        <div className="profile-image-container">
          <div className="image-wrapper">
            <ProfilePicture
              avatarUrl={avatarUrl || user.avatar_url}
              firstname={user.firstname}
              lastname={user.lastname}
              fontSize={PX_TO_REM['21']}
              fontWeight="semibold"
              bgColor={avatarBg}
              width={isMobile ? PX_TO_REM['93'] : PX_TO_REM['62']}
              height={isMobile ? PX_TO_REM['93'] : PX_TO_REM['62']}
              showImage={!removeImage}
            />
            {avatarUrl && !isMobile && (
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                arrow
                disableFocusListener
                disableTouchListener
                title={Translation.delete}
              >
                <button
                  className="remove-btn"
                  type="button"
                  onClick={handleRemoveImage}
                >
                  <RemoveIcon />
                </button>
              </Tooltip>
            )}
          </div>
          {isMobile ? (
            <Typography
              fontSize={PX_TO_REM['16']}
              lineHeight={PX_TO_REM['21']}
              label={Translation.update_avatar_on_desktop}
            />
          ) : (
            <>
              <div className="update-avatar">
                <div className="file-input-container" onClick={handleUploadClick}>
                  <div className="click-to-upload">
                    <Typography
                      theme="dark"
                      variant="xs"
                      font="semibold"
                      label={Translation.click_to_upload}
                    />
                    <Typography variant="xs" font="semibold" label="-" />
                    <Typography variant="xs" label={`JPG (${Translation.max}. 2mb)`} />
                  </div>
                  <input
                    type="file"
                    name="file"
                    ref={fileUploadInputRef}
                    accept={VALID_PROFILE_IMAGE_FORMATS.mime.join()}
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                </div>
                <Tooltip
                  className="info-tooltip"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={Translation.change_colour_tooltip}
                  placement="top-start"
                  arrow
                >
                  <button
                    type="button"
                    className={`change-colour-btn ${avatarUrl ? 'disabled' : ''}`}
                    onClick={() => setIsShowColours(true)}
                    disabled={!!avatarUrl}
                  >
                    <Typography
                      theme="dark"
                      variant="xs"
                      font="semibold"
                      label={Translation.change_colour}
                    />
                  </button>
                </Tooltip>
              </div>
              {
                isShowColours && !avatarUrl && (
                  <div className="colours">
                    {
                      colours.map((colour) => (
                        <div
                          key={colour}
                          className={`colour ${avatarBg === colour ? 'active' : ''}`}
                          style={{ backgroundColor: colour }}
                          onClick={() => setAvatarBg(colour)}
                        />
                      ))
                    }
                  </div>
                )
              }
            </>
          )}

          <Modal
            hasCloseIcon
            isShown={pictureModalOpen}
            onClickCloseIcon={() => setPictureModalOpen(false)}
            onClickEscClose={() => setPictureModalOpen(false)}
            size="large"
          >
            <div className="picture-editor">
              <AvatarEditor
                ref={editorRef}
                className="avatar-editor"
                width={300}
                height={300}
                image={image}
                borderRadius={1000}
                backgroundColor="#ffffff"
                border={0}
                scale={imageScale}
              />
              <Slider
                defaultValue={1}
                step={0.01}
                min={1}
                max={2}
                onChange={(e) => setImageScale(e.target.value)}
                className="range-input"
              />
              <Button label={Translation.upload} onClick={getCroppedImg} />
            </div>
          </Modal>

          <Dialog
            hasOnlyYes
            isWarning
            isShown={sizeDialogOpen}
            onClickCloseIcon={() => setSizeDialogOpen(false)}
            isCentered
            onClickCancel={() => setSizeDialogOpen(false)}
            title={Translation.warning}
            yesLabel={Translation.ok}
            onClickYes={() => setSizeDialogOpen(false)}
            content={
              <Typography variant="xs" label={Translation.picture_size_warning} />
            }
          />
        </div>

        <UserInformation
          email={user.email}
          title={title}
          handleTitleChange={handleTitleChange}
          firstname={firstname}
          handleFirstnameChange={handleFirstnameChange}
          lastname={lastname}
          handleLastnameChange={handleLastnameChange}
          telephone={telephone}
          handleTelephoneChange={handleTelephoneChange}
          formErrors={formErrors}
          isFormErrors={isFormErrors}
        />

        <div className="profile-radio-group">
          <div className="radio-group-column">
            <Typography variant="xs" font="medium" label={Translation.email_notifications} />
            <RadioGroup
              name="profile-email-notifications"
              value={emailNotifications}
              values={Translation.email_notifications_options}
              color="gray"
              onChange={(e) => setEmailNotifications(e.target.value)}
            />
          </div>
          <div className="radio-group-column">
            <Typography variant="xs" font="medium" label={Translation.newsletter} />
            <RadioGroup
              name="profile-newsletter"
              value={newsletter}
              values={Translation.newsletter_options}
              color="gray"
              onChange={(e) => setNewsletter(e.target.value)}
            />
          </div>
        </div>

        <div className="save-button-container">
          <Button
            onClick={onSaveChanges}
            label={Translation.save_changes}
            disabled={!isFormChanged && !imageChange}
          />
        </div>

        <div className="unsaved-dialog">
          <UnsavedChangesDialog
            leaveDialogOpen={leaveDialogOpen}
            handleLeaveApprove={handleLeaveApprove}
            handleClickCancel={handleClickCancel}
          />
        </div>
      </div>
      <div className="my-details-right">
        <img src="/image/profile_page.png" alt={Translation.my_details} className="profile-page-image" />
      </div>
    </div>
  )
}

export default MyDetails
