import React, { useState, useEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import PropTypes from 'prop-types'

import { PX_TO_REM } from '../../../../../helpers/Constants'
import { Translation } from '../../../../../helpers/Translation'

import Typography from '../../../../../components/Typography'
import Modal from '../../../../../components/Modal'
import Button from '../../../../../components/Button'

import { ReactComponent as StarSvg } from '../../../../../svg/star.svg'

import './index.scss'

const RateModal = ({
  rate,
  orderId,
  onCloseModal,
  fetchAgainOrderDetail,
  reviewOptions,
}) => {
  const getOrderRating = useStoreActions((actions) => actions.order.getOrderRating)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)

  const [chooseOptions, setChooseOptions] = useState('')
  const [comment, setComment] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const options = [
    {
      id: 'DELIVERY_TIME',
      label: 'delivery_time',
    },
    {
      id: 'USER_EXPERIENCE',
      label: 'user_experience',
    },
    {
      id: 'PRICING',
      label: 'pricing',
    },
    {
      id: 'IMAGE_QUALITY',
      label: 'image_quality',
    },
  ]

  useEffect(() => {
    if (reviewOptions) {
      setChooseOptions(reviewOptions)
    }
  }, [reviewOptions])

  const submitRate = async () => {
    const payload = {
      order_id: orderId,
      rating: rate,
      review: comment,
      review_option: chooseOptions,
    }
    setIsLoading(true)
    const result = await getOrderRating(payload)
    setIsLoading(false)

    if (result) {
      onCloseModal(false)
      if (fetchAgainOrderDetail) {
        fetchAgainOrderDetail(true)
      }
      updateSnackbarState({
        message: Translation.success_rating_text,
        isOpen: true,
        type: 'success',
      })
    } else {
      updateSnackbarState({
        message: Translation.error_rating_text,
        isOpen: true,
        type: 'error',
      })
    }
  }

  return (
    <div className="rate-modal">
      <Modal
        hasCloseIcon={false}
        isShown
        onClickEscClose={() => onCloseModal(false)}
      >
        <div className="rate-modal-stars">
          {
            Array.from({ length: rate }, (_, index) => index + 1).map((item) => <StarSvg key={item} />)
          }
        </div>
        <div className="rate-modal-title">
          <Typography
            variant="h6"
            font="semibold"
            lineHeight={PX_TO_REM['25']}
            label={Translation.thank_you_for_rating_us}
          />
        </div>
        <div className={`rate-modal-text ${rate > 2 ? 'm-bottom-32' : 'm-bottom-8'}`}>
          <Typography
            variant="xs-narrow"
            label={Translation.rate_modal_text}
            containsHtml
          />
        </div>
        {
          rate < 3 && (
            <>
              <div className="rate-modal-options">
                {
                  options.map((item) => (
                    <button
                      key={item.id}
                      type="button"
                      className={`${chooseOptions.includes(item.id) ? 'active' : ''}`}
                      onClick={
                        chooseOptions.includes(item.id)
                          ? () => setChooseOptions(chooseOptions.replace(`${item.id};`, ''))
                          : () => setChooseOptions(`${chooseOptions}${item.id};`)
                      }
                    >
                      <Typography variant="body1" label={Translation[item.label]} font="medium" />
                    </button>
                  ))
                }
              </div>
              <div className="rate-modal-message">
                <textarea placeholder="Message" onChange={(e) => setComment(e.target.value)} />
              </div>
            </>
          )
        }
        <div className="rate-modal-buttons">
          <Button label={Translation.close} type="contained" onClick={() => onCloseModal(false)} />
          <Button
            label={Translation.submit}
            onClick={() => submitRate()}
            disabled={isLoading}
          />
        </div>
      </Modal>
    </div>
  )
}

RateModal.propTypes = {
  rate: PropTypes.number,
  orderId: PropTypes.number,
  onCloseModal: PropTypes.func,
  fetchAgainOrderDetail: PropTypes.func,
  reviewOptions: PropTypes.string,
}

RateModal.defaultProps = {
  rate: 0,
  orderId: 0,
  onCloseModal: () => { },
  fetchAgainOrderDetail: () => { },
  reviewOptions: '',
}

export default RateModal
