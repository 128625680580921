import React, { useEffect, useState } from 'react'
import spacetime from 'spacetime'

import { lang, Translation } from './Translation'
import { Cookie, isLocal } from './Cookie'
import {
  DEFAULT_TIMEZONE,
  FIELDS_PATTERNS,
  KEYS,
  TABLE_ROWS_PER_PAGE,
  IMG_SIZE_LARGE, IMAGE_TYPE_IN, IMAGE_TYPE_OUT, QA_ROLES, USER_ROLE,
} from './Constants'

import { luhn10 } from './Card'

import { ReactComponent as VisaIcon } from '../svg/visa.svg'
import { ReactComponent as MasterCardIcon } from '../svg/mastercard.svg'
import { ReactComponent as AmexIcon } from '../svg/amex.svg'
import { ReactComponent as SepaIcon } from '../svg/sepa.svg'

export const isAuthenticated = () => !!Cookie.getCookie('PHPSESSID')

export const isAdminRole = (role) => QA_ROLES.includes(role?.toLowerCase())
export const isClientAdminRole = (role) => (role && role?.toLowerCase() !== USER_ROLE)

export const log = (...arg) => {
  // eslint-disable-next-line no-console
  if (isLocal) console.log(...arg)
}

export const isValidValue = (value, pattern) => {
  if (!value || !pattern) return false

  return value.match(new RegExp(`^${pattern}$`, 'i'))
}

export const isValidInput = (input) => {
  if (!input) return true

  const value = input.value.trim()
  const pattern = input.getAttribute('pattern').trim()

  if (!value || !pattern) return true

  return value.replace(/\r\n|\r|\n/g, '').match(new RegExp(`^${pattern}$`, 'i')) !== null
}

export const removeInputError = (input, isFormErrors, setIsFormErrors) => {
  if (!input || !input.name || !setIsFormErrors) return

  if (isValidInput(input)) setIsFormErrors({ ...isFormErrors, [input.name]: false })
}

export const validateInput = (input, isFormErrors, setIsFormErrors) => {
  if (!input || !input.name || !setIsFormErrors) return

  setIsFormErrors({ ...isFormErrors, [input.name]: !isValidInput(input) })
}

export const isEnterKey = (key) => key === KEYS.enter && document.activeElement.tagName !== 'TEXTAREA'

export const validateInputWithError = (
  input,
  formErrors,
  setFormErrors,
  isFormErrors,
  setIsFormErrors,
  error = Translation.must_be_at_least_chars_long,
) => {
  if (!input || !input.name || !setFormErrors || !setIsFormErrors) return

  const isValid = isValidInput(input)

  if (isValid) setFormErrors({ ...formErrors, [input.name]: '' })
  else setFormErrors({ ...formErrors, [input.name]: error })

  setIsFormErrors({ ...isFormErrors, [input.name]: !isValid })
}

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

export const textDebounce = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => clearTimeout(handler)
  }, [value, delay])
  return debouncedValue
}

export const windowWidth = () => document.documentElement.clientWidth || document.body.clientWidth

if (isLocal) {
  log('lang', `"${lang}"`, 'windowWidth', windowWidth())
}

export const validateCardNumber = (cardNumber, isFormErrors, setIsFormErrors) => {
  let isValid = true

  const cardNumberVal = cardNumber.trim().replace(/ /g, '')

  if (
    !cardNumberVal
    || !luhn10(cardNumberVal)
    || !isValidValue(cardNumberVal, FIELDS_PATTERNS.cardNumber)
  ) {
    setIsFormErrors({ ...isFormErrors, cardNumber: true })
    isValid = false
  }

  return isValid
}

export const validateMonthYear = (cardExpireMonth, cardExpireYear) => {
  let isValid = true

  if (!cardExpireMonth || !cardExpireYear) return false

  const curYear = new Date().getFullYear()
  const curMonth = new Date().getMonth() + 1

  const yearVal = cardExpireYear.trim()
  const monthVal = cardExpireMonth.trim()

  const year = parseInt(`20${yearVal}`, 10)
  const month = parseInt(monthVal, 10)

  if (yearVal.length < 2 || year < curYear) {
    isValid = false
  }

  if (monthVal.length < 2 || month < 1 || month > 12) {
    isValid = false
  } else if (year === curYear && month < curMonth) {
    isValid = false
  }

  return isValid
}

export const validateCardCVC = (cardCVC) => {
  let isValid = true

  const cardCVCVal = cardCVC.trim()

  if (!(/^\d{3,4}$/).test(cardCVCVal)) {
    isValid = false
  }

  return isValid
}

export const getCardInfo = (method) => {
  switch (method.cardtype) {
    case 'V':
      return {
        name: 'Visa',
        icon: <VisaIcon />,
        cardNumber: method.truncatedcardpan,
        cardNameNumber: `Visa ${method.truncatedcardpan}`,
      }
    case 'M':
      return {
        name: 'MasterCard',
        icon: <MasterCardIcon />,
        cardNumber: method.truncatedcardpan,
        cardNameNumber: `MasterCard ${method.truncatedcardpan}`,
      }
    case 'A':
      return {
        name: 'Amex',
        icon: <AmexIcon />,
        cardNumber: method.truncatedcardpan,
        cardNameNumber: `Amex ${method.truncatedcardpan}`,
      }
    default:
      return {
        name: 'IBAN',
        icon: <SepaIcon />,
        cardNumber: method.iban,
        cardNameNumber: `IBAN ${method.iban}`,
      }
  }
}

export const localizeDate = (date, withHour = true) => {
  if (date === null || date === '') return '-'
  let targetTimeInUserTimezone
  try {
    const targetDateTime = spacetime(date, DEFAULT_TIMEZONE)

    const now = spacetime.now()

    let currentTimezone = now.timezone().name
    if (currentTimezone === 'cet') currentTimezone = DEFAULT_TIMEZONE
    targetTimeInUserTimezone = targetDateTime.goto(currentTimezone)
  } catch (e) {
    return date
  }

  if (withHour) {
    return `${new Date(targetTimeInUserTimezone.epoch).toLocaleDateString('en-GB').replace(/\//g, '.')} 
  ${new Date(targetTimeInUserTimezone.epoch).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  })}`
  }

  return `${new Date(targetTimeInUserTimezone.epoch).toLocaleDateString('en-GB').replace(/\//g, '.')}`
}

// save the number of rows per page in the local storage
// hold them in one object in the local storage
export const savePerPage = (table, rowsPerPage) => {
  const perPage = JSON.parse(localStorage.getItem('perPage')) || {}
  perPage[table] = rowsPerPage
  localStorage.setItem('perPage', JSON.stringify(perPage))
}

export const getPerPage = (table) => {
  const perPage = JSON.parse(localStorage.getItem('perPage')) || {}
  return perPage[table] || TABLE_ROWS_PER_PAGE
}

export const removePerPage = () => {
  localStorage.removeItem('perPage')
}

export const saveDashboardFilters = (filters, userId) => {
  const dashboardFilters = JSON.parse(localStorage.getItem('dashboardFilters')) || {}
  dashboardFilters[userId] = filters
  localStorage.setItem('dashboardFilters', JSON.stringify(dashboardFilters))
}

export const getDashboardFilters = (userId) => {
  const dashboardFilters = JSON.parse(localStorage.getItem('dashboardFilters')) || {}
  return dashboardFilters[userId] || {}
}

export const getGalleryUrl = (orderId, isImageTypeOutput = false, isLargeView = false) => {
  let url = process.env.REACT_APP_GALLERY_URL

  url += `/gallery/${isImageTypeOutput
    ? IMAGE_TYPE_OUT : IMAGE_TYPE_IN}/${orderId}${isLargeView
    ? `/${IMG_SIZE_LARGE}` : ''}`

  return url
}

export const getGalleryListUrl = (orderId, imageId, isImageTypeOutput = false) => {
  let url = process.env.REACT_APP_GALLERY_URL

  url += `/list/gallery/${isImageTypeOutput
    ? IMAGE_TYPE_OUT : IMAGE_TYPE_IN}/${orderId}#image-${imageId}`

  return url
}

export const clickToDownload = (url) => {
  if (!url) return

  try {
    const link = document.createElement('a')
    link.href = url
    link.click()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Could not create element: ', error)
  }
}

export const clickToCopy = (text) => {
  if (!text) return

  try {
    navigator.clipboard.writeText(text).then(() => {
      // Copying to clipboard was successful
    }, (err) => {
      // eslint-disable-next-line no-console
      console.error('Async: Could not copy text: ', err)
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Could not copy text: ', error)
  }
}
