import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'

import { PAYMENT_MODAL_TYPE, PX_TO_REM } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'

const PaymentModalHeader = ({
  paymentModalState,
  paymentType,
  handleAddPaymentMethod,
  isCheckout,
}) => {
  const unpaidData = useStoreState((state) => state.order.unpaidData)

  return (
    <>
      {
        isCheckout
        && paymentModalState !== PAYMENT_MODAL_TYPE.paymentError
        && paymentModalState !== PAYMENT_MODAL_TYPE.inProcess && (
          <>
            <div className="manage-payments-title">
              <Typography
                theme="dark"
                variant="s"
                font="semibold"
                label={
                  // eslint-disable-next-line no-nested-ternary
                  paymentModalState === PAYMENT_MODAL_TYPE.paymentList
                    ? Translation.saved_payment_methods
                    : paymentType === 'card'
                      ? Translation.select_payment_method
                      : Translation.payment_method
                }
              />
            </div>
            <div className="row">
              <div className="description">
                <Typography
                  theme="dark"
                  variant="xs"
                  lineHeight={PX_TO_REM['18']}
                  containsHtml
                  label={
                    Translation.you_are_about_to_pay
                      .replace(/%AMOUNT%/g, `<b>${unpaidData?.final_price}${unpaidData?.currency_sign}</b>`)
                  }
                />
              </div>
            </div>
          </>
        )
      }

      {!isCheckout && (
        <>
          <div className="manage-payments-title">
            <Typography
              theme="dark"
              variant="s"
              font="semibold"
              label={
                paymentModalState === PAYMENT_MODAL_TYPE.editList
                  ? Translation.manage_payment_methods
                  : Translation.add_payment_method
              }
            />
          </div>
          <div className="row">
            <div className="description">
              <Typography
                theme="dark"
                variant="xs"
                lineHeight={PX_TO_REM['18']}
                label={
                  paymentModalState === PAYMENT_MODAL_TYPE.editList
                    ? Translation.here_you_can_add_payment
                    : Translation.select_which_payment_method
                }
              />
            </div>
            {paymentModalState === PAYMENT_MODAL_TYPE.editList && (
              <button type="button" className="add-methods-button" onClick={handleAddPaymentMethod}>
                <Typography
                  variant="xs"
                  font="semibold"
                  theme="dark"
                  noWrap
                  label={`+ ${Translation.add_payment_method}`}
                />
              </button>
            )}
          </div>
        </>
      )}
    </>
  )
}

PaymentModalHeader.propTypes = {
  paymentModalState: PropTypes.number,
  paymentType: PropTypes.string,
  handleAddPaymentMethod: PropTypes.func,
  isCheckout: PropTypes.bool,
}

PaymentModalHeader.defaultProps = {
  paymentModalState: PAYMENT_MODAL_TYPE.paymentList,
  paymentType: '',
  handleAddPaymentMethod: () => { },
  isCheckout: false,
}

export default PaymentModalHeader
