import React, { useEffect } from 'react'
import spacetime from 'spacetime'

import { DEFAULT_TIMEZONE } from '../../helpers/Constants'

import ErrorJs from '../ErrorJs'

const ErrorFake = () => {
  // generate random JS error
  useEffect(() => {
    let randBool = Math.random()
    if (randBool < 0.5) randBool = 0
    else randBool = 1

    const currentTimezone = randBool ? DEFAULT_TIMEZONE : 'cet'
    const targetDateTime = spacetime('10.11.2003', DEFAULT_TIMEZONE)
    const targetTimeInUserTimezone = targetDateTime.goto(currentTimezone)
    // eslint-disable-next-line no-console
    console.log(new Date(targetTimeInUserTimezone.epoch).toLocaleDateString('en-GB').replace(/\//g, '.'))
  }, [])

  return (
    <div>
      <ErrorJs />
    </div>
  )
}

export default ErrorFake
