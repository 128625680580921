import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import { PX_TO_REM } from '../../../../helpers/Constants'

import Typography from '../../../../components/Typography'

import { ReactComponent as ArrowRightSvg } from '../../../../svg/arrow_right.svg'
import { ReactComponent as ClippingSvg } from '../../../../svg/clipping.svg'
import { ReactComponent as ColoursSvg } from '../../../../svg/colours.svg'
import { ReactComponent as LayersSvg } from '../../../../svg/layers.svg'
import { ReactComponent as MarginSvg } from '../../../../svg/margin.svg'
import { ReactComponent as MaskSvg } from '../../../../svg/mask.svg'
import { ReactComponent as MoreOptionsSvg } from '../../../../svg/more_options.svg'
import { ReactComponent as PathsSvg } from '../../../../svg/paths.svg'
import { ReactComponent as PositioningSvg } from '../../../../svg/positioning.svg'
import { ReactComponent as RemarkSvg } from '../../../../svg/remark.svg'
import { ReactComponent as ResolutionSvg } from '../../../../svg/resolution.svg'
import { ReactComponent as RetouchSvg } from '../../../../svg/retouch.svg'
import { ReactComponent as SizeSvg } from '../../../../svg/size.svg'
import { ReactComponent as PresetSvg } from '../../../../svg/preset.svg'

import './index.scss'

const OrderDetailsInfo = ({ orderDetailsItem, index }) => {
  const [isOpen, setIsOpen] = useState(false)

  const icons = {
    clipping: <ClippingSvg />,
    colours: <ColoursSvg />,
    layers: <LayersSvg />,
    margin: <MarginSvg />,
    mask: <MaskSvg />,
    more: <MoreOptionsSvg />,
    paths: <PathsSvg />,
    positioning: <PositioningSvg />,
    remark: <RemarkSvg />,
    resolution: <ResolutionSvg />,
    retouch: <RetouchSvg />,
    size: <SizeSvg />,
    presets: <PresetSvg />,
  }

  useEffect(() => {
    setIsOpen(index === 0)
  }, [index])

  const columnWidth = (label) => (
    label === 'General Retouch - Other'
    || label === 'Skin Retouch - Other'
    || label === 'Remark'
  )

  return (
    <div className="order-details-info">
      <button type="button" className="order-details-info-top" onClick={() => setIsOpen(!isOpen)}>
        <Typography
          variant="subtitle-narrow"
          label={orderDetailsItem.label}
          font="semibold"
        />
        <div className="info-top-summary">
          <Typography
            label={
              orderDetailsItem?.children?.map((item) => (
                `${item.label ?? ''}`
              ))?.join(', ')
            }
            fontSize={PX_TO_REM['10']}
            lineHeight={PX_TO_REM['14']}
          />
          <div className="info-top-summary-arrow" style={{ rotate: isOpen ? '90deg' : '' }}>
            <ArrowRightSvg />
          </div>
        </div>
      </button>
      {
        isOpen && (
          <div className="order-details-info-content">
            <div className="content-row">
              {
                orderDetailsItem?.children?.map((item) => (
                  <div
                    key={`${item.label}-${Math.random()}`}
                    className={`content-row-column ${columnWidth(item.label) ? 'full' : 'quarter'}`}
                  >
                    <div className="content-row-column-title">
                      {icons[item.icon]}
                      <Typography
                        variant="caption"
                        label={item.label}
                        font="semibold"
                      />
                    </div>
                    <div className="content-row-column-list">
                      <ul>
                        {
                          item?.children && (
                            Object.values(item.children).map((child) => (
                              <li
                                key={`${child.label}-${Math.random()}`}
                                style={{ listStyleType: child.font === 'semibold' ? 'none' : '' }}
                              >
                                <div className="list-item" style={{ opacity: child.opacity ?? '1' }}>
                                  <Typography
                                    variant="xs"
                                    label={child.label}
                                    fontSize={PX_TO_REM['11']}
                                    lineHeight={PX_TO_REM['15']}
                                    font={child.font ?? ''}
                                  />
                                  {
                                    child.type && (
                                      <Tooltip
                                        className="info-tooltip"
                                        PopperProps={{
                                          disablePortal: true,
                                        }}
                                        title={child.value}
                                        placement="top"
                                        arrow
                                      >
                                        <div className="list-item-bgBox" style={{ backgroundColor: child.value }} />
                                      </Tooltip>
                                    )
                                  }
                                </div>
                              </li>
                            ))
                          )
                        }
                      </ul>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

OrderDetailsInfo.propTypes = {
  orderDetailsItem: PropTypes.shape({
    label: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.string,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          opacity: PropTypes.string,
          font: PropTypes.string,
          type: PropTypes.string,
        })),
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          opacity: PropTypes.string,
          font: PropTypes.string,
          type: PropTypes.string,
        }),
      ]),
    })),
  }),
  index: PropTypes.number,
}

OrderDetailsInfo.defaultProps = {
  index: 0,
  orderDetailsItem: {
    label: '',
    children: [],
  },
}

export default OrderDetailsInfo
