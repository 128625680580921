import { action, thunk } from 'easy-peasy'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS } from '../helpers/Urls'
import { Translation } from '../helpers/Translation'

const TemplateStore = {
  apiErrors: '',
  list: null,
  templateCount: 0,
  savedList: null,
  savedCount: 0,
  workflowList: null,
  workflowCount: 0,

  addApiErrors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = payload
  }),

  clearApiErrors: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = ''
  }),

  setTemplates: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.list = payload.templates
    // eslint-disable-next-line no-param-reassign
    state.templateCount = payload.templates_count
  }),

  setSavedList: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.savedList = payload.templates
    // eslint-disable-next-line no-param-reassign
    state.savedCount = payload.templates_count
  }),

  setWorkflowList: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.workflowList = payload.templates
    // eslint-disable-next-line no-param-reassign
    state.workflowCount = payload.templates_count
  }),

  fetchTemplates: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.templates, { params: payload })

      if (response.data.result.success) {
        if (payload.type === 'saved') {
          actions.setSavedList(response.data.result)
        } else if (payload.type === 'workflow') {
          actions.setWorkflowList(response.data.result)
        } else {
          actions.setTemplates(response.data.result)
        }
        return true
      }

      return true
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  applyTemplate: thunk(async (actions, payload) => {
    try {
      const applyTemplateUrl = API_ENDPOINTS.templateApply.replace('{id}', payload.template_id)
      const response = await axios.post(applyTemplateUrl, payload)

      if (response.data.result.success) {
        return true
      }

      return true
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  deleteTemplate: thunk(async (actions, payload) => {
    try {
      const deleteTemplateUrl = API_ENDPOINTS.template.replace('{id}', payload.template_id)
      const response = await axios.delete(deleteTemplateUrl)

      if (response.data.result.success) {
        return true
      }

      return true
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  templateEqual: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.templateEqual.replace('{id}', payload))

      if (response.data.result.success) {
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  templateOrderSave: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.templateOrderSave.replace('{id}', payload.order_id), payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  templateOrderApply: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.templateOrderApply.replace('{id}', payload.order_id), payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  templateOrderUpdate: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.templateOrderUpdate.replace('{id}', payload), payload)

      return response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getTemplateDetail: thunk(async (actions, { id, tableType }) => {
    try {
      const response = await axios.get(API_ENDPOINTS.template_details.replace('{id}', id), { params: { type: tableType } })

      if (response.data.result.success) {
        return response.data.result
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getDuplicateTemplate: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.template_duplicate.replace('{id}', payload))

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),
}

export default TemplateStore
