import React, { useState } from 'react'
import { useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'
import { unstable_useBlocker as useBlocker, useLocation } from 'react-router-dom'

import { PAYMENT_MODAL_TYPE, CHECKOUT_PAGE } from '../../../helpers/Constants'

import Modal from '../../../components/Modal'

import PaymentError from './PaymentError'
import PaymentInProcess from './PaymentInProcess'
import PaymentList from './PaymentList'
import PaymentModalHeader from './PaymentModalHeader'
import EditList from './EditList'
import PaymentForm from './PaymentForm'

import './index.scss'

const ManagePaymentMethods = ({
  isOpen,
  paymentModalState,
  setPaymentModalState,
  closeModal,
  openSepaInfoModal,
  leaveDialogOpen,
  setLeaveDialogOpen,
  isFormChanged,
  setIsFormChanged,
  setNavigateTo,
}) => {
  const location = useLocation()

  const [paymentType, setPaymentType] = useState('card') // card | sepa
  const isPaymentInProcess = useStoreState((state) => state.invoice.isPaymentInProcess)

  useBlocker((e) => {
    if (isOpen && isFormChanged && !leaveDialogOpen && !isPaymentInProcess) {
      setNavigateTo(`${e.nextLocation.pathname}${e.nextLocation.hash}`)
      closeModal()
      setLeaveDialogOpen(true)
      return true
    }
    return false
  })

  const handleAddPaymentMethod = () => {
    setPaymentModalState(PAYMENT_MODAL_TYPE.form)
  }

  const checkModalClosable = () => {
    if (isFormChanged) {
      closeModal()
      setLeaveDialogOpen(true)
    } else closeModal()
  }

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      checkModalClosable()
    }
  }

  const handleUseDifferentMethod = () => {
    setPaymentType('card')
    setPaymentModalState(PAYMENT_MODAL_TYPE.form)
  }

  return (
    <Modal
      isShown={isOpen}
      hasCloseIcon={false}
      onClickOutside={onClickOutside}
      onClickEscClose={() => closeModal()}
      size="large"
    >
      <div className="manage-payments-container">
        <PaymentModalHeader
          handleAddPaymentMethod={handleAddPaymentMethod}
          paymentModalState={paymentModalState}
          paymentType={paymentType}
          isCheckout={location.pathname === CHECKOUT_PAGE}
        />

        {paymentModalState === PAYMENT_MODAL_TYPE.paymentList && (
          <PaymentList
            setPaymentModalState={setPaymentModalState}
            handleModalClose={() => closeModal()}
            handleUseDifferentMethod={handleUseDifferentMethod}
            openSepaInfoModal={openSepaInfoModal}
          />
        )}

        {paymentModalState === PAYMENT_MODAL_TYPE.editList && (
          <EditList
            isOpen={isOpen}
            openSepaInfoModal={openSepaInfoModal}
            setPaymentModalState={setPaymentModalState}
            handleModalClose={() => closeModal()}
            checkModalClosable={checkModalClosable}
            isFormChanged={isFormChanged}
            setIsFormChanged={setIsFormChanged}
          />
        )}

        {paymentModalState === PAYMENT_MODAL_TYPE.form && (
          <PaymentForm
            closeModal={closeModal}
            isFormChanged={isFormChanged}
            setIsFormChanged={setIsFormChanged}
            setLeaveDialogOpen={setLeaveDialogOpen}
            setNavigateTo={setNavigateTo}
            setPaymentModalState={setPaymentModalState}
            isCheckout={location.pathname === CHECKOUT_PAGE}
          />
        )}

        {paymentModalState === PAYMENT_MODAL_TYPE.inProcess && (
          <PaymentInProcess />
        )}

        {paymentModalState === PAYMENT_MODAL_TYPE.paymentError && (
          <PaymentError setPaymentModalState={setPaymentModalState} />
        )}
      </div>
    </Modal>
  )
}

ManagePaymentMethods.propTypes = {
  isOpen: PropTypes.bool,
  paymentModalState: PropTypes.number,
  setPaymentModalState: PropTypes.func,
  closeModal: PropTypes.func,
  openSepaInfoModal: PropTypes.func,
  leaveDialogOpen: PropTypes.bool,
  setLeaveDialogOpen: PropTypes.func,
  isFormChanged: PropTypes.bool,
  setIsFormChanged: PropTypes.func,
  setNavigateTo: PropTypes.func,
}

ManagePaymentMethods.defaultProps = {
  isOpen: false,
  paymentModalState: 1,
  setPaymentModalState: () => { },
  closeModal: () => { },
  openSepaInfoModal: () => { },
  leaveDialogOpen: false,
  setLeaveDialogOpen: () => { },
  isFormChanged: false,
  setIsFormChanged: () => { },
  setNavigateTo: () => { },
}

export default ManagePaymentMethods
