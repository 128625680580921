import React, { useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import { Translation } from '../../../../helpers/Translation'
import { REMARK_CHARACTERS_LIMIT } from '../../../../helpers/Constants'

import Textarea from '../../../../components/Textarea'
import Dialog from '../../../../components/Dialog'
import Typography from '../../../../components/Typography'

import SectionTop from '../../Common/SectionTop'

import { ReactComponent as RemarkSvg } from '../../../../svg/remark.svg'

import './index.scss'

const Remark = ({ handlePropertyId, orderFormatActions, disableAll }) => {
  const updateLocaleFormatActions = useStoreActions((actions) => actions.order.updateLocaleFormatActions)
  const [isShowRemarkCharactersLimitWarning, setIsShowRemarkCharactersLimitWarning] = useState(false)

  const handleRemark = (value) => {
    if (value.length > REMARK_CHARACTERS_LIMIT) {
      setIsShowRemarkCharactersLimitWarning(true)
    } else {
      setIsShowRemarkCharactersLimitWarning(false)
      updateLocaleFormatActions({
        remark_remark:
          { ...orderFormatActions.remark_remark, value },
      })
    }
  }

  return (
    <section className={`new-order-section section-remark ${orderFormatActions?.remark?.value ? 'active' : ''}`}>
      <Accordion expanded={!!orderFormatActions?.remark?.value} className="accordion">
        <AccordionSummary className="accordion-summary">
          <SectionTop
            showContent={!!orderFormatActions?.remark?.value}
            id="remark"
            icon={<RemarkSvg />}
            defaultChecked={orderFormatActions?.remark?.value !== null}
            labelText={orderFormatActions?.remark?.label}
            badgeText={orderFormatActions?.remark?.price}
            onChange={(id, value) => handlePropertyId(id, value)}
            disabled={disableAll}
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="section-content">
            <Textarea
              width="100%"
              placeholder={`${Translation.remark_placeholder_top}\n\n${Translation.remark_placeholder_bottom}`}
              value={orderFormatActions.remark_remark.value}
              onChange={(e) => handleRemark(e.target.value)}
              onBlur={(e) => handlePropertyId('remark_remark', e.target.value.trim())}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Dialog
        isShown={isShowRemarkCharactersLimitWarning}
        title={`${Translation.attention}!`}
        content={(
          <Typography
            variant="xs"
            label={Translation.remark_characters_limit_warning_text.replace('{characters_limit}', REMARK_CHARACTERS_LIMIT)}
            containsHtml
          />
        )}
        yesLabel={Translation.ok}
        onClickYes={() => {
          setIsShowRemarkCharactersLimitWarning(false)
        }}
        hasOnlyYes
      />
    </section>
  )
}

Remark.propTypes = {
  handlePropertyId: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderFormatActions: PropTypes.objectOf(PropTypes.any),
  disableAll: PropTypes.bool,
}

Remark.defaultProps = {
  orderFormatActions: {},
  disableAll: false,
}

export default Remark
