import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import CheckboxMui from '@mui/material/Checkbox'

import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'

import { ReactComponent as CheckboxCheckedSvg } from '../../../../svg/checkbox_checked.svg'
import { ReactComponent as CheckedUncheckedSvg } from '../../../../svg/checkbox_unchecked.svg'
import { ReactComponent as EyeSvg } from '../../../../svg/eye.svg'

import './index.scss'

const TemplateManagement = ({
  isUpdated,
  templateList,
  assignedTemplateList,
  handleAssignTemplate,
  handleUnAssignTemplate,
  viewWorkflowId,
}) => {
  const [tempTemplateList, setTempTemplateList] = useState(null)
  const [tempAssignedTemplateList, setTempAssignedTemplateList] = useState(null)

  useEffect(() => {
    if (
      (tempAssignedTemplateList === null
        && tempTemplateList === null
        && templateList)
      || isUpdated
    ) {
      setTempTemplateList(() => {
        const temp = {}
        Object.keys(templateList).forEach((key) => {
          if (!assignedTemplateList[key]) temp[key] = templateList[key]
        })
        return temp
      })
      setTempAssignedTemplateList(assignedTemplateList)
    }
  }, [assignedTemplateList, templateList, isUpdated])

  const toggleTemplate = (key) => {
    if (assignedTemplateList[key]) {
      handleUnAssignTemplate(key)
    } else {
      handleAssignTemplate(key)
    }
  }

  return (
    <div className="template-management">
      <Typography
        theme="dark"
        variant="xs"
        font="medium"
        label={Translation.workflows}
      />
      <div className="template-list-wrapper scrollbar-overflow">
        {tempAssignedTemplateList && Object.keys(tempAssignedTemplateList).length !== 0 && (
          <List className="template-list">
            {Object.keys(tempAssignedTemplateList).map((key) => (
              <ListItem key={key} disablePadding>
                <ListItemButton className="template-button" onClick={() => toggleTemplate(key)}>
                  <Typography
                    variant="xs"
                    label={tempAssignedTemplateList[key]}
                  />
                  <div className="template-button-actions">
                    <button
                      type="submit"
                      className="detail-view-btn"
                      onClick={(e) => {
                        e.stopPropagation()
                        viewWorkflowId(key)
                      }}
                    >
                      <EyeSvg />
                    </button>
                    <CheckboxMui
                      edge="end"
                      checked={!!assignedTemplateList[key]}
                      icon={<CheckedUncheckedSvg />}
                      checkedIcon={<CheckboxCheckedSvg />}
                      disableRipple
                    />
                  </div>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
        {tempAssignedTemplateList
          && Object.keys(tempAssignedTemplateList).length !== 0
          && Object.keys(templateList).length !== 0
          && (
            <div className="seperator" />
          )}
        <List className="template-list">
          {tempTemplateList && Object.keys(tempTemplateList).map((key) => (
            <ListItem key={key} disablePadding>
              <ListItemButton className="template-button" onClick={() => toggleTemplate(key)}>
                <Typography
                  variant="xs"
                  label={tempTemplateList[key]}
                />
                <div className="template-button-actions">
                  <button
                    type="submit"
                    className="detail-view-btn"
                    onClick={(e) => {
                      e.stopPropagation()
                      viewWorkflowId(key)
                    }}
                  >
                    <EyeSvg />
                  </button>
                  <CheckboxMui
                    edge="end"
                    checked={!!assignedTemplateList[key]}
                    icon={<CheckedUncheckedSvg />}
                    checkedIcon={<CheckboxCheckedSvg />}
                    disableRipple
                  />
                </div>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  )
}

TemplateManagement.propTypes = {
  isUpdated: PropTypes.bool,
  templateList: PropTypes.objectOf(PropTypes.string),
  assignedTemplateList: PropTypes.objectOf(PropTypes.string),
  handleAssignTemplate: PropTypes.func,
  handleUnAssignTemplate: PropTypes.func,
  viewWorkflowId: PropTypes.func,
}

TemplateManagement.defaultProps = {
  isUpdated: false,
  templateList: [],
  assignedTemplateList: [],
  handleAssignTemplate: () => { },
  handleUnAssignTemplate: () => { },
  viewWorkflowId: () => { },
}

export default TemplateManagement
