import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
  IMAGE_PREVIEW_HORIZONTAL_MAX_HEIGHT,
  IMAGE_PREVIEW_VERTICAL_MAX_WIDTH,
  IMAGE_PREVIEW_PER_PAGE,
  IMAGE_PREVIEW_MAX_HEIGHT,
  IMAGE_TYPE_OUT,
  IMAGE_TYPE_IN,
} from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'
import { getGalleryUrl } from '../../../../helpers/Utils'

import Link from '../../../../components/Link'

// eslint-disable-next-line import/no-cycle
import OrderDetailsImage from './OrderDetailsImage'

import { ReactComponent as ArrowLeftSvg } from '../../../../svg/arrow_left.svg'
import { ReactComponent as ArrowRightSvg } from '../../../../svg/arrow_right.svg'
import { ReactComponent as RightArrowSvg } from '../../../../svg/right_arrow.svg'
import { ReactComponent as LeftArrowSvg } from '../../../../svg/left_arrow.svg'

import './index.scss'

export const getPreviewSizes = (preview) => {
  let previewWidth
  if (preview.width_preview > preview.height_preview) {
    if (preview.height_preview <= IMAGE_PREVIEW_MAX_HEIGHT) {
      previewWidth = preview.width_preview
    } else {
      previewWidth = (preview.width_preview / preview.height_preview) * IMAGE_PREVIEW_MAX_HEIGHT
    }

    if (previewWidth > IMAGE_PREVIEW_VERTICAL_MAX_WIDTH) {
      previewWidth = IMAGE_PREVIEW_VERTICAL_MAX_WIDTH
    }
  } else if (preview.width_preview < preview.height_preview) {
    previewWidth = (preview.width_preview / preview.height_preview) * IMAGE_PREVIEW_MAX_HEIGHT
    if (previewWidth > IMAGE_PREVIEW_HORIZONTAL_MAX_HEIGHT) {
      previewWidth = IMAGE_PREVIEW_HORIZONTAL_MAX_HEIGHT
    }
  } else {
    previewWidth = IMAGE_PREVIEW_MAX_HEIGHT
  }

  let previewHeight = 0
  if (previewWidth) {
    previewHeight = (preview.height_preview / preview.width_preview) * previewWidth
  }

  return {
    width: previewWidth,
    height: previewHeight,
  }
}

export const getPreviewOrientationClass = (preview) => {
  if (preview.width_preview > preview.height_preview) return 'vertical'
  if (preview.width_preview < preview.height_preview) return 'horizontal'
  return 'square'
}

const OrderDetailsImages = ({ isLargeView, orderDetails }) => {
  const orderDetailsImages = useStoreState((state) => state.order.orderDetailsImages[orderDetails?.id])
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const getOrderDetailsImages = useStoreActions((actions) => actions.order.getOrderDetailsImages)

  const [activeSlidePage, setActiveSlidePage] = useState(1)
  const [newImgRequestPageNumber, setNewImgRequestPageNumber] = useState(0)
  const [shownImages, setShownImages] = useState([])
  const [maxSlidePage, setMaxSlidePage] = useState(1)
  const [skeletonImages, setSkeletonImages] = useState([])

  let slideWidth = ((orderDetails?.type === 0 && orderDetails?.test_order_notice_1 && orderDetails?.test_order_notice_2)
    || orderDetails?.is_show_test_image_confirm_decline) ? 480 : 840
  slideWidth = isMobile ? 390 : slideWidth

  const isImageTypeOutput = orderDetails?.output_count > 0
  const totalImgCount = isImageTypeOutput ? orderDetails?.output_count : orderDetails?.input_count

  const totalImgRequest = Math.ceil(
    totalImgCount / IMAGE_PREVIEW_PER_PAGE,
  )

  const handleSlideNextChange = () => {
    if ((activeSlidePage + 1 > newImgRequestPageNumber) && (activeSlidePage + 1 <= totalImgRequest)) {
      setNewImgRequestPageNumber(activeSlidePage + 1)
    }

    setActiveSlidePage((prev) => prev + 1)
  }

  useEffect(() => {
    const tempShownImages = []
    let tempImagesWidth = 0
    let slideIndex = 0
    let tempSlideImages = []

    if (orderDetailsImages?.length) {
      orderDetailsImages.forEach((image) => {
        const previewSizes = getPreviewSizes(image)

        tempImagesWidth += previewSizes.width
        if (tempImagesWidth <= slideWidth) {
          tempSlideImages.push(image)
        } else {
          tempImagesWidth = previewSizes.width
          tempShownImages[slideIndex] = tempSlideImages
          slideIndex += 1
          tempSlideImages = []
          tempSlideImages.push(image)
        }
      })
    }

    if (tempSlideImages.length > 0) {
      tempShownImages[slideIndex] = tempSlideImages
    }

    setMaxSlidePage(slideIndex + 1)
    setShownImages(tempShownImages)
  }, [orderDetailsImages?.length, activeSlidePage])

  useEffect(() => {
    if (!orderDetails?.id) return
    setNewImgRequestPageNumber(1)

    let tempImagesWidth = 0
    const skeletonSlideImages = []

    orderDetails?.previews.forEach((image) => {
      const previewSizes = getPreviewSizes(image)

      tempImagesWidth += previewSizes.width
      if (tempImagesWidth <= slideWidth) {
        skeletonSlideImages.push(image)
      }
    })

    setSkeletonImages(skeletonSlideImages)
  }, [orderDetails?.id])

  useEffect(() => {
    if (!newImgRequestPageNumber) return

    getOrderDetailsImages({
      id: orderDetails?.id,
      params: {
        page: newImgRequestPageNumber,
        limit: IMAGE_PREVIEW_PER_PAGE,
        image_type: isImageTypeOutput ? IMAGE_TYPE_OUT : IMAGE_TYPE_IN,
      },
    })
  }, [newImgRequestPageNumber])

  return (
    <div style={{ marginLeft: 'auto', marginRight: isMobile ? 'auto' : '' }}>
      <div
        className={`imagesDetail-slidesWrapper ${isMobile ? 'mobile' : ''} slides-${slideWidth}`}
      >
        <button
          type="button"
          className={`imagesDetail-leftArrow ${activeSlidePage === 1 ? 'disabled' : ''}`}
          onClick={() => setActiveSlidePage(activeSlidePage > 1 ? activeSlidePage - 1 : 1)}
          disabled={activeSlidePage === 1}
        >
          {isMobile ? <LeftArrowSvg /> : <ArrowLeftSvg />}
        </button>
        <div className="imagesDetail-slides">
          {
            !orderDetailsImages?.length
              ? skeletonImages.length && skeletonImages.map((image) => (
                <div key={`${image.name}-${Math.random()}`} className="imagesDetail-slide">
                  <a
                    href={getGalleryUrl(orderDetails.id, isImageTypeOutput, isLargeView)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/image/about_blank.gif"
                      className={`${getPreviewOrientationClass(image)} skeleton`}
                      style={getPreviewSizes(image)}
                      alt=""
                    />
                  </a>
                </div>
              )) : shownImages.length && shownImages[activeSlidePage - 1]?.map((image) => (
                <div key={`${image.name}-${Math.random()}`} className="imagesDetail-slide">
                  <a
                    href={getGalleryUrl(orderDetails.id, isImageTypeOutput, isLargeView)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <OrderDetailsImage
                      image={image}
                      receivedOffset={orderDetails.received_offset}
                    />
                  </a>
                </div>
              ))
          }
        </div>
        <button
          type="button"
          className={`imagesDetail-rightArrow ${activeSlidePage === maxSlidePage ? 'disabled' : ''}`}
          style={{ opacity: activeSlidePage === maxSlidePage && activeSlidePage === 1 ? '0' : '' }}
          onClick={handleSlideNextChange}
          disabled={activeSlidePage === maxSlidePage}
        >
          {isMobile ? <RightArrowSvg /> : <ArrowRightSvg />}
        </button>
      </div>
      {
        !isMobile && (
          <div className="content-viewAllImagesButton">
            <Link
              href={getGalleryUrl(orderDetails.id, isImageTypeOutput, isLargeView)}
              label={Translation.view_all_images}
              underline
              isNewTab
              font="semibold"
            />
          </div>
        )
      }
    </div>
  )
}

OrderDetailsImages.propTypes = {
  orderDetails: PropTypes.instanceOf(Object),
  isLargeView: PropTypes.bool,
}

OrderDetailsImages.defaultProps = {
  orderDetails: {},
  isLargeView: false,
}

export default OrderDetailsImages
