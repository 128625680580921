import React, { useEffect, useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

import { ORDER_STATUS } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import Link from '../../../../components/Link'

import RateModal from './RateModal'
import EkomiModal from './EkomiModal'

import { ReactComponent as StarFillSvg } from '../../../../svg/star_fill.svg'
import { ReactComponent as CommentSvg } from '../../../../svg/comment.svg'

import './index.scss'

const OrderDetailsRate = ({ orderDetails, fetchAgainOrderDetail }) => {
  const getOrderRating = useStoreActions((actions) => actions.order.getOrderRating)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)

  const [showRateModal, setShowRateModal] = useState(false)
  const [rate, setRate] = useState(0)
  const [hoveredStars, setHoveredStars] = useState(0)
  const [orderRate, setOrderRate] = useState(orderDetails?.order_rating)

  useEffect(() => {
    setOrderRate(orderDetails?.order_rating)
  }, [orderDetails?.order_rating])

  const handleStarHover = (index) => {
    setHoveredStars(index + 1)
  }

  const handleStarLeave = () => {
    setHoveredStars(0)
  }

  const handleSubmit = async (value) => {
    if (!orderDetails.is_order_rated || orderDetails.is_order_rating_editable) {
      setOrderRate(value)
      const result = await getOrderRating({ order_id: orderDetails?.id, rating: value })

      if (result) {
        setOrderRate(value)
        updateSnackbarState({
          message: Translation.success_rating_text,
          isOpen: true,
          type: 'success',
        })
        if (value > 2 || !orderDetails?.order_rating_review) {
          setShowRateModal(true)
        }
      } else {
        setOrderRate(value)
        updateSnackbarState({
          message: Translation.error_rating_text,
          isOpen: true,
          type: 'error',
        })
      }
    }
    setRate(value)
  }

  return (
    <div className="content-rateRow">
      <div className="content-rateRow-text">
        <Link href={`/support?orderId=${orderDetails?.id ?? ''}`} label={Translation.contact_us} underline isNewTab />
        <Typography
          variant="subtitle-narrow"
          label={Translation.about_your_order}
        />
      </div>
      {
        orderDetails?.order_status_id === ORDER_STATUS.ORDER_STATUS_COMPLETED && (
          <div className="rate">
            {
              showRateModal && rate < 3 && (
                <RateModal
                  rate={rate}
                  orderId={orderDetails?.id}
                  onCloseModal={(value) => setShowRateModal(value)}
                  fetchAgainOrderDetail={fetchAgainOrderDetail}
                  reviewOptions={orderDetails?.order_rating_review_options}
                />
              )
            }
            {
              showRateModal && rate > 2 && (
                <EkomiModal
                  rate={rate}
                  orderId={orderDetails?.id}
                  onCloseModal={(value) => setShowRateModal(value)}
                />
              )
            }
            <Typography variant="subtitle" label={`${Translation.rate_the_order}: `} />
            <div className="rate-stars">
              {
                [1, 2, 3, 4, 5].map((item, index) => (
                  <button
                    key={item}
                    type="button"
                    onClick={() => handleSubmit(item)}
                    className={
                      `rate-star ${index < hoveredStars ? 'hovered' : ''} 
                      ${item <= orderRate ? 'fill' : ''} 
                      ${index + 1 === hoveredStars ? 'last-child' : ''}
                      ${(!orderDetails?.is_order_rating_editable && orderDetails?.is_order_rated) ? 'disabled' : ''}`
                    }
                    disabled={!orderDetails?.is_order_rating_editable && orderDetails?.is_order_rated}
                    onMouseEnter={() => handleStarHover(index)}
                    onMouseLeave={handleStarLeave}
                  >
                    <StarFillSvg />
                  </button>
                ))
              }
            </div>
            {
              orderDetails?.order_rating_review && (
                <Tooltip
                  className="info-tooltip"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={orderDetails?.order_rating_review}
                  placement="top"
                  arrow
                >
                  <div className="rate-comment">
                    <CommentSvg />
                  </div>
                </Tooltip>
              )
            }
          </div>
        )
      }
    </div>
  )
}

OrderDetailsRate.propTypes = {
  orderDetails: PropTypes.shape({
    id: PropTypes.number,
    order_status_id: PropTypes.number,
    is_order_rated: PropTypes.bool,
    order_rating: PropTypes.number,
    is_order_rating_editable: PropTypes.bool,
    order_rating_review: PropTypes.string,
    order_rating_review_options: PropTypes.string,
  }),
  fetchAgainOrderDetail: PropTypes.func,
}

OrderDetailsRate.defaultProps = {
  orderDetails: {},
  fetchAgainOrderDetail: () => { },
}

export default OrderDetailsRate
