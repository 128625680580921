import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'
import { ORDER_STATUS, PX_TO_REM } from '../../../../helpers/Constants'

import Typography from '../../../../components/Typography'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'

import { ReactComponent as StatusEye } from '../../../../svg/status_eye.svg'
import { ReactComponent as StatusCheck } from '../../../../svg/status_check.svg'

import './index.scss'

const OrderDetailsConfirm = ({ orderDetails, fetchAgainOrderDetail }) => {
  const getOrderTestImageConfirm = useStoreActions((actions) => actions.order.getOrderTestImageConfirm)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const [isResult, setIsResult] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [comment, setComment] = useState('')

  const handleConfirmTestImage = async (status, commentValue) => {
    const result = await getOrderTestImageConfirm({ id: orderDetails.id, status, comment: commentValue })

    if (result) {
      fetchAgainOrderDetail(true)
      setIsResult(true)
      updateSnackbarState({
        message: status ? Translation.success_confirm_test_image : Translation.success_decline_test_image,
        isOpen: true,
        type: 'success',
      })
    } else {
      updateSnackbarState({
        message: status ? Translation.error_confirm_test_image : Translation.error_decline_test_image,
        isOpen: true,
        type: 'error',
      })
    }
  }

  return (
    <>
      {
        (orderDetails?.is_show_test_image_confirm_decline && !isResult) ? (
          <div className="imagesDetail-confirm">
            <div className="imagesDetail-confirm-title">
              <Typography
                variant="xs"
                label={Translation.confirm_test_image}
                lineHeight={PX_TO_REM['25']}
                font="semibold"
              />
            </div>
            <div className="imagesDetail-confirm-text">
              <Typography
                variant="body1"
                label={Translation.confirm_test_image_text}
              />
            </div>
            <div className="imagesDetail-confirm-buttons">
              <Button type="contained" label={Translation.decline} onClick={() => setShowModal(true)} />
              <Button id="imagesDetail-confirm-btn" label={Translation.confirm} onClick={() => handleConfirmTestImage(true)} />
            </div>
            <div className="testImage-decline-modal">
              <Modal
                hasCloseIcon
                isShown={showModal}
                onClickCloseIcon={() => setShowModal(false)}
                onClickEscClose={() => setShowModal(false)}
              >
                <div className="testImage-decline-modal-title">
                  <Typography variant="h6" font="semibold" lineHeight={1.9230769} label={Translation.decline_test_image} />
                </div>
                <div className="testImage-decline-modal-message">
                  <textarea
                    placeholder={Translation.decline_test_image_textarea_text}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
                <div className="testImage-decline-modal-buttons">
                  <Button label={Translation.close} type="contained" onClick={() => setShowModal(false)} />
                  <Button label={Translation.save} onClick={() => handleConfirmTestImage(false, comment)} />
                </div>
              </Modal>
            </div>
          </div>
        ) : null
      }
      {
        (orderDetails?.type === 0 && orderDetails?.test_order_notice_1 && orderDetails?.test_order_notice_2) ? (
          <div className="imagesDetail-processed">
            <div className="imagesDetail-processed-icon">
              {
                orderDetails?.order_status_id === ORDER_STATUS.ORDER_STATUS_COMPLETED ? <StatusCheck /> : <StatusEye />
              }
            </div>
            <div className="imagesDetail-processed-title">
              <Typography
                variant="xs"
                label={orderDetails?.test_order_notice_1 ?? ''}
                lineHeight={1.2307692}
                font="semibold"
                containsHtml
              />
            </div>
            <div className="imagesDetail-processed-text">
              <Typography
                variant="body1"
                label={orderDetails?.test_order_notice_2 ?? ''}
                containsHtml
              />
            </div>
          </div>
        ) : null
      }
    </>
  )
}

OrderDetailsConfirm.propTypes = {
  orderDetails: PropTypes.shape({
    id: PropTypes.number,
    is_show_test_image_confirm_decline: PropTypes.bool,
    type: PropTypes.number,
    test_order_notice_1: PropTypes.string,
    test_order_notice_2: PropTypes.string,
    order_status_id: PropTypes.number,
  }).isRequired,
  fetchAgainOrderDetail: PropTypes.func,
}

OrderDetailsConfirm.defaultProps = {
  fetchAgainOrderDetail: () => { },
}

export default OrderDetailsConfirm
