import React, { useEffect, useRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useLocation, useNavigate } from 'react-router-dom'

import { HIDE_DROPDOWN_TIMEOUT } from '../../helpers/Constants'
import { Translation, DOOPIC_URLS } from '../../helpers/Translation'

import Typography from '../../components/Typography'

import { ReactComponent as SupportSvg } from '../../svg/support.svg'
import { ReactComponent as InfoSvg } from '../../svg/info.svg'

import './index.scss'

const SupportMenu = () => {
  // init navigation
  const navigate = useNavigate()
  const location = useLocation()

  // init all component actions
  const menuState = useStoreState((state) => state.layout.menuState)
  const isPendingConfirmation = useStoreState((state) => state.user.isPendingConfirmation)
  const updateMenuState = useStoreActions((actions) => actions.layout.updateMenuState)

  // init all component references
  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)

  const handleSupportMenuClick = (e) => {
    // ignore later layout click
    e.stopPropagation()

    updateMenuState({
      lang: false,
      support: !isOpenedRef.current,
      profile: false,
    })
  }

  const handleSupportMenuMouseEnter = () => {
    isMouseOverRef.current = true
  }

  const handleSupportMenuMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        updateMenuState({
          ...menuState,
          support: false,
        })
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  // watch menuState change
  useEffect(() => {
    isOpenedRef.current = menuState.support
  }, [menuState])

  const handleLinkClick = (e) => {
    if (isPendingConfirmation) return
    const { href } = e.currentTarget.dataset

    if (href !== '#') {
      if (e.currentTarget.dataset.target === '_blank') {
        window.open(href, e.currentTarget.dataset.target).focus()
      } else {
        navigate(href)
      }
    }
  }

  const supportMenuPages = [
    {
      pageName: 'support',
      path: '/support',
      label: Translation.support,
      icon: <SupportSvg />,
      handleClick: handleLinkClick,
      isNewTab: false,
    },
    {
      pageName: 'prices',
      path: DOOPIC_URLS.prices,
      label: Translation.prices,
      icon: <InfoSvg />,
      handleClick: handleLinkClick,
      isNewTab: true,
    },
  ]

  return (
    <div
      onMouseEnter={handleSupportMenuMouseEnter}
      onMouseLeave={handleSupportMenuMouseLeave}
      className="support-menu--wrap"
    >
      <div className="support-menu">
        <div
          onClick={handleSupportMenuClick}
          id="support-menu--icon"
          className="support-menu--icon"
        />
      </div>
      {
        (menuState.support) ? (
          <div className="support-menu__menu">
            {
              supportMenuPages.map((page) => (
                <div
                  id={page.pageName}
                  key={page.pageName}
                  data-href={page.path}
                  data-target={`${page.isNewTab ? '_blank' : ''}`}
                  onClick={page.handleClick}
                  className={`support-menu__menu-item 
                    ${(location.pathname === page.path) ? 'support-menu__menu-item--active' : ''}
                    bg-color-transition`}
                >
                  {page.icon}

                  <Typography
                    variant="xs"
                    label={page.label}
                  />
                </div>
              ))
            }
          </div>
        ) : null
      }
    </div>
  )
}

export default SupportMenu
