import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'

import { lang, Translation } from '../../../../../helpers/Translation'
import { EKOMI_MIN_RATING, PX_TO_REM } from '../../../../../helpers/Constants'

import Typography from '../../../../../components/Typography'
import Modal from '../../../../../components/Modal'
import Button from '../../../../../components/Button'

import { ReactComponent as StarSvg } from '../../../../../svg/star.svg'

import '../RateModal/index.scss'

const EkomiModal = ({
  rate,
  orderId,
  onCloseModal,
}) => {
  const user = useStoreState((state) => state.user.user)

  const getEkomiWebhook = useStoreActions((actions) => actions.order.getEkomiWebhook)

  const postDoohookEkomi = (id) => {
    getEkomiWebhook({ id, rating: rate })
  }

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutationRecord) => {
      if (mutationRecord.target.style.display === 'none') {
        const id = parseInt(mutationRecord.target.getAttribute('data-order-id'), 10)

        const hookTimeout = 1500
        // doo ekomi hook with timeout
        setTimeout(() => {
          postDoohookEkomi(id)
        }, hookTimeout)

        // doo ekomi hook with timeout
        setTimeout(() => {
          postDoohookEkomi(id)
        }, 3 * hookTimeout)

        // doo ekomi hook with timeout
        setTimeout(() => {
          postDoohookEkomi(id)
        }, 6 * hookTimeout)
      }
    })
  })

  const showEkomiWidget = async () => {
    onCloseModal(false)

    // avoid creating of ekomi iframe with random url
    if (process.env.REACT_APP_EKOMI_SERVER_URL !== 'https://smartforms.ekomi.com') return
    if (process.env.REACT_APP_EKOMI_SERVER_SCRIPT !== '/script/widget.js') return

    const ekomiWidgetContainer = document.getElementById(process.env.REACT_APP_EKOMI_CONTAINER_ID)
    // show ekomi iframe
    if (ekomiWidgetContainer) {
      if (ekomiWidgetContainer.childNodes.length) {
        ekomiWidgetContainer.childNodes[0].style.display = ''
      } else {
        // eslint-disable-next-line no-underscore-dangle
        window._ekomiServerUrl = process.env.REACT_APP_EKOMI_SERVER_URL
        // eslint-disable-next-line no-underscore-dangle
        if (lang === 'de') window._ekomiShopId = process.env.REACT_APP_EKOMI_SHOP_ID_DE
        // eslint-disable-next-line no-underscore-dangle
        else if (lang === 'it') window._ekomiShopId = process.env.REACT_APP_EKOMI_SHOP_ID_IT
        // eslint-disable-next-line no-underscore-dangle
        else window._ekomiShopId = process.env.REACT_APP_EKOMI_SHOP_ID_EN
        // wnd['_ekomiFormId'] = '183204';
        if (orderId) {
          // eslint-disable-next-line no-underscore-dangle
          window._ekomiTransactionId = orderId
        } else {
          // eslint-disable-next-line no-underscore-dangle
          window._ekomiTransactionId = `ua${user.id}`
        }
        // wnd._ekomiProductIds = 'product_ids';
        // eslint-disable-next-line no-underscore-dangle
        window._ekomiWidgetWidth = '1020px'
        // eslint-disable-next-line no-underscore-dangle
        window._ekomiWidgetHeight = '1290px'
        // eslint-disable-next-line no-underscore-dangle
        window._ekomiEmbedWidget = '0'
        // eslint-disable-next-line no-underscore-dangle
        window._ekomiDisableAutoClose = '0'
        // eslint-disable-next-line no-underscore-dangle
        window._ekomiEmail = ''

        const ekomiScript = document.createElement('script')
        // eslint-disable-next-line no-underscore-dangle
        ekomiScript.src = `${window._ekomiServerUrl}${process.env.REACT_APP_EKOMI_SERVER_SCRIPT}?v=${new Date().getTime()}`
        ekomiScript.async = true

        const ekomiNode = document.getElementsByTagName('script')[0]
        ekomiNode.parentNode.insertBefore(ekomiScript, ekomiNode)

        setTimeout(() => {
          const ekomiIframe = ekomiWidgetContainer.querySelector(`#${process.env.REACT_APP_EKOMI_IFRAME_ID}`)

          if (ekomiIframe) {
            ekomiIframe.setAttribute('data-order-id', orderId)
            // observe inline style change
            observer.observe(ekomiIframe, { attributes: true, attributeFilter: ['style'] })
          }
        }, 2000)
      }
    }
  }

  return (
    <div className="rate-modal">
      <Modal
        hasCloseIcon={false}
        isShown
        onClickEscClose={() => onCloseModal(false)}
      >
        <div className="rate-modal-stars">
          {
            Array.from({ length: rate }, (_, index) => index + 1).map((item) => <StarSvg key={item} />)
          }
        </div>
        <div className="rate-modal-title">
          <Typography
            variant="h6"
            font="semibold"
            lineHeight={PX_TO_REM['25']}
            label={`${rate}
            ${Translation.stars_thank_you}`}
          />
        </div>
        <div className={`rate-modal-text ${rate > 2 ? 'm-bottom-32' : 'm-bottom-8'}`}>
          <Typography
            variant="xs-narrow"
            label={Translation.rate_modal_stars_text}
            containsHtml
          />
        </div>
        <div className="rate-modal-buttons">
          <Button
            label={Translation.close}
            width={PX_TO_REM['172']}
            type="contained"
            onClick={() => onCloseModal(false)}
          />
          {
            rate >= EKOMI_MIN_RATING && (
              <Button
                label={Translation.rate_us_on_ekomi}
                onClick={() => showEkomiWidget(orderId)}
              />
            )
          }
        </div>
      </Modal>
    </div>
  )
}

EkomiModal.propTypes = {
  rate: PropTypes.number,
  orderId: PropTypes.number,
  onCloseModal: PropTypes.func,
}

EkomiModal.defaultProps = {
  rate: 0,
  orderId: 0,
  onCloseModal: () => { },
}

export default EkomiModal
