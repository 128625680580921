import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'

import { Translation, DOOPIC_URLS } from '../../../../helpers/Translation'
import {
  DEFAULT_CURRENCY,
  ORDER_SUCCESS_PAGE,
} from '../../../../helpers/Constants'
import { isLocal } from '../../../../helpers/Cookie'

import Typography from '../../../../components/Typography'
import Checkbox from '../../../../components/Checkbox'
import Button from '../../../../components/Button'
import Dialog from '../../../../components/Dialog'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import Link from '../../../../components/Link'

import './index.scss'

const Overview = ({
  newOrderTemplate,
  priceWidgetPosition,
  newOrderPricing,
  images,
  isTestOrder,
  currency,
  orderName,
  showEmptyOrderNameDialog,
}) => {
  const addCouponCode = useStoreActions((actions) => actions.order.addCouponCode)
  const deleteCouponCode = useStoreActions((actions) => actions.order.deleteCouponCode)
  const getNewOrderPricing = useStoreActions((actions) => actions.order.getNewOrderPricing)
  const setCreatedOrderId = useStoreActions((actions) => actions.order.setCreatedOrderId)
  const createOrder = useStoreActions((actions) => actions.order.createOrder)
  const [showCouponInput, setShowCouponInput] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [resultText, setResultText] = useState('')
  const [isAgreeTerms, setIsAgreeTerms] = useState(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [apiErrors, setApiErrors] = useState('')
  const [hasFalseCritical, setHasFalseCritical] = useState(false)
  const [showCriticalModal, setShowCriticalModal] = useState(false)
  const [isCriticalModalSelected, setIsCriticalModalSelected] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [isPlaceOrderButtonDisabled, setIsPlaceOrderButtonDisabled] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    setCouponCode(newOrderPricing?.code_incentive ?? '')
  }, [newOrderPricing])

  const handleDeleteCouponCode = async () => {
    const deleteCouponCodeResult = await deleteCouponCode({ code: couponCode })
    if (deleteCouponCodeResult) {
      setResultText('')
      setShowCouponInput(true)
      getNewOrderPricing({ template_id: newOrderTemplate.template_id })
    }
  }

  const handleAddCouponCode = async () => {
    if (couponCode) {
      const addCouponCodeResult = await addCouponCode(couponCode)
      setShowCouponInput(false)
      if (addCouponCodeResult) {
        setResultText(
          <div className="coupon-success-text">
            {Translation.coupon_success_text}
            <button type="button" onClick={(() => handleDeleteCouponCode(couponCode))}>{Translation.remove}</button>
          </div>,
        )
        getNewOrderPricing({ template_id: newOrderTemplate.template_id })
      } else {
        setResultText(
          <div className="coupon-error-text">
            {Translation.coupon_error_text}
            <button type="button" onClick={() => { setResultText(''); setShowCouponInput(true) }}>
              {Translation.try_again}
            </button>
          </div>,
        )
      }
    }
  }

  const placeOrder = async () => {
    if (!isAgreeTerms) {
      setTermsError(true)
      return
    }
    if (!orderName) {
      showEmptyOrderNameDialog(true)
    } else if (hasFalseCritical) {
      setShowCriticalModal(true)
    } else if (isAgreeTerms) {
      setIsPlaceOrderButtonDisabled(true)

      const result = await createOrder({ template_id: newOrderTemplate.template_id })
      import('lottie-react')
      if (result.success && result.order_id) {
        setCreatedOrderId(result.order_id)

        // send conversion to AdWords
        if (!isLocal && typeof gtag !== 'undefined') {
          // eslint-disable-next-line no-undef
          gtag(
            'event',
            'conversion',
            {
              send_to: isTestOrder
                ? process.env.REACT_APP_AW_TEST_ORDER_CONVERSION
                : process.env.REACT_APP_AW_NEW_ORDER_CONVERSION,
              value: isTestOrder ? 0.0 : 1.0,
              currency: currency || DEFAULT_CURRENCY,
              transaction_id: '',
            },
          )
        }

        navigate(ORDER_SUCCESS_PAGE)
      } else {
        setIsPlaceOrderButtonDisabled(false)
        setApiErrors(result.error)
        setIsErrorModalOpen(true)
      }
    }
  }

  useEffect(() => {
    if (Object.keys(newOrderPricing?.errors).length > 0) {
      const hasFalseCriticalError = Object.values(newOrderPricing?.errors).some((data) => {
        const { errors } = data
        return errors.some((error) => error.is_critical === false)
      })
      setHasFalseCritical(hasFalseCriticalError)
    } else {
      setHasFalseCritical(false)
    }
  }, [newOrderPricing])

  useEffect(() => {
    if (isCriticalModalSelected) {
      placeOrder().then(() => { })
      setIsCriticalModalSelected(false)
    }
  }, [isCriticalModalSelected])

  const priceDetail = [
    {
      description: Translation.price_per_picture,
      price: newOrderPricing?.price_per_image,
      line: true,
      isShow: newOrderPricing?.price_per_image !== null,
    },
    {
      description: `${Translation.subtotal_for} ${images.length}
        ${images.length === 1 ? Translation.image : Translation.images}`,
      price: `${newOrderPricing?.price_subtotal}`,
      isShow: newOrderPricing?.price_subtotal !== null,
      font: 'semibold',
      color: isTestOrder ? 'orange' : '',
    },
    {
      description: `${Translation.surcharge_to_the_minimum_order_value} (9.99 €)`,
      price: `+${newOrderPricing?.price_surcharge}`,
      line: true,
      isShow: newOrderPricing?.price_surcharge !== null,
    },
    {
      description: (
        <>
          {`${Translation.discount}: ${newOrderPricing?.what_incentive} ${newOrderPricing?.code_incentive}`}
          <button type="button" className="remove-coupon" onClick={handleDeleteCouponCode}>
            {Translation.remove}
          </button>
        </>
      ),
      price: `-${newOrderPricing?.price_incentive}`,
      isShow: newOrderPricing?.what_incentive,
    },
    {
      description: newOrderPricing?.price_surcharge !== null ? Translation.total_price
        : `${Translation.total_for} ${images.length} ${images.length === 1 ? Translation.image : Translation.images}`,
      price: `${newOrderPricing?.price_total}`,
      line: false,
      isShow: newOrderPricing?.price_total !== null,
      font: 'semibold',
      color: isTestOrder ? 'orange' : '',
    },
    {
      description: `${Translation.vat} (19%)`,
      price: `+${newOrderPricing?.vat_price}`,
      line: true,
      isShow: newOrderPricing?.vat_price !== null,
    },
    {
      description: Translation.total_gross,
      price: newOrderPricing?.price_gross,
      isShow: newOrderPricing?.price_gross !== null,
    },
  ]

  const onTermsCheckChange = (e) => {
    const isChecked = e.target.checked
    setTermsError(!isChecked)
    setIsAgreeTerms(isChecked)
    setIsPlaceOrderButtonDisabled(!isChecked)
  }

  return (
    <div className="overview-wrapper">
      <section className="overview-section">
        <div className="overview-section-top">
          <Typography variant="subtitle" font="semibold" label={Translation.overview} />
          <Typography variant="xs" label={Translation.overview_text} />
        </div>
        <div className="overview-table">
          <div className="format-price">
            {
              newOrderPricing?.pricing.map((item) => (
                <div key={item.label} className={`format-price-row ${item.is_base_price ? 'bgFill' : ''}`}>
                  <Typography variant="xs" label={item.label} font={item.is_base_price || item.is_format ? 'semibold' : ''} />
                  <Typography variant="xs" label={item.price} />
                </div>
              ))
            }
          </div>
          {
            priceDetail.map((item) => (
              item.isShow && (
                <div key={item.description} className={`table-price-row ${item.color ?? ''}`}>
                  <div className="row-description">
                    <Typography variant="xs" font={item.font ?? ''} label={item.description} />
                  </div>
                  <div className={`row-price ${item.line ? 'bottom-line' : ''}`}>
                    <Typography variant="xs" font={item.font ?? ''} label={item.price} />
                  </div>
                </div>
              )
            ))
          }
          <div className="overview-table-bottom">
            {
              !isTestOrder && (
                <>
                  {
                    !showCouponInput && !resultText && !couponCode && (
                      <button type="button" className="table-bottom-couponText" onClick={() => setShowCouponInput(true)}>
                        <Typography variant="xs" label={Translation.add_coupon_code} />
                      </button>
                    )
                  }
                  {
                    showCouponInput && (
                      <div className="add-coupon">
                        <Input value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                        <Button label={Translation.add} type="fit-content" onClick={handleAddCouponCode} />
                        <Button
                          label={Translation.cancel}
                          type="contained"
                          onClick={() => { setShowCouponInput(false); setResultText(''); setCouponCode('') }}
                        />
                      </div>
                    )
                  }
                  {!showCouponInput && resultText ? resultText : null}
                </>
              )
            }
            <div className="table-bottom-check">
              { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="new-order-terms-check">
                <div className="align-left flex">
                  <div>
                    <Checkbox
                      id="new-order-terms-check"
                      checked={isAgreeTerms}
                      error={termsError}
                      onChange={(e) => onTermsCheckChange(e)}
                    />
                  </div>
                  <div className="min-width-330">
                    <Typography
                      variant="xs"
                      theme="light"
                      inline
                      label={Translation.new_order_terms_agree_1}
                    />
                    {' '}
                    <Link
                      href={DOOPIC_URLS.terms}
                      variant="xs"
                      theme="light"
                      underline
                      label={Translation.new_order_terms_agree_2}
                      isNewTab
                    />
                    {' '}
                    <Typography
                      variant="xs"
                      theme="light"
                      inline
                      label={Translation.new_order_terms_agree_3}
                    />
                    {' '}
                    <Link
                      href={DOOPIC_URLS.privacy}
                      variant="xs"
                      theme="light"
                      underline
                      label={Translation.new_order_terms_agree_4}
                      isNewTab
                    />
                    {' '}
                    <Typography
                      variant="xs"
                      theme="light"
                      inline
                      label={Translation.new_order_terms_agree_5}
                    />
                  </div>
                </div>
              </label>
              {termsError && (
                <div className="m-top-4 align-left error">
                  <Typography
                    variant="xs"
                    theme="error"
                    inline
                    label={Translation.accept_terms_conditions_1}
                  />
                  {' '}
                  <Typography
                    variant="xs"
                    theme="error"
                    inline
                    font="semibold"
                    label={Translation.accept_terms_conditions_2}
                  />
                  {' '}
                  <Typography
                    variant="xs"
                    theme="error"
                    inline
                    label={Translation.accept_terms_conditions_3}
                  />
                  {' '}
                  <Typography
                    variant="xs"
                    theme="error"
                    inline
                    font="semibold"
                    label={Translation.accept_terms_conditions_4}
                  />
                  .
                </div>
              )}
            </div>
            <div className="table-bottom-button">
              <Button
                label={isTestOrder ? Translation.place_order_for_free : Translation.place_order}
                type="fit-content"
                onClick={placeOrder}
                disabled={isPlaceOrderButtonDisabled}
              />
            </div>
          </div>
        </div>
        <div className="overview-bottom">
          <div className="overview-bottom-title">
            <Typography variant="subtitle" font="semibold" label={Translation.overview} />
          </div>
          <div className="overview-bottom-description">
            <Typography variant="xs" label={Translation.overview_bottom_text_1} />
            <Typography variant="xs" label={Translation.overview_bottom_text_2} />
          </div>
        </div>
      </section>

      <div className={`price-widget ${priceWidgetPosition}`}>
        <Typography variant="h6" label={`${Translation.total_price}: `} />
        <Typography variant="h6" label={newOrderPricing?.price_total} font="semibold" />
      </div>
      {isErrorModalOpen && (
        <Dialog
          isShown={isErrorModalOpen}
          title={`${Translation.attention}!`}
          content={(
            <Typography
              variant="xs"
              containsHtml
              label={apiErrors}
            />
          )}
          yesLabel={Translation.ok}
          onClickCancel={() => setIsErrorModalOpen(false)}
          onClickYes={() => setIsErrorModalOpen(false)}
          hasOnlyYes
          isWarning
        />
      )}
      {
        hasFalseCritical && showCriticalModal && (
          <div className="new-order-modal">
            <Modal
              hasCloseIcon={false}
              onClickEscClose={() => setShowCriticalModal(false)}
              isShown
            >
              <div className="new-order-modal-title">
                <Typography variant="h6" font="semibold" label={Translation.attention} />
              </div>
              <div className="new-order-modal-content">
                <Typography variant="xs" label={Translation.place_order_critical_modal_text} />
                <ul>
                  {
                    Object.keys(newOrderPricing?.errors).map((key) => {
                      // eslint-disable-next-line no-unsafe-optional-chaining
                      const { header, errors } = newOrderPricing?.errors[key]
                      return (
                        errors.some((error) => error.is_critical === false) && (
                          <li key={key} className="critical-group">
                            <Typography variant="xs" font="semibold" label={header} />
                            <ul className="critical-group-content">
                              {
                                errors.map((error) => error.is_critical === false && (
                                  <li key={error.action_id}>
                                    <Typography variant="xs" label={error.error_short} />
                                  </li>
                                ))
                              }
                            </ul>
                          </li>
                        )
                      )
                    })
                  }
                </ul>
                <div className="critical-bottom-text">
                  <Typography variant="xs" label={Translation.do_you_still_want_to_place_the_order} />
                </div>
              </div>
              <div className="new-order-modal-buttons">
                <Button type="contained" color="primary" label={Translation.no} onClick={() => setShowCriticalModal(false)} />
                <Button
                  color="primary"
                  label={Translation.yes}
                  onClick={() => {
                    setShowCriticalModal(false)
                    setHasFalseCritical(false)
                    setIsCriticalModalSelected(true)
                  }}
                />
              </div>
            </Modal>
          </div>
        )
      }
    </div>
  )
}

Overview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newOrderTemplate: PropTypes.objectOf(PropTypes.any),
  priceWidgetPosition: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  newOrderPricing: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  isTestOrder: PropTypes.bool,
  currency: PropTypes.string,
  orderName: PropTypes.string.isRequired,
  showEmptyOrderNameDialog: PropTypes.func.isRequired,
}

Overview.defaultProps = {
  newOrderTemplate: {},
  priceWidgetPosition: '',
  newOrderPricing: {},
  isTestOrder: false,
  currency: DEFAULT_CURRENCY,
}

export default Overview
