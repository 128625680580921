import { useSearchParams } from 'react-router-dom'

import { Cookie } from './Cookie'
import { COOKIE_NAMES } from './Constants'

const CheckUTMParams = () => {
  const [searchParams] = useSearchParams()

  return () => {
    const partnerId = searchParams.get('a')
    const referrerId = searchParams.get('r')
    const salesRepId = searchParams.get('s') || searchParams.get('sr')

    if (partnerId) {
      Cookie.setCookie(COOKIE_NAMES.AFFILIATE_PARTNER_ID_COOKIE, partnerId)
    }
    if (referrerId) {
      Cookie.setCookie(COOKIE_NAMES.REFERRER_ID_COOKIE, referrerId)
    }
    if (salesRepId) {
      Cookie.setCookie(COOKIE_NAMES.SALES_REP_ID_COOKIE, salesRepId)
    }

    if (partnerId || referrerId || salesRepId) {
      const newUrl = new URL(window.location.href)
      newUrl.search = ''
      if (partnerId) {
        newUrl.searchParams.append(
          'utm_source',
          `a${partnerId}`,
        )
      } else if (referrerId) {
        newUrl.searchParams.append(
          'utm_source',
          `r${referrerId}`,
        )
      } else if (salesRepId) {
        newUrl.searchParams.append(
          'utm_source',
          `s${salesRepId}`,
        )
      }
      window.location.href = newUrl.href
    }
  }
}

export default CheckUTMParams
