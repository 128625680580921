import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import Backdrop from '@mui/material/Backdrop'
import { ShepherdTour } from 'react-shepherd'

import {
  CHECKOUT_PAGE,
  DEFAULT_PAGE,
  LOGIN_PAGE,
  INTERVAL_60_SECONDS,
  HORIZONTAL_SCROLL_PAGES,
} from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import { debounce, isAuthenticated } from '../../helpers/Utils'
import CheckUTMParams from '../../helpers/CheckUTMParams'
import { dashboardSteps, dashboardTourOptions } from '../../helpers/Shepherd'

import SnackBar from '../../components/SnackBar'
import Typography from '../../components/Typography'

import Footer from '../Footer'
// eslint-disable-next-line import/no-cycle
import Sidebar from '../Sidebar'
import Header from '../Header'
import DotAnimation from '../Common/DotAnimation'

import './index.scss'

const Layout = ({ pageName, title, children }) => {
  // init page title
  document.title = `${title} / ${Translation.my_doopic}`

  const setUTMTags = CheckUTMParams()
  const params = useParams()
  const location = useLocation()

  // init all component actions
  const updateBreakpoints = useStoreActions((actions) => actions.layout.updateBreakpoints)
  const meAction = useStoreActions((actions) => actions.user.me)
  const getUnpaidDetails = useStoreActions((state) => state.invoice.getUnpaidDetails)
  const getUnpaidDataDashboard = useStoreActions((state) => state.invoice.getUnpaidDataDashboard)
  const updateSidebarMobile = useStoreActions((actions) => actions.layout.updateSidebarMobile)
  const updateMenuState = useStoreActions((actions) => actions.layout.updateMenuState)
  const updateHorizontalScroll = useStoreActions((actions) => actions.layout.updateHorizontalScroll)
  const fetchCurrentReleaseVersion = useStoreActions((actions) => actions.global.fetchCurrentReleaseVersion)
  const user = useStoreState((state) => state.user.user)
  const isSidebarMobile = useStoreState((state) => state.layout.isSidebarMobile)
  const isHorizontalScroll = useStoreState((state) => state.layout.isHorizontalScroll)
  const isSidebarOpened = useStoreState((state) => state.layout.isSidebarOpened)
  const requestCount = useStoreState((state) => state.global.requestCount)
  const menuState = useStoreState((state) => state.layout.menuState)
  const snackbarState = useStoreState((state) => state.global.snackbarState)
  const backdropState = useStoreState((state) => state.global.backdropState)
  const isPendingConfirmation = useStoreState((state) => state.user.isPendingConfirmation)

  const [isHeaderShadow, setHeaderIsShadow] = useState(false)

  const checkCurrentReleaseVersion = async () => {
    const result = await fetchCurrentReleaseVersion()
    if (result.react_app_version) {
      // check if the current version is different from the latest version, if not refresh the page
      // check if we have the version info in the local storage first
      const currentVersion = localStorage.getItem('currentReleaseVersion')
      if (!currentVersion) {
        localStorage.setItem('currentReleaseVersion', result.react_app_version)
      } else if (currentVersion !== result.react_app_version) {
        localStorage.setItem('currentReleaseVersion', result.react_app_version)
        window.location.reload()
      }
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    setUTMTags()

    if (!isAuthenticated()) {
      // if we have token and id in the url, redirect to "/login" as query params
      if (params.token && params.id) {
        window.location.href = `${LOGIN_PAGE}?token=${params.token}&id=${params.id}`
      } else {
        const redirectTo = `${LOGIN_PAGE}${window.location.pathname.slice(1)}${window.location.search}${window.location.hash}`
        window.location.href = `${LOGIN_PAGE}?redirect=${encodeURIComponent(redirectTo)}`
      }
    } else {
      // check if user is not empty
      if (Object.keys(user).length === 0) {
        meAction()
      }

      // listen to window resize
      window.addEventListener('resize', debounce(() => {
        updateBreakpoints()
      }), true)
    }

    checkCurrentReleaseVersion().then(() => { })
    const checkCurrentReleaseVersionInterval = setInterval(() => {
      checkCurrentReleaseVersion().then(() => { })
    }, INTERVAL_60_SECONDS)

    return () => clearInterval(checkCurrentReleaseVersionInterval)
  }, [])

  useEffect(() => {
    if (HORIZONTAL_SCROLL_PAGES.includes(pageName)) {
      updateHorizontalScroll(true)
    } else {
      updateHorizontalScroll(false)
    }
  }, [pageName])

  const fetchUnpaidDetailsWithDelay = useCallback(async () => {
    await getUnpaidDetails()
  }, [])

  useEffect(() => {
    if (isAuthenticated() && location.pathname !== CHECKOUT_PAGE && location.pathname !== DEFAULT_PAGE) {
      // get unpaid details with delay, so it does not block other requests
      setTimeout(() => {
        fetchUnpaidDetailsWithDelay().then(() => { })
      }, 200)
    }

    // 1 minute interval for fetching unpaid details, if we are not on checkout page
    const interval = setInterval(() => {
      if (location.pathname !== CHECKOUT_PAGE && location.pathname !== DEFAULT_PAGE) {
        getUnpaidDetails()
      }

      if (location.pathname === DEFAULT_PAGE) {
        getUnpaidDataDashboard()
      }
    }, INTERVAL_60_SECONDS)

    return () => clearInterval(interval)
  }, [location.pathname])

  const handleLayoutClick = () => {
    if (menuState.lang || menuState.support || menuState.profile) {
      updateMenuState({
        lang: false,
        support: false,
        profile: false,
      })
    }

    if (isSidebarMobile) updateSidebarMobile(false)
  }

  const handleLayoutScroll = (e) => {
    if (e.currentTarget.scrollTop) setHeaderIsShadow(true)
    else setHeaderIsShadow(false)
  }

  // show empty page, before redirect
  if (!isAuthenticated()) return null

  return (
    <div>
      <ShepherdTour tourOptions={dashboardTourOptions} steps={dashboardSteps}>
        <div
          onClick={handleLayoutClick}
          id="layout"
        >
          {!isPendingConfirmation && (
            <Sidebar pageName={pageName} />
          )}

          <div
            className={
              `layout--wrap ${isPendingConfirmation ? 'no-margin' : ''} ${(isSidebarOpened) ? 'layout--wrap--opened' : ''}`
            }
          >
            {!isPendingConfirmation && (
              <Header
                title={title}
                isShadow={isHeaderShadow}
              />
            )}

            <div
              onScroll={handleLayoutScroll}
              className={`layout scrollbar-overflow scrollbar-overflow-layout ${isPendingConfirmation ? 'full-height' : ''}`}
            >
              <div className={`content ${isHorizontalScroll ? 'content-horizontal-scroll' : ''}`}>
                {children}
              </div>

              <Footer isNewTab />
            </div>

            <SnackBar isOpen={snackbarState.isOpen} />

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={backdropState.isOpen}
            >
              <div className="backdrop-content">
                <Typography variant="xs" label={backdropState.message} />
              </div>
            </Backdrop>
          </div>
          {requestCount > 0 && <div className="screen-center general-loading-icon"><DotAnimation /></div>}
        </div>
      </ShepherdTour>
    </div>
  )
}

Layout.propTypes = {
  pageName: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  pageName: '',
  title: '',
}

export default Layout
