import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'

import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Tooltip from '@mui/material/Tooltip'

import { Translation } from '../../../../helpers/Translation'
import {
  PAYMENT_MODAL_TYPE,
  PAYMENT_SUCCESS_PAGE,
  PX_TO_REM,
} from '../../../../helpers/Constants'
import { getCardInfo } from '../../../../helpers/Utils'

import Button from '../../../../components/Button'
import Typography from '../../../../components/Typography'

import { ReactComponent as RadioCheckedGraySvg } from '../../../../svg/radio_checked_gray.svg'
import { ReactComponent as RadioUncheckedSvg } from '../../../../svg/radio_unchecked.svg'
import { ReactComponent as InfoIcon } from '../../../../svg/info.svg'

import './index.scss'

const PaymentList = ({
  handleUseDifferentMethod,
  openSepaInfoModal,
  handleModalClose,
  setPaymentModalState,
}) => {
  const navigate = useNavigate()

  const paymentMethods = useStoreState((state) => state.invoice.paymentMethods)
  const unpaidData = useStoreState((state) => state.order.unpaidData)
  const payWithSepa = useStoreActions((state) => state.invoice.payWithSepa)
  const payWithCreditCard = useStoreActions((state) => state.invoice.payWithCreditCard)

  const [selectedMethod, setSelectedMethod] = useState(0)

  useEffect(() => {
    setSelectedMethod(paymentMethods.find((m) => m.primary)?.id)
  }, [paymentMethods])

  const handleMakeSavedPayment = async () => {
    setPaymentModalState(PAYMENT_MODAL_TYPE.inProcess)
    const method = paymentMethods.find((item) => item.id === parseInt(selectedMethod, 10))
    let res
    if (method.iban) {
      res = await payWithSepa({
        po_id: selectedMethod,
        final_price: unpaidData.final_price_no_format,
        currency: unpaidData.currency,
      })
    } else {
      res = await payWithCreditCard({
        po_id: selectedMethod,
        final_price: unpaidData.final_price_no_format,
        currency: unpaidData.currency,
      })
    }

    if (res.success) {
      if (res.redirecturl) {
        window.location.href = res.redirecturl
      } else {
        navigate(PAYMENT_SUCCESS_PAGE)
      }
    } else {
      setPaymentModalState(PAYMENT_MODAL_TYPE.paymentError)
    }
  }

  return (
    <>
      <div
        className={
          `select-payment-list scrollbar-overflow 
            ${paymentMethods.filter((method) => method.status !== 9).length > 5 ? 'scrollable' : ''}`
        }
      >
        <RadioGroup
          value={selectedMethod || null}
          className="payment-method-radio"
          onChange={(e) => setSelectedMethod(e.currentTarget.value)}
        >
          {paymentMethods
            .filter((method) => method.status !== 9)
            .map((method) => (
              <div className="payment-method-item" key={method.id}>
                <div className="method-left">
                  <Radio
                    disableRipple
                    value={method.id}
                    icon={<RadioUncheckedSvg className="checkbox-icon" />}
                    checkedIcon={<RadioCheckedGraySvg className="checkbox-icon" />}
                  />

                  <div className="payment-icon-wrapper">
                    {getCardInfo(method).icon}
                  </div>

                  <div className="payment-method-name">
                    <Typography
                      variant="s"
                      label={getCardInfo(method).cardNameNumber}
                    />
                  </div>
                </div>
                <div className="method-right">
                  {method.iban && (
                    <Tooltip
                      arrow
                      className="info-tooltip"
                      PopperProps={{
                        disablePortal: true,
                      }}
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      title={Translation.view_direct_debit}
                    >
                      <InfoIcon className="info-icon" onClick={() => openSepaInfoModal(method)} />
                    </Tooltip>
                  )}

                  {method.primary === 1 && (
                    <div className="primary-blue">
                      <Typography
                        variant="xs"
                        font="semibold"
                        label={Translation.primary}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </RadioGroup>
      </div>
      <div className="form-actions">
        <button
          type="button"
          className="use-different-payment-btn"
          onClick={handleUseDifferentMethod}
        >
          <Typography
            variant="xs"
            font="semibold"
            label={`+ ${Translation.use_a_different_payment_method}`}
          />
        </button>

        <Button
          type="contained"
          onClick={handleModalClose}
          label={Translation.cancel}
          width={PX_TO_REM['116']}
        />
        <Button
          onClick={handleMakeSavedPayment}
          disabled={!selectedMethod}
          label={Translation.make_payment}
          width={PX_TO_REM['185']}
        />
      </div>
    </>
  )
}

PaymentList.propTypes = {
  handleUseDifferentMethod: PropTypes.func,
  openSepaInfoModal: PropTypes.func,
  handleModalClose: PropTypes.func,
  setPaymentModalState: PropTypes.func,
}

PaymentList.defaultProps = {
  handleUseDifferentMethod: () => { },
  openSepaInfoModal: () => { },
  handleModalClose: () => { },
  setPaymentModalState: () => { },
}

export default PaymentList
