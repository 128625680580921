import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'
import SnackbarMui from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'
import MuiAlert from '@mui/material/Alert'

import { HIDE_SNACKBAR_ERROR_TIMEOUT, HIDE_SNACKBAR_SUCCESS_TIMEOUT } from '../../helpers/Constants'

import { ReactComponent as SuccessIcon } from '../../svg/snackbar_successful.svg'
import { ReactComponent as WarningIcon } from '../../svg/warning.svg'

import './index.scss'

const SlideTransition = (props) => <Slide {...props} direction="down" />

const Alert = React.forwardRef((props, ref) => <MuiAlert icon={false} ref={ref} variant="filled" {...props} />)

const SnackBar = ({ isOpen = false, message = 'Snackbar Example', type = 'success' }) => {
  let snackbarState = {}
  let updateSnackbarState

  try {
    snackbarState = useStoreState((state) => state.global.snackbarState) || {
      message: 'Snackbar Example',
      type: 'success',
    }
    updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  } catch (error) {
    // This try catch is for storybook
  }

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return
    try {
      updateSnackbarState({
        ...snackbarState,
        isOpen: false,
      })
    } catch (error) {
      // This try catch is for storybook
    }
  }

  return (
    <SnackbarMui
      open={snackbarState.isOpen || isOpen}
      autoHideDuration={snackbarState.type === 'success' ? HIDE_SNACKBAR_SUCCESS_TIMEOUT : HIDE_SNACKBAR_ERROR_TIMEOUT}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={`doo-snackbar-${snackbarState.type || type} doo-snackbar`}
      TransitionComponent={SlideTransition}
    >
      <Alert severity={snackbarState.type || type}>
        {snackbarState.type === 'success' && (<SuccessIcon />)}
        {snackbarState.type === 'error' && (<WarningIcon />)}
        {snackbarState.message || message}
      </Alert>
    </SnackbarMui>
  )
}

SnackBar.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error']),
}

SnackBar.defaultProps = {
  isOpen: false,
  message: 'Snackbar Example',
  type: 'success',
}

export default SnackBar
