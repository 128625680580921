import React, { useState, useEffect, useRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

import {
  DESKTOP_PX_TO_REM,
  DESKTOP_TABLE_WIDTH,
  NEW_ORDER_PAGE,
  TABLES,
  TEMPLATE_PAGE,
} from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'
import { getPerPage, localizeDate, savePerPage } from '../../helpers/Utils'

import Table from '../../components/Table'
import Typography from '../../components/Typography'
import Dialog from '../../components/Dialog'

import TableTopInfo from '../../layouts/Common/TableTopInfo'
import MobileView from '../../layouts/Common/MobileView'
import TableContent from './TableContent'

import { ReactComponent as PlusCircleSvg } from '../../svg/plus_circle.svg'
import { ReactComponent as TemplatesSvg } from '../../svg/templates.svg'
import { ReactComponent as ThreeDots } from '../../svg/three_dots.svg'
import { ReactComponent as EditSvg } from '../../svg/menu-edit.svg'
import { ReactComponent as TrashSvg } from '../../svg/menu-trash.svg'
import { ReactComponent as DuplicateIcon } from '../../svg/menu-duplicate.svg'

import './index.scss'

const processFormats = (value, maxLength) => {
  // truncate formats
  if (value.length > maxLength) {
    return (
      <Tooltip
        className="info-tooltip"
        PopperProps={{
          disablePortal: true,
        }}
        title={value}
        placement="top"
        arrow
      >
        <div className="order-formats">
          <span>{`${value.substring(0, maxLength)}...`}</span>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="order-formats">
      <span>{value}</span>
    </div>
  )
}

const processFormatsActions = (value, maxLength) => {
  // truncate actions
  if (value.length > maxLength) {
    return (
      <Tooltip
        className="info-tooltip w-max-314"
        PopperProps={{
          disablePortal: true,
        }}
        title={value}
        placement="left"
        arrow
      >
        <div className="order-actions">
          <span>{`${value.substring(0, maxLength)}...`}</span>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="order-actions">
      <span>{value}</span>
    </div>
  )
}

const IconTooltip = ({ children, text }) => (
  <Tooltip
    className="info-tooltip"
    PopperProps={{ disablePortal: true }}
    title={text}
    placement="left"
    arrow
  >
    {children}
  </Tooltip>
)

const Templates = () => {
  const navigate = useNavigate()

  const isMobile = useStoreState((state) => state.layout.isMobile)
  // init all component actions
  const templatesAction = useStoreActions((actions) => actions.template.fetchTemplates)
  const setSavedList = useStoreActions((actions) => actions.template.setSavedList)
  const setWorkflowList = useStoreActions((actions) => actions.template.setWorkflowList)
  const applyTemplate = useStoreActions((actions) => actions.template.applyTemplate)
  const deleteTemplate = useStoreActions((actions) => actions.template.deleteTemplate)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const getTemplateDetail = useStoreActions((actions) => actions.template.getTemplateDetail)
  const getDuplicateTemplate = useStoreActions((actions) => actions.template.getDuplicateTemplate)

  const savedList = useStoreState((state) => state.template.savedList)
  const savedCount = useStoreState((state) => state.template.savedCount)
  const workflowList = useStoreState((state) => state.template.workflowList)

  const [savedPage, setSavedPage] = useState(1)
  const [savedRowsPerPage, setSavedRowsPerPage] = useState(getPerPage(TABLES.SAVED_TEMPLATES))
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [applyTemplateDialog, setApplyTemplateDialog] = useState(false)
  const [deleteTemplateDialog, setDeleteTemplateDialog] = useState(false)
  const [isLoadingDeleteTemplate, setIsLoadingDeleteTemplate] = useState(false)
  const [isShowRowActions, setIsShowRowActions] = useState(false)
  const [templatesTableRowCollapseContent, setTemplatesTableRowCollapseContent] = useState({})

  const actionsBoxRef = useRef(null)

  const fetchTemplates = (payload) => {
    templatesAction(payload)
  }

  useEffect(() => {
    setSavedList({ templates: null })
    setWorkflowList({ templates: null })
  }, [])

  useEffect(() => {
    fetchTemplates({ page: savedPage, limit: savedRowsPerPage, type: 'saved' })
    savePerPage(TABLES.SAVED_TEMPLATES, savedRowsPerPage)
  }, [savedPage, savedRowsPerPage])

  const formatActionsTemplatesMaxLength = 80
  const templatesTableColWidth = [400, 500, 100, 170]
  // last column width is calculated as the rest of available table width
  templatesTableColWidth.push(DESKTOP_TABLE_WIDTH - templatesTableColWidth.reduce((a, b) => a + b))

  const templatesTableColumns = [
    { id: 'template_name', label: Translation.name, width: templatesTableColWidth[0] / DESKTOP_PX_TO_REM },
    { id: 'formats', label: Translation.templates_formats, width: templatesTableColWidth[1] / DESKTOP_PX_TO_REM },
    { id: 'turnaround', label: Translation.time, width: templatesTableColWidth[2] / DESKTOP_PX_TO_REM },
    { id: 'timestamp', label: Translation.templates_changed, width: templatesTableColWidth[3] / DESKTOP_PX_TO_REM },
    {
      id: 'warningEditDelete',
      label: null,
      render: 'jsx',
      width: templatesTableColWidth[5] / DESKTOP_PX_TO_REM,
    },
  ]

  const handleApplyClick = (template) => {
    setSelectedTemplate(template)
    setApplyTemplateDialog(true)
  }

  const onEdit = (template) => {
    navigate(`${TEMPLATE_PAGE}/${template.id}`)
  }

  const onDelete = (template) => {
    setSelectedTemplate(template)
    setDeleteTemplateDialog(true)
  }

  const handleDuplicateTemplate = async (id) => {
    setIsShowRowActions(false)
    const result = await getDuplicateTemplate(id)

    if (result) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.template_duplicated_successfully,
        type: 'success',
      })

      fetchTemplates({ page: savedPage, limit: savedRowsPerPage, type: 'saved' })
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.template_duplicated_error,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (savedList !== null && savedList.length > 0) {
      const obj = {}
      savedList.forEach((item) => {
        obj[item.id] = (
          <TableContent />
        )
      })

      Object.keys(templatesTableRowCollapseContent).forEach((key) => {
        if (obj[key]) {
          obj[key] = templatesTableRowCollapseContent[key]
        }
      })

      setTemplatesTableRowCollapseContent({ ...obj })
    }
  }, [savedList, savedList?.length])

  const handleRowClick = async (id, show, tableType) => {
    if (show) {
      const templateContentData = await getTemplateDetail({ id, tableType })

      if (templateContentData) {
        setTemplatesTableRowCollapseContent((prev) => ({
          ...prev,
          [id]: (
            <TableContent data={templateContentData} tableType="saved" />
          ),
        }))
      }
    }
  }

  const toggleIsShowRowActions = (e, id) => {
    e.stopPropagation()
    setIsShowRowActions((prev) => (prev === id ? false : id))
  }

  const handleOnClickOutside = (e) => {
    if (actionsBoxRef.current && !actionsBoxRef.current.contains(e.target)) {
      setIsShowRowActions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOnClickOutside)
    return () => {
      document.removeEventListener('click', handleOnClickOutside)
    }
  })

  const transformSavedData = () => {
    const tempData = []
    let tableType = 'table-collapse'

    savedList?.map((template) => (
      tempData.push({
        ...template,
        timestamp: localizeDate(template.timestamp),
        template_name: (
          <div className="word-break">
            <Typography
              variant="xs-narrow"
              label={template?.name}
              style={{ cursor: 'default' }}
            />
          </div>
        ),
        formats: (
          <div className="templates-table-formats-cell">
            {processFormats(template.formats, formatActionsTemplatesMaxLength)}
            {processFormatsActions(template.actions, formatActionsTemplatesMaxLength)}
          </div>
        ),
        imagesEdited: template.images_edited,
        warningEditDelete: (
          <div className="more-actions" ref={actionsBoxRef}>
            <button
              type="button"
              className="more-actions-icon"
              onClick={(e) => toggleIsShowRowActions(e, template.id)}
            >
              <ThreeDots />
            </button>
            <div
              className="more-actions-content"
              style={{ display: isShowRowActions === template.id ? 'block' : 'none' }}
            >
              <button
                type="button"
                className="more-actions-content-icon plus-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  handleApplyClick(template)
                }}
              >
                <PlusCircleSvg />
                <Typography
                  variant="xs"
                  label={Translation.place_order}
                />
              </button>
              <button
                type="button"
                className="more-actions-content-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  handleDuplicateTemplate(template.id).then(() => { })
                }}
              >
                <DuplicateIcon />
                <Typography
                  variant="xs"
                  label={Translation.duplicate}
                />
              </button>
              <button
                type="button"
                className="more-actions-content-icon edit-icon"
                onClick={() => onEdit(template)}
              >
                <EditSvg />
                <Typography
                  variant="xs"
                  label={Translation.edit}
                />
              </button>
              <button
                type="button"
                className="more-actions-content-icon delete-icon"
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete(template)
                }}
              >
                <TrashSvg />
                <Typography
                  variant="xs"
                  label={Translation.delete}
                />
              </button>
            </div>
          </div>
        ),
      })
    ))

    if (tempData.length === 0) {
      tempData.push({
        id: 0,
        emptyDataText: Translation.you_have_not_created_templates,
      })

      tableType = 'table-default'
    }

    return { data: tempData, tableType }
  }

  const handleApplyTemplate = async () => {
    const res = await applyTemplate({ template_id: selectedTemplate.id, is_save_template: false })

    if (res) {
      navigate(NEW_ORDER_PAGE)
    } else {
      setApplyTemplateDialog(false)
      updateSnackbarState({
        isOpen: true,
        message: Translation.nonce,
        type: 'error',
      })
    }
  }

  const handleDeleteTemplate = async () => {
    setIsLoadingDeleteTemplate(true)
    const res = await deleteTemplate({ template_id: selectedTemplate.id })
    setIsLoadingDeleteTemplate(false)

    if (res) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.template_deleted_successfully,
        type: 'success',
      })
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.nonce,
        type: 'error',
      })
    }
    setDeleteTemplateDialog(false)
    if (savedList.length === 1) fetchTemplates({ page: 1, limit: savedRowsPerPage, type: 'saved' })
    else fetchTemplates({ page: savedPage, limit: savedRowsPerPage, type: 'saved' })
  }

  if (workflowList === null && savedList === null) return null

  return isMobile ? (
    <MobileView label={Translation.to_access_templates_switch_desktop} />
  ) : (
    <div className="templates-page">
      <div
        id="saved-table"
        className={transformSavedData()?.tableType === 'table-default' ? 'table-cursor-default' : ''}
      >
        <div>
          <div className="m-top-35 m-bottom-20">
            <TableTopInfo
              title={Translation.templates}
              chipText={`${savedCount} ${savedCount === 1 ? Translation.template : Translation.templates}`}
              description={Translation.templates_can_be_generated}
              icon={<TemplatesSvg className="table-top-icon" />}
            />
          </div>
        </div>
        <Table
          data={transformSavedData()?.data}
          columns={templatesTableColumns}
          count={savedCount}
          tableType={transformSavedData()?.tableType}
          collapseContents={templatesTableRowCollapseContent}
          onRowClick={(id, show) => handleRowClick(id, show, 'saved')}
          onPageChange={(value) => setSavedPage(value)}
          rowsPerPageValue={savedRowsPerPage}
          onRowsPerPageChange={(value) => setSavedRowsPerPage(value)}
          scrollable={false}
          tableContainerStyle={{ overflow: savedList?.length < 3 ? 'revert' : '' }}
        />
      </div>

      {applyTemplateDialog && (
        <Dialog
          isShown={applyTemplateDialog}
          title={`${Translation.attention}!`}
          content={(
            <>
              <Typography
                variant="xs"
                label={Translation.apply_template_to_order}
              />
              <br />
              <Typography
                variant="xs"
                label={Translation.to_save_current_settings}
              />
            </>
          )}
          cancelLabel={Translation.no}
          yesLabel={Translation.yes}
          onClickCancel={() => setApplyTemplateDialog(false)}
          onClickEscClose={() => setApplyTemplateDialog(false)}
          onClickYes={handleApplyTemplate}
        />
      )}

      {deleteTemplateDialog && (
        <Dialog
          isShown={deleteTemplateDialog}
          title={`${Translation.attention}!`}
          content={Translation.template_delete_message}
          cancelLabel={Translation.no}
          yesLabel={Translation.yes}
          onClickCancel={() => setDeleteTemplateDialog(false)}
          onClickEscClose={() => setDeleteTemplateDialog(false)}
          onClickYes={handleDeleteTemplate}
          isDisabledYes={isLoadingDeleteTemplate}
          isWarning
        />
      )}
    </div>
  )
}

export default Templates

IconTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}
