import React from 'react'

import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'

import './index.scss'

const RetailSection = () => (
  <section className="new-order-section retail-section">
    <div className="section-top">
      <Typography variant="xs" label={Translation.retail_section_title} />
    </div>
    <div className="section-content">
      <Typography variant="xs" font="semibold" label={Translation.retail_section_content_title} />
      {' '}
      <Typography variant="body1" label={Translation.retail_section_content_description} />
      <ul>
        <li><Typography variant="body1" label={Translation.retail_section_li_1} /></li>
        <li><Typography variant="body1" label={Translation.retail_section_li_2} /></li>
        <li><Typography variant="body1" label={Translation.retail_section_li_3} /></li>
      </ul>
    </div>
  </section>
)

export default RetailSection
