import React from 'react'
import PropTypes from 'prop-types'

import { getVariantClass, getFontClass, getThemeClass } from '../../helpers/Fonts'

import './index.scss'

const getNoWrapClass = (noWrap = false) => (noWrap ? 'no-wrap' : '')

const getInlineClass = (inline = false) => (inline ? 'inline' : '')

const getInlineBlockClass = (inlineBlock = false) => (inlineBlock ? 'inline-block' : '')

const getClassName = (variant = '', theme = '', noWrap = false, inline = false, inlineBlock = false, font = '') => {
  let className = getVariantClass(variant)
  if (theme) className += ` ${getThemeClass(theme)}`
  if (noWrap) className += ` ${getNoWrapClass(noWrap)}`
  if (inline) className += ` ${getInlineClass(inline)}`
  if (inlineBlock) className += ` ${getInlineBlockClass(inlineBlock)}`
  if (font) className += ` ${getFontClass(font)}`
  return className
}

const Typography = ({
  variant,
  isSpan,
  theme,
  noWrap,
  inline,
  inlineBlock,
  label,
  font,
  lineHeight,
  containsHtml,
  fontSize,
  children,
}) => {
  if (containsHtml) {
    return (
      <p
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: label }}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      />
    )
  }

  if (isSpan || variant === 'caption') {
    return (
      <span
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      >
        {label}
      </span>
    )
  }

  if (variant === 'h1') {
    return (
      <h1
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      >
        {label}
      </h1>
    )
  }

  if (variant === 'h2') {
    return (
      <h2
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      >
        {label}
      </h2>
    )
  }

  if (variant === 'h3') {
    return (
      <h3
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      >
        {label}
      </h3>
    )
  }

  if (variant === 'h4') {
    return (
      <h4
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      >
        {label}
      </h4>
    )
  }

  if (variant === 'h5') {
    return (
      <h5
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      >
        {label}
      </h5>
    )
  }

  if (variant === 'h6') {
    return (
      <h6
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      >
        {label}
      </h6>
    )
  }

  if (children) {
    return (
      <p
        className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
        style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
      >
        {children}
      </p>
    )
  }

  return (
    <p
      className={getClassName(variant, theme, noWrap, inline, inlineBlock, font)}
      style={{ lineHeight: `${lineHeight}rem`, ...(fontSize && { fontSize: `${fontSize}rem` }) }}
    >
      {label}
    </p>
  )
}

Typography.propTypes = {
  variant: PropTypes.oneOf(['',
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
    'body1',
    'caption',
    'xs', 'xs-narrow',
    'subtitle', 'subtitle-narrow',
    's',
    'title',
    'xl',
  ]),
  isSpan: PropTypes.bool,
  theme: PropTypes.oneOf(['', 'lighter', 'light', 'dark', 'error']),
  noWrap: PropTypes.bool,
  inline: PropTypes.bool,
  inlineBlock: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  font: PropTypes.oneOf(['', 'italic', 'medium', 'semibold']),
  lineHeight: PropTypes.number,
  containsHtml: PropTypes.bool,
  fontSize: PropTypes.number,
  children: PropTypes.node,
}

Typography.defaultProps = {
  variant: '',
  isSpan: false,
  theme: '',
  noWrap: false,
  inline: false,
  inlineBlock: false,
  label: 'Typography',
  font: '',
  lineHeight: null,
  containsHtml: false,
  fontSize: null,
  children: null,
}

export default Typography
