import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from '@mui/material/Skeleton'

import { Translation } from '../../../helpers/Translation'

import './index.scss'

const ReactPasswordStrengthBar = React.lazy(() => import('react-password-strength-bar'))

const Loader = () => <Skeleton height={33} />

const PasswordStrengthBar = ({
  password,
  minLength,
  setPasswordScore,
}) => (
  <React.Suspense fallback={<Loader />}>
    <ReactPasswordStrengthBar
      password={password}
      minLength={minLength}
      barColors={['#F8F8F8', '#C65054', '#FFA117', '#447AF0', '#12B76A']}
      shortScoreWord={Translation.too_short_pass}
      scoreWords={[
        Translation.weak_pass,
        Translation.weak_pass,
        Translation.okay_pass,
        Translation.good_pass,
        Translation.strong_pass,
      ]}
      scoreWordClassName="doo-score-word"
      className="doo-password-strength"
      onChangeScore={(score) => {
        setPasswordScore(score)
      }}
    />
  </React.Suspense>
)

PasswordStrengthBar.propTypes = {
  password: PropTypes.string.isRequired,
  minLength: PropTypes.number.isRequired,
  setPasswordScore: PropTypes.func.isRequired,
}

export default PasswordStrengthBar
