import { action, thunk } from 'easy-peasy'

import { axiosInstance as axios } from '../helpers/Axios'
import { API_ENDPOINTS } from '../helpers/Urls'
import { LOGIN_PAGE } from '../helpers/Constants'
import { Translation } from '../helpers/Translation'
import { Cookie } from '../helpers/Cookie'
import { removePerPage } from '../helpers/Utils'

const UserStore = {
  apiErrors: '',
  user: {},
  userList: null,
  userCount: 0,
  detailedUser: {},
  isResetPasswordToken: false,
  templates: [],
  publicGalleryUsers: [],
  publicGalleryUsersCount: 0,
  publicGalleryData: {},
  isPendingConfirmation: false,
  shouldLogout: false,
  countries: [],
  userAddress: {},
  registerPrefilledData: {
    email: '',
    firstname: '',
    lastname: '',
    company: '',
    password: '',
    passwordRepeat: '',
  },

  setIsResetPasswordToken: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isResetPasswordToken = payload
  }),

  setIsPendingConfirmation: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isPendingConfirmation = payload
  }),

  addApiErrors: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = payload
  }),

  clearApiErrors: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.apiErrors = ''
  }),

  addUser: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.user = payload
  }),

  addDetailedUser: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.detailedUser = payload
  }),

  addTemplates: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.templates = payload
  }),

  removeTemplates: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.templates = []
  }),

  addCountries: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.countries = payload
  }),

  addAddress: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.userAddress = payload
  }),

  addUsers: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.userList = payload
  }),

  setUserCount: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.userCount = payload
  }),

  setRegisterPrefilledData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.registerPrefilledData = payload
  }),

  setPublicGalleryUsers: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.publicGalleryUsers = payload
  }),

  setPublicGalleryUsersCount: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.publicGalleryUsersCount = payload
  }),

  setPublicGalleryData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.publicGalleryData = payload
  }),

  me: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.me)

      if (response.data.result.success) {
        actions.addUser(response.data.result.user_account)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  updateMe: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.me, payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  meWithParams: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.me, { params: payload })

      if (response.data.result.success) {
        actions.addDetailedUser(response.data.result.user_account)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getUserAddress: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.user)

      if (response.data.result.success) {
        actions.addAddress(response.data.result.user)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  updateUserAddress: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.user, payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  login: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.login, payload)

      if (response.data.result.success && response.data.result.PHPSESSID) {
        Cookie.setCookie('PHPSESSID', response.data.result.PHPSESSID)
        actions.addUser(response.data.result.user_account)
        return response.data.result
      }

      actions.addApiErrors(Translation.wrong_email_or_password)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  googleLogin: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.googleSignIn, payload)
      if (!response.data.result.success) {
        actions.setRegisterPrefilledData({
          email: response.data.result.email,
          firstname: response.data.result.firstname,
          lastname: response.data.result.lastname,
          company: response.data.result.company,
          password: response.data.result.password,
          passwordRepeat: response.data.result.password,
        })
      }
      return response.data.result
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  logout: thunk(async (actions) => {
    try {
      const response = await axios.post(API_ENDPOINTS.logout)
      // eslint-disable-next-line no-console
      if (response.status === 200) {
        Cookie.deleteCookie('PHPSESSID')
        removePerPage()
        window.location.href = LOGIN_PAGE
      }
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
    }
  }),

  forgot: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.forgot, payload)

      if (response.data.result.success) {
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  validateToken: thunk(async (actions, payload) => {
    try {
      return await axios.post(API_ENDPOINTS.validateToken, payload)
    } catch (err) {
      actions.setIsResetPasswordToken(true)
      return false
    }
  }),

  resetPassword: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.reset, payload)

      if (response.data.result.success) {
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  setNewPassword: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.setNewPassword, payload)

      if (response.data.result.success) {
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }

      return false
    }
  }),

  updatePassword: thunk(async (actions, payload) => {
    try {
      const { userId, ...rest } = payload
      const response = await axios.patch(`${API_ENDPOINTS.user_account_password_update}${payload.userId}`, rest)

      if (response.data.result.success) {
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  register: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.register, payload)
      if (response.data.result.success && response.data.result.PHPSESSID) {
        Cookie.setCookie('PHPSESSID', response.data.result.PHPSESSID)
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  confirmEmail: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.email_confirmation, payload)
      if (response.data.result.success && response.data.result.is_email_confirmed) {
        return response.data.result
      }

      return false
    } catch (error) {
      // if we have 301 and redirect, logout the user
      if (error.response && error.response.status === 301) {
        actions.logout()
      }

      return false
    }
  }),

  support: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.support, payload, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })

      if (response.data.result.success) {
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  userSave: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.user_account_new, payload)

      if (response.data.result.success) {
        return response.data.result
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getUser: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.user_account}${payload.userId}`)

      if (response.data.result.success) {
        return response.data.result.user_account
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  userUpdate: thunk(async (actions, payload) => {
    try {
      const { userId, ...rest } = payload
      const response = await axios.patch(`${API_ENDPOINTS.user_account}${payload.userId}`, { ...rest })

      if (response.data.result.success) {
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  userDelete: thunk(async (actions, payload) => {
    try {
      const response = await axios.delete(`${API_ENDPOINTS.user_account}${payload.userId}`)

      if (response.data.result.success) {
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getTemplates: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.userTemplates)

      if (response.data.result.success) {
        actions.addTemplates(response.data.result.templates)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getUsers: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.user_accounts, { params: payload })

      if (response.data.result.success) {
        actions.addUsers(response.data.result.user_accounts)
        actions.setUserCount(response.data.result.user_accounts_count)
        return true
      }

      actions.addApiErrors(Translation.nonce)
      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  assignTemplate: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.template_assign, payload)

      if (response.data.result.success) {
        return response.data.result.templates
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  unAssignTemplate: thunk(async (actions, payload) => {
    try {
      const response = await axios.delete(API_ENDPOINTS.template_unassign, { data: payload })

      if (response.data.result.success) {
        return response.data.result.templates
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  reAssignTemplate: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.template_reassign, payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  getPublicGalleryUsers: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(API_ENDPOINTS.public_user_accounts, { params: payload })

      if (response.data.result.success) {
        actions.setPublicGalleryUsers(response.data.result.user_accounts_public)
        actions.setPublicGalleryUsersCount(response.data.result.user_accounts_public_count)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  getPublicGalleryData: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.public_gallery}/${payload}`)

      if (response.data.result.success) {
        actions.setPublicGalleryData(response.data.result.public_gallery)
        return true
      }

      return false
    } catch (error) {
      return false
    }
  }),

  createPublicGallery: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.public_gallery, payload)

      if (response.data.result.success) {
        actions.setPublicGalleryData(response.data.result.public_gallery)
        return response.data.result.public_gallery.id
      }

      return false
    } catch (error) {
      return false
    }
  }),

  updatePublicGallery: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(`${API_ENDPOINTS.public_gallery}/${payload.id}`, payload.data)

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  getPublicUserAccount: thunk(async (actions, payload) => {
    try {
      const response = await axios.get(`${API_ENDPOINTS.public_user_account}/${payload}`)
      if (response.data.result.success) {
        return response.data.result.user_accounts_public[0]
      }

      return false
    } catch (error) {
      return false
    }
  }),

  updatePublicUserAccount: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(`${API_ENDPOINTS.public_user_account}/${payload.id}`, payload.data)

      if (response.data.result.success) {
        return { success: true }
      }

      return false
    } catch (error) {
      return {
        success: false,
        message: error.response.data.result?.error,
      }
    }
  }),

  deletePublicGallery: thunk(async (actions, payload) => {
    try {
      const response = await axios.delete(`${API_ENDPOINTS.public_gallery}/${payload}`)

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  getPublicGalleries: thunk(async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.public_gallery)

      if (response.data.result.success) {
        return response.data.result.public_galleries
      }

      return false
    } catch (error) {
      return false
    }
  }),

  reassignPublicGallery: thunk(async (actions, payload) => {
    try {
      const response = await axios.patch(API_ENDPOINTS.public_gallery_reassign, payload)

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  deletePublicGalleryUser: thunk(async (actions, payload) => {
    try {
      const response = await axios.delete(`${API_ENDPOINTS.public_user_account}/${payload}`)

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  createPublicGalleryUser: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.public_user_account_new, payload)

      return !!response.data.result.success
    } catch (error) {
      return false
    }
  }),

  userAvatarUpdate: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.user_account_avatar, payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  userAccountForgot: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.user_account_forgot, payload)

      return !!response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  userAccountPendingConfirmation: thunk(async () => {
    try {
      const response = await axios.post(API_ENDPOINTS.user_account_pending_confirmation)

      return response.data.result
    } catch (error) {
      return false
    }
  }),

  getCountries: thunk(async (actions) => {
    try {
      const response = await axios.get(API_ENDPOINTS.countries)

      if (response.data.result.success) {
        actions.addCountries(response.data.result.countries)
        return true
      }

      return false
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  switchAccount: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.switchAccount, payload)

      return response.data.result
    } catch (error) {
      // if we have 301 and redirect, logout the user
      if (error.response && error.response.status === 301) {
        actions.logout()
      }

      return false
    }
  }),

  switchToRealAccount: thunk(async (actions) => {
    try {
      const response = await axios.post(API_ENDPOINTS.switchToRealAccount)

      return response.data.result
    } catch (error) {
      // if we have 301 and redirect, logout the user
      if (error.response && error.response.status === 301) {
        actions.logout()
      }

      return false
    }
  }),

  setStepperShown: thunk(async (actions, payload) => {
    try {
      const response = await axios.post(API_ENDPOINTS.user_account_mapping, payload)

      return response.data.result.success
    } catch (error) {
      if (error.response && error.response.data.result) {
        actions.addApiErrors(error.response.data.result)
      } else {
        actions.addApiErrors({ serverError: Translation.nonce })
      }
      return false
    }
  }),

  unsubscribeFromMailing: thunk(async (actions, payload) => {
    try {
      // replace url params with payload
      const url = API_ENDPOINTS.unsubscribeFromMailing
        .replace(':lang', payload.lang)
        .replace(':user_account_id_code', payload.user_account_id_code)
        .replace(':mailing_id_code', payload.mailing_id_code)
      const response = await axios.patch(url, { status: payload.status })

      return response.data.result.success
    } catch (error) {
      return false
    }
  }),

}

export default UserStore
