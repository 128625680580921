import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { FormControlLabel, FormGroup } from '@mui/material'
import {
  unstable_useBlocker as useBlocker,
  useNavigate,
} from 'react-router-dom'

import { Translation } from '../../../helpers/Translation'
import { isValidValue, removeInputError } from '../../../helpers/Utils'
import { FIELDS_PATTERNS } from '../../../helpers/Constants'

import Modal from '../../../components/Modal'
import Typography from '../../../components/Typography'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import Checkbox from '../../../components/Checkbox'
import Button from '../../../components/Button'
import Dialog from '../../../components/Dialog'

import './index.scss'

const BillingAddress = ({
  isOpen,
  closeModal,
  modalTitle,
  isDashboard,
}) => {
  const navigate = useNavigate()

  const userAddress = useStoreState((state) => state.user.userAddress)
  const countries = useStoreState((state) => state.user.countries)
  const getCountryList = useStoreActions((state) => state.user.getCountries)
  const updateUserAddress = useStoreActions((actions) => actions.user.updateUserAddress)
  const getUserAddress = useStoreActions((state) => state.user.getUserAddress)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const meAction = useStoreActions((actions) => actions.user.me)

  const [navigateTo, setNavigateTo] = useState(null)
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false)
  const [company, setCompany] = useState(userAddress.company || '')
  const [address1, setAddress1] = useState(userAddress.address1 || '')
  const [address2, setAddress2] = useState(userAddress.address2 || '')
  const [zipCode, setZipCode] = useState(userAddress.zip || '')
  const [city, setCity] = useState(userAddress.city || '')
  const [country, setCountry] = useState(userAddress.country || '')
  const [vatNumber, setVatNumber] = useState(userAddress.ust_id || '')
  const [dontShowName, setDontShowName] = useState(!!userAddress.invoice_omit_name || false)

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors] = useState({
    company: Translation.this_field_is_required,
    address1: Translation.this_field_is_required,
    zipCode: Translation.this_field_is_required,
    city: Translation.this_field_is_required,
    country: Translation.this_field_is_required,
  })

  useBlocker((e) => {
    if (isDashboard) {
      if (!company && !address1 && !address2 && !zipCode && !city && !country && !vatNumber) {
        return false
      }
      setNavigateTo(`${e.nextLocation.pathname}${e.nextLocation.hash}`)
      setLeaveDialogOpen(true)
    }
    if (!isButtonDisabled && !leaveDialogOpen) {
      setNavigateTo(`${e.nextLocation.pathname}${e.nextLocation.hash}`)
      setLeaveDialogOpen(true)
      return true
    }
    return false
  })

  useEffect(() => {
    getCountryList()
    setCompany(userAddress.company || '')
    setAddress1(userAddress.address1 || '')
    setAddress2(userAddress.address2 || '')
    setZipCode(userAddress.zip || '')
    setCity(userAddress.city || '')
    setCountry(userAddress.country || '')
    setVatNumber(userAddress.ust_id || '')
    setDontShowName(!!userAddress.invoice_omit_name || false)
  }, [userAddress, isOpen])

  useEffect(() => {
    if (
      company !== userAddress.company
      || address1 !== userAddress.address1
      || address2 !== userAddress.address2
      || zipCode !== userAddress.zip
      || city !== userAddress.city
      || country !== userAddress.country
      || vatNumber !== userAddress.ust_id
      || dontShowName !== !!userAddress.invoice_omit_name
    ) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }, [company, address1, address2, zipCode, city, country, vatNumber, dontShowName])

  const handleModalClose = () => {
    if (isButtonDisabled) {
      closeModal()
    } else {
      setNavigateTo(null)
      setLeaveDialogOpen(true)
    }
  }

  const handleCompanyChange = (e) => {
    const input = e.currentTarget
    setCompany(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleAddress1Change = (e) => {
    const input = e.currentTarget
    setAddress1(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleAddress2Change = (e) => {
    const input = e.currentTarget
    setAddress2(input.value)
  }

  const handleZipCodeChange = (e) => {
    const input = e.currentTarget
    setZipCode(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleCityChange = (e) => {
    const input = e.currentTarget
    setCity(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleCountryChange = (e) => {
    const input = e.target
    setCountry(input.value)
    if (input.value) {
      setIsFormErrors({ ...isFormErrors, country: false })
    }
  }

  const onClickOutside = (e) => {
    if (!isDashboard) {
      if (e.target?.outerHTML?.includes('modal--wrap')) {
        handleModalClose()
      }
    }
  }

  const isFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(company, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.company = !isValid
    }

    if (!isValidValue(address1, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.address1 = !isValid
    }

    if (!isValidValue(zipCode, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.zipCode = !isValid
    }

    if (!isValidValue(city, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.city = !isValid
    }

    if (!isValidValue(country, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.country = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const getCountries = () => {
    const countriesList = {}
    Object.keys(countries).forEach((key) => {
      countriesList[key] = countries[key].name
    })
    return countriesList
  }

  const handleLeaveApprove = () => {
    setLeaveDialogOpen(false)
    if (navigateTo) navigate(navigateTo)
    closeModal()
  }

  const handleSave = async () => {
    if (!isFormValid()) return

    const res = await updateUserAddress({
      company,
      address1,
      address2,
      zip: zipCode,
      city,
      country,
      'ust-id': vatNumber,
      'invoice-omit-name': dontShowName ? 1 : 0,
    })
    if (res) {
      updateSnackbarState({
        message: Translation.billing_address_updated,
        isOpen: true,
        type: 'success',
      })
      await meAction()
      getUserAddress()
      closeModal()
    } else {
      updateSnackbarState({
        message: Translation.billing_address_update_error,
        isOpen: true,
        type: 'error',
      })
    }
  }

  return (
    <Modal
      isShown={isOpen}
      onClickOutside={onClickOutside}
      hasCloseIcon={false}
      onClickEscClose={() => closeModal()}
      size="large"
    >
      <div className="billing-address-container">
        <div className="billing-address-title">
          <Typography
            theme="dark"
            variant="s"
            font="semibold"
            label={modalTitle}
          />
        </div>
        <div className="billing-address-form">
          <div className="billing-address-form-row">
            <div className="billing-address-form-row-item">
              <Input
                label={`${Translation.company}*`}
                value={company}
                onChange={handleCompanyChange}
                type="text"
                name="company"
                error={formErrors.company}
                isError={isFormErrors.company}
              />
            </div>
          </div>
          <div className="billing-address-form-row">
            <div className="billing-address-form-row-item">
              <Input
                label={`${Translation.address}*`}
                value={address1}
                onChange={handleAddress1Change}
                type="text"
                name="address1"
                error={formErrors.address1}
                isError={isFormErrors.address1}
              />
            </div>
            <div className="billing-address-form-row-item">
              <Input
                label={Translation.address}
                value={address2}
                onChange={handleAddress2Change}
                type="text"
                name="address2"
              />
            </div>
          </div>
          <div className="billing-address-form-row">
            <div className="billing-address-form-row-item">
              <Input
                label={`${Translation.zip_code}*`}
                value={zipCode}
                onChange={handleZipCodeChange}
                type="text"
                name="zipCode"
                error={formErrors.zipCode}
                isError={isFormErrors.zipCode}
              />
            </div>
            <div className="billing-address-form-row-item">
              <Input
                label={`${Translation.city}*`}
                value={city}
                onChange={handleCityChange}
                type="text"
                name="city"
                error={formErrors.city}
                isError={isFormErrors.city}
              />
            </div>
          </div>
          <div className="billing-address-form-row">
            <div className="billing-address-form-row-item">
              <Select
                isEmptyOption={false}
                label={`${Translation.country}*`}
                value={country}
                values={getCountries()}
                onChange={handleCountryChange}
                error={formErrors.country}
                isError={isFormErrors.country}
              />
            </div>
            <div className="billing-address-form-row-item">
              <Input
                label={Translation.vat_reg}
                value={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
                type="text"
                name="vat-number"
              />
            </div>
          </div>
          <div className="billing-address-form-row">
            <div className="billing-address-form-row-item">
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={dontShowName}
                      onChange={() => setDontShowName(!dontShowName)}
                    />
                  )}
                  label={(
                    <Typography
                      variant="xs"
                      inlineBlock
                      label={Translation.dont_show_my_name}
                    />
                  )}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="billing-address-footer">
          {!isDashboard ? (
            <>
              <Button type="contained" label={Translation.cancel} onClick={handleModalClose} />
              <Button disabled={isButtonDisabled} label={Translation.ok} onClick={handleSave} />
            </>
          ) : (
            <Button disabled={isButtonDisabled} label={Translation.update} onClick={handleSave} />
          )}
        </div>
      </div>
      <Dialog
        isWarning
        isShown={leaveDialogOpen}
        onClickCloseIcon={() => setLeaveDialogOpen(false)}
        isCentered
        onClickCancel={() => setLeaveDialogOpen(false)}
        title={Translation.unsaved_changes_title}
        yesLabel={Translation.ok}
        onClickYes={handleLeaveApprove}
        content={
          <Typography variant="xs" label={Translation.unsaved_changes_message} />
        }
      />
    </Modal>
  )
}

BillingAddress.propTypes = {
  user: PropTypes.shape({
    company: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    invoice_omit_name: PropTypes.number,
    id: PropTypes.number,
    currency: PropTypes.string,
    ust_id: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  modalTitle: PropTypes.string.isRequired,
  isDashboard: PropTypes.bool,
}

BillingAddress.defaultProps = {
  user: {},
  isOpen: false,
  closeModal: () => { },
  isDashboard: false,
}

export default BillingAddress
