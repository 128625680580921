import React, { useCallback, useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'

import { FIELDS_PATTERNS } from '../../../../helpers/Constants'
import {
  isClientAdminRole, isValidInput, isValidValue, removeInputError,
} from '../../../../helpers/Utils'
import { Translation, lang } from '../../../../helpers/Translation'

import Modal from '../../../../components/Modal'
import Typography from '../../../../components/Typography'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import RadioGroup from '../../../../components/RadioGroup'

import UserInformation from '../../Common/UserInformation'
import TemplateManagement from '../../Common/TemplateManagement'

import FormatAccordion from './FormatAccordion'

import './index.scss'

const UserModal = ({
  open,
  closeModal,
  user,
  activeTab,
  refreshTable,
  templates,
  leaveDialogOpen,
  setLeaveDialogOpen,
  setNavigateTo,
  languageList,
  isFormChanged,
  setIsFormChanged,
}) => {
  const apiErrors = useStoreState((state) => state.user.apiErrors)
  const clearApiErrors = useStoreActions((state) => state.user.clearApiErrors)
  const userUpdate = useStoreActions((state) => state.user.userUpdate)
  const userSave = useStoreActions((state) => state.user.userSave)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)
  const reAssignTemplate = useStoreActions((state) => state.user.reAssignTemplate)
  const userAccountForgot = useStoreActions((state) => state.user.userAccountForgot)

  const getTemplateDetail = useStoreActions((state) => state.template.getTemplateDetail)

  const [isUserInfo, setIsUserInfo] = useState(activeTab)
  const [title, setTitle] = useState(user.gender || 'm')
  const [firstname, setFirstname] = useState(user.firstname || '')
  const [lastname, setLastname] = useState(user.lastname || '')
  const [email, setEmail] = useState(user.email || '')
  const [telephone, setTelephone] = useState(user.telephone || '')
  const [isClientAdmin, setIsClientAdmin] = useState(isClientAdminRole(user?.role_after_login))
  // eslint-disable-next-line no-nested-ternary
  const [isShowBilling, setIsShowBilling] = useState(user.id ? (user.billing_info === 1 ? 'show' : 'hide') : 'hide')
  const [emailNotifications, setEmailNotifications] = useState(user.email_type || 'all')
  const [newsletter, setNewsletter] = useState(
    // eslint-disable-next-line no-nested-ternary
    user.id
      ? (user.newsletter ? 'subscribe' : 'unsubscribe')
      : 'subscribe',
  )
  const [language, setLanguage] = useState(user.id ? user.lang : lang)

  const [isTemplatesChanged, setIsTemplatesChanged] = useState(false)
  const [filteredTemplates, setFilteredTemplates] = useState(null)
  const [assignedTemplates, setAssignedTemplates] = useState({})
  const [isTemplatesUpdated, setIsTemplatesUpdated] = useState(false)
  const [viewWorkflowId, setViewWorkflowId] = useState(null)
  const [viewWorkflowContent, setViewWorkflowContent] = useState(null)

  const [isShowSureQuestion, setIsShowSureQuestion] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)

  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors, setFormErrors] = useState({
    firstname: Translation.this_field_is_required,
    lastname: Translation.this_field_is_required,
    email: Translation.enter_valid_email,
  })

  useBlocker((e) => {
    if (open && (isFormChanged || isTemplatesChanged) && !leaveDialogOpen) {
      setNavigateTo(`${e.nextLocation.pathname}${e.nextLocation.hash}`)
      setLeaveDialogOpen(true)
      return true
    }
    return false
  })

  const handleGetTemplateDetail = async (id, tableType) => {
    const result = await getTemplateDetail({ id, tableType })
    setViewWorkflowContent(result)
  }

  useEffect(() => {
    if (viewWorkflowId) {
      handleGetTemplateDetail(viewWorkflowId, 'workflows').then(() => { })
    }
  }, [viewWorkflowId])

  useEffect(() => {
    if (apiErrors) {
      // API server is down
      if (apiErrors.serverError) return

      let isValid = true

      const formErrorsUpdated = {}
      const isFormErrorsUpdated = {}

      if (apiErrors.required) {
        if (apiErrors.required.email) {
          isValid = false
          formErrorsUpdated.email = apiErrors.required.email
          isFormErrorsUpdated.email = !isValid
        }

        if (apiErrors.required.firstname) {
          isValid = false
          formErrorsUpdated.firstname = apiErrors.required.firstname
          isFormErrorsUpdated.firstname = !isValid
        }

        if (apiErrors.required.lastname) {
          isValid = false
          formErrorsUpdated.firstname = apiErrors.required.lastname
          isFormErrorsUpdated.firstname = !isValid
        }
      }

      if (!isValid) {
        setFormErrors(formErrorsUpdated)
        setIsFormErrors(isFormErrorsUpdated)
      }
    } else {
      // clear all form errors
      setIsFormErrors({})
    }
  }, [apiErrors])

  useEffect(() => {
    clearApiErrors()
    setIsFormErrors({})
  }, [open])

  const mapAssignedTemplates = (list) => {
    const newList = {}
    list.forEach((template) => {
      newList[template.id] = template?.name
    })
    setAssignedTemplates(newList)
  }

  const mapAllTemplates = () => {
    const newTemplates = {}
    templates.forEach((template) => {
      if (Object.keys(assignedTemplates).length > 0 && assignedTemplates[template.id]) return
      newTemplates[template.id] = template?.name
    })
    setFilteredTemplates(newTemplates)
  }

  useEffect(() => {
    setTitle(user.gender || 'm')
    setFirstname(user.firstname || '')
    setLastname(user.lastname || '')
    setEmail(user.email || '')
    setTelephone(user.telephone || '')
    setIsClientAdmin(user?.role_after_login ? isClientAdminRole(user?.role_after_login) : false)
    // eslint-disable-next-line no-nested-ternary
    setIsShowBilling(user.id ? (user.billing_info === 1 ? 'show' : 'hide') : 'hide')
    setEmailNotifications(user.email_type || 'all')
    setNewsletter(
      // eslint-disable-next-line no-nested-ternary
      user.id
        ? (user.newsletter ? 'subscribe' : 'unsubscribe')
        : 'subscribe',
    )
    setLanguage(user.id ? user.lang : lang)
    setIsFormChanged(false)
    setIsTemplatesChanged(false)
    setIsUserInfo(activeTab)
    if (user.assigned_templates) mapAssignedTemplates(user.assigned_templates)
    else mapAssignedTemplates([])
  }, [user])

  useEffect(() => {
    mapAllTemplates()
  }, [templates])

  useEffect(() => {
    if (!user.assigned_templates) return
    mapAssignedTemplates(user.assigned_templates)
    mapAllTemplates()
  }, [user.assigned_templates])

  useEffect(() => {
    mapAllTemplates()
  }, [assignedTemplates])

  useEffect(() => {
    if (!user.id && !(
      title !== 'm'
      || firstname !== ''
      || lastname !== ''
      || email !== ''
      || telephone !== ''
      || isClientAdmin !== false
      || isShowBilling !== 'hide'
      || emailNotifications !== 'all'
      || newsletter !== 'subscribe'
      || language !== lang
    )) {
      setIsFormChanged(false)
    } else {
      setIsFormChanged(true)
    }
    if (user.id) {
      if (
        user.gender !== title
        || user.firstname !== firstname
        || user.lastname !== lastname
        || user.telephone !== telephone
        || isClientAdminRole(user?.role_after_login) !== isClientAdmin
        || user.billing_info !== (isShowBilling === 'show' ? 1 : 0)
        || user.email_type !== emailNotifications
        || user.newsletter !== (newsletter === 'subscribe' ? 1 : 0)
        || user.lang !== language
      ) {
        setIsFormChanged(true)
      } else {
        setIsFormChanged(false)
      }
    }
  }, [user,
    title,
    firstname,
    lastname,
    email,
    telephone,
    isClientAdmin,
    isShowBilling,
    emailNotifications,
    language,
    newsletter,
  ])

  useEffect(() => {
    const handleTabClose = (e) => {
      e.preventDefault()
    }
    if (isFormChanged || isTemplatesChanged) {
      window.addEventListener('beforeunload', handleTabClose)
    } else {
      window.removeEventListener('beforeunload', handleTabClose)
    }

    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [isFormChanged, isTemplatesChanged])

  const handleToggleFormSection = (e) => {
    const { name } = e.currentTarget.dataset
    setIsUserInfo((name === 'user-info'))
  }

  const handleFirstnameChange = (e) => {
    const input = e.currentTarget
    setFirstname(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleLastnameChange = (e) => {
    const input = e.currentTarget
    setLastname(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleEmailChange = (e) => {
    const input = e.currentTarget
    setEmail(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  const handleTelephoneChange = (e) => {
    const input = e.currentTarget
    if (isValidInput(input)) setTelephone(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const checkModalClosable = () => {
    if (isFormChanged || isTemplatesChanged) {
      closeModal()
      setLeaveDialogOpen(true)
    } else closeModal()

    setViewWorkflowId(null)
    setViewWorkflowContent(null)
  }

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      checkModalClosable()
      setViewWorkflowId(null)
      setViewWorkflowContent(null)
    }
  }

  const resetUserPassword = async () => {
    const result = await userAccountForgot({
      id: user.id,
    })

    if (result) {
      setIsShowSureQuestion(false)
      setIsEmailSent(true)
    }
  }

  const infoColumns = [
    {
      columnTitle: Translation.billing,
      name: 'billing',
      value: isShowBilling,
      values: Translation.show_billing_options,
      onChange: (e) => setIsShowBilling(e.target.value),
    },
    {
      columnTitle: Translation.email_notifications,
      name: 'email-notifications',
      value: emailNotifications,
      values: Translation.email_notifications_options,
      onChange: (e) => setEmailNotifications(e.target.value),
    },
    {
      columnTitle: Translation.newsletter,
      name: 'newsletter',
      value: newsletter,
      values: Translation.newsletter_options,
      onChange: (e) => setNewsletter(e.target.value),
    },
    {
      columnTitle: Translation.language,
      name: 'language',
      value: language,
      values: languageList,
      onChange: (e) => setLanguage(e.target.value),
    },
  ]

  const checkTemplates = (newList) => {
    const initialAssignedTemplates = user.assigned_templates?.map((template) => template.id) || []
    const changedAssignedTemplates = Object.keys(newList).map((key) => parseInt(key, 10))

    const compare = (a1, a2) => a1.length === a2.length
      && `${a1} ` === `${a2} `

    setIsTemplatesChanged(!compare(initialAssignedTemplates.sort(), changedAssignedTemplates.sort()))
  }

  const handleAssignTemplate = useCallback((templateId) => {
    let newState = {}
    setAssignedTemplates((prevState) => {
      newState = { ...prevState }
      const template = templates.find((t) => t.id.toString() === templateId.toString())
      newState[templateId] = template?.name
      return newState
    })
    checkTemplates(newState)
  }, [assignedTemplates])

  const handleUnAssignTemplate = useCallback((templateId) => {
    let newState = {}
    setAssignedTemplates((prevState) => {
      newState = { ...prevState }
      delete newState[templateId]
      return newState
    })
    checkTemplates(newState)
  }, [assignedTemplates])

  const userUpdateReq = async () => {
    const showBilling = isShowBilling === 'show'
    const news = newsletter === 'subscribe'

    return userUpdate({
      ...(title !== user.gender && { gender: title }),
      ...(firstname !== user.firstname && { firstname }),
      ...(lastname !== user.lastname && { lastname }),
      ...(telephone !== user.telephone && { telephone }),
      ...(showBilling !== Boolean(user.billing_info) && { 'billing-info': showBilling }),
      ...(emailNotifications !== user.email_type && { 'email-type': emailNotifications }),
      ...(news !== user.newsletter && { newsletter: news }),
      ...(isClientAdmin !== isClientAdminRole(user?.role_after_login) && { 'is-admin': isClientAdmin }),
      ...(language !== user.lang && { lang: language }),
      userId: user.id,
    })
  }

  const reAssignTemplateReq = async (id) => reAssignTemplate({
    unassigned_template_ids: Object.keys(filteredTemplates).map((key) => Number(key)),
    assigned_template_ids: Object.keys(assignedTemplates).map((key) => Number(key)),
    user_account_id: id || user.id,
  })

  const finish = (message) => {
    updateSnackbarState({
      isOpen: true,
      message,
      type: 'success',
    })
    refreshTable()
    closeModal()
    setIsFormChanged(false)
  }

  const isFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(firstname, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.firstname = !isValid
    }

    if (!isValidValue(lastname, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.lastname = !isValid
    }

    if (!user.id) {
      if (!isValidValue(email, FIELDS_PATTERNS.email)) {
        isValid = false
        isFormErrorsUpdated.email = !isValid
      }
    }

    if (!isValid) {
      setIsFormErrors(isFormErrorsUpdated)
      setIsUserInfo(true)
    }

    return isValid
  }

  const onSaveChanges = async () => {
    if (!isFormValid()) return

    if (user.id) {
      const reqList = []
      if (isFormChanged) {
        reqList.push(userUpdateReq())
      }
      if (isTemplatesChanged) {
        reqList.push(reAssignTemplateReq())
      }

      const res = await Promise.all(reqList)
      if (isTemplatesChanged) {
        setTimeout(() => {
          setIsTemplatesUpdated(true)
        }, 100)
      }
      if (res.every((r) => r)) {
        finish(Translation.updated_successfully)
        setTimeout(() => {
          setIsTemplatesUpdated(false)
        }, 200)
      }
    } else {
      const res = await userSave({
        gender: title,
        email,
        firstname,
        lastname,
        telephone,
        'email-type': emailNotifications,
        'billing-info': isShowBilling === 'show',
        newsletter: newsletter === 'subscribe',
        'is-admin': isClientAdmin,
        lang: language,
      })
      if (res) {
        if (Object.keys(assignedTemplates).length > 0) {
          const assignRes = await reAssignTemplateReq(res.id)
          setTimeout(() => {
            setIsTemplatesUpdated(true)
          }, 100)
          if (assignRes) {
            finish(Translation.new_user_added)
            setTimeout(() => {
              setIsTemplatesUpdated(false)
            }, 200)
          }
        } else {
          finish(Translation.new_user_added)
        }
      }
    }
  }

  return (
    <Modal
      hasCloseIcon
      size="large"
      isShown={open}
      onClickCloseIcon={checkModalClosable}
      onClickOutside={onClickOutside}
      onClickEscClose={checkModalClosable}
    >
      <div className="user-modal" style={{ display: viewWorkflowContent ? 'none' : 'block' }}>
        <div className="flex m-bottom-25">
          <div
            onClick={handleToggleFormSection}
            data-name="user-info"
            className={`user-form__tab ${(isUserInfo) ? 'user-form__tab--active' : ''}`}
          >
            <Typography
              variant="s"
              font={`${(isUserInfo) ? 'semibold' : ''}`}
              theme="dark"
              label={Translation.user_information}
            />
          </div>

          {!isClientAdmin && filteredTemplates && Object.keys(filteredTemplates).length > 0 && (
            <div
              onClick={handleToggleFormSection}
              data-name="manage-templates"
              className={`user-form__tab ${(!isUserInfo) ? 'user-form__tab--active' : ''}`}
            >
              <Typography
                variant="s"
                font={`${(!isUserInfo) ? 'semibold' : ''}`}
                theme="dark"
                label={Translation.manage_workflows}
              />
            </div>
          )}
        </div>

        {isUserInfo ? (
          <>
            <div className="tab-description">
              <Typography
                variant="xs-narrow"
                label={Translation.here_you_can_update_user_info}
              />
            </div>
            <UserInformation
              email={email}
              // eslint-disable-next-line no-unneeded-ternary
              emailDisabled={!!user.id}
              handleEmailChange={handleEmailChange}
              title={title}
              handleTitleChange={handleTitleChange}
              firstname={firstname}
              handleFirstnameChange={handleFirstnameChange}
              lastname={lastname}
              handleLastnameChange={handleLastnameChange}
              telephone={telephone}
              handleTelephoneChange={handleTelephoneChange}
              formErrors={formErrors}
              isFormErrors={isFormErrors}
            />
            <div className="admin-checkbox">
              <Typography
                variant="xs-narrow"
                font="medium"
                label={Translation.admin_user}
                inlineBlock
              />
              <Checkbox checked={isClientAdmin} onChange={(e) => setIsClientAdmin(e.currentTarget.checked)} />
            </div>
            <div className="user-modal-row">
              {infoColumns.map((column) => (
                (!isClientAdmin || (isClientAdmin && column.name !== 'billing')) && (
                  <div className="column" key={column.name}>
                    <div className="column-title">
                      <Typography
                        variant="xs-narrow"
                        font="medium"
                        label={column.columnTitle}
                      />
                    </div>
                    <RadioGroup
                      name={`${column.name} ${isClientAdmin}`}
                      value={column.value}
                      values={column.values}
                      onChange={column.onChange}
                      color="gray"
                    />
                  </div>
                )
              ))}
            </div>
          </>
        ) : (
          <TemplateManagement
            isUpdated={isTemplatesUpdated}
            templateList={filteredTemplates}
            assignedTemplateList={assignedTemplates}
            handleAssignTemplate={handleAssignTemplate}
            handleUnAssignTemplate={handleUnAssignTemplate}
            viewWorkflowId={(id) => setViewWorkflowId(id)}
          />
        )}
        <div className="modal-footer">
          <div>
            {isUserInfo && user.email && (
              <div className="reset-password-question">
                {
                  isEmailSent ? (
                    <Typography variant="xs" label={Translation.reset_link_has_been_sent} inlineBlock />
                  ) : null
                }
                {
                  !isEmailSent && (
                    !isShowSureQuestion ? (
                      <Button
                        onClick={() => setIsShowSureQuestion(true)}
                        label={Translation.reset_user_password}
                      />
                    ) : (
                      <>
                        <Typography variant="xs" label={Translation.are_you_sure} inlineBlock />
                        {' '}
                        <Button onClick={() => setIsShowSureQuestion(false)} label={Translation.cancel} />
                        {' '}
                        <Typography variant="xs" label="/" inlineBlock />
                        {' '}
                        <Button onClick={resetUserPassword} label={Translation.yes} />
                      </>
                    )
                  )
                }
              </div>
            )}
          </div>
          <Button
            id="save_changes"
            onClick={onSaveChanges}
            label={Translation.save_changes}
            disabled={!isFormChanged && !isTemplatesChanged}
          />
        </div>
      </div>
      <div className="workflow-detail" style={{ display: viewWorkflowContent ? 'block' : 'none' }}>
        <div className="workflow-detail-title">
          <Typography variant="subtitle" font="semibold" label={viewWorkflowContent?.template?.name} />
        </div>
        <div className="workflow-detail-accordions scrollbar-overflow">
          {viewWorkflowContent?.template_actions?.map((workflowContentItem, workflowContentIndex) => (
            <FormatAccordion key={workflowContentItem.label} data={workflowContentItem} index={workflowContentIndex} />
          ))}
        </div>
        <div className="workflow-detail-btn">
          <Button
            label={Translation.back}
            onClick={() => {
              setViewWorkflowId(null)
              setViewWorkflowContent(null)
            }}
            type="fit-content"
          />
        </div>
      </div>
    </Modal>
  )
}

export default UserModal

UserModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  user: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      user_id: PropTypes.number,
      email: PropTypes.string,
      email_type: PropTypes.string,
      role: PropTypes.string,
      role_after_login: PropTypes.string,
      billing_info: PropTypes.number,
      lang: PropTypes.string,
      gender: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      pending_confirmation: PropTypes.bool,
      telephone: PropTypes.string,
      dsgvo: PropTypes.number,
      newsletter: PropTypes.number,
      terms_conditions: PropTypes.number,
      created_ts: PropTypes.shape({
        date: PropTypes.string,
        timezone_type: PropTypes.number,
        timezone: PropTypes.string,
      }),
      timestamp: PropTypes.shape({
        date: PropTypes.string,
        timezone_type: PropTypes.number,
        timezone: PropTypes.string,
      }),
      assigned_templates: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })),
    }),
    PropTypes.shape({}),
  ]),
  activeTab: PropTypes.bool,
  refreshTable: PropTypes.func,
  templates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  leaveDialogOpen: PropTypes.bool,
  setLeaveDialogOpen: PropTypes.func,
  setNavigateTo: PropTypes.func,
  languageList: PropTypes.objectOf(PropTypes.string),
  isFormChanged: PropTypes.bool,
  setIsFormChanged: PropTypes.func,
}

UserModal.defaultProps = {
  open: false,
  closeModal: () => { },
  user: {},
  activeTab: true,
  refreshTable: () => { },
  templates: [],
  leaveDialogOpen: false,
  setLeaveDialogOpen: () => { },
  setNavigateTo: () => { },
  languageList: [],
  isFormChanged: false,
  setIsFormChanged: () => { },
}
