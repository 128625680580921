import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import {
  DEFAULT_PAGE,
  DESKTOP_PX_TO_REM,
  DESKTOP_TABLE_WIDTH,
  IMAGE_TYPE_IN,
  IMAGE_TYPE_OUT,
} from '../../helpers/Constants'
import { getGalleryListUrl, getGalleryUrl } from '../../helpers/Utils'

import { Translation } from '../../helpers/Translation'

import Input from '../../components/Input'
import Typography from '../../components/Typography'
import Table from '../../components/Table'
import Checkbox from '../../components/Checkbox'
import Button from '../../components/Button'
import Link from '../../components/Link'

import './index.scss'

const ImageSearch = () => {
  const getOrderSearchImages = useStoreActions((actions) => actions.order.getOrderSearchImages)
  const setOrderSearchImages = useStoreActions((actions) => actions.order.setOrderSearchImages)
  const orderSearchImages = useStoreState((state) => state.order.orderSearchImages)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)

  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [transformedData, setTransformedData] = useState([])
  const [imageSearchData, setImageSearchData] = useState({
    id_search: '',
    name_search: '',
    folder_search: '',
    filter_input: true,
    filter_output: false,
    filter_3m_older: false,
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const searchDataShallow = { ...imageSearchData }
    if (searchDataShallow.name_search || searchDataShallow.folder_search || searchDataShallow.id_search) {
      setIsSubmit(true)
      setIsLoadingSearch(true)
      setOrderSearchImages([])

      if (searchDataShallow.name_search?.startsWith('%') || searchDataShallow.name_search?.endsWith('%')) {
        searchDataShallow.name_search = searchDataShallow.name_search.replace('%', ';%;')
      }

      await getOrderSearchImages(searchDataShallow)
      setIsLoadingSearch(false)
    } else {
      updateSnackbarState({
        message: Translation.image_search_warning_text,
        isOpen: true,
        type: 'error',
      })
    }
  }

  const checkEnterKeyEvent = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e).then(() => { })
    }
  }

  useEffect(() => {
    if (!imageSearchData.filter_input) {
      setImageSearchData((prev) => ({ ...prev, filter_output: true }))
    }
  }, [imageSearchData.filter_input])

  useEffect(() => {
    if (!imageSearchData.filter_output) {
      setImageSearchData((prev) => ({ ...prev, filter_input: true }))
    }
  }, [imageSearchData.filter_output])

  const colWidth = [135, 135, 398, 398]
  colWidth.push(DESKTOP_TABLE_WIDTH - colWidth.reduce((a, b) => a + b))

  const columns = [
    {
      id: 'order_id',
      render: 'jsx',
      label: Translation.order_id,
      width: colWidth[0] / DESKTOP_PX_TO_REM,
    },
    { id: 'images', label: Translation.images, width: colWidth[1] / DESKTOP_PX_TO_REM },
    { id: 'filename', label: Translation.file_name, width: colWidth[2] / DESKTOP_PX_TO_REM },
    { id: 'folder', label: Translation.order_name, width: colWidth[3] / DESKTOP_PX_TO_REM },
    {
      id: 'date',
      render: 'jsx',
      label: Translation.date,
      width: colWidth[4] / DESKTOP_PX_TO_REM,
    },
  ]

  useEffect(() => {
    const tempData = []

    orderSearchImages.map((item) => (
      tempData.push({
        order_id: (
          <Link
            href={`${DEFAULT_PAGE}?order=${item.order_id}`}
            label={(item.order_id).toString()}
            underline
            isNewTab
          />
        ),
        images: (
          <Link
            href={getGalleryUrl(item.order_id, item.type === IMAGE_TYPE_OUT)}
            label={item.type === IMAGE_TYPE_IN ? Translation.input : Translation.output}
            underline
            isNewTab
          />
        ),
        filename: (
          <Link
            href={getGalleryListUrl(item.order_id, item.image_id, item.type === IMAGE_TYPE_OUT)}
            label={item.file}
            underline
            isNewTab
          />),
        folder: (
          <div>
            {
              item.template_name && (
                <div className="order-template-name">
                  {item.template_name}
                </div>
              )
            }
            <div>
              {item.order_name}
            </div>
          </div>
        ),
        date: (
          <Typography
            variant="xs"
            noWrap
            label={`
              <span style="opacity: .5">${item.type === IMAGE_TYPE_IN ? Translation.received : Translation.completed}</span> 
              <br>
              ${new Date(item.timestamp_received).toLocaleDateString('en-GB').replace(/\//g, '.')} 
              ${new Date(item.timestamp_received).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
            `}
            containsHtml
          />
        ),
      })
    ))

    setTransformedData(tempData)
  }, [orderSearchImages])

  return (
    <div className="image-search">
      <div className="image-search-actions">
        <form className="actions-form">
          <div className="actions-form-inputs">
            <Input
              type="text"
              id="image-search-filename"
              label={Translation.image_name}
              value={imageSearchData.name_search}
              onChange={
                (e) => setImageSearchData((prev) => ({ ...prev, name_search: `${e.target.value}` }))
              }
              onKeyUp={(e) => checkEnterKeyEvent(e)}
            />
            <Input
              type="text"
              id="image-search-folder"
              label={Translation.folder}
              value={imageSearchData.folder_search}
              onChange={(e) => setImageSearchData((prev) => ({ ...prev, folder_search: e.target.value }))}
              onKeyUp={(e) => checkEnterKeyEvent(e)}
            />
            <Input
              type="text"
              id="image-search-order"
              label={Translation.order_id}
              value={imageSearchData.id_search}
              onChange={(e) => setImageSearchData((prev) => ({ ...prev, id_search: e.target.value }))}
              onKeyUp={(e) => checkEnterKeyEvent(e)}
            />
          </div>
          <div className="actions-form-right">
            <div className="actions-form-checkboxs">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="search-image-input">
                <Checkbox
                  id="search-image-input"
                  onChange={(e) => setImageSearchData((prev) => ({ ...prev, filter_input: e.target.checked }))}
                  checked={imageSearchData.filter_input}
                />
                <Typography variant="xs" theme="dark" font="medium" label={Translation.input} />
              </label>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="search-image-output">
                <Checkbox
                  id="search-image-output"
                  onChange={(e) => setImageSearchData((prev) => ({ ...prev, filter_output: e.target.checked }))}
                  checked={imageSearchData.filter_output}
                />
                <Typography variant="xs" theme="dark" font="medium" label={Translation.output} />
              </label>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="search-image-older">
                <Checkbox
                  id="search-image-older"
                  onChange={(e) => setImageSearchData((prev) => ({ ...prev, filter_3m_older: e.target.checked }))}
                  checked={imageSearchData.filter_3m_older}
                />
                <Typography variant="xs" theme="dark" font="medium" label={Translation.older_than_3_months} />
              </label>
            </div>
            <Button type="fit-content" onClick={handleSubmit} label={Translation.search} />
          </div>
        </form>
        <div className="actions-warning">
          <Typography variant="xs" label={Translation.image_search_form_bottom_text} />
        </div>
      </div>
      {
        isSubmit && !isLoadingSearch ? (
          <div className="image-search-table">
            <Table
              columns={columns}
              data={transformedData}
              showPagination={false}
              showRowsPerPage={false}
              count={transformedData.length}
              emptyDataText="no_data_with_filters"
              cellHeight="small"
              scrollable={false}
            />
            <div id="image-search-box-table-bottom" className="box-table-bottom" />
          </div>
        ) : null
      }
    </div>
  )
}

export default ImageSearch
