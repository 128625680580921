/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'

import Typography from '../../../components/Typography'
import Switch from '../../../components/Switch'
import RadioGroup from '../../../components/RadioGroup'
import Input from '../../../components/Input'

import './index.scss'

const DataTransfer = ({ galleryData, updateGalleryData }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <section className="public-gallery-settings-section data-transfer">
      <div className="section-title">
        <Typography
          variant="subtitle"
          font="semibold"
          label={Translation.data_transfer}
        />
      </div>
      <div className="switch-columns">
        <div className="switch-column">
          <div className="switch-column-title">
            <Typography
              label={Translation.download_button}
            />
          </div>
          <div className="switch-group">
            <div>
              <label htmlFor="switch-separate-images" className="m-bottom-8">
                <Switch
                  id="switch-separate-images"
                  checked={!!galleryData.separate_download_button}
                  onChange={(value) => updateGalleryData('separate_download_button', value)}
                />
                <Typography label={Translation.separate_images} />
              </label>
              <RadioGroup
                name="approval"
                color="gray"
                values={{
                  after: Translation.after_approval,
                  before: Translation.before_approval,
                }}
                onChange={(e) => updateGalleryData('separate_download_availability', e.target.value)}
                value={galleryData.separate_download_availability}
                allDisabled
              />
            </div>
            <div>
              <label htmlFor="switch-all-images" className="m-bottom-8">
                <Switch
                  id="switch-all-images"
                  checked={!!galleryData.all_download_button}
                  onChange={(value) => updateGalleryData('all_download_button', value)}
                />
                <Typography label={Translation.all_images_in_order} />
              </label>
              <RadioGroup
                name="approval"
                color="gray"
                values={{
                  after: Translation.after_approval,
                  before: Translation.before_approval,
                }}
                onChange={(e) => updateGalleryData('all_download_availability', e.target.value)}
                value={galleryData.all_download_availability}
                allDisabled
              />
            </div>
          </div>
        </div>
        <div className="switch-column">
          <div className="switch-column-title">
            <Typography
              label={Translation.ftp}
            />
          </div>
          <div className="switch-group">
            <label htmlFor="switch-ftp">
              <Switch
                id="switch-ftp"
                checked={!!galleryData.ftp}
                onChange={(value) => updateGalleryData('ftp', value)}
              />
              <Typography label={Translation.ftp_transfer_after_approval} />
            </label>
            <div className="input-rows">
              <div className="input-row">
                <div className="input-box">
                  <label htmlFor="ftp-host">
                    <Typography label={`${Translation.host}:`} />
                  </label>
                  <Input
                    value={galleryData.ftp_host}
                    onChange={(e) => updateGalleryData('ftp_host', e.target.value)}
                  />
                </div>
                <div className="input-box small">
                  <label htmlFor="ftp-port">
                    <Typography label={`${Translation.port}:`} />
                  </label>
                  <Input
                    value={galleryData.ftp_port}
                    onChange={(e) => updateGalleryData('ftp_port', e.target.value)}
                  />
                </div>
              </div>
              <div className="input-row">
                <div className="input-box">
                  <label htmlFor="ftp-username">
                    <Typography label={`${Translation.username}:`} />
                  </label>
                  <Input
                    value={galleryData.ftp_username}
                    onChange={(e) => updateGalleryData('ftp_username', e.target.value)}
                  />
                </div>
                <div className="input-box small">
                  <label htmlFor="ftp-path">
                    <Typography label={`${Translation.path}:`} />
                  </label>
                  <Input
                    value={galleryData.ftp_path}
                    onChange={(e) => updateGalleryData('ftp_path', e.target.value)}
                  />
                </div>
              </div>
              <div className="input-row">
                <div className="input-box">
                  <label htmlFor="ftp-password">
                    <Typography label={`${Translation.password}:`} />
                  </label>
                  <div className="password-input-wrapper">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      value={galleryData.ftp_password}
                      onChange={(e) => updateGalleryData('ftp_password', e.target.value)}
                    />
                    <button type="button" className="show-password" onClick={() => setShowPassword(!showPassword)}>
                      <Typography label={showPassword ? Translation.hide_password : Translation.show_password} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DataTransfer

DataTransfer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  galleryData: PropTypes.objectOf(PropTypes.any).isRequired,
  updateGalleryData: PropTypes.func.isRequired,
}
