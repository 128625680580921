import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'

import './index.scss'

const SaveTemplate = ({
  templateId,
  orderName,
  showEmptyOrderNameDialog,
}) => {
  const templateOrderUpdate = useStoreActions((state) => state.template.templateOrderUpdate)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)

  const [isTemplateSaved, setIsTemplateSaved] = useState(false)

  const handleSaveTemplate = async () => {
    if (!orderName) {
      showEmptyOrderNameDialog(true)
      return
    }

    const res = await templateOrderUpdate(templateId)

    if (res) {
      updateSnackbarState({
        isOpen: true,
        message: (isTemplateSaved) ? Translation.template_update_successfully : Translation.template_saved_successfully,
        type: 'success',
      })

      setIsTemplateSaved(true)
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.nonce,
        type: 'error',
      })
    }
  }

  return (
    <div className="new-order--save-template">
      <button type="button" onClick={handleSaveTemplate} className="button button--fit-content">
        <Typography
          variant="xs"
          label={(isTemplateSaved) ? Translation.update_template : Translation.save_as_template}
          font="semibold"
        />
      </button>
    </div>
  )
}

SaveTemplate.propTypes = {
  templateId: PropTypes.number.isRequired,
  orderName: PropTypes.string.isRequired,
  showEmptyOrderNameDialog: PropTypes.func.isRequired,
}

SaveTemplate.defaultProps = {}

export default SaveTemplate
