/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import Chip from '@mui/material/Chip'
import { Link } from 'react-router-dom'

import { Translation } from '../../../helpers/Translation'
import { getPerPage, savePerPage } from '../../../helpers/Utils'
import {
  DESKTOP_PX_TO_REM, DESKTOP_TABLE_WIDTH, TABLES, PX_TO_REM,
} from '../../../helpers/Constants'

import Table from '../../../components/Table'
import Dialog from '../../../components/Dialog'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import Typography from '../../../components/Typography'

import TableTopInfo from '../../../layouts/Common/TableTopInfo'
import MobileView from '../../../layouts/Common/MobileView'

import UserModal from './UserModal'

import { ReactComponent as EditSvg } from '../../../svg/edit.svg'
import { ReactComponent as TrashSvg } from '../../../svg/trash.svg'
import { ReactComponent as PlusIcon } from '../../../svg/plus.svg'
import { ReactComponent as ThreeDots } from '../../../svg/three_dots.svg'

import './index.scss'

const PublicGalleryUsers = () => {
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const deletePublicGalleryUser = useStoreActions((actions) => actions.user.deletePublicGalleryUser)
  const publicGalleryUsers = useStoreState((state) => state.user.publicGalleryUsers)
  const publicGalleryUsersCount = useStoreState((state) => state.user.publicGalleryUsersCount)
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const getPublicGalleryUsers = useStoreActions((state) => state.user.getPublicGalleryUsers)
  const createPublicGalleryUser = useStoreActions((actions) => actions.user.createPublicGalleryUser)

  const [rowsPerPage, setRowsPerPage] = useState(getPerPage(TABLES.USER_MANAGEMENT))
  const [page, setPage] = useState(1)
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false)
  const [activePublicGalleryUsers, setActivePublicGalleryUsers] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedShowAllGallery, setSelectedShowAllGallery] = useState([])
  const [isShowRowActions, setIsShowRowActions] = useState(false)

  const actionsBoxRef = useRef(null)

  const handleOnClickOutside = (e) => {
    if (actionsBoxRef.current && !actionsBoxRef.current.contains(e.target)) {
      setIsShowRowActions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOnClickOutside)
    return () => {
      document.removeEventListener('click', handleOnClickOutside)
    }
  })

  const toggleIsShowRowActions = (e, id) => {
    e.stopPropagation()
    setIsShowRowActions((prev) => (prev === id ? false : id))
  }

  const getPublicGalleryUserList = async () => {
    await getPublicGalleryUsers({ page, limit: rowsPerPage })
  }

  const handleDeleteGalleryUser = async () => {
    const deleteUserResult = await deletePublicGalleryUser(selectedUser.id)

    if (deleteUserResult) {
      updateSnackbarState({
        message: Translation.deleted_successfully,
        isOpen: true,
        type: 'success',
      })
      getPublicGalleryUserList().then(() => { })
    } else {
      updateSnackbarState({
        message: Translation.something_went_wrong,
        isOpen: true,
        type: 'error',
      })
    }

    setIsRemoveDialogOpen(false)
  }

  const handleCreatePublicGalleryUser = async (data) => {
    const createResult = await createPublicGalleryUser(data)

    if (createResult) {
      updateSnackbarState({
        message: Translation.saved_successfully,
        isOpen: true,
        type: 'success',
      })
      getPublicGalleryUserList().then(() => { })
    } else {
      updateSnackbarState({
        message: Translation.something_went_wrong,
        isOpen: true,
        type: 'error',
      })
    }
    setIsModalOpen(false)
  }

  useEffect(() => {
    getPublicGalleryUserList().then(() => { })
    savePerPage(TABLES.USER_MANAGEMENT, rowsPerPage)
  }, [page, rowsPerPage])

  useEffect(() => {
    setActivePublicGalleryUsers(publicGalleryUsers)
  }, [publicGalleryUsers])

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      setSelectedShowAllGallery([])
    }
  }

  const colWidth = [300, 250, 520]
  // last column width is calculated as the rest of available table width
  colWidth.push(DESKTOP_TABLE_WIDTH - colWidth.reduce((a, b) => a + b))

  const tableColumns = [
    {
      id: 'name',
      label: Translation.name,
      render: 'jsx',
      sortable: false,
      width: colWidth[1] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'email',
      label: Translation.email_address,
      sortable: false,
      width: colWidth[0] / DESKTOP_PX_TO_REM,
      truncateLength: 25,
    },
    {
      id: 'assignedGalleries',
      label: Translation.assigned_galleries,
      render: 'jsx',
      sortable: false,
      width: colWidth[2] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'editDelete',
      label: '',
      render: 'jsx',
      sortable: false,
      width: colWidth[3] / DESKTOP_PX_TO_REM,
    },
  ]

  const onRowsPerPageChange = (value) => {
    setRowsPerPage(value)
  }

  const onPageChange = (value) => {
    setPage(value)
  }

  const transformData = () => {
    const tempData = []

    activePublicGalleryUsers.forEach((user) => {
      tempData.push({
        name: `${user.firstname} ${user.lastname}`,
        email: user.email,
        assignedGalleries: (
          <div className="template-chips-container">
            {
              user.template_public_galleries.map((gallery, galleryIndex) => (
                galleryIndex < 3 && (
                  <PublicGalleryChipTooltip key={gallery.id} tooltipText={Translation.edit_gallery_text}>
                    <Link to={`/public-gallery-settings/${gallery.id}`}>
                      <Chip className="template-chip" label={gallery?.name} variant="outlined" />
                    </Link>
                  </PublicGalleryChipTooltip>
                )
              ))
            }
            {
              user.template_public_galleries.length > 3 && (
                <PublicGalleryChipTooltip tooltipText={Translation.all_see_public_gallery}>
                  <button type="button" onClick={() => setSelectedShowAllGallery(user.template_public_galleries)}>
                    <Chip
                      className="template-chip"
                      label={`${user.template_public_galleries.length - 3}+`}
                      variant="outlined"
                    />
                  </button>
                </PublicGalleryChipTooltip>
              )
            }
          </div>
        ),
        editDelete: (
          <div className="more-actions" ref={actionsBoxRef}>
            <button
              type="button"
              className="more-actions-icon"
              onClick={(e) => toggleIsShowRowActions(e, user.id)}
            >
              <ThreeDots />
            </button>
            <div className="more-actions-content" style={{ display: isShowRowActions === user.id ? 'block' : 'none' }}>
              <button
                type="button"
                className="more-actions-content-row edit-icon"
                onClick={() => {
                  setIsModalOpen(true)
                  setSelectedUser(user)
                }}
              >
                <EditSvg />
                <Typography
                  variant="xs"
                  label={Translation.edit_user}
                />
              </button>
              <button
                type="button"
                className="more-actions-content-row delete-icon"
                onClick={() => {
                  setIsRemoveDialogOpen(true)
                  setSelectedUser(user)
                }}
              >
                <TrashSvg />
                <Typography
                  variant="xs"
                  label={Translation.delete_user}
                />
              </button>
            </div>
          </div>
        ),
      })
    })

    return tempData
  }

  if (activePublicGalleryUsers === null) return null

  return !isMobile ? (
    <div className="public-gallery-users">
      <div className="table-top">
        <TableTopInfo
          title={Translation.profile_page_titles.public_gallery_users}
          titleFontSize={PX_TO_REM['16']}
          chipText={
            publicGalleryUsersCount === 1
              ? `${publicGalleryUsersCount} ${Translation.User}`
              : `${publicGalleryUsersCount} ${Translation.Users}`
          }
        />
        <div className="add-button-container">
          <Button
            type="outlined"
            size="s"
            onClick={() => {
              setSelectedUser(null)
              setIsModalOpen(true)
            }}
            label={(
              <div className="add-button-inner">
                <PlusIcon />
                <Typography label={Translation.add_user} />
              </div>
            )}
          />
        </div>
      </div>

      <div className="table-wrapper">
        <Table
          data={transformData()}
          count={publicGalleryUsersCount}
          columns={tableColumns}
          onPageChange={onPageChange}
          rowsPerPageValue={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          emptyDataText="you_have_not_added_any_public_gallery_users"
          scrollable={false}
          cellHeight="small"
        />
      </div>

      {
        isModalOpen && (
          <UserModal
            closeModal={() => setIsModalOpen(false)}
            user={selectedUser}
            activeTab
            onClickCloseIcon={() => {
              setIsModalOpen(false)
              getPublicGalleryUserList().then(() => { })
            }}
            createUserData={handleCreatePublicGalleryUser}
          />
        )
      }

      {isRemoveDialogOpen && (
        <Dialog
          isShown={isRemoveDialogOpen}
          title={`${Translation.delete_user}?`}
          content={Translation.sure_to_delete}
          cancelLabel={Translation.cancel}
          yesLabel={Translation.delete}
          onClickCancel={() => setIsRemoveDialogOpen(false)}
          onClickEscClose={() => setIsRemoveDialogOpen(false)}
          onClickYes={handleDeleteGalleryUser}
          isWarning
        />
      )}

      <div className="allGalleryChips-modal">
        <Modal
          hasCloseIcon
          onClickCloseIcon={() => setSelectedShowAllGallery([])}
          onClickEscClose={() => setSelectedShowAllGallery([])}
          onClickOutside={onClickOutside}
          size="large"
          isShown={selectedShowAllGallery.length > 0}
        >
          <Typography label={Translation.assigned_galleries} variant="h5" font="semibold" />
          <div className="template-chips-container allGalleryChips-modal-content">
            {
              selectedShowAllGallery.map((gallery) => (
                <PublicGalleryChipTooltip key={gallery.id} tooltipText={Translation.edit_gallery_text}>
                  <Link to={`/public-gallery-settings/${gallery.id}`}>
                    <Chip className="template-chip" label={gallery?.name} variant="outlined" />
                  </Link>
                </PublicGalleryChipTooltip>
              ))
            }
          </div>
        </Modal>
      </div>
    </div>
  ) : (
    <MobileView
      label={Translation.go_to_desktop_for_public_gallery_users}
    />
  )
}

const PublicGalleryChipTooltip = ({ tooltipText, key, children }) => (
  <Tooltip
    arrow
    className="info-tooltip"
    PopperProps={{
      disablePortal: true,
    }}
    placement="top"
    disableFocusListener
    disableTouchListener
    title={tooltipText}
    key={key}
  >
    {children}
  </Tooltip>
)

PublicGalleryChipTooltip.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  key: PropTypes.string,
  children: PropTypes.node.isRequired,
}

PublicGalleryChipTooltip.defaultProps = {
  key: null,
}

export default PublicGalleryUsers
