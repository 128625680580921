import React, { useEffect, useRef, useState } from 'react'

import { DOOPIC_URLS, Translation } from '../../helpers/Translation'

import Typography from '../../components/Typography'

import { ReactComponent as PlusFaqSvg } from '../../svg/plus_faq.svg'
import { ReactComponent as MinusFaqSvg } from '../../svg/minus_faq.svg'

import './index.scss'

const Faq = () => {
  const answersHeightInit = {
    height_1: 0,
    height_2: 0,
    height_3: 0,
    height_4: 0,
    height_5: 0,
    height_6: 0,
    height_7: 0,
    height_8: 0,
    height_9: 0,
    height_10: 0, // should be max number of items out of all "accordions"
  }

  const [generalAnswersHeight, setGeneralAnswersHeight] = useState(answersHeightInit)

  const [orderAnswersHeight, setOrderAnswersHeight] = useState(answersHeightInit)

  const [technicalAnswersHeight, setTechnicalAnswersHeight] = useState(answersHeightInit)

  const questions = {
    general: {
      title: Translation.general_faqs,
      subtitle: Translation.general_faqs_description,
      questions: [
        {
          key: 1,
          question: Translation.general_question_1,
          answer: Translation.general_answer_1.replace(/%DOOPIC_URL_PRICES%/g, DOOPIC_URLS.prices),
          answerHeight: generalAnswersHeight.height_1,
          ref: useRef(null),
        },
        {
          key: 2,
          question: Translation.general_question_2,
          answer: Translation.general_answer_2,
          answerHeight: generalAnswersHeight.height_2,
          ref: useRef(null),
        },
        {
          key: 3,
          question: Translation.general_question_3,
          answer: Translation.general_answer_3,
          answerHeight: generalAnswersHeight.height_3,
          ref: useRef(null),
        },
        {
          key: 4,
          question: Translation.general_question_4,
          answer: Translation.general_answer_4.replace(/%DOOPIC_URL_PRIVACY%/g, DOOPIC_URLS.privacy),
          answerHeight: generalAnswersHeight.height_4,
          ref: useRef(null),
        },
        {
          key: 5,
          question: Translation.general_question_5,
          answer: Translation.general_answer_5.replace(/%DOOPIC_URL_PRICES%/g, DOOPIC_URLS.prices),
          answerHeight: generalAnswersHeight.height_5,
          ref: useRef(null),
        },
        {
          key: 6,
          question: Translation.general_question_6,
          answer: Translation.general_answer_6,
          answerHeight: generalAnswersHeight.height_6,
          ref: useRef(null),
        },
        {
          key: 7,
          question: Translation.general_question_7,
          answer: Translation.general_answer_7,
          answerHeight: generalAnswersHeight.height_7,
          ref: useRef(null),
        },
        {
          key: 8,
          question: Translation.general_question_8,
          answer: Translation.general_answer_8,
          answerHeight: generalAnswersHeight.height_8,
          ref: useRef(null),
        },
        {
          key: 9,
          question: Translation.general_question_9,
          answer: Translation.general_answer_9,
          answerHeight: generalAnswersHeight.height_9,
          ref: useRef(null),
        },
      ],
    },
    order: {
      title: Translation.order_faqs,
      subtitle: Translation.order_faqs_description,
      questions: [
        {
          key: 1,
          question: Translation.order_question_1,
          answer: Translation.order_answer_1,
          answerHeight: orderAnswersHeight.height_1,
          ref: useRef(null),
        },
        {
          key: 2,
          question: Translation.order_question_2,
          answer: Translation.order_answer_2,
          answerHeight: orderAnswersHeight.height_2,
          ref: useRef(null),
        },
        {
          key: 3,
          question: Translation.order_question_3,
          answer: Translation.order_answer_3.replace(/%DOOPIC_URL_PRICES%/g, DOOPIC_URLS.prices),
          answerHeight: orderAnswersHeight.height_3,
          ref: useRef(null),
        },
        {
          key: 4,
          question: Translation.order_question_4,
          answer: Translation.order_answer_4,
          answerHeight: orderAnswersHeight.height_4,
          ref: useRef(null),
        },
        {
          key: 5,
          question: Translation.order_question_5,
          answer: Translation.order_answer_5,
          answerHeight: orderAnswersHeight.height_5,
          ref: useRef(null),
        },
        {
          key: 6,
          question: Translation.order_question_6,
          answer: Translation.order_answer_6,
          answerHeight: orderAnswersHeight.height_6,
          ref: useRef(null),
        },
        {
          key: 7,
          question: Translation.order_question_7,
          answer: Translation.order_answer_7,
          answerHeight: orderAnswersHeight.height_7,
          ref: useRef(null),
        },
        {
          key: 8,
          question: Translation.order_question_8,
          answer: Translation.order_answer_8.replace(/%DOOPIC_URL_PRICES%/g, DOOPIC_URLS.prices),
          answerHeight: orderAnswersHeight.height_8,
          ref: useRef(null),
        },
        {
          key: 9,
          question: Translation.order_question_9,
          answer: Translation.order_answer_9,
          answerHeight: orderAnswersHeight.height_9,
          ref: useRef(null),
        },
      ],
    },
    technical: {
      title: Translation.technical_faqs,
      subtitle: Translation.technical_faqs_description,
      questions: [
        {
          key: 1,
          question: Translation.technical_question_1,
          answer: Translation.technical_answer_1,
          answerHeight: technicalAnswersHeight.height_1,
          ref: useRef(null),
        },
        {
          key: 2,
          question: Translation.technical_question_2,
          answer: Translation.technical_answer_2,
          answerHeight: technicalAnswersHeight.height_2,
          ref: useRef(null),
        },
        {
          key: 3,
          question: Translation.technical_question_3,
          answer: Translation.technical_answer_3,
          answerHeight: technicalAnswersHeight.height_3,
          ref: useRef(null),
        },
        {
          key: 4,
          question: Translation.technical_question_4,
          answer: Translation.technical_answer_4,
          answerHeight: technicalAnswersHeight.height_4,
          ref: useRef(null),
        },
        {
          key: 5,
          question: Translation.technical_question_5,
          answer: Translation.technical_answer_5,
          answerHeight: technicalAnswersHeight.height_5,
          ref: useRef(null),
        },
        {
          key: 6,
          question: Translation.technical_question_6,
          answer: Translation.technical_answer_6,
          answerHeight: technicalAnswersHeight.height_6,
          ref: useRef(null),
        },
        {
          key: 7,
          question: Translation.technical_question_7,
          answer: Translation.technical_answer_7,
          answerHeight: technicalAnswersHeight.height_7,
          ref: useRef(null),
        },
        {
          key: 8,
          question: Translation.technical_question_8,
          answer: Translation.technical_answer_8,
          answerHeight: technicalAnswersHeight.height_8,
          ref: useRef(null),
        },
        {
          key: 9,
          question: Translation.technical_question_9,
          answer: Translation.technical_answer_9,
          answerHeight: technicalAnswersHeight.height_9,
          ref: useRef(null),
        },
        {
          key: 10,
          question: Translation.technical_question_10,
          answer: Translation.technical_answer_10,
          answerHeight: technicalAnswersHeight.height_10,
          ref: useRef(null),
        },
      ],
    },
  }

  const handleToggleQuestion = (e) => {
    const { id, name } = e.currentTarget.dataset

    if (name === 'general') {
      setGeneralAnswersHeight({
        ...answersHeightInit,
        [`height_${id}`]: (generalAnswersHeight[`height_${id}`] === 0)
          ? questions.general.questions[id - 1].ref.current.dataset.height : 0,
      })
    } else if (name === 'order') {
      setOrderAnswersHeight({
        ...answersHeightInit,
        [`height_${id}`]: (orderAnswersHeight[`height_${id}`] === 0)
          ? questions.order.questions[id - 1].ref.current.dataset.height : 0,
      })
    } else if (name === 'technical') {
      setTechnicalAnswersHeight({
        ...answersHeightInit,
        [`height_${id}`]: (technicalAnswersHeight[`height_${id}`] === 0)
          ? questions.technical.questions[id - 1].ref.current.dataset.height : 0,
      })
    }
  }

  // executed only once on first page load
  useEffect(() => {
    questions.general.questions.forEach((question) => {
      // eslint-disable-next-line no-param-reassign
      question.ref.current.dataset.height = `${question.ref.current.scrollHeight}px`
    })

    questions.order.questions.forEach((question) => {
      // eslint-disable-next-line no-param-reassign
      question.ref.current.dataset.height = `${question.ref.current.scrollHeight}px`
    })

    questions.technical.questions.forEach((question) => {
      // eslint-disable-next-line no-param-reassign
      question.ref.current.dataset.height = `${question.ref.current.scrollHeight}px`
    })
  }, [])

  return (
    <div className="support-faq">
      {
        Object.keys(questions).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className="m-top-70">
            <div className="align-center">
              <Typography
                variant="xl"
                font="semibold"
                theme="dark"
                label={`${questions[item].title} ${item}`}
              />
            </div>

            <div className="align-center m-top-20">
              <Typography
                variant="h6"
                label={questions[item].subtitle}
              />
            </div>

            <div className="m-top-70">
              {
                questions[item].questions.map((question) => (
                  <div
                    key={question.key}
                    className="support-faq--line"
                  >
                    <div
                      data-id={question.key}
                      data-name={item}
                      onClick={handleToggleQuestion}
                      className="support-faq--question"
                    >
                      <Typography
                        variant="h6"
                        font="semibold"
                        theme="dark"
                        label={question.question}
                      />
                      {
                        (question.answerHeight !== 0) ? <MinusFaqSvg /> : <PlusFaqSvg />
                      }
                    </div>

                    <div
                      className="support-faq--answer m-top-8"
                      ref={question.ref}
                      style={(question.answerHeight !== 0) ? { height: question.answerHeight } : null}
                    >
                      {
                        question.answer.split('\n').map((answer, key) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={key}>
                            <Typography
                              variant="s"
                              label={answer}
                              containsHtml
                            />
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Faq
