import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'
import { Tooltip } from '@mui/material'

import { NEW_ORDER_PAGE } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'
import { isClientAdminRole } from '../../../helpers/Utils'

import Button from '../../../components/Button'
import Typography from '../../../components/Typography'

import TableTopInfo from '../../../layouts/Common/TableTopInfo'

import ImageSearchBox from './ImageSearchBox'
import FilterBox from './FilterBox'

import { ReactComponent as SearchSvg } from '../../../svg/search.svg'
import { ReactComponent as ResetSvg } from '../../../svg/close.svg'
import { ReactComponent as FilterSvg } from '../../../svg/filter.svg'
import { ReactComponent as SearchOptionsSvg } from '../../../svg/search_options.svg'
import { ReactComponent as PlusSvg } from '../../../svg/plus.svg'
import { ReactComponent as FilterWarningSvg } from '../../../svg/filter_warning.svg'

import './index.scss'

const templatesListMaxLength = 100

const processTemplateList = (templates) => {
  const templatesList = templates.map((template) => `${template?.name}`).join(', ')

  return (
    <Tooltip
      className="info-tooltip"
      PopperProps={{
        disablePortal: true,
      }}
      title={(
        <div className="dashboard-pricing-tooltip">
          {templates.map((template) => (
            <div key={template.id}>
              {template?.name}
            </div>
          ))}
        </div>
      )}
      placement="top"
      arrow
    >
      <div>
        <Typography
          variant="xs"
          label={`${Translation.dashboard_table_top_text_assigned_templates} 
                <span class="tableTopInfo-templates-list">${templatesList.substring(0, templatesListMaxLength)}...</span>`}
          containsHtml
        />
      </div>
    </Tooltip>
  )
}

const OrderTableTop = ({
  handleFilterOptions,
  filterOptions,
  setSearchValue,
  searchValue,
}) => {
  const navigate = useNavigate()

  const ordersCount = useStoreState((state) => state.order.ordersCount)
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const detailedUser = useStoreState((state) => state.user.detailedUser)

  const [isApplyFilter, setIsApplyFilter] = useState(JSON.stringify(filterOptions) !== JSON.stringify({
    filter_order_status: '',
    filter_date_from: null,
    filter_date_till: null,
    filter_tid: [],
  }))
  const [showImageSearchBox, setShowImageSearchBox] = useState(false)
  const [showFilterBox, setShowFilterBox] = useState(false)
  const [searchInputData, setSearchInputData] = useState(searchValue || '')
  const [isClearData, setIsClearData] = useState(false)
  const [orderStatus, setOrderStatus] = useState('')
  const [isFocusSearch, setIsFocusSearch] = useState(false)

  const orderStatusValue = {
    '': Translation.all_orders,
    approval: Translation.approval_required,
    redo: Translation.in_redo,
    open: Translation.open_orders,
    completed: Translation.completed_orders,
  }

  const defaultTableDescription = isMobile
    ? Translation.dashboard_table_top_text_mobile : Translation.dashboard_table_top_text
  const [tableDescription, setTableDescription] = useState('')

  const imageSearchBoxRef = useRef(null)
  const filterBoxRef = useRef(null)

  const handleOnClickOutside = (e) => {
    if (imageSearchBoxRef.current && !imageSearchBoxRef.current.contains(e.target)) {
      setShowImageSearchBox(false)
    }
  }

  useEffect(() => {
    // only user role can see assigned templates
    if (!isClientAdminRole(detailedUser?.role_after_login) && detailedUser?.assigned_templates?.length) {
      const templatesList = detailedUser.assigned_templates.map((template) => `<b>${template?.name}</b>`).join(', ')

      if (templatesList.length > templatesListMaxLength) {
        const templatesListObject = processTemplateList(detailedUser.assigned_templates)
        setTableDescription(templatesListObject)
      } else {
        setTableDescription(`${Translation.dashboard_table_top_text_assigned_templates} 
            <span class="tableTopInfo-templates-list">${templatesList}</span>`)
      }
    } else {
      setTableDescription(defaultTableDescription)
    }
  }, [detailedUser?.assigned_templates])

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleOnClickOutside)
    }
  })

  useEffect(() => {
    if (showImageSearchBox || showFilterBox) {
      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          e.preventDefault()
          setShowImageSearchBox(false)
          setShowFilterBox(false)
        }
      }
      window.addEventListener('keydown', handleKeyDown)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }

    return () => { }
  }, [showImageSearchBox, showFilterBox])

  const handleSearch = (e) => {
    e.preventDefault()
    setSearchValue(searchInputData)
  }

  useEffect(() => {
    if (isApplyFilter || setIsClearData) {
      setShowFilterBox(false)
    }
  }, [isApplyFilter, setIsClearData])

  const textTooltip = (text) => (
    <div className="textTooltip">
      <Typography variant="body1" font="medium" label={text} />
    </div>
  )

  const handleResetInput = (e) => {
    e.preventDefault()
    if (e.clientX === 0 && e.clientY === 0) {
      setSearchValue(searchInputData)
    } else {
      setSearchInputData('')
      setSearchValue('')
    }
  }

  return (
    <div className="table-top">
      <TableTopInfo
        title={orderStatusValue[orderStatus]}
        chipText={`${ordersCount} ${ordersCount === 1 ? Translation.order : Translation.orders}`}
        description={tableDescription}
      />
      {
        !isMobile && (
          <div>
            <div className="table-top-actions">
              <div id="step-2">
                <div
                  className={
                    `actions-search${showImageSearchBox || isFocusSearch ? ' active' : ''}${searchValue ? ' applied' : ''}`
                  }
                >
                  <form onSubmit={handleSearch} style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="actions-search-input">
                      <input
                        type="text"
                        placeholder={Translation.search}
                        onChange={(e) => setSearchInputData(e.target.value)}
                        value={searchInputData}
                        onFocus={() => setIsFocusSearch(true)}
                        onBlur={() => setIsFocusSearch(false)}
                      />
                    </div>
                    {searchValue ? (
                      <button type="submit" className="actions-search-resetIcon" onClick={handleResetInput}>
                        <ResetSvg />
                      </button>
                    ) : (
                      <button type="submit" className="actions-search-searchIcon">
                        <SearchSvg />
                      </button>
                    )}
                  </form>
                  <div ref={imageSearchBoxRef}>
                    <Tooltip
                      className="info-tooltip"
                      PopperProps={{
                        disablePortal: true,
                      }}
                      title={textTooltip(Translation.image_search)}
                      placement="top"
                      arrow
                    >
                      <button
                        type="button"
                        className={`actions-search-filterOptionIcon ${showImageSearchBox ? 'active' : ''}`}
                        onClick={() => { setShowImageSearchBox(!showImageSearchBox); setShowFilterBox(false) }}
                      >
                        <SearchOptionsSvg />
                      </button>
                    </Tooltip>
                    <div style={{ display: showImageSearchBox ? 'block' : 'none' }}>
                      <ImageSearchBox />
                    </div>
                  </div>
                </div>
              </div>

              <div ref={filterBoxRef} id="step-3" className="actions-filter">
                <Tooltip
                  className="info-tooltip nowrap"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={orderStatusValue[orderStatus]}
                  placement="top"
                  arrow
                >
                  <button
                    type="button"
                    className={`actions-filter-btn${showFilterBox ? ' active' : ''}${isApplyFilter ? ' applied' : ''}`}
                    onClick={() => { setShowFilterBox(!showFilterBox); setShowImageSearchBox(false) }}
                  >
                    <FilterSvg />
                    <div className="actions-filter-btn-text">
                      <Typography
                        variant="caption"
                        font="semibold"
                        label={isApplyFilter ? Translation.filters_applied : Translation.filters}
                        noWrap
                      />
                    </div>
                    {
                      isApplyFilter && (
                        <div className="actions-filter-btn-warning">
                          <FilterWarningSvg />
                        </div>
                      )
                    }
                  </button>
                </Tooltip>
                <div style={{ display: showFilterBox ? 'block' : 'none' }}>
                  <FilterBox
                    ordersCount={ordersCount}
                    setIsApplyFilter={setIsApplyFilter}
                    isClearData={isClearData}
                    setIsClearData={setIsClearData}
                    filterOptions={filterOptions}
                    handleFilterOptions={
                      (values) => { handleFilterOptions(values); setOrderStatus(values.filter_order_status) }
                    }
                    onClose={() => setShowFilterBox(false)}
                    filterBoxRef={filterBoxRef}
                  />
                </div>
              </div>
              <div className="actions-button">
                <Button
                  width="auto"
                  onClick={() => navigate(NEW_ORDER_PAGE)}
                  label={(
                    <>
                      <PlusSvg />
                      {Translation.new_order}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

OrderTableTop.propTypes = {
  handleFilterOptions: PropTypes.func,
  filterOptions: PropTypes.shape({
    filter_order_status: PropTypes.string,
    filter_date_from: PropTypes.string,
    filter_date_till: PropTypes.string,
    filter_tid: PropTypes.arrayOf(PropTypes.string),
  }),
  setSearchValue: PropTypes.func,
  searchValue: PropTypes.string,
}

OrderTableTop.defaultProps = {
  handleFilterOptions: () => { },
  filterOptions: {
    filter_order_status: '',
    filter_date_from: null,
    filter_date_till: null,
    filter_tid: [],
  },
  setSearchValue: () => { },
  searchValue: '',
}

export default OrderTableTop
