import React, { useEffect, useRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate, useLocation } from 'react-router-dom'

import { BILLING_PAGE, HIDE_DROPDOWN_TIMEOUT, PX_TO_REM } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'

import Typography from '../../components/Typography'

import ProfilePicture from '../Common/ProfilePicture'

import { ReactComponent as ProfileGraySvg } from '../../svg/profile_gray.svg'
import { ReactComponent as BillingSvg } from '../../svg/billing.svg'
import { ReactComponent as SignOutSvg } from '../../svg/sign_out.svg'

import './index.scss'

const ProfileMenu = () => {
  // init navigation
  const navigate = useNavigate()
  const location = useLocation()

  // init all component actions
  const menuState = useStoreState((state) => state.layout.menuState)
  const user = useStoreState((state) => state.user.user)
  const isPendingConfirmation = useStoreState((state) => state.user.isPendingConfirmation)
  const updateMenuState = useStoreActions((actions) => actions.layout.updateMenuState)
  const logoutAction = useStoreActions((actions) => actions.user.logout)

  // init all component references
  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)

  const handleProfileMenuClick = (e) => {
    // ignore later layout click
    e.stopPropagation()

    updateMenuState({
      lang: false,
      support: false,
      profile: !isOpenedRef.current,
    })
  }

  const handleProfileMenuMouseEnter = () => {
    isMouseOverRef.current = true
  }

  const handleProfileMenuMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        updateMenuState({
          ...menuState,
          profile: !isOpenedRef.current,
        })
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  // watch menuState change
  useEffect(() => {
    isOpenedRef.current = menuState.profile
  }, [menuState])

  const handleLogOut = (e) => {
    e.preventDefault()
    logoutAction()
  }

  const handleLinkClick = (e) => {
    if (isPendingConfirmation) return
    navigate(e.currentTarget.dataset.href)
  }

  const profileMenuPages = [
    {
      pageName: 'profile',
      path: '/profile#my-details',
      label: Translation.my_details,
      icon: <ProfileGraySvg />,
      handleClick: handleLinkClick,
    },
    {
      pageName: 'billing',
      path: BILLING_PAGE,
      label: Translation.billing,
      icon: <BillingSvg />,
      handleClick: handleLinkClick,
    },
    {
      pageName: 'sign_out',
      path: '/sign-out',
      label: Translation.logout,
      icon: <SignOutSvg />,
      handleClick: handleLogOut,
    },
  ]

  return (
    <div
      onMouseEnter={handleProfileMenuMouseEnter}
      onMouseLeave={handleProfileMenuMouseLeave}
      className="profile-menu--wrap"
    >
      <div className="profile-menu">
        <div
          onClick={handleProfileMenuClick}
          id="profile-menu--icon"
          className="profile-menu--icon"
        >
          <ProfilePicture
            avatarUrl={user.avatar_url}
            bgColor={user.avatar_bg ?? null}
            firstname={user.firstname}
            lastname={user.lastname}
            fontSize={PX_TO_REM['18']}
            width={4}
            height={4}
          />
        </div>
      </div>

      {
        (menuState.profile) ? (
          <div className="profile-menu__menu">
            {
              profileMenuPages
                // eslint-disable-next-line no-confusing-arrow
                .filter((page) => page.pageName !== 'billing' ? true : (user.billing_info === 1))
                .map((page) => (
                  <div
                    id={page.pageName}
                    key={page.pageName}
                    data-href={page.path}
                    onClick={page.handleClick}
                    className={`profile-menu__menu-item 
                      ${(`${location.pathname}${location.hash}` === page.path) ? 'profile-menu__menu-item--active' : ''}
                      bg-color-transition`}
                  >
                    {page.icon}

                    <Typography
                      variant="xs"
                      label={page.label}
                    />
                  </div>
                ))
            }
          </div>
        ) : null
      }
    </div>
  )
}

export default ProfileMenu
