import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { LOGIN_PAGE } from '../../helpers/Constants'
import { Translation } from '../../helpers/Translation'

import Button from '../../components/Button'
import Typography from '../../components/Typography'

import OrderSuccessfulAnimation from '../../animation/order_successful_animation.json'

import { ReactComponent as SuccessfulCheckmarkSvg } from '../../svg/successful_checkmark.svg'

const splashDefaultOptions = {
  loop: false,
  autoplay: true,
  animationData: OrderSuccessfulAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Loader = () => <SuccessfulCheckmarkSvg className="loader-icon" />

const ResetPasswordSuccess = ({ isSubmitButtonDisabled }) => {
  const Lottie = React.lazy(() => import('lottie-react'))
  const navigate = useNavigate()

  return (
    <div className="screen-center-top">

      <div className="reset-form-line">
        <React.Suspense fallback={<Loader />}>
          <Lottie {...splashDefaultOptions} className="lottie-animation" />
        </React.Suspense>
      </div>

      <div className="reset-form__line">
        <Typography
          variant="xl"
          theme="dark"
          noWrap
          font="semibold"
          label={Translation.password_reset}
        />
      </div>

      <div className="reset-form__line m-top-12 m-bottom-32">
        <Typography
          variant="s"
          theme="light"
          label={Translation.password_reset_text}
        />
      </div>

      <div className="reset-form__line m-top-24">
        <Button
          size="s"
          label={Translation.login}
          disabled={isSubmitButtonDisabled}
          onClick={() => navigate(LOGIN_PAGE)}
          id="reset--button"
        />
      </div>

    </div>
  )
}

ResetPasswordSuccess.propTypes = {
  isSubmitButtonDisabled: PropTypes.bool.isRequired,
}

export default ResetPasswordSuccess
