import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const getClassName = (size = '', type = '', isWarning = false) => {
  let className = 'button'
  if (size) className += ` button--${size}`
  if (type) className += ` button--${type}`
  if (isWarning) className += '  button--warning'
  return className
}

const Button = ({
  id,
  type,
  isWarning,
  size,
  label,
  disabled,
  width,
  onClick,
}) => (
  <button
    id={id}
    type="button"
    className={getClassName(size, type, isWarning)}
    disabled={disabled}
    onClick={onClick}
    style={{
      width: /^\d+(\.\d+)?$/.test(width) ? `${width}rem` : width,
    }}
  >
    {label}
  </button>
)

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['', 'contained', 'outlined', 'disabled', 'fit-content']),
  size: PropTypes.oneOf(['', 'xs', 's', 'xl']),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  isWarning: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClick: PropTypes.func,
}

Button.defaultProps = {
  id: '',
  type: '',
  size: '',
  label: 'Button',
  disabled: false,
  isWarning: false,
  width: '',
  onClick: () => { },
}

export default Button
