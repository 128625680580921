import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import SelectMui from '@mui/material/Select'
import PropTypes from 'prop-types'

import { ReactComponent as SelectArrowDownSvg } from '../../svg/select_arrow_down.svg'

import './index.scss'

const theme = createTheme({
  typography: {
    fontFamily: 'roobert, Helvetica, Arial, sans-serif',
  },
})

const Select = ({
  label,
  placeholder,
  value,
  values,
  isEmptyOption,
  onChange,
  error,
  isError,
  itemClass,
  disabled,
}) => {
  const [open, setOpen] = React.useState(false)

  const onKeyDown = (e) => {
    if ((e.key >= 'a' && e.key <= 'z') || (e.key >= '0' && e.key <= '9') || e.key === ' ') {
      setOpen(true)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth className="doo-select--wrap" error={isError}>
        {
          (label) ? (
            <div className="input__label">
              {label}
            </div>
          ) : null
        }
        <SelectMui
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={value}
          onChange={onChange}
          IconComponent={SelectArrowDownSvg}
          displayEmpty
          SelectDisplayProps={{
            onKeyDown: (e) => onKeyDown(e),
          }}
          renderValue={(selected) => {
            if (selected === '') {
              return <span className="select--placeholder">{placeholder}</span>
            }
            return values[selected]
          }}
          disabled={disabled}
        >
          {
            (isEmptyOption) ? <MenuItem value="">{placeholder}</MenuItem> : null
          }
          {
            Object.keys(values).map((key) => (
              <MenuItem
                key={key}
                value={key}
                disableRipple
                className={`doo-select--item ${itemClass}`}
              >
                {values[key]}
              </MenuItem>
            ))
          }
        </SelectMui>
        {
          (isError && error) ? (
            <div className="input__error">
              {
                error.replace(/%MIN_LENGTH%/g, '')
              }
            </div>
          ) : null
        }
      </FormControl>
    </ThemeProvider>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.string),
  isEmptyOption: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
  isError: PropTypes.bool,
  itemClass: PropTypes.string,
  disabled: PropTypes.bool,
}

Select.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  values: [],
  isEmptyOption: true,
  onChange: () => { },
  error: '',
  isError: false,
  itemClass: '',
  disabled: false,
}

export default Select
