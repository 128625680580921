import React from 'react'

import { Translation } from '../../../helpers/Translation'

import Typography from '../../../components/Typography'

import { ReactComponent as WarningSvg } from '../../../svg/warning.svg'

import './index.scss'

const FtpConnectionDenied = () => (
  <div className="ftpConnectionDenied">
    <div className="ftpConnectionDenied-icon">
      <WarningSvg />
    </div>
    <div className="ftpConnectionDenied-title">
      <Typography variant="s" font="semibold" label={Translation.ftp_connection_denied} />
    </div>
    <div className="ftpConnectionDenied-text">
      <Typography variant="subtitle" label={Translation.ftp_connection_denied_text} containsHtml />
    </div>
  </div>
)

export default FtpConnectionDenied
