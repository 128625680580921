import React from 'react'

import { PX_TO_REM } from '../../../helpers/Constants'
import { Translation } from '../../../helpers/Translation'

import Typography from '../../../components/Typography'

import { ReactComponent as DoopicIcon } from '../../../svg/logo_icon.svg'

const RequestRequestedView = () => (
  <div className="request-requested-view">
    <DoopicIcon className="doopic-logo" />
    <div className="title-container">
      <Typography fontSize={PX_TO_REM['23']} lineHeight={PX_TO_REM['26']} font="semibold" label={Translation.ftp_connection} />
    </div>
    <Typography variant="s" lineHeight={PX_TO_REM['20']} label={Translation.ftp_request_successfully_received} />
  </div>
)

export default RequestRequestedView
