import React, { useState, useEffect, Fragment } from 'react'
import propTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import Textarea from '../../../../components/Textarea'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import Modal from '../../../../components/Modal'

import ColumnSwitch from '../../Common/ColumnSwitch'
import SectionTop from '../../Common/SectionTop'

import { ReactComponent as RetouchSvg } from '../../../../svg/retouch.svg'
import { ReactComponent as EditSvg } from '../../../../svg/edit.svg'

import './index.scss'

const Retouch = ({ handlePropertyId, orderFormatActions, disableAll }) => {
  const [clickOtherType, setClickOtherType] = useState(null)
  const [generalOtherValue, setGeneralOtherValue] = useState('')
  const [skinOtherValue, setSkinOtherValue] = useState('')

  useEffect(() => {
    setGeneralOtherValue(orderFormatActions?.retouch_general_other_remark.value)
    setSkinOtherValue(orderFormatActions?.retouch_skin_other_remark.value)
  }, [orderFormatActions])

  const generalRetouchValues = [
    'retouch_general_dirt',
    'retouch_general_scratches',
    'retouch_general_dust',
    'retouch_general_reflections',
    'retouch_general_wrinkles',
    'retouch_general_other',
  ]

  const skinRetouchValues = [
    'retouch_skin_scars',
    'retouch_skin_pimples',
    'retouch_skin_moles',
    'retouch_skin_tattoos',
    'retouch_skin_wrinkles',
    'retouch_skin_other',
  ]

  const onClickOutsideModal = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      setClickOtherType('')
    }
  }

  const handleChangeCheckbox = (id, value) => {
    if ((id === 'retouch_general_other' || id === 'retouch_skin_other') && value) {
      setClickOtherType(id)
    } else {
      handlePropertyId(id, value)
    }
  }

  return (
    <section className={`new-order-section section-retouch ${orderFormatActions?.retouch?.value ? 'active' : ''}`}>
      <Accordion expanded={!!orderFormatActions?.retouch?.value} className="accordion">
        <AccordionSummary className="accordion-summary">
          <SectionTop
            showContent={!!orderFormatActions?.retouch?.value}
            onChange={(id, value) => handlePropertyId(id, value)}
            id="retouch"
            icon={<RetouchSvg className="retouch-icon" />}
            defaultChecked={orderFormatActions?.retouch?.value !== null}
            labelText={orderFormatActions?.retouch?.label}
            modalText={Translation.retouch_modal_text}
            badgeText={orderFormatActions?.retouch?.price}
            disabled={disableAll}
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="section-content">
            <div className="section-content-columns">
              <div className="section-content-column">
                <ColumnSwitch
                  id="retouch_general"
                  labelText={orderFormatActions?.retouch_general?.label}
                  modalText={Translation.retouch_general_retouch_modal}
                  badgeText={orderFormatActions?.retouch_general?.price}
                  defaultChecked={orderFormatActions?.retouch_general?.value !== null}
                  onChange={(id, value) => { handlePropertyId(id, value) }}
                  disabled={disableAll}
                />
                <div
                  className="column-content"
                  style={{ display: orderFormatActions?.retouch_general?.value ? 'block' : 'none' }}
                >
                  {
                    generalRetouchValues.map((generalRetouchItem) => (
                      <Fragment key={generalRetouchItem}>
                        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={generalRetouchItem} className="column-check-label">
                          <Checkbox
                            id={generalRetouchItem}
                            checked={orderFormatActions[generalRetouchItem]?.value !== null}
                            onChange={(e) => handleChangeCheckbox(generalRetouchItem, e.target.checked)}
                            disabled={disableAll}
                          />
                          <Typography variant="xs" theme="light" label={orderFormatActions[generalRetouchItem].label} />
                        </label>
                      </Fragment>
                    ))
                  }
                  {
                    orderFormatActions?.retouch_general_other?.value && (
                      <div className="remark-modal-value">
                        <Typography variant="xs" label={orderFormatActions?.retouch_general_other_remark?.value} />
                        <button
                          type="button"
                          className="remark-modal-value-edit"
                          onClick={() => setClickOtherType('retouch_general_other')}
                          disabled={disableAll}
                        >
                          <EditSvg />
                        </button>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="section-content-column" style={{ paddingLeft: '4rem' }}>
                <ColumnSwitch
                  id="retouch_skin"
                  labelText={orderFormatActions?.retouch_skin?.label}
                  modalText={Translation.retouch_skin_retouch_modal}
                  badgeText={orderFormatActions?.retouch_skin?.price}
                  defaultChecked={orderFormatActions?.retouch_skin?.value !== null}
                  onChange={(id, value) => { handlePropertyId(id, value) }}
                  disabled={disableAll}
                />
                <div className="column-content" style={{ display: orderFormatActions?.retouch_skin?.value ? 'block' : 'none' }}>
                  {
                    skinRetouchValues.map((skinRetouchItem) => (
                      <Fragment key={skinRetouchItem}>
                        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={`retouch-${skinRetouchItem}`} className="column-check-label">
                          <Checkbox
                            id={`retouch-${skinRetouchItem}`}
                            checked={orderFormatActions[skinRetouchItem]?.value !== null}
                            onChange={(e) => handleChangeCheckbox(skinRetouchItem, e.target.checked)}
                            disabled={disableAll}
                          />
                          <Typography variant="xs" theme="light" label={orderFormatActions[skinRetouchItem].label} />
                        </label>
                      </Fragment>
                    ))
                  }
                  {
                    orderFormatActions?.retouch_skin_other?.value && (
                      <div className="remark-modal-value">
                        <Typography variant="xs" label={orderFormatActions?.retouch_skin_other_remark?.value} />
                        <button
                          type="button"
                          className="remark-modal-value-edit"
                          onClick={() => setClickOtherType('retouch_skin_other')}
                          disabled={disableAll}
                        >
                          <EditSvg />
                        </button>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="new-order-modal">
        <Modal
          hasCloseIcon
          isShown={clickOtherType}
          onClickOutside={onClickOutsideModal}
          onClickCloseIcon={() => setClickOtherType('')}
          onClickEscClose={() => setClickOtherType('')}
        >
          <div className="new-order-modal-title">
            <Typography
              variant="h6"
              font="semibold"
              label={
                clickOtherType === 'retouch_general_other'
                  ? `${orderFormatActions?.retouch_general?.label} - ${orderFormatActions?.retouch_general_other?.label}`
                  : `${orderFormatActions?.retouch_skin?.label} - ${orderFormatActions?.retouch_skin_other?.label}`
              }
            />
          </div>
          <div className="new-order-modal-content">
            <Textarea
              placeholder={
                clickOtherType === 'retouch_general_other'
                  ? `${orderFormatActions?.retouch_general?.label} - ${orderFormatActions?.retouch_general_other?.label}`
                  : `${orderFormatActions?.retouch_skin?.label} - ${orderFormatActions?.retouch_skin_other?.label}`
              }
              value={clickOtherType === 'retouch_general_other' ? (generalOtherValue || '') : (skinOtherValue || '')}
              onChange={(e) => {
                if (clickOtherType === 'retouch_general_other') {
                  setGeneralOtherValue(e.target.value)
                } else {
                  setSkinOtherValue(e.target.value)
                }
              }}
            />
          </div>
          <div className="new-order-modal-buttons">
            <Button type="contained" label={Translation.close} onClick={() => setClickOtherType('')} />
            <Button
              label={Translation.save}
              onClick={() => {
                handlePropertyId(clickOtherType, true)
                handlePropertyId(
                  clickOtherType === 'retouch_general_other' ? 'retouch_general_other_remark' : 'retouch_skin_other_remark',
                  clickOtherType === 'retouch_general_other' ? generalOtherValue : skinOtherValue,
                )
                setClickOtherType('')
              }}
              disabled={clickOtherType === 'retouch_general_other' ? !generalOtherValue : !skinOtherValue}
            />
          </div>
        </Modal>
      </div>
    </section>
  )
}

Retouch.propTypes = {
  handlePropertyId: propTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderFormatActions: propTypes.objectOf(propTypes.any),
  disableAll: propTypes.bool,
}

Retouch.defaultProps = {
  orderFormatActions: {},
  disableAll: false,
}

export default Retouch
