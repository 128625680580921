import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../helpers/Translation'

import Dialog from '../../../../components/Dialog'
import Typography from '../../../../components/Typography'

const UnsavedChangesDialog = ({
  leaveDialogOpen,
  handleClickCancel,
  handleLeaveApprove,
}) => (
  <Dialog
    isWarning
    isShown={leaveDialogOpen}
    isCentered
    onClickCancel={handleClickCancel}
    onClickEscClose={handleClickCancel}
    title={Translation.unsaved_changes_title}
    yesLabel={Translation.yes}
    onClickYes={handleLeaveApprove}
    content={
      <Typography variant="xs" label={Translation.unsaved_changes_message} />
    }
  />
)

UnsavedChangesDialog.propTypes = {
  leaveDialogOpen: PropTypes.bool,
  handleClickCancel: PropTypes.func,
  handleLeaveApprove: PropTypes.func,
}

UnsavedChangesDialog.defaultProps = {
  leaveDialogOpen: false,
  handleClickCancel: () => { },
  handleLeaveApprove: () => { },
}

export default UnsavedChangesDialog
