import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import dayjs from 'dayjs'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Slide } from 'react-slideshow-image'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-slideshow-image/dist/styles.css'

import {
  DEFAULT_PAGE, FIELDS_MIN_LENGTH, FIELDS_PATTERNS, PX_TO_REM,
} from '../../helpers/Constants'
import {
  Translation, DOOPIC_URLS, lang, setLang,
} from '../../helpers/Translation'
import { isLocal } from '../../helpers/Cookie'
import {
  isEnterKey, isValidValue, removeInputError, validateInput, validateInputWithError,
} from '../../helpers/Utils'

import Button from '../../components/Button'
import Input from '../../components/Input'
import Typography from '../../components/Typography'
import Link from '../../components/Link'

import Footer from '../../layouts/Footer'

import { ReactComponent as LogoSvg } from '../../svg/logo.svg'
import { ReactComponent as ArrowLeftWhiteSvg } from '../../svg/arrow_left_white.svg'
import { ReactComponent as ArrowRightWhiteSvg } from '../../svg/arrow_right_white.svg'
import { ReactComponent as GoogleSvg } from '../../svg/google.svg'

import './index.scss'

const Login = () => {
  // init navigation
  const navigate = useNavigate()

  // get parameters from url
  const [searchParams] = useSearchParams()

  // init all component actions
  const loginAction = useStoreActions((actions) => actions.user.login)
  const googleLogin = useStoreActions((actions) => actions.user.googleLogin)
  const clearApiErrorsAction = useStoreActions((actions) => actions.user.clearApiErrors)
  const confirmEmailAction = useStoreActions((actions) => actions.user.confirmEmail)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const apiErrors = useStoreState((state) => state.user.apiErrors)

  // init all component states
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hideCommentSection] = useState(
    dayjs().diff(dayjs(process.env.REACT_APP_LOGIN_PAGE_DATE), 'day') >= 90,
  )

  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors, setFormErrors] = useState({
    email: Translation.enter_valid_email,
    password: Translation.must_be_at_least_chars_long,
  })

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)

  // watch API errors change
  useEffect(() => {
    if (apiErrors) {
      // API server is down
      if (apiErrors.serverError) return

      setFormErrors({
        ...formErrors,
        email: (apiErrors.email) ? apiErrors.email : Translation.wrong_email_or_password,
      })
      setIsFormErrors({ ...isFormErrors, email: true })
    } else {
      // clear all form errors
      setIsFormErrors({})
    }
  }, [apiErrors])

  const handleConfirmEmail = async () => {
    const result = await confirmEmailAction({
      pending_confirmation: searchParams.get('token'),
      user_account_id_code: searchParams.get('id'),
    })
    if (result.is_email_confirmed === true) {
      // redirect user to the set new password page with token and id in the url
      if (result.is_terms_conditions === 0) {
        navigate(`/set-your-password/${searchParams.get('id')}`)
        return
      }
      updateSnackbarState({
        message: Translation.email_confirmed,
        isOpen: true,
        type: 'success',
      })
    } else {
      updateSnackbarState({
        message: Translation.email_not_confirmed,
        isOpen: true,
        type: 'error',
      })
    }
  }

  // executed only once on first page load
  useEffect(() => {
    // if we have token and id in the url, send request to verify email
    if (searchParams.get('token') && searchParams.get('id')) {
      handleConfirmEmail()
    }
    clearApiErrorsAction()
  }, [])

  // check if "simple" layout should be rendered
  const isSimpleLayout = searchParams.get('simple') !== null

  const handleEmailChange = (e) => {
    const input = e.currentTarget
    setEmail(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleEmailBlur = (e) => {
    validateInputWithError(
      e.currentTarget,
      formErrors,
      setFormErrors,
      isFormErrors,
      setIsFormErrors,
      Translation.enter_valid_email,
    )
  }

  const handlePasswordChange = (e) => {
    const input = e.currentTarget
    setPassword(input.value.trim())
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handlePasswordBlur = (e) => {
    validateInput(e.currentTarget, isFormErrors, setIsFormErrors)
  }

  const isFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!email) {
      isValid = false
      isFormErrorsUpdated.email = !isValid
      setFormErrors({ ...formErrors, email: Translation.required_field })
    } else if (!isValidValue(email, FIELDS_PATTERNS.email)) {
      isValid = false
      isFormErrorsUpdated.email = !isValid
      setFormErrors({ ...formErrors, email: Translation.enter_valid_email })
    }

    if (!isValidValue(password, FIELDS_PATTERNS.simplePassword)) {
      isValid = false
      isFormErrorsUpdated.password = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const handleSubmitClick = async () => {
    if (!isFormValid()) return

    setIsSubmitButtonDisabled(true)

    const result = await loginAction({
      email,
      password,
    })

    setIsSubmitButtonDisabled(false)
    if (result.success && result.user_account) {
      const redirectTo = searchParams.get('redirect')
      if (result.user_account.lang === lang) {
        if (redirectTo) {
          if (redirectTo.startsWith('/admin/') || redirectTo.startsWith('/gallery/')) window.location.href = redirectTo
          else navigate(redirectTo)
        } else {
          navigate(DEFAULT_PAGE)
        }
      } else {
        setLang(result.user_account.lang)
        if (redirectTo) window.location.href = redirectTo
        else window.location.href = DEFAULT_PAGE
      }
    }
  }

  const handleInputKeyUp = (e) => {
    if (isEnterKey(e.key)) {
      handleSubmitClick()
    }
  }

  const handleLinkClick = (e) => {
    e.preventDefault()
    const url = new URL(e.currentTarget.href)
    navigate(url.pathname)
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const accessToken = tokenResponse.access_token
      const result = await googleLogin({ id_token: accessToken })
      navigate(result.redirect)
    },
    onError: () => {
      updateSnackbarState({
        message: Translation.nonce,
        isOpen: true,
        type: 'error',
      })
    },
  })

  const indicators = (index) => (<div className="indicator">{index + 1}</div>)

  const arrowStyle = {
    background: 'none',
    border: 'none',
    width: '2.3076923rem',
    height: '2.3076923rem',
    bottom: '-5.1307692rem',
  }

  const arrowLeftStyle = {
    left: '6.9230769rem',
  }

  const arrowRightStyle = {
    right: '6.9230769rem',
  }

  const properties = {
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    prevArrow: <button style={{ ...arrowStyle, ...arrowLeftStyle }} type="button"><ArrowLeftWhiteSvg /></button>,
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    nextArrow: <button style={{ ...arrowStyle, ...arrowRightStyle }} type="button"><ArrowRightWhiteSvg /></button>,
  }

  const slides = [
    {
      id: '1',
      image: 'login_ad_dashboard_1.jpg',
      title: Translation.login_slider_item_1_title,
      subtitle: Translation.login_slider_item_1_subtitle,
    },
    {
      id: '2',
      image: 'login_ad_dashboard_2.jpg',
      title: Translation.login_slider_item_2_title,
      subtitle: Translation.login_slider_item_2_subtitle,
    },
    {
      id: '3',
      image: 'login_ad_dashboard_3.jpg',
      title: Translation.login_slider_item_3_title,
      subtitle: Translation.login_slider_item_3_subtitle,
    },
  ]

  return (
    <div className="login-page">
      <div className={`login-form--wrap${isSimpleLayout || hideCommentSection ? ' login-form--wrap--simple' : ''}`}>
        <div>
          <div className="screen-center">
            <div className="login-form--wrap--form">
              <div className="login-form__line flex">
                <LogoSvg className="login--logo" />
              </div>

              <div className="login-form__line m-top-32">
                <Typography
                  variant="xl"
                  theme="dark"
                  font="semibold"
                  fontSize={PX_TO_REM['27']}
                  label={Translation.login_to_your_account}
                />
              </div>

              <div className="login-form__line m-top-12 m-bottom-32 flex justify-center">
                <Typography
                  variant="s"
                  theme="light"
                  noWrap
                  label={Translation.welcome_enter_details}
                />
              </div>

              <div className="login-form__line">
                <Input
                  type="text"
                  name="email"
                  size="s"
                  label={Translation.email}
                  error={formErrors.email}
                  isError={isFormErrors.email}
                  pattern={FIELDS_PATTERNS.email}
                  placeholder={Translation.enter_your_email}
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                  onKeyUp={handleInputKeyUp}
                />
              </div>

              <div className="login-form__line m-top-20">
                <Input
                  type="password"
                  name="password"
                  size="s"
                  label={Translation.password}
                  error={formErrors.password}
                  isError={isFormErrors.password}
                  minLength={FIELDS_MIN_LENGTH.password}
                  pattern={FIELDS_PATTERNS.simplePassword}
                  placeholder={Translation.enter_your_password}
                  value={password}
                  onChange={handlePasswordChange}
                  onBlur={handlePasswordBlur}
                  onKeyUp={handleInputKeyUp}
                />
              </div>

              <div className="login-form__line m-top-24">
                <div className="align-right">
                  <Link
                    id="forgot-link"
                    href="/forgot"
                    variant="xs"
                    theme="dark"
                    font="semibold"
                    label={Translation.forgot_password_question}
                    onClick={handleLinkClick}
                  />
                </div>
              </div>

              {
                (apiErrors && apiErrors.serverError) ? (
                  <div className="login-form__line m-top-4 align-left error">
                    <Typography
                      variant="xs"
                      theme="light"
                      inline
                      label={apiErrors.serverError}
                    />
                  </div>
                ) : null
              }

              <div className="login-form__line m-top-24">
                <Button
                  id="login-link"
                  size="s"
                  label={Translation.login}
                  disabled={isSubmitButtonDisabled}
                  onClick={handleSubmitClick}
                />
              </div>

              <div className="login-form__line m-top-16 google-btn">
                <Button
                  type="outlined"
                  size="s"
                  onClick={handleGoogleLogin}
                  label={(
                    <>
                      <GoogleSvg />
                      {Translation.login_with_google}
                    </>
                  )}
                />
              </div>

              <div className="login-form__line m-top-30">
                <Typography
                  variant="xs"
                  theme="light"
                  inline
                  label={Translation.no_account_question}
                />
                {' '}
                <Link
                  id="register-link"
                  href="/register"
                  variant="xs"
                  theme="dark"
                  font="semibold"
                  label={Translation.no_account_question_link}
                  onClick={handleLinkClick}
                />
              </div>

              <div className="login-form__line m-top-30 recapthca">
                <Typography
                  variant="xs"
                  theme="light"
                  inline
                  label={Translation.recapthca_info_1}
                />
                {' '}
                <Link
                  href={DOOPIC_URLS.privacy}
                  variant="xs"
                  theme="light"
                  underline
                  label={Translation.recapthca_info_2}
                  isNewTab
                />
                {' '}
                <Typography
                  variant="xs"
                  theme="light"
                  inline
                  label={Translation.recapthca_info_3}
                />
                {' '}
                <Link
                  href={DOOPIC_URLS.terms}
                  variant="xs"
                  theme="light"
                  underline
                  label={Translation.recapthca_info_4}
                  isNewTab
                />
                {' '}
                <Typography
                  variant="xs"
                  theme="light"
                  inline
                  label={Translation.recapthca_info_5}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="m-top-auto">
          <Footer isNewTab />
        </div>
      </div>
      {
        (!isSimpleLayout && !hideCommentSection) ? (
          <div className="login-ad--wrap">
            <div className="screen-center">
              <div className="login-ad-line--wrap">
                <Slide
                  {...properties}
                  autoplay={!isLocal}
                  transitionDuration={500}
                  duration={6000}
                  easing="ease-in"
                  indicators={indicators}
                >
                  {
                    slides.map((slide) => (
                      <div key={slide.id} className="login-ad-slide">
                        <div className="login-ad__image--wrap">
                          <div
                            className="login-ad__image"
                            style={{ background: `url("../../../image/${slide.image}") center center/cover no-repeat` }}
                          />
                        </div>

                        <div className="m-top-37">
                          <Typography
                            variant="h6"
                            font="semibold"
                            label={slide.title}
                          />
                        </div>

                        <div className="login-ad__subtitle">
                          <Typography
                            variant="s"
                            font="medium"
                            label={slide.subtitle}
                          />
                        </div>
                      </div>
                    ))
                  }
                </Slide>
              </div>
            </div>
          </div>
        ) : null
      }
    </div>
  )
}

export default Login
