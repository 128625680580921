import { Translation } from './Translation'

export const dashboardSteps = [
  {
    id: 'step1',
    attachTo: {
      element: '#sidebar-menu-item-dashboard',
      on: 'right-start',
    },
    title: Translation.shepherd_dashboard_step_1_title,
    text: [`${Translation.shepherd_dashboard_step_1}<div class="shepherd-step">1/7</div>`],
    modalOverlayOpeningRadius: 6,
    buttons: [
      {
        type: 'back',
        text: `← ${Translation.back}`,
        classes: 'shepherd-button-back shepherd-button-disabled',
        disabled: true,
      },
      {
        type: 'next',
        text: `${Translation.next} →`,
        classes: 'shepherd-button-next',
      },
    ],
  },
  {
    id: 'step2',
    attachTo: {
      element: '#step-2',
      on: 'bottom',
    },
    title: Translation.shepherd_dashboard_step_2_title,
    text: [`${Translation.shepherd_dashboard_step_2}<div class="shepherd-step">2/7</div>`],
    modalOverlayOpeningRadius: 4,
    modalOverlayOpeningPadding: 6,
    buttons: [
      {
        type: 'back',
        text: `← ${Translation.back}`,
        classes: 'shepherd-button-back',
      },
      {
        type: 'next',
        text: `${Translation.next} →`,
        classes: 'shepherd-button-next',
      },
    ],
  },
  {
    id: 'step3',
    attachTo: {
      element: '#step-3',
      on: 'bottom',
    },
    title: Translation.shepherd_dashboard_step_3_title,
    text: [`${Translation.shepherd_dashboard_step_3}<div class="shepherd-step">3/7</div>`],
    modalOverlayOpeningRadius: 4,
    modalOverlayOpeningPadding: 6,
    buttons: [
      {
        type: 'back',
        text: `← ${Translation.back}`,
        classes: 'shepherd-button-back',
      },
      {
        type: 'next',
        text: `${Translation.next} →`,
        classes: 'shepherd-button-next',
      },
    ],
  },
  {
    id: 'step4',
    attachTo: {
      element: '#sidebar-menu-item-new_order',
      on: 'right-start',
    },
    title: Translation.new_order,
    text: [`${Translation.shepherd_dashboard_step_4}<div class="shepherd-step">4/7</div>`],
    modalOverlayOpeningRadius: 6,
    buttons: [
      {
        type: 'back',
        text: `← ${Translation.back}`,
        classes: 'shepherd-button-back',
      },
      {
        type: 'next',
        text: `${Translation.next} →`,
        classes: 'shepherd-button-next',
      },
    ],
  },
  {
    id: 'step5',
    attachTo: {
      element: '#sidebar-menu-item-templates',
      on: 'right-start',
    },
    title: Translation.shepherd_dashboard_step_5_title,
    text: [`${Translation.shepherd_dashboard_step_5}<div class="shepherd-step">5/7</div>`],
    modalOverlayOpeningRadius: 6,
    buttons: [
      {
        type: 'back',
        text: `← ${Translation.back}`,
        classes: 'shepherd-button-back',
      },
      {
        type: 'next',
        text: `${Translation.next} →`,
        classes: 'shepherd-button-next',
      },
    ],
  },
  {
    id: 'step6',
    attachTo: {
      element: '#sidebar-menu-item-profile',
      on: 'right-start',
    },
    title: Translation.shepherd_dashboard_step_6_title,
    text: [`${Translation.shepherd_dashboard_step_6}<div class="shepherd-step">6/7</div>`],
    modalOverlayOpeningRadius: 6,
    buttons: [
      {
        type: 'back',
        text: `← ${Translation.back}`,
        classes: 'shepherd-button-back',
      },
      {
        type: 'next',
        text: `${Translation.next} →`,
        classes: 'shepherd-button-next',
      },
    ],
  },
  {
    id: 'step7',
    attachTo: {
      element: '#support-menu--icon',
      on: 'bottom',
    },
    title: Translation.shepherd_dashboard_step_7_title,
    text: [`${Translation.shepherd_dashboard_step_7}<div class="shepherd-step">7/7</div>`],
    modalOverlayOpeningRadius: 16,
    modalOverlayOpeningPadding: 6,
    buttons: [
      {
        type: 'back',
        text: `← ${Translation.back}`,
        classes: 'shepherd-button-back',
      },
      {
        type: 'next',
        text: `${Translation.done}`,
        classes: 'shepherd-button-next',
      },
    ],
  },
]

export const dashboardTourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
    arrow: true,
    buttons: [
      {
        type: 'back',
        text: 'Back',
        classes: 'shepherd-button-back',
      },
      {
        type: 'next',
        text: 'Next',
        classes: 'shepherd-button-next',
      },
    ],
  },
  useModalOverlay: true,
}
