import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions } from 'easy-peasy'

import { PX_TO_REM } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import Button from '../../../../components/Button'
import Modal from '../../../../components/Modal'

import './index.scss'

const OrderDetailsAcceptPrice = ({ orderId, isSuccess }) => {
  const getOrderAcceptPrice = useStoreActions((actions) => actions.order.getOrderAcceptPrice)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [isLoadingAcceptPrice, setIsLoadingAcceptPrice] = useState(false)

  const handleOrderAcceptPrice = async () => {
    setIsLoadingAcceptPrice(true)
    const result = await getOrderAcceptPrice({ id: orderId })
    setIsLoadingAcceptPrice(false)

    if (result) {
      isSuccess(true)
      updateSnackbarState({
        message: Translation.success_accept_price,
        isOpen: true,
        type: 'success',
      })

      setShowAcceptModal(false)
    } else {
      updateSnackbarState({
        message: Translation.error_accept_price,
        isOpen: true,
        type: 'error',
      })
    }
  }

  return (
    <>
      <div className="acceptPriceBox">
        <div className="acceptPriceBox-title">
          <Typography
            variant="h6"
            label={Translation.attention}
            lineHeight={PX_TO_REM['25']}
            font="semibold"
          />
        </div>
        <div className="acceptPriceBox-text">
          <Typography variant="xs" label={Translation.accept_price_text} />
        </div>
        <div className="acceptPriceBox-button">
          <Button type="fit-content" label={Translation.accept_price_changed} onClick={() => setShowAcceptModal(true)} />
        </div>
      </div>
      <div className="acceptPriceBox-modal">
        <Modal
          hasCloseIcon
          onClickCloseIcon={() => setShowAcceptModal(false)}
          onClickEscClose={() => setShowAcceptModal(false)}
          isShown={showAcceptModal}
        >
          <div className="acceptPriceBox-modal-title">
            <Typography variant="h6" font="semibold" lineHeight={1.9230769} label={Translation.attention} />
          </div>
          <div className="acceptPriceBox-modal-text">
            <Typography
              variant="xs-narrow"
              label={Translation.accept_changes}
            />
          </div>
          <div className="acceptPriceBox-modal-buttons">
            <Button label={Translation.no} type="contained" onClick={() => setShowAcceptModal(false)} />
            <Button label={Translation.yes} onClick={handleOrderAcceptPrice} disabled={isLoadingAcceptPrice} />
          </div>
        </Modal>
      </div>
    </>
  )
}

OrderDetailsAcceptPrice.propTypes = {
  orderId: PropTypes.number,
  isSuccess: PropTypes.func,
}

OrderDetailsAcceptPrice.defaultProps = {
  orderId: 0,
  isSuccess: () => { },
}

export default OrderDetailsAcceptPrice
