import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material'
import { useStoreState, useStoreActions } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'
import { isAdminRole } from '../../../../helpers/Utils'
import { ONLY_NUMBER_REGEX } from '../../../../helpers/Constants'

import RadioGroup from '../../../../components/RadioGroup'
import Typography from '../../../../components/Typography'
import Input from '../../../../components/Input'

import SectionTop from '../../Common/SectionTop'

import { ReactComponent as FileImage } from '../../../../svg/file-format.svg'

import './index.scss'

const FileFormat = ({
  handlePropertyId,
  orderFormatActions,
  isTestOrder,
  isIframeTemplate,
  disableAll,
}) => {
  const user = useStoreState((actions) => actions.user.user)

  const updateLocaleFormatActions = useStoreActions((actions) => actions.order.updateLocaleFormatActions)

  const fileFormatValues = {
    jpg: 'JPG',
    png: 'PNG',
    gif: 'GIF',
    psd: 'PSD',
    tiff: 'TIFF',
    Original: (Translation.original).toUpperCase(),
  }

  const transformData = (data) => {
    Object.keys(data).forEach((key) => {
      if (key === 'gif' || key === 'psd' || key === 'tiff' || key === 'Original') {
        // eslint-disable-next-line no-param-reassign
        data[key] = (
          <Tooltip
            arrow
            className="info-tooltip w-max-314"
            PopperProps={{
              disablePortal: true,
            }}
            placement="top"
            disableFocusListener
            disableTouchListener
            title={Translation.test_order_disabled_tooltip_text}
          >
            <span className="disabled">{data[key]}</span>
          </Tooltip>
        )
      }
    })

    return data
  }

  return (
    <section className={`new-order-section section-file-format ${orderFormatActions?.format?.value ? 'active' : ''}`}>
      <Accordion expanded={!!orderFormatActions?.format?.value} className="accordion">
        <AccordionSummary className="accordion-summary">
          <SectionTop
            showContent={!!orderFormatActions?.format?.value}
            onChange={(id, value) => handlePropertyId(id, value)}
            id="format"
            icon={<FileImage />}
            defaultChecked
            labelText={orderFormatActions?.format?.label}
            modalText={Translation.file_format_modal_text}
            badgeText={Translation.file_format_badge_text}
            disabled
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          <div className="section-content">
            <RadioGroup
              name="new-order-file-format"
              value={orderFormatActions.format.value}
              values={isTestOrder ? transformData(fileFormatValues) : fileFormatValues}
              row
              color="gray"
              disabledData={isTestOrder ? ['gif', 'psd', 'tiff', 'Original'] : []}
              onChange={(e) => handlePropertyId('format', e.target.value)}
              allDisabled={disableAll}
            />
            {
              Object.keys(user).length > 0 && isIframeTemplate && (
                isAdminRole(user?.role_after_login) && (
                  <div className="section-content-input">
                    <Typography variant="xs" label={Translation.preset_format_price} />
                    <Input
                      value={orderFormatActions?.preset_price?.value}
                      onChange={(e) => {
                        if (ONLY_NUMBER_REGEX.test(e.target.value)) {
                          updateLocaleFormatActions({
                            preset_price:
                              { ...orderFormatActions.preset_price, value: e.target.value },
                          })
                        }
                      }}
                      onBlur={(e) => handlePropertyId('preset_price', e.target.value)}
                      endAdornment={Translation.euro_sign}
                    />
                  </div>
                )
              )
            }
          </div>
        </AccordionDetails>
      </Accordion>
    </section>
  )
}

FileFormat.propTypes = {
  handlePropertyId: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orderFormatActions: PropTypes.objectOf(PropTypes.any),
  isTestOrder: PropTypes.bool,
  isIframeTemplate: PropTypes.bool,
  disableAll: PropTypes.bool,
}

FileFormat.defaultProps = {
  orderFormatActions: {},
  isTestOrder: false,
  isIframeTemplate: false,
  disableAll: false,
}

export default FileFormat
