/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import CheckboxMui from '@mui/material/Checkbox'

import {
  FIELDS_PATTERNS,
  PX_TO_REM,
} from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Modal from '../../../../components/Modal'
import Typography from '../../../../components/Typography'
import Input from '../../../../components/Input'
import Select from '../../../../components/Select'
import Button from '../../../../components/Button'

import { ReactComponent as CheckboxCheckedSvg } from '../../../../svg/checkbox_checked.svg'
import { ReactComponent as CheckedUncheckedSvg } from '../../../../svg/checkbox_unchecked.svg'

import './index.scss'

const UserModal = ({
  user,
  activeTab,
  onClickCloseIcon,
  createUserData,
}) => {
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const getPublicUserAccount = useStoreActions((actions) => actions.user.getPublicUserAccount)
  const updatePublicUserAccount = useStoreActions((actions) => actions.user.updatePublicUserAccount)
  const getPublicGalleries = useStoreActions((actions) => actions.user.getPublicGalleries)
  const reassignPublicGallery = useStoreActions((actions) => actions.user.reassignPublicGallery)
  const [isUserInfo, setIsUserInfo] = useState(activeTab)
  const [gender, setGender] = useState('m')
  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState('')
  const [userPublicGalleries, setUserPublicGalleries] = useState({})
  const [publicGalleries, setPublicGalleries] = useState({})

  const [unAssignedPublicGalleries, setUnAssignedPublicGalleries] = useState([])
  const [assignedPublicGalleries, setAssignedPublicGalleries] = useState([])

  const getPublicUserData = async () => {
    const userResult = await getPublicUserAccount(user?.id)
    setGender(userResult.gender || 'm')
    setFirstname(userResult.firstname || '')
    setLastname(userResult.lastname || '')
    setEmail(userResult.email || '')

    const tempData = {}
    userResult.template_public_galleries.forEach((gallery) => {
      tempData[gallery.id] = gallery
    })
    setUserPublicGalleries(tempData)
  }

  const updatePublicUserData = async () => {
    if (gender && firstname && lastname && email && (password.length === 0 || password.length >= 6)) {
      const updateResult = await updatePublicUserAccount({
        id: user?.id,
        data: {
          gender,
          firstname,
          lastname,
          password,
        },
      })

      const reassignResult = await reassignPublicGallery({
        unassigned_public_gallery_ids: unAssignedPublicGalleries,
        assigned_public_gallery_ids: assignedPublicGalleries,
        user_account_public_id: user?.id,
      })

      if (updateResult.success && reassignResult) {
        updateSnackbarState({
          message: Translation.updated_successfully,
          isOpen: true,
          type: 'success',
        })
        onClickCloseIcon()
      } else {
        updateSnackbarState({
          message: updateResult.message || Translation.update_unsuccessful,
          isOpen: true,
          type: 'error',
        })
      }
    } else {
      updateSnackbarState({
        message: (password.length > 0 && password.length < 6) ? Translation.password_length_error : Translation.fill_all_fields,
        isOpen: true,
        type: 'error',
      })
    }
  }

  const getPublicGalleriesData = async () => {
    const publicGalleriesResult = await getPublicGalleries()
    const tempData = {}

    publicGalleriesResult.forEach((gallery) => {
      tempData[gallery.id] = gallery.name
    })
    setPublicGalleries(tempData)
  }

  const createUser = async () => {
    if (gender && firstname && lastname && email && password) {
      createUserData({
        gender,
        firstname,
        lastname,
        email,
        password,
      })
    } else {
      updateSnackbarState({
        message: Translation.fill_all_fields,
        isOpen: true,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (user?.id) {
      getPublicUserData().then(() => { })
    }
    getPublicGalleriesData().then(() => { })
  }, [user?.id])

  const changeCheckbox = (key) => {
    const tempData = { ...userPublicGalleries }
    if (!tempData[key]) {
      setAssignedPublicGalleries([...assignedPublicGalleries, key])
      setUnAssignedPublicGalleries(unAssignedPublicGalleries.filter((item) => item !== key))
      tempData[key] = publicGalleries[key]
    } else {
      setUnAssignedPublicGalleries([...unAssignedPublicGalleries, key])
      setAssignedPublicGalleries(assignedPublicGalleries.filter((item) => item !== key))
      delete tempData[key]
    }
    setUserPublicGalleries(tempData)
  }

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      onClickCloseIcon()
    }
  }

  return (
    <Modal
      hasCloseIcon
      onClickCloseIcon={onClickCloseIcon}
      onClickOutside={onClickOutside}
      onClickEscClose={onClickCloseIcon}
      size="large"
      height={PX_TO_REM['475']}
      isShown
    >
      <div className="public-user-modal">
        <div className="flex m-bottom-25">
          <div
            data-name="user-info"
            className={`user-form__tab ${(isUserInfo) ? 'user-form__tab--active' : ''}`}
            onClick={() => setIsUserInfo(true)}
          >
            <Typography
              variant="s"
              font={`${(isUserInfo) ? 'semibold' : ''}`}
              theme="dark"
              label={user?.id ? Translation.edit_user : Translation.add_user}
            />
          </div>
          {
            user?.id && (
              <div
                data-name="manage-templates"
                className={`user-form__tab ${(!isUserInfo) ? 'user-form__tab--active' : ''}`}
                onClick={() => setIsUserInfo(false)}
              >
                <Typography
                  variant="s"
                  font={`${(!isUserInfo) ? 'semibold' : ''}`}
                  theme="dark"
                  label={Translation.assigned_galleries}
                />
              </div>
            )
          }
        </div>

        {isUserInfo ? (
          <div className="profile-form">
            <div className="title-firstname-lastname">
              <div className="title-select-container">
                <Select
                  isEmptyOption={false}
                  label={`${Translation.title}*`}
                  values={Translation.titles}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                />
              </div>
              <Input
                type="text"
                name="firstname"
                label={`${Translation.firstname}*`}
                placeholder={Translation.firstname}
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <Input
                type="text"
                name="lastname"
                label={`${Translation.lastname}*`}
                placeholder={Translation.lastname}
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>

            <div className="email-phonenumber">
              <Input
                type="email"
                name="email"
                label={`${Translation.email}*`}
                placeholder={Translation.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!!user?.id}
              />
              <Input
                label={`${Translation.password}*`}
                placeholder={Translation.password}
                type="password"
                name="password"
                value={password}
                pattern={FIELDS_PATTERNS.simplePassword}
                onChange={(e) => setPassword(e.target.value)}
                disableAutocomplete
              />
            </div>
          </div>
        ) : (
          <div className="gallery-management-wrapper scrollbar-overflow">
            {
              Object.keys(publicGalleries).length > 0 && (
                <List className="template-list">
                  {Object.keys(publicGalleries).map((key) => (
                    <ListItem
                      className="template-item"
                      key={key}
                      disablePadding
                      secondaryAction={(
                        <CheckboxMui
                          edge="end"
                          onClick={() => changeCheckbox(key)}
                          checked={!!userPublicGalleries[key]}
                          icon={<CheckedUncheckedSvg />}
                          checkedIcon={<CheckboxCheckedSvg />}
                          disableRipple
                        />
                      )}
                    >
                      <ListItemButton className="template-button" onClick={() => changeCheckbox(key)}>
                        <Typography
                          variant="xs"
                          label={publicGalleries[key]}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )
            }
          </div>
        )}
        <div className="modal-footer">
          <div>
            {' '}
          </div>
          <Button
            id="save_changes"
            label={Translation.save_changes}
            onClick={() => (user?.id ? updatePublicUserData() : createUser())}
          />
        </div>
      </div>
    </Modal>
  )
}

export default UserModal

UserModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.objectOf(PropTypes.any),
  activeTab: PropTypes.bool,
  onClickCloseIcon: PropTypes.func.isRequired,
  createUserData: PropTypes.func.isRequired,
}

UserModal.defaultProps = {
  user: {},
  activeTab: true,
}
