/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../helpers/Translation'

import Typography from '../../../components/Typography'
import Switch from '../../../components/Switch'

import './index.scss'

const ViewsFeatures = ({ galleryData, updateGalleryData }) => (
  <section className="public-gallery-settings-section views-features">
    <div className="section-title">
      <Typography
        variant="subtitle"
        font="semibold"
        label={Translation.views_features}
      />
    </div>
    <div className="switch-columns">
      <div className="switch-column">
        <div className="switch-column-title">
          <Typography
            label={Translation.image_info}
          />
        </div>
        <div className="switch-group">
          <label htmlFor="switch-filename">
            <Switch
              id="switch-filename"
              checked={!!galleryData.filename}
              onChange={(value) => updateGalleryData('filename', value)}
            />
            <Typography label={Translation.filename} />
          </label>
          <label htmlFor="switch-folder">
            <Switch
              id="switch-folder"
              checked={!!galleryData.folder}
              onChange={(value) => updateGalleryData('folder', value)}
            />
            <Typography label={Translation.folder} />
          </label>
          <label htmlFor="switch-image-size">
            <Switch
              id="switch-image-size"
              checked={!!galleryData.sizes}
              onChange={(value) => updateGalleryData('sizes', value)}
            />
            <Typography label={Translation.image_size_dpi} />
          </label>
          <label htmlFor="switch-colour-space">
            <Switch
              id="switch-colour-space"
              checked={!!galleryData.colour_space}
              onChange={(value) => updateGalleryData('colour_space', value)}
            />
            <Typography label={Translation.colour_space} />
          </label>
          <label htmlFor="switch-background-colour">
            <Switch
              id="switch-background-colour"
              checked={!!galleryData.background_color}
              onChange={(value) => updateGalleryData('background_color', value)}
            />
            <Typography label={Translation.background_colour} />
          </label>
        </div>
      </div>
      <div className="switch-column">
        <div className="switch-column-title">
          <Typography
            label={Translation.features}
          />
        </div>
        <div className="switch-group">
          <label htmlFor="switch-layers">
            <Switch
              id="switch-layers"
              checked={!!galleryData.layers}
              onChange={(value) => updateGalleryData('layers', value)}
            />
            <Typography label={Translation.layers} />
          </label>
          <label htmlFor="switch-paths">
            <Switch
              id="switch-paths"
              checked={!!galleryData.paths}
              onChange={(value) => updateGalleryData('paths', value)}
            />
            <Typography label={Translation.paths} />
          </label>
          <label htmlFor="switch-grids">
            <Switch
              id="switch-grids"
              checked={!!galleryData.grids}
              onChange={(value) => updateGalleryData('grids', value)}
            />
            <Typography label={Translation.grids} />
          </label>
          <label htmlFor="switch-bg">
            <Switch
              id="switch-bg"
              checked={!!galleryData.bg}
              onChange={(value) => updateGalleryData('bg', value)}
            />
            <Typography label={Translation.background} />
          </label>
          <label htmlFor="switch-before-after">
            <Switch
              id="switch-before-after"
              checked={!!galleryData.compare}
              onChange={(value) => updateGalleryData('compare', value)}
            />
            <Typography label={Translation.before_after} />
          </label>
          <label htmlFor="switch-ps-guides">
            <Switch
              id="switch-ps-guides"
              checked={!!galleryData.ps_guides}
              onChange={(value) => updateGalleryData('ps_guides', value)}
            />
            <Typography label={Translation.ps_guides} />
          </label>
          <label htmlFor="switch-border">
            <Switch
              id="switch-border"
              checked={!!galleryData.border}
              onChange={(value) => updateGalleryData('border', value)}
            />
            <Typography label={Translation.borders} />
          </label>
        </div>
      </div>
      <div className="switch-column">
        <div className="switch-column-title">
          <Typography
            label={Translation.view}
          />
        </div>
        <div className="switch-group">
          <label htmlFor="switch-grid">
            <Switch
              id="switch-grid"
              checked={!!galleryData.grid}
              onChange={(value) => updateGalleryData('grid', value)}
            />
            <Typography label={Translation.grid_view} />
          </label>
          <label htmlFor="switch-middle">
            <Switch
              id="switch-middle"
              checked={!!galleryData.middle}
              onChange={(value) => updateGalleryData('middle', value)}
            />
            <Typography label={Translation.middle_view} />
          </label>
          <label htmlFor="switch-fullscreen">
            <Switch
              id="switch-fullscreen"
              checked={!!galleryData.fullscreen}
              onChange={(value) => updateGalleryData('fullscreen', value)}
            />
            <Typography label={Translation.fullscreen} />
          </label>
          <label htmlFor="switch-small">
            <Switch
              id="switch-small"
              checked={!!galleryData.small}
              onChange={(value) => updateGalleryData('small', value)}
            />
            <Typography label={Translation.small_view} />
          </label>
          <label htmlFor="switch-original" style={{ display: 'none' }}>
            <Switch
              id="switch-original"
              checked={!!galleryData.original}
              onChange={(value) => updateGalleryData('original', value)}
            />
            <Typography label={Translation.original} />
          </label>
          <label htmlFor="switch-list">
            <Switch
              id="switch-list"
              checked={!!galleryData.list}
              onChange={(value) => updateGalleryData('list', value)}
            />
            <Typography label={Translation.list_view} />
          </label>
        </div>
      </div>
    </div>
  </section>
)

export default ViewsFeatures

ViewsFeatures.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  galleryData: PropTypes.objectOf(PropTypes.any).isRequired,
  updateGalleryData: PropTypes.func.isRequired,
}
