import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../helpers/Translation'

import Button from '../../components/Button'
import Typography from '../../components/Typography'
import Link from '../../components/Link'

import { ReactComponent as BackArrowSvg } from '../../svg/arrow_back_large.svg'

const CheckYourEmail = ({
  email,
  isSubmitButtonDisabled,
  handleSubmitClick,
  handleLinkClick,
}) => (
  <div className="screen-center-top">
    <div className="forgot-form__line">
      <Typography
        variant="xl"
        theme="dark"
        noWrap
        font="semibold"
        label={Translation.check_your_email}
      />
    </div>

    <div className="forgot-form__line m-top-12 m-bottom-32">
      <Typography
        variant="s"
        theme="light"
        containsHtml
        label={Translation.send_message_text.replace(/%EMAIL%/g, email)}
      />
    </div>

    <div className="forgot-form__line m-top-24">
      <Button
        size="s"
        label={Translation.resend_link}
        disabled={isSubmitButtonDisabled}
        onClick={handleSubmitClick}
        id="forgot--button"
      />
    </div>

    <div className="forgot-form__line m-top-32">
      <a
        id="back-to-login--arrow"
        href="/"
        onClick={handleLinkClick}
      >
        <BackArrowSvg className="back-arrow" />
      </a>

      <Link
        id="back-to-login"
        href="/"
        variant="xs"
        theme="dark"
        font="semibold"
        label={Translation.back_to_login}
        onClick={handleLinkClick}
      />
    </div>
  </div>
)

CheckYourEmail.propTypes = {
  email: PropTypes.string.isRequired,
  isSubmitButtonDisabled: PropTypes.bool.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
}

export default CheckYourEmail
