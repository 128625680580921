import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { HIDE_DROPDOWN_TIMEOUT } from '../../helpers/Constants'
import { lang, languages, setLang } from '../../helpers/Translation'
import { isAuthenticated } from '../../helpers/Utils'

import Typography from '../../components/Typography'

import './index.scss'

const LangMenu = ({ isWhite, isNotLogged }) => {
  // init all component actions
  const menuState = useStoreState((state) => state.layout.menuState)
  const updateMenuState = useStoreActions((actions) => actions.layout.updateMenuState)
  const updateMe = useStoreActions((actions) => actions.user.updateMe)

  // init all component references
  const isMouseOverRef = useRef(false)
  const isOpenedRef = useRef(false)

  const handleLangMenuClick = (e) => {
    // ignore later layout click
    e.stopPropagation()

    updateMenuState({
      lang: !isOpenedRef.current,
      support: false,
      profile: false,
    })
  }

  const handleLangMenuMouseEnter = () => {
    isMouseOverRef.current = true
  }

  const handleLangMenuMouseLeave = () => {
    isMouseOverRef.current = false

    setTimeout(() => {
      if (isOpenedRef.current && !isMouseOverRef.current) {
        updateMenuState({
          ...menuState,
          lang: false,
        })
      }
    }, HIDE_DROPDOWN_TIMEOUT)
  }

  // watch menuState change
  useEffect(() => {
    isOpenedRef.current = menuState.lang
  }, [menuState])

  const handleLangChangeClick = async (e) => {
    const newLang = e.currentTarget.dataset.lang
    if (isAuthenticated()) {
      await updateMe({
        lang: newLang,
      })
    }
    setLang(newLang)
    window.location.reload()
  }

  return (
    <div
      onMouseEnter={handleLangMenuMouseEnter}
      onMouseLeave={handleLangMenuMouseLeave}
      className="lang-menu--wrap"
    >
      <div className="lang-menu">
        <div
          onClick={handleLangMenuClick}
          id="lang-menu--icon"
          className={`${isNotLogged ? 'lang-menu--icon__not-logged' : 'lang-menu--icon'} 
          ${isWhite ? 'lang-menu--icon__white' : ''}`}
        />
      </div>
      {
        (menuState.lang) ? (
          <div className="lang-menu__menu">
            {
              Object.keys(languages).map((key) => (
                <div
                  id={`switch-language-${key}`}
                  key={key}
                  data-lang={key}
                  onClick={handleLangChangeClick}
                  className={`lang-menu__menu-item 
                    ${(key === lang) ? 'lang-menu__menu-item--active' : ''}
                    bg-color-transition`}
                >
                  {languages[key].icon}

                  <Typography
                    variant="xs"
                    label={languages[key].label}
                  />
                </div>
              ))
            }
          </div>
        ) : null
      }
    </div>
  )
}

LangMenu.propTypes = {
  isWhite: PropTypes.bool,
  isNotLogged: PropTypes.bool,
}

LangMenu.defaultProps = {
  isWhite: false,
  isNotLogged: false,
}

export default LangMenu
