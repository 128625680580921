import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStoreState, useStoreActions } from 'easy-peasy'
import dayjs from 'dayjs'
import Tooltip from '@mui/material/Tooltip'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'dayjs/locale/de'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'dayjs/locale/en'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'dayjs/locale/it'

import { lang, Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import RadioGroup from '../../../../components/RadioGroup'
import Checkbox from '../../../../components/Checkbox'
import Button from '../../../../components/Button'

import { ReactComponent as ListSvg } from '../../../../svg/list.svg'
import { ReactComponent as FilterInfoSvg } from '../../../../svg/filter_info.svg'
import { ReactComponent as RepeatSvg } from '../../../../svg/repeat.svg'
import { ReactComponent as WorkflowsSvg } from '../../../../svg/workflows.svg'
import { ReactComponent as CheckSlimSvg } from '../../../../svg/check-slim.svg'

import './index.scss'

const FilterBox = ({
  setIsApplyFilter,
  isClearData,
  setIsClearData,
  filterOptions,
  handleFilterOptions,
  onClose,
  filterBoxRef,
}) => {
  const getDetailedUser = useStoreActions((actions) => actions.user.meWithParams)
  const getTemplates = useStoreActions((state) => state.user.getTemplates)
  const templates = useStoreState((state) => state.user.templates)

  const [filterBoxData, setFilterBoxData] = useState(filterOptions || {
    filter_order_status: '',
    filter_date_from: null,
    filter_date_till: null,
    filter_tid: [],
  })

  useEffect(() => {
    getDetailedUser({ with_assigned_templates: 1 })
    getTemplates()
  }, [])

  const orderStatusValue = {
    '': Translation.all_orders,
    approval: Translation.approval_required,
    redo: Translation.in_redo,
    open: Translation.open_orders,
    completed: Translation.completed_orders,
  }

  const handleOrderStatusChange = (e) => {
    setFilterBoxData((prevData) => ({ ...prevData, filter_order_status: e.target.value }))
  }

  const handleTemplateChange = (id) => {
    setFilterBoxData((prevData) => ({
      ...prevData,
      filter_tid:
        prevData.filter_tid.includes(id)
          ? prevData.filter_tid.filter((template) => template !== id)
          : [...prevData.filter_tid, id],
    }))
  }

  const handleResetFilter = () => {
    setIsApplyFilter(false)

    setFilterBoxData({
      filter_order_status: '',
      filter_date_from: null,
      filter_date_till: null,
      filter_tid: [],
    })
    handleFilterOptions({
      filter_order_status: '',
      filter_date_from: null,
      filter_date_till: null,
      filter_tid: [],
    })
  }

  useEffect(() => {
    if (isClearData) {
      handleResetFilter()
      setIsClearData(false)
    }
  }, [isClearData])

  const handleMouseDown = (e) => {
    // if we click the datepicker, we don't want to close the filter box
    if (filterBoxRef.current && !filterBoxRef.current.contains(e.target)) {
      const datePickerExists = document.querySelector('.MuiPickersPopper-root')
      if ((datePickerExists && !datePickerExists.contains(e.target)) || !datePickerExists) {
        // hide datepicker if it exists
        if (datePickerExists) {
          datePickerExists.style.display = 'none'
        }
        onClose()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    const tempOption = {
      filter_order_status: '',
      filter_date_from: null,
      filter_date_till: null,
      filter_tid: [],
    }
    if (JSON.stringify(filterBoxData) === JSON.stringify(tempOption)) {
      setIsApplyFilter(false)
    } else {
      setIsApplyFilter(true)
    }
    handleFilterOptions(filterBoxData)
  }

  const templateNameMaxLength = 22

  const truncateTemplateName = (value, maxLength) => {
    if (value?.length > maxLength) {
      return (
        <Tooltip
          className="info-tooltip"
          PopperProps={{
            disablePortal: true,
          }}
          title={value}
          placement="top"
          arrow
        >
          <span className="xs roobert-medium">{`${value.substring(0, maxLength)}...`}</span>
        </Tooltip>
      )
    }

    return (
      <span className="xs roobert-medium">{value}</span>
    )
  }

  return (
    <form className="filter-box" onSubmit={handleSubmit}>
      <div className="filter-box-top">
        <Typography variant="xs" font="semibold" label={Translation.filter} />
        <button type="button" className="filter-box-reset" onClick={handleResetFilter}>
          <Typography variant="caption" font="semibold" label={Translation.reset_filter} />
        </button>
      </div>
      <div className="filter-box-date">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
          <DatePicker
            format="DD.MM.YYYY"
            value={dayjs(filterBoxData.filter_date_from)}
            onChange={(date) => {
              const dateFormatted = (new Date(date)).toString()
              setFilterBoxData((prevData) => ({ ...prevData, filter_date_from: dateFormatted }))
            }}
            maxDate={dayjs(new Date())}
          />
        </LocalizationProvider>
        <div className="filter-box-date-hyphen">-</div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
          <DatePicker
            format="DD.MM.YYYY"
            value={dayjs(filterBoxData.filter_date_till)}
            onChange={(date) => {
              const dateFormatted = (new Date(date)).toString()
              setFilterBoxData((prevData) => ({ ...prevData, filter_date_till: dateFormatted }))
            }}
            minDate={dayjs(filterBoxData.filter_date_from)}
            maxDate={dayjs(new Date())}
          />
        </LocalizationProvider>
      </div>
      <div className="filter-box-content scroll scrollbar-overflow">
        <div className="filter-box-radioGroup">
          <ul className="filter-box-radioGroup-icons">
            <li><ListSvg /></li>
            <li><FilterInfoSvg /></li>
            <li><RepeatSvg /></li>
            <li><ListSvg /></li>
            <li><CheckSlimSvg /></li>
          </ul>
          <RadioGroup
            name="orderStatus"
            value={filterBoxData.filter_order_status}
            values={orderStatusValue}
            color="gray"
            onChange={handleOrderStatusChange}
          />
        </div>
        <div className="filter-box-line" />
        {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
        <label htmlFor="image-search-no-template" className="filter-box-row cursor-default">
          <div className="filter-box-row-title cursor-default">
            <WorkflowsSvg className="workflow-icon" />
            <Typography variant="xs" font="medium" label={Translation.filter_by_workflows} />
          </div>
        </label>
        {
            templates?.map((template) => (
              <label key={template.id} htmlFor={`image-search-checkbox-${template.id}`} className="filter-box-row">
                <div className="filter-box-row-title">
                  <WorkflowsSvg className="workflow-icon" />
                  {
                      truncateTemplateName(template?.name, templateNameMaxLength)
                  }
                </div>
                <Checkbox
                  id={`image-search-checkbox-${template.id}`}
                  onChange={() => handleTemplateChange(template.id)}
                  checked={filterBoxData.filter_tid.includes(template.id)}
                />
              </label>
            ))
          }
      </div>
      <div className="filter-box-apply">
        <Button type="fit-content" onClick={handleSubmit} label={Translation.apply_filter} />
      </div>
    </form>
  )
}

FilterBox.propTypes = {
  setIsApplyFilter: PropTypes.func,
  isClearData: PropTypes.bool,
  setIsClearData: PropTypes.func,
  filterOptions: PropTypes.shape({
    filter_order_status: PropTypes.string,
    filter_date_from: PropTypes.string,
    filter_date_till: PropTypes.string,
    filter_tid: PropTypes.arrayOf(PropTypes.string),
  }),
  handleFilterOptions: PropTypes.func,
  onClose: PropTypes.func,
  filterBoxRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
}

FilterBox.defaultProps = {
  setIsApplyFilter: () => { },
  isClearData: false,
  setIsClearData: () => { },
  filterOptions: {
    filter_order_status: '',
    filter_date_from: null,
    filter_date_till: null,
    filter_tid: [],
  },
  handleFilterOptions: () => { },
  onClose: () => { },
  filterBoxRef: null,
}

export default FilterBox
