import React from 'react'

import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'

import { ReactComponent as DoopicIcon } from '../../../../svg/logo_icon.svg'

const PaymentInProcess = () => (
  <div className="after-payment-status">
    <DoopicIcon className="doopic-icon" />
    <div className="title">
      <Typography
        variant="xl"
        font="semibold"
        theme="dark"
        label={Translation.your_payment_being_processed}
      />
    </div>
  </div>
)

export default PaymentInProcess
