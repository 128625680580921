import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import Typography from '../../../../components/Typography'
import Switch from '../../../../components/Switch'

import InfoModal from '../InfoModal'

import './index.scss'

const SectionTop = ({
  showContent,
  onChange,
  id,
  icon,
  defaultChecked,
  labelText,
  modalText,
  badgeText,
  disabled,
}) => {
  const switchRef = useRef(null)

  const onClickRow = (e) => {
    if (typeof e.target?.className === 'string' && e.target?.className?.includes('section-top')) switchRef.current.click()
  }

  return (
    <div className="section-top" onClick={onClickRow}>
      { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id} style={{ cursor: disabled ? 'default' : 'pointer' }}>
        <div className="section-top-icon">
          {icon}
        </div>
        <Switch
          id={id}
          onChange={(value) => onChange(id, value)}
          color={showContent ? 'white' : 'default'}
          checked={defaultChecked}
          disabled={disabled}
          switchRef={switchRef}
        />
        <Typography variant="xs" label={labelText} />
        {
          badgeText && <span className="price-badge">{badgeText}</span>
        }
      </label>
      {
        modalText && <InfoModal labelText={labelText} modalText={modalText} />
      }
    </div>
  )
}

SectionTop.propTypes = {
  showContent: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  icon: PropTypes.element,
  defaultChecked: PropTypes.bool.isRequired,
  labelText: PropTypes.string,
  modalText: PropTypes.string,
  badgeText: PropTypes.string,
  disabled: PropTypes.bool,
}

SectionTop.defaultProps = {
  showContent: false,
  onChange: () => { },
  icon: null,
  labelText: '',
  modalText: '',
  badgeText: '',
  disabled: false,
}

export default SectionTop
