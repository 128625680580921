/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Tooltip from '@mui/material/Tooltip'

import { Translation } from '../../helpers/Translation'
import { clickToDownload } from '../../helpers/Utils'
import {
  DESKTOP_TABLE_WIDTH,
  DESKTOP_PX_TO_REM,
  PAYMENT_MODAL_TYPE,
  PAYMENT_SUCCESS_PAGE,
  BROWSER_SCROLLBAR_WIDTH,
  DEFAULT_PAGE,
} from '../../helpers/Constants'

import Table from '../../components/Table'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import Link from '../../components/Link'

import TableTopInfo from '../../layouts/Common/TableTopInfo'
import ManagePaymentMethods from '../../layouts/Common/ManagePaymentMethods'
import SepaInfoModal from '../../layouts/Common/SepaInfoModal'

import UnsavedChangesDialog from '../Profile/Common/UnsavedChangesDialog'

import { ReactComponent as DownloadSvg } from '../../svg/download.svg'

import './index.scss'

const Checkout = () => {
  // init all component actions
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const unpaidData = useStoreState((state) => state.order.unpaidData)
  const getUnpaidData = useStoreActions((actions) => actions.order.getUnpaidData)
  const downloadInvoice = useStoreActions((actions) => actions.invoice.downloadInvoice)
  const getPaymentMethods = useStoreActions((state) => state.invoice.getPaymentMethods)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [paymentModalState, setPaymentModalState] = useState(PAYMENT_MODAL_TYPE.paymentList)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [navigateTo, setNavigateTo] = useState(null)
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false)
  const [sepaInfo, setSepaInfo] = useState(null)
  const [isSepaInformationModalOpen, setIsSepaInformationModalOpen] = useState(false)

  useEffect(() => {
    getUnpaidData()
    if (
      searchParams.get('paypal_payment_cancelled') === '1'
      || searchParams.get('creditcard_payment_cancelled') === '1'
    ) {
      setPaymentModalState(PAYMENT_MODAL_TYPE.paymentError)
      setIsModalOpen(true)
    }
    if (
      searchParams.get('paypal_payment_success') === '1'
      || searchParams.get('creditcard_payment_success') === '1'
    ) {
      navigate(PAYMENT_SUCCESS_PAGE)
    }
  }, [])

  const orderNameMaxLength = 28

  const colWidth = [115, 410, 141, 135, 168, 125]
  // last column width is calculated as the rest of available table width
  colWidth.push(DESKTOP_TABLE_WIDTH - BROWSER_SCROLLBAR_WIDTH - colWidth.reduce((a, b) => a + b))

  const columns = [
    { id: 'date', label: Translation.date, width: colWidth[0] / DESKTOP_PX_TO_REM },
    {
      id: 'description',
      render: 'jsx',
      label: Translation.description,
      width: colWidth[1] / DESKTOP_PX_TO_REM,
    },
    { id: 'order_count', label: Translation.order_count, width: colWidth[2] / DESKTOP_PX_TO_REM },
    { id: 'image_count', label: Translation.image_count, width: colWidth[3] / DESKTOP_PX_TO_REM },
    {
      id: 'amount',
      render: 'jsx',
      label: Translation.amount_excl_vat,
      width: colWidth[4] / DESKTOP_PX_TO_REM,
    },
    { id: 'vat', label: Translation.vat, width: colWidth[5] / DESKTOP_PX_TO_REM },
    { id: 'total', label: Translation.total, width: colWidth[6] / DESKTOP_PX_TO_REM },
  ]

  const rowCellAmount = (discountPrice, price, currencySign) => (
    <div className={`checkout-table-amount ${discountPrice !== '0,00' ? 'hasDiscount' : ''}`}>
      <span className={`checkout-table-amount-price ${discountPrice !== '0,00' ? 'hasDiscount' : ''}`}>
        {`${price} ${currencySign}`}
      </span>
      {
        discountPrice !== '0,00' && (
          <span className="checkout-table-amount-discount">
            {`Discount: -${discountPrice} ${currencySign}`}
          </span>
        )
      }
    </div>
  )

  const handleSureToLeave = () => {
    if (navigateTo) {
      navigate(navigateTo)
      setNavigateTo(null)
    }
    setPaymentModalState(PAYMENT_MODAL_TYPE.paymentList)
    setIsModalOpen(false)
    setLeaveDialogOpen(false)
    setIsFormChanged(false)
  }

  const handleClickCancel = () => {
    setLeaveDialogOpen(false)
    setNavigateTo(null)
    setIsModalOpen(true)
  }

  const handleCloseInfoModal = () => {
    setSepaInfo(null)
    setIsSepaInformationModalOpen(false)
    setIsModalOpen(true)
  }

  const onClickOutside = (e) => {
    if (e.target?.outerHTML?.includes('modal--wrap')) {
      setIsSepaInformationModalOpen(false)
      setSepaInfo(null)
      setIsModalOpen(true)
    }
  }

  const onClickPayment = async () => {
    const res = await getPaymentMethods()
    if (res.length) setPaymentModalState(PAYMENT_MODAL_TYPE.paymentList)
    else setPaymentModalState(PAYMENT_MODAL_TYPE.form)
    setIsModalOpen(true)
    import('lottie-react')
  }

  const handleSepaInfoModal = (sepa) => {
    if (sepa) {
      setSepaInfo(sepa)
      setIsModalOpen(false)
      setIsSepaInformationModalOpen(true)
    }
  }

  const handleInvoiceDownload = async (e, invoiceId) => {
    e.preventDefault()
    e.stopPropagation()
    const result = await downloadInvoice({ id: invoiceId })
    if (result === false) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.something_went_wrong,
        type: 'error',
      })
      return
    }

    if (result.success) {
      clickToDownload(result.invoice_url)
    }
  }

  const truncateOrderName = (value, maxLength) => {
    if (value.length > maxLength) {
      return (
        <Tooltip
          className="info-tooltip"
          PopperProps={{
            disablePortal: true,
          }}
          title={value}
          placement="top"
          arrow
        >
          <span>{`${value.substring(0, maxLength)}...`}</span>
        </Tooltip>
      )
    }

    return value
  }

  const rowCellDescription = (text, id, name, isNameLink, icon) => (
    <div className="checkout-table-description">
      {isNameLink ? `${text}: ` : `${text}: #${name}`}
      <span className={`checkout-table-description-link ${icon ? 'icon' : ''}`}>
        <Link
          href={icon ? '' : `${DEFAULT_PAGE}?order=${id}`}
          onClick={icon ? (e) => handleInvoiceDownload(e, name) : null}
          isNewTab
          {...(isNameLink && { underline: true })}
          label={(
            <>
              {
                isNameLink ? truncateOrderName(name, orderNameMaxLength) : ''
              }
              {
                icon && <DownloadSvg className="checkout-table-description-link-icon" />
              }
            </>
          )}
        />
      </span>
    </div>
  )

  const transformData = () => {
    const transformedData = []

    unpaidData?.orders_wo_invoice?.forEach((item) => {
      transformedData.push({
        id: transformedData.length + 1,
        date: item.date_from,
        description: rowCellDescription(Translation.order_name, item.id, item.name, true, false),
        order_count: item.orders_count,
        image_count: item.image_count,
        amount: rowCellAmount(item.discount_price, item.net_price, item.currency_sign),
        vat: `${item.vat_price} ${item.currency_sign}`,
        total: `${item.price} ${item.currency_sign}`,
      })
    })

    unpaidData?.invoices?.forEach((item) => {
      transformedData.push({
        id: transformedData.length + 1,
        date: item.date_from,
        description: rowCellDescription(Translation.invoice_number, '', item.id, false, true),
        order_count: item.orders_count,
        image_count: item.image_count,
        amount: rowCellAmount(item.discount_price, item.net_price, item.currency_sign),
        vat: `${item.vat_price} ${item.currency_sign}`,
        total: `${item.price} ${item.currency_sign}`,
      })
    })

    return transformedData
  }

  return (
    <div className="checkout-page">
      <TableTopInfo
        title={Translation.unpaid_orders_invoices}
        description={Translation.unpaid_orders_invoices_description}
      />

      {
        unpaidData && (
          <Table
            data={transformData()}
            columns={columns}
            count={transformData().length}
            cellHeight="small"
            showPagination={false}
            showRowsPerPage={false}
            emptyDataText="you_account_is_paid"
          />
        )
      }

      {
        unpaidData && unpaidData?.final_price_no_format !== 0 && (
          <>
            <div className="table-bottom-totalPrice">
              <div className="table-bottom-totalPrice-title">
                <Typography
                  variant="xs-narrow"
                  label={`${Translation.total_price}:`}
                  font="semibold"
                />
              </div>
              <Typography
                variant="xs-narrow"
                label={`${unpaidData?.final_price} ${unpaidData?.currency_sign}`}
                font="semibold"
              />
            </div>
            <div className="table-bottom-btn-wrapper">
              <Button
                label={Translation.proceed_to_payment}
                width="auto"
                onClick={onClickPayment}
              />
            </div>
          </>
        )
      }

      <div className="payment-methods-modal">
        <ManagePaymentMethods
          isOpen={isModalOpen}
          paymentModalState={paymentModalState}
          setPaymentModalState={setPaymentModalState}
          closeModal={() => setIsModalOpen(false)}
          isFormChanged={isFormChanged}
          setIsFormChanged={setIsFormChanged}
          setNavigateTo={setNavigateTo}
          unpaidData={unpaidData}
          openSepaInfoModal={handleSepaInfoModal}
          leaveDialogOpen={leaveDialogOpen}
          setLeaveDialogOpen={setLeaveDialogOpen}
        />
      </div>

      <SepaInfoModal
        handleCloseInfoModal={handleCloseInfoModal}
        isSepaInformationModalOpen={isSepaInformationModalOpen}
        onClickOutside={onClickOutside}
        sepaInfo={sepaInfo}
      />

      <UnsavedChangesDialog
        leaveDialogOpen={leaveDialogOpen}
        handleLeaveApprove={handleSureToLeave}
        handleClickCancel={handleClickCancel}
      />
    </div>
  )
}

export default Checkout
