import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../../../components/Modal'

import './index.scss'

const SepaInfoModal = ({
  isSepaInformationModalOpen,
  handleCloseInfoModal,
  onClickOutside,
  sepaInfo,
}) => (
  <div className="sepa-info-modal">
    <Modal
      isShown={isSepaInformationModalOpen}
      hasCloseIcon
      onClickCloseIcon={handleCloseInfoModal}
      onClickOutside={onClickOutside}
      onClickEscClose={handleCloseInfoModal}
      size="large"
    >
      <div
        className="sepa-info-modal-content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: sepaInfo?.mandate_html }}
      />
    </Modal>
  </div>
)

SepaInfoModal.propTypes = {
  isSepaInformationModalOpen: PropTypes.bool,
  handleCloseInfoModal: PropTypes.func,
  onClickOutside: PropTypes.func,
  sepaInfo: PropTypes.objectOf(PropTypes.string),
}

SepaInfoModal.defaultProps = {
  isSepaInformationModalOpen: false,
  handleCloseInfoModal: () => { },
  onClickOutside: () => { },
  sepaInfo: {},
}

export default SepaInfoModal
