import React, { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import PropTypes from 'prop-types'

import { localizeDate } from '../../../../helpers/Utils'
import { ORDER_STATUS, PX_TO_REM } from '../../../../helpers/Constants'
import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'

import { ReactComponent as ClockSvg } from '../../../../svg/clock.svg'
import { ReactComponent as ShowSvg } from '../../../../svg/show.svg'
import { ReactComponent as ScissorsSvg } from '../../../../svg/scissors.svg'
import { ReactComponent as AlarmSvg } from '../../../../svg/alarm.svg'
import { ReactComponent as ArrowUpCircle } from '../../../../svg/arrow_up_circle.svg'

import './index.scss'

const OrderDetailsTop = ({ orderDetails }) => {
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const getPrioritiseOrder = useStoreActions((actions) => actions.order.getPrioritiseOrder)
  const updateSnackbarState = useStoreActions((actions) => actions.global.updateSnackbarState)
  const updateOrderIsPrioritised = useStoreActions((actions) => actions.order.updateOrderIsPrioritised)

  const [isLoadingPrioritise, setIsLoadingPrioritise] = useState(false)
  const [isPrioritised, setIsPrioritised] = useState()

  useEffect(() => {
    if (orderDetails?.id) {
      setIsPrioritised(orderDetails?.is_prioritised)
    }
  }, [orderDetails?.id])

  const handlePrioritiseOrder = async () => {
    setIsLoadingPrioritise(true)
    const result = await getPrioritiseOrder({ id: orderDetails?.id, status: !isPrioritised })
    setIsLoadingPrioritise(false)

    if (result) {
      updateOrderIsPrioritised({ id: orderDetails?.id, is_prioritised: !isPrioritised })
      setIsPrioritised(!isPrioritised)

      updateSnackbarState({
        message: isPrioritised ? Translation.unprioritise_success_text : Translation.prioritise_success_text,
        isOpen: true,
        type: 'success',
      })
    } else {
      updateSnackbarState({
        message: isPrioritised ? Translation.unprioritise_error_text : Translation.prioritise_error_text,
        isOpen: true,
        type: 'error',
      })
    }
  }

  return (
    !isMobile && (
      <div className="content-top">
        <div className="content-top-column">
          <ClockSvg />
          <Typography variant="xs" label={`${Translation.turnaround}: ${orderDetails.turnaround}h`} />
        </div>
        <div className="content-top-column">
          <ShowSvg />
          <Typography
            variant="xs"
            label={`${Translation.received}: ${localizeDate(orderDetails.received_timestamp)}`}
          />
        </div>
        {/* if the accepted timestamp is null have opacity */}
        <div className="content-top-column" style={{ opacity: orderDetails.accepted_timestamp === null ? '0.5' : '1' }}>
          <ScissorsSvg />
          <Typography
            variant="xs"
            label={`${Translation.accepted}:
              ${orderDetails.accepted_timestamp === null ? '-' : localizeDate(orderDetails.accepted_timestamp)}`}
          />
        </div>
        {
          orderDetails?.order_status_id < ORDER_STATUS.ORDER_STATUS_COMPLETED ? (
            <div className="content-top-column" style={{ opacity: orderDetails.delivery_timestamp === null ? '0.5' : '1' }}>
              <AlarmSvg />
              <Typography
                variant="xs"
                label={`${Translation.eta_delivery}:
                ${orderDetails.delivery_timestamp === null ? '-' : localizeDate(orderDetails.delivery_timestamp)}`}
              />
            </div>
          ) : null
        }
        {
          orderDetails?.is_prioritised_client ? (
            <div className="btn-prioritise-container">
              <button
                type="button"
                className={
                  `content-top-column btn-prioritise 
                ${isPrioritised ? 'unPrioritise' : ''} ${isLoadingPrioritise ? 'disabled' : ''}`
                }
                onClick={handlePrioritiseOrder}
                disabled={isLoadingPrioritise}
              >
                <Typography
                  variant="xs"
                  fontSize={PX_TO_REM['10']}
                  label={isPrioritised ? Translation.remove_order_priority : Translation.prioritise_order}
                />
                <div style={{ display: 'flex', transform: isPrioritised ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                  <ArrowUpCircle />
                </div>
              </button>
            </div>
          ) : <div style={{ width: '8rem' }} />
        }

      </div>
    )
  )
}

OrderDetailsTop.propTypes = {
  orderDetails: PropTypes.shape({
    id: PropTypes.number,
    order_status_id: PropTypes.number,
    is_prioritised: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    is_prioritised_client: PropTypes.bool,
    turnaround: PropTypes.number,
    received_timestamp: PropTypes.string,
    accepted_timestamp: PropTypes.string,
    delivery_timestamp: PropTypes.string,
  }),
}

OrderDetailsTop.defaultProps = {
  orderDetails: {},
}

export default OrderDetailsTop
