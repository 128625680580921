import React, { useEffect, useState, useRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

import { Translation, languages } from '../../../helpers/Translation'
import { ReactComponent as PlusIcon } from '../../../svg/plus.svg'
import {
  DESKTOP_PX_TO_REM, DESKTOP_TABLE_WIDTH, PX_TO_REM, TABLES,
} from '../../../helpers/Constants'
import { getPerPage, isClientAdminRole, savePerPage } from '../../../helpers/Utils'

import Table from '../../../components/Table'
import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import Dialog from '../../../components/Dialog'

import TableTopInfo from '../../../layouts/Common/TableTopInfo'
import ProfilePicture from '../../../layouts/Common/ProfilePicture'
import MobileView from '../../../layouts/Common/MobileView'
import UnsavedChangesDialog from '../Common/UnsavedChangesDialog'

import UserModal from './UserModal'

import { ReactComponent as WarningSvg } from '../../../svg/warning.svg'
import { ReactComponent as EditSvg } from '../../../svg/menu-edit.svg'
import { ReactComponent as TrashSvg } from '../../../svg/menu-trash.svg'
import { ReactComponent as ThreeDots } from '../../../svg/three_dots.svg'

import './index.scss'

const UserManagement = () => {
  const navigate = useNavigate()

  const activeUser = useStoreState((state) => state.user.user)
  const userList = useStoreState((state) => state.user.userList)
  const templates = useStoreState((state) => state.user.templates)
  const userCount = useStoreState((state) => state.user.userCount)
  const getUsers = useStoreActions((state) => state.user.getUsers)
  const getUser = useStoreActions((state) => state.user.getUser)
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const userDelete = useStoreActions((state) => state.user.userDelete)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)
  const userAccountForgot = useStoreActions((state) => state.user.userAccountForgot)
  const getTemplates = useStoreActions((state) => state.user.getTemplates)

  const [langList, setLangList] = useState({})
  const [selectedUser, setSelectedUser] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(getPerPage(TABLES.USER_MANAGEMENT))
  const [page, setPage] = useState(1)
  const [activeUserList, setActiveUserList] = useState(userList)
  const [userToBeDeleted, setUserToBeDeleted] = useState(null)
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTab, setModalTab] = useState(true) // true for user, false for template
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false)
  const [navigateTo, setNavigateTo] = useState(null)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [isShowRowActions, setIsShowRowActions] = useState(false)

  const actionsBoxRef = useRef(null)

  const handleOnClickOutside = (e) => {
    if (actionsBoxRef.current && !actionsBoxRef.current.contains(e.target)) {
      setIsShowRowActions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOnClickOutside)
    return () => {
      document.removeEventListener('click', handleOnClickOutside)
    }
  })

  const toggleIsShowRowActions = (e, id) => {
    e.stopPropagation()
    setIsShowRowActions((prev) => (prev === id ? false : id))
  }

  const getUserList = async () => {
    await getUsers({ page, limit: rowsPerPage })
  }

  useEffect(() => {
    if (activeUser && !isClientAdminRole(activeUser?.role_after_login)) {
      navigate('/profile#my-details')
    }
  }, [activeUser])

  useEffect(() => {
    if (isClientAdminRole(activeUser?.role_after_login)) {
      getUserList().then(() => { })
      savePerPage(TABLES.USER_MANAGEMENT, rowsPerPage)
    }
  }, [page, rowsPerPage])

  useEffect(() => {
    setActiveUserList(userList)
  }, [userList])

  useEffect(() => {
    const languageList = {}
    Object.keys(languages).forEach((key) => {
      languageList[key] = languages[key].label
    })
    setLangList(languageList)
  }, [])

  const colWidth = [300, 250, 520]
  // last column width is calculated as the rest of available table width
  colWidth.push(DESKTOP_TABLE_WIDTH - colWidth.reduce((a, b) => a + b))

  const tableColumns = [
    {
      id: 'name',
      label: Translation.name,
      render: 'jsx',
      sortable: false,
      width: colWidth[0] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'email',
      label: Translation.email_address,
      sortable: false,
      width: colWidth[1] / DESKTOP_PX_TO_REM,
      truncateLength: 25,
    },
    {
      id: 'assignedTemplates',
      label: Translation.assigned_workflows,
      render: 'jsx',
      sortable: false,
      width: colWidth[2] / DESKTOP_PX_TO_REM,
    },
    {
      id: 'warningEditDelete',
      label: null,
      render: 'jsx',
      sortable: false,
      width: colWidth[3] / DESKTOP_PX_TO_REM,
    },
  ]

  const onRowsPerPageChange = (value) => {
    setRowsPerPage(value)
  }

  const onPageChange = (value) => {
    setPage(value)
  }

  const onAddUser = async () => {
    await getTemplates()
    setModalTab(true)
    setModalTab(true)
    setSelectedUser({})
    setTimeout(() => {
      setIsModalOpen(true)
    }, 100)
  }

  const onAddTemplates = async (userId) => {
    const [user] = await Promise.all([getUser({ userId }), getTemplates()])
    setSelectedUser(user)
    setModalTab(false)
    setTimeout(() => {
      setIsModalOpen(true)
    }, 100)
  }

  const updateUser = (user) => {
    if (user.id === selectedUser.id) setSelectedUser(user)
    const index = userList.findIndex((item) => item.id === user.id)
    const newUserList = [...userList]
    newUserList[index] = user
    setActiveUserList(newUserList)
  }

  const handleEdit = async (val) => {
    const [user] = await Promise.all([getUser({ userId: val.id }), getTemplates()])
    setSelectedUser(user)
    setModalTab(true)
    setTimeout(() => {
      setIsModalOpen(true)
    }, 100)
  }

  const handleDelete = (val) => {
    setUserToBeDeleted(val)
    setIsRemoveDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    const res = await userDelete({
      userId: userToBeDeleted.id,
    })
    if (res) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.deleted_successfully,
        type: 'success',
      })
      setIsRemoveDialogOpen(false)
      if (userList.length === 1 && userCount > 1) setPage(1)
      else getUserList().then(() => { })
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.something_went_wrong,
        type: 'error',
      })
    }
  }

  const closeUserModal = () => {
    setIsModalOpen(false)
  }

  const handleResendEmail = async (id) => {
    const result = await userAccountForgot({
      id,
    })

    if (result) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.email_sent_successfully,
        type: 'success',
      })
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.something_went_wrong,
        type: 'error',
      })
    }
  }

  const setShownTemplates = (user) => {
    let characterCount = 0
    const shownTemplates = []
    user.assigned_templates.forEach((template) => {
      const templateNameLength = template?.name.length
      if (characterCount + templateNameLength > 58) return
      shownTemplates.push(template)
      characterCount += templateNameLength
    })
    if (user.assigned_templates.length - shownTemplates.length > 0) {
      shownTemplates.push({
        id: 'more',
        name: `+${user.assigned_templates.length - shownTemplates.length}`,
      })
    }
    return shownTemplates
  }

  const getName = (user) => {
    const fullName = `${user.firstname} ${user.lastname}`
    // truncate name if more than 35 characters
    if (fullName.length > 35) {
      return (
        <Tooltip
          arrow
          className="info-tooltip"
          PopperProps={{
            disablePortal: true,
          }}
          placement="top"
          disableFocusListener
          disableTouchListener
          title={fullName}
        >
          <span style={{ cursor: 'pointer' }}>
            <Typography
              label={`${fullName.substring(0, 30)}...`}
              theme="dark"
              variant="xs"
              noWrap
            />
          </span>
        </Tooltip>
      )
    }
    return (
      <Typography
        label={fullName}
        theme="dark"
        variant="xs"
        noWrap
      />
    )
  }

  const transformData = () => {
    const tempData = []

    activeUserList.forEach((user) => {
      tempData.push({
        ...user,
        name: (
          <div className="user-info-content">
            <ProfilePicture
              avatarUrl={user.avatar_url}
              firstname={user.firstname}
              lastname={user.lastname}
              bgColor={user.avatar_bg ?? null}
              width={3.0769230}
              height={3.0769230}
            />
            {getName(user)}
            {isClientAdminRole(user?.role_after_login) && <Chip className="admin-user-chip" label={Translation.admin} />}
          </div>
        ),
        assignedTemplates:
          user.assigned_templates.length < 1
            ? (
              !isClientAdminRole(user?.role_after_login) && (
                <Tooltip
                  arrow
                  className="info-tooltip <span style={{ cursor: 'pointer' }}>"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  title={Translation.click_to_assign_workflows}
                >
                  <div className="add-template-button">
                    <Button label={<PlusIcon />} onClick={() => onAddTemplates(user.id)} />
                  </div>
                </Tooltip>
              )
            )
            : (
              <div className="template-chips-container">
                {
                  !isClientAdminRole(user?.role_after_login) && setShownTemplates(user).map((template) => (
                    <Tooltip
                      arrow
                      className="info-tooltip"
                      PopperProps={{
                        disablePortal: true,
                      }}
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      title={Translation.click_to_view_all}
                      key={template.id}
                    >
                      <Chip
                        className="template-chip"
                        label={template?.name}
                        variant="outlined"
                        onClick={() => onAddTemplates(user.id)}
                      />
                    </Tooltip>
                  ))
                }
              </div>
            ),
        warningEditDelete: (
          <div className="table-row-cell-buttons">
            {user.pending_confirmation !== null && (
              <Tooltip
                arrow
                className="info-tooltip warning-tooltip"
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                disableFocusListener
                disableTouchListener
                title={
                  (
                    <Typography variant="body1" inlineBlock>
                      {Translation.email_not_confirmed_click}
                      {' '}
                      <button type="button" onClick={() => handleResendEmail(user.id)}>{Translation.here}</button>
                      {' '}
                      {Translation.resend_email_with_code}
                    </Typography>
                  )
                }
              >
                <button type="button" className="table-row-cell-buttons-warning">
                  <WarningSvg />
                </button>
              </Tooltip>
            )}
            <div className="more-actions" ref={actionsBoxRef}>
              <button
                type="button"
                className="more-actions-icon"
                onClick={(e) => toggleIsShowRowActions(e, user.id)}
              >
                <ThreeDots />
              </button>
              <div className="more-actions-content" style={{ display: isShowRowActions === user.id ? 'block' : 'none' }}>
                <button
                  type="button"
                  className="more-actions-content-row edit-icon"
                  onClick={() => handleEdit(user)}
                >
                  <EditSvg />
                  <Typography
                    variant="xs"
                    label={Translation.edit_user}
                  />
                </button>
                <button
                  type="button"
                  className="more-actions-content-row delete-icon"
                  onClick={() => handleDelete(user)}
                >
                  <TrashSvg />
                  <Typography
                    variant="xs"
                    label={Translation.delete_user}
                  />
                </button>
              </div>
            </div>
          </div>
        ),
      })
    })

    if (tempData.length === 0) {
      tempData.push({
        id: 0,
        emptyDataText: Translation.you_have_not_added_any_other_users,
      })
    }

    return tempData
  }

  const handleSureToLeave = () => {
    if (navigateTo) {
      navigate(navigateTo)
      setNavigateTo(null)
    }
    setSelectedUser(null)
    setIsModalOpen(false)
    setLeaveDialogOpen(false)
    setIsFormChanged(false)
  }

  const handleClickCancel = () => {
    setLeaveDialogOpen(false)
    setNavigateTo(null)
    setIsModalOpen(true)
  }

  if (activeUserList === null) return null

  return !isMobile ? (
    <div className="user-management-section">
      <TableTopInfo
        title={Translation.user_management}
        titleFontSize={PX_TO_REM['16']}
        description={Translation.create_and_manage_users}
        chipText={
          userCount === 1
            ? `${userCount} ${Translation.User}`
            : `${userCount} ${Translation.Users}`
        }
      />
      <div className="add-button-container">
        <Button
          type="outlined"
          size="s"
          onClick={onAddUser}
          label={(
            <div className="add-button-inner">
              <PlusIcon />
              <Typography label={Translation.add_user} />
            </div>
          )}
        />
      </div>
      <div className="table-wrapper">
        <Table
          data={transformData()}
          count={userCount}
          columns={tableColumns}
          onPageChange={onPageChange}
          rowsPerPageValue={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          emptyDataText="you_have_not_added_any_other_users"
          scrollable={false}
          tableContainerStyle={{ overflow: userCount < 3 ? 'revert' : '' }}
          cellHeight="small"
        />
      </div>
      {isModalOpen && (
        <UserModal
          open={isModalOpen}
          user={selectedUser || {}}
          closeModal={() => closeUserModal()}
          updateUser={updateUser}
          activeTab={modalTab}
          refreshTable={() => getUserList()}
          templates={templates}
          leaveDialogOpen={leaveDialogOpen}
          setLeaveDialogOpen={setLeaveDialogOpen}
          setNavigateTo={setNavigateTo}
          languageList={langList}
          isFormChanged={isFormChanged}
          setIsFormChanged={setIsFormChanged}
        />
      )}
      {isRemoveDialogOpen && (
        <Dialog
          isShown={isRemoveDialogOpen}
          title={`${Translation.delete_user}?`}
          content={Translation.sure_to_delete}
          cancelLabel={Translation.cancel}
          yesLabel={Translation.delete}
          onClickCancel={() => setIsRemoveDialogOpen(false)}
          onClickEscClose={() => setIsRemoveDialogOpen(false)}
          onClickYes={handleDeleteConfirm}
          isWarning
        />
      )}
      <UnsavedChangesDialog
        leaveDialogOpen={leaveDialogOpen}
        handleLeaveApprove={handleSureToLeave}
        handleClickCancel={handleClickCancel}
      />
    </div>
  ) : (
    <MobileView
      label={Translation.go_to_desktop_for_user_management}
    />
  )
}

export default UserManagement
