import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'
import { FormControlLabel, FormGroup } from '@mui/material'

import { Translation } from '../../../../helpers/Translation'
import {
  isValidValue,
  removeInputError,
  validateCardCVC,
  validateCardNumber,
  validateMonthYear,
} from '../../../../helpers/Utils'
import { getCardType } from '../../../../helpers/Card'
import {
  BILLING_PAGE,
  FIELDS_PATTERNS,
  PAYMENT_MODAL_TYPE,
  PAYMENT_SUCCESS_PAGE,
  PX_TO_REM,
} from '../../../../helpers/Constants'

import Input from '../../../../components/Input'
import Select from '../../../../components/Select'
import Typography from '../../../../components/Typography'
import Checkbox from '../../../../components/Checkbox'
import Link from '../../../../components/Link'
import Button from '../../../../components/Button'

import { ReactComponent as CardIcon } from '../../../../svg/card.svg'
import { ReactComponent as PaypalIcon } from '../../../../svg/paypal.svg'
import { ReactComponent as GiropayIcon } from '../../../../svg/giropay.svg'
import { ReactComponent as SofortIcon } from '../../../../svg/sofort.svg'
import { ReactComponent as FileIcon } from '../../../../svg/file.svg'
import { ReactComponent as SepaIcon } from '../../../../svg/sepa.svg'
import { ReactComponent as VisaIcon } from '../../../../svg/visa.svg'
import { ReactComponent as AmexIcon } from '../../../../svg/amex.svg'
import { ReactComponent as MasterCardOnlyIcon } from '../../../../svg/mastercard_only_icon.svg'

import './index.scss'

const PaymentForm = ({
  closeModal,
  isFormChanged,
  setIsFormChanged,
  setNavigateTo,
  setLeaveDialogOpen,
  setPaymentModalState,
  isCheckout,
}) => {
  const navigate = useNavigate()

  const ibanValidationInfo = useStoreState((state) => state.invoice.ibanValidationInfo)
  const paymentMethods = useStoreState((state) => state.invoice.paymentMethods)
  const unpaidData = useStoreState((state) => state.order.unpaidData)
  const createInvoice = useStoreActions((state) => state.invoice.createInvoice)
  const updateSnackbarState = useStoreActions((state) => state.global.updateSnackbarState)
  const getIbanInfo = useStoreActions((state) => state.invoice.getIbanInfo)
  const payWithCreditCard = useStoreActions((state) => state.invoice.payWithCreditCard)
  const payWithGiropay = useStoreActions((state) => state.invoice.payWithGiropay)
  const payWithSofort = useStoreActions((state) => state.invoice.payWithSofort)
  const savePaymentMethod = useStoreActions((state) => state.invoice.savePaymentMethod)
  const getPaymentMethods = useStoreActions((state) => state.invoice.getPaymentMethods)
  const setPaymentInProcess = useStoreActions((state) => state.invoice.setPaymentInProcess)
  const addIbanValidationInfo = useStoreActions((state) => state.invoice.addIbanValidationInfo)

  const countries = useStoreState((state) => state.user.countries)
  const [paymentType, setPaymentType] = useState('card') // card | sepa
  const [cardType, setCardType] = useState('')
  const [nameOnCard, setNameOnCard] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [expiryDate, setExpiryDate] = useState('')
  const [cvc, setCvc] = useState('')
  const [cvcMaxLength, setCvcMaxLength] = useState(3)
  const [iban, setIban] = useState('')
  const [bic, setBic] = useState('')
  const [bankName, setBankName] = useState('')
  const [isDifferentAddress, setIsDifferentAddress] = useState(false)
  const [title, setTitle] = useState('')
  const [salutation, setSalutation] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [address, setAddress] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [isSaveCard, setIsSaveCard] = useState(false)
  const [isFormErrors, setIsFormErrors] = useState({})
  const [formErrors, setFormErrors] = useState({
    nameOnCard: Translation.enter_valid_name_on_card,
    cardNumber: Translation.enter_valid_card_number,
    expiryDate: Translation.enter_valid_card_expiration_date,
    cvc: Translation.enter_valid_cvc_code,
    iban: Translation.this_field_is_required,
    bic: Translation.this_field_is_required,
    title: Translation.this_field_is_required,
    salutation: Translation.this_field_is_required,
    firstname: Translation.this_field_is_required,
    lastname: Translation.this_field_is_required,
    address: Translation.this_field_is_required,
    zip: Translation.this_field_is_required,
    city: Translation.this_field_is_required,
    country: Translation.this_field_is_required,
  })

  const paymentTypes = [
    {
      type: 'card',
      icon: <CardIcon />,
    },
    {
      type: 'paypal',
      icon: <PaypalIcon />,
    },
    {
      type: 'giropay',
      icon: <GiropayIcon />,
    },
    {
      type: 'sepa',
      icon: <SepaIcon />,
    },
    {
      type: 'sofort',
      icon: <SofortIcon />,
    },
    {
      type: 'file',
      icon: <FileIcon />,
    },
  ]

  useEffect(() => {
    if (paymentType === 'card') {
      if (nameOnCard || cardNumber || expiryDate || cvc) setIsFormChanged(true)
      else setIsFormChanged(false)
    } else if (paymentType === 'sepa' || paymentType === 'giropay') {
      if (iban || bic) setIsFormChanged(true)
      else setIsFormChanged(false)
    }
  }, [nameOnCard, cardNumber, expiryDate, cvc, iban, bic, paymentType])

  useEffect(() => {
    setIsFormErrors({})
  }, [paymentType])

  useEffect(() => {
    if (ibanValidationInfo) {
      if (ibanValidationInfo.valid) {
        setBankName(ibanValidationInfo.bankData.name)
        setBic(ibanValidationInfo.bankData.bic)
        setFormErrors({ ...formErrors, iban: Translation.this_field_is_required })
        setIsFormErrors({ ...isFormErrors, iban: false, bic: false })
      } else {
        setBankName('')
        setBic('')
        setFormErrors({ ...formErrors, iban: Translation.enter_valid_iban })
        setIsFormErrors({ ...isFormErrors, iban: true })
      }
    }
  }, [ibanValidationInfo])

  const adornment = () => {
    if (paymentType === 'card') {
      return (
        <>
          {cardType === 'visa' && <VisaIcon />}
          {cardType === 'mastercard' && <MasterCardOnlyIcon />}
          {cardType === 'amex' && <AmexIcon />}
        </>
      )
    }
    return null
  }

  const getCountries = () => {
    const countriesList = {}
    Object.keys(countries).forEach((key) => {
      countriesList[key] = countries[key].name
    })
    return countriesList
  }

  const resetForm = () => {
    setPaymentType('card')
    setNameOnCard('')
    setCardNumber('')
    setExpiryDate('')
    setCvc('')
    setIban('')
    setBic('')
    setBankName('')
    setTitle('')
    setSalutation('')
    setFirstname('')
    setLastname('')
    setAddress('')
    setZip('')
    setCity('')
    setCountry('')
    setIsFormChanged(false)
    setIsFormErrors({})
  }

  const handleCreateInvoice = async (e) => {
    e.preventDefault()
    const res = await createInvoice()
    if (res) {
      updateSnackbarState({
        isOpen: true,
        message: Translation.create_invoice_success,
        type: 'success',
      })
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.create_invoice_error,
        type: 'error',
      })
    }
    closeModal()
  }

  const handleNameChange = (e) => {
    const input = e.currentTarget
    if (!input.value || isValidValue(input.value, FIELDS_PATTERNS.onlyChars)) {
      setNameOnCard(input.value)
    }
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleCardNumberChange = (e) => {
    const input = e.currentTarget
    if (!input.value || isValidValue(input.value.replace(/\s/g, ''), FIELDS_PATTERNS.onlyNumber)) {
      const ct = getCardType(input.value.replace(/\s/g, ''))
      setCardType(ct)
      if (ct === 'amex') {
        setCvcMaxLength(4)
      } else {
        setCvcMaxLength(3)
      }
      setCardNumber((prev) => {
        let { value } = input

        if (prev.length < value.length) {
          if (value.length === 4 || value.length === 9 || value.length === 14) {
            value = `${input.value} `
          }
        } else if (prev.length > value.length && (prev.length === 5 || prev.length === 10 || prev.length === 15)) {
          value = input.value.replace(/.$/, '')
        } else {
          value = input.value
        }

        // when paste credit card
        if (value.length === 16 && !value.includes(' ')) {
          return value.match(/.{1,4}/g).join(' ')
        }

        return value
      })
    }
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const cardNumberBlur = (e) => {
    const input = e.currentTarget
    if (!input.value) return
    validateCardNumber(cardNumber, isFormErrors, setIsFormErrors)
  }

  const expirationDateBlur = (e) => {
    const input = e.currentTarget
    const [month, year] = input.value.split('/')
    if (input.value && !validateMonthYear(month, year)) {
      setIsFormErrors({ ...isFormErrors, expiryDate: true })
    }
  }

  const handleExpiryDateChange = (e) => {
    const input = e.currentTarget
    if (input.value.length > 5) return
    const code = e.keyCode
    const allowedKeys = [8]
    if (allowedKeys.indexOf(code) !== -1) return

    const newValue = input.value
      .replace(/^([1-9]\/|[2-9])$/g, '0$1/')
      .replace(/^(0[1-9]|1[0-2])$/g, '$1/')
      .replace(/^([0-1])([3-9])$/g, '0$1/$2')
      .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2')
      .replace(/^([0]+)\/|[0]+$/g, '0')
      .replace(/[^\d/]|^[/]*$/g, '')
      .replace(/\/\//g, '/')

    setExpiryDate((prev) => {
      if (input.value === prev.slice(0, -1)) {
        return input.value
      }
      return newValue
    })
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const cvcBlur = (e) => {
    const input = e.currentTarget
    if (!input.value) return
    const isValid = validateCardCVC(input.value)
    if (!isValid) {
      setIsFormErrors({ ...isFormErrors, cvc: true })
    }
  }

  const handleCvcChange = (e) => {
    const input = e.currentTarget
    if (!input.value || isValidValue(input.value, FIELDS_PATTERNS.onlyNumber)) {
      setCvc(input.value)
    }
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleIbanChange = (e) => {
    setBankName('')
    const input = e.currentTarget
    setIban(input.value.toUpperCase())
    const countryList = getCountries()
    const countryCode = input.value.toUpperCase().substring(0, 2)
    if (countryCode.length === 2 && countryList[countryCode]) {
      setCountry(countryCode)
    }
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleIbanBlur = async (e) => {
    const input = e.currentTarget
    if (input.value) await getIbanInfo(input.value)
  }

  const handleBicChange = (e) => {
    const input = e.currentTarget
    setBic(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleBillingPageClick = (e) => {
    e.preventDefault()
    navigate(BILLING_PAGE)
  }

  const handleValueChange = (e, setValue) => {
    const input = e.currentTarget
    setValue(input.value)
    removeInputError(input, isFormErrors, setIsFormErrors)
  }

  const handleSelectChange = (e, setValue, field) => {
    const input = e.target
    setValue(input.value)
    setIsFormErrors({ ...isFormErrors, [field]: false })
  }

  const handleModalClose = () => {
    if (paymentMethods.length === 0) {
      if (!isFormChanged) {
        resetForm()
        closeModal()
      } else {
        setNavigateTo(null)
        setLeaveDialogOpen(true)
        closeModal()
      }
    } else {
      setPaymentModalState(isCheckout ? PAYMENT_MODAL_TYPE.paymentList : PAYMENT_MODAL_TYPE.editList)
    }
  }

  const isCreditCardFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(nameOnCard, FIELDS_PATTERNS.cardName)) {
      isValid = false
      isFormErrorsUpdated.nameOnCard = !isValid
    }

    if (!validateCardNumber(cardNumber, isFormErrors, setIsFormErrors)) {
      isValid = false
      isFormErrorsUpdated.cardNumber = !isValid
    }

    if (expiryDate) {
      const [month, year] = expiryDate.split('/')
      if (!validateMonthYear(month, year)) {
        isValid = false
        isFormErrorsUpdated.expiryDate = !isValid
      }
    } else {
      isValid = false
      isFormErrorsUpdated.expiryDate = !isValid
    }

    if (!isValidValue(cvc, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.cvc = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const paymentAddSuccessful = () => {
    resetForm()
    getPaymentMethods()
    closeModal()
    addIbanValidationInfo(null)
    updateSnackbarState({
      isOpen: true,
      message: Translation.payment_add_success,
      type: 'success',
    })
  }

  const isSepaFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(iban, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.iban = !isValid
    }

    if (!isValidValue(bic, FIELDS_PATTERNS.bicCode)) {
      isValid = false
      isFormErrorsUpdated.bic = !isValid
    }

    if (isDifferentAddress) {
      if (!isValidValue(title, FIELDS_PATTERNS.default)) {
        isValid = false
        isFormErrorsUpdated.title = !isValid
      }

      if (!isValidValue(salutation, FIELDS_PATTERNS.default)) {
        isValid = false
        isFormErrorsUpdated.salutation = !isValid
      }

      if (!isValidValue(firstname, FIELDS_PATTERNS.default)) {
        isValid = false
        isFormErrorsUpdated.firstname = !isValid
      }

      if (!isValidValue(lastname, FIELDS_PATTERNS.default)) {
        isValid = false
        isFormErrorsUpdated.lastname = !isValid
      }

      if (!isValidValue(address, FIELDS_PATTERNS.default)) {
        isValid = false
        isFormErrorsUpdated.address = !isValid
      }

      if (!isValidValue(zip, FIELDS_PATTERNS.default)) {
        isValid = false
        isFormErrorsUpdated.zip = !isValid
      }

      if (!isValidValue(city, FIELDS_PATTERNS.default)) {
        isValid = false
        isFormErrorsUpdated.city = !isValid
      }

      if (!isValidValue(country, FIELDS_PATTERNS.default)) {
        isValid = false
        isFormErrorsUpdated.country = !isValid
      }
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const isGiropayFormValid = () => {
    let isValid = true
    const isFormErrorsUpdated = {}

    if (!isValidValue(iban, FIELDS_PATTERNS.default)) {
      isValid = false
      isFormErrorsUpdated.iban = !isValid
    }

    if (!isValidValue(bic, FIELDS_PATTERNS.bicCode)) {
      isValid = false
      isFormErrorsUpdated.bic = !isValid
    }

    if (!isValid) setIsFormErrors(isFormErrorsUpdated)

    return isValid
  }

  const handleSaveMethod = async () => {
    if (paymentType === 'card') {
      if (!isCreditCardFormValid()) return

      if (cardType !== 'visa' && cardType !== 'mastercard' && cardType !== 'amex') {
        updateSnackbarState({
          isOpen: true,
          message: Translation.payment_method_not_supported,
          type: 'error',
        })
        return
      }

      const res = await savePaymentMethod({
        type: 'creditcard',
        card_cvc: cvc,
        card_expire_month: expiryDate.slice(0, 2),
        card_expire_year: expiryDate.slice(3, 5),
        card_number: cardNumber,
        // eslint-disable-next-line no-nested-ternary
        card_type: cardType === 'visa' ? 'V' : cardType === 'mastercard' ? 'M' : cardType === 'amex' ? 'A' : undefined,
        card_name: nameOnCard,
      })
      if (res) {
        paymentAddSuccessful()
      } else {
        updateSnackbarState({
          isOpen: true,
          message: Translation.payment_add_error,
          type: 'error',
        })
      }
    } else {
      if (!isSepaFormValid()) return
      const res = await savePaymentMethod({
        type: 'sepa',
        iban,
        bic,
        ...(isDifferentAddress && { title }),
        ...(isDifferentAddress && { salutation }),
        ...(isDifferentAddress && { firstname }),
        ...(isDifferentAddress && { lastname }),
        ...(isDifferentAddress && { street: address }),
        ...(isDifferentAddress && { zip }),
        ...(isDifferentAddress && { city }),
        ...(isDifferentAddress && { country }),
      })
      if (res) paymentAddSuccessful()
      else {
        updateSnackbarState({
          isOpen: true,
          message: Translation.payment_add_error,
          type: 'error',
        })
      }
    }
  }

  const makePaypalPayment = (retryCount) => {
    if (document.getElementById('_xclick')) {
      const paypalForm = document.getElementById('_xclick')
      paypalForm.submit()
    } else if (retryCount > 0) {
      setTimeout(() => {
        makePaypalPayment(retryCount - 1)
      }, 2000)
    } else {
      updateSnackbarState({
        isOpen: true,
        message: Translation.paypal_failed,
        type: 'error',
      })
      setPaymentModalState(PAYMENT_MODAL_TYPE.form)
    }
  }

  const handleMakePayment = async () => {
    if (paymentType === 'card') {
      if (!isCreditCardFormValid()) return

      setPaymentModalState(PAYMENT_MODAL_TYPE.inProcess)
      setPaymentInProcess(true)
      const res = await payWithCreditCard({
        final_price: unpaidData.final_price_no_format,
        currency: unpaidData.currency,
        card_cvc: cvc,
        card_expire_month: expiryDate.slice(0, 2),
        card_expire_year: expiryDate.slice(3, 5),
        card_number: cardNumber,
        // eslint-disable-next-line no-nested-ternary
        card_type: cardType === 'visa' ? 'V' : cardType === 'mastercard' ? 'M' : cardType === 'amex' ? 'A' : undefined,
        card_name: nameOnCard,
        is_save: isSaveCard,
      })
      if (res.success) {
        if (res.redirecturl) {
          window.location.href = res.redirecturl
        } else {
          navigate(PAYMENT_SUCCESS_PAGE)
        }
      } else {
        setPaymentModalState(PAYMENT_MODAL_TYPE.paymentError)
      }
      return
    }
    if (paymentType === 'paypal') {
      setPaymentModalState(PAYMENT_MODAL_TYPE.inProcess)
      makePaypalPayment(2)
      return
    }
    if (paymentType === 'giropay') {
      if (!isGiropayFormValid()) return

      setPaymentModalState(PAYMENT_MODAL_TYPE.inProcess)
      const res = await payWithGiropay({
        final_price: unpaidData.final_price_no_format,
        currency: unpaidData.currency,
        iban,
        bic,
      })
      if (res.redirecturl) window.location.href = res.redirecturl
      else setPaymentModalState(PAYMENT_MODAL_TYPE.paymentError)
      return
    }
    if (paymentType === 'sepa' || paymentType === 'file') {
      closeModal()
    }
    if (paymentType === 'sofort') {
      setPaymentModalState(PAYMENT_MODAL_TYPE.inProcess)
      const res = await payWithSofort({
        final_price: unpaidData.final_price_no_format,
        currency: unpaidData.currency,
      })
      if (res.redirecturl) window.location.href = res.redirecturl
      else setPaymentModalState(PAYMENT_MODAL_TYPE.paymentError)
    }
  }

  return (
    <div className="payment-form">
      <div className="button-container">
        {paymentTypes
          // eslint-disable-next-line no-confusing-arrow
          .filter((type) => isCheckout ? true : (type.type === 'card' || type.type === 'sepa'))
          .map((item) => (
            <button
              key={item.type}
              type="button"
              className={`payment-type-btn ${paymentType === item.type ? 'active' : null}`}
              onClick={() => setPaymentType(item.type)}
            >
              {item.icon}
            </button>
          ))}
      </div>

      {paymentType === 'card' && (
        <>
          <div className="card-types">
            <div className={`card-type ${cardType === 'visa' ? 'active' : null}`}>
              <VisaIcon />
            </div>
            <div className={`card-type ${cardType === 'mastercard' ? 'active' : null}`}>
              <MasterCardOnlyIcon />
            </div>
            <div className={`card-type ${cardType === 'amex' ? 'active' : null}`}>
              <AmexIcon />
            </div>
          </div>

          <div className="form-inputs scrollbar-overflow">
            <div className="form-row">
              <Input
                label={Translation.name_on_card}
                name="nameOnCard"
                onChange={handleNameChange}
                width={PX_TO_REM['394']}
                type="text"
                value={nameOnCard}
                pattern={FIELDS_PATTERNS.cardName}
                error={formErrors.nameOnCard}
                isError={isFormErrors.nameOnCard}
              />
            </div>
            <div className="form-row">
              <Input
                label={Translation.card_number}
                name="cardNumber"
                onChange={handleCardNumberChange}
                onBlur={cardNumberBlur}
                pattern={FIELDS_PATTERNS.cardNumber}
                width={PX_TO_REM['394']}
                type="text"
                value={cardNumber}
                error={formErrors.cardNumber}
                isError={isFormErrors.cardNumber}
                endAdornment={adornment()}
                maxLength={19}
              />
            </div>
            <div className="form-row">
              <div className="expiry-date">
                <Input
                  label={Translation.expiration_date}
                  name="expiryDate"
                  placeholder="mm/yy"
                  onChange={handleExpiryDateChange}
                  onBlur={expirationDateBlur}
                  width={PX_TO_REM['113']}
                  type="text"
                  value={expiryDate}
                  isError={isFormErrors.expiryDate}
                />
              </div>
              <div className="cvc">
                <Input
                  label={Translation.cvc}
                  name="cvc"
                  onChange={handleCvcChange}
                  onBlur={cvcBlur}
                  width={PX_TO_REM['67']}
                  type="text"
                  value={cvc}
                  isError={isFormErrors.cvc}
                  maxLength={cvcMaxLength}
                />
              </div>
            </div>
            <div className="error-messages">
              {isFormErrors.expiryDate && (
                <Typography
                  variant="xs"
                  label={formErrors.expiryDate}
                />
              )}
              {isFormErrors.cvc && (
                <Typography
                  variant="xs"
                  label={formErrors.cvc}
                />
              )}
            </div>
            {isCheckout && (
              <div className="form-row">
                <FormGroup>
                  <FormControlLabel
                    className="save-card-checkbox"
                    control={(
                      <Checkbox
                        checked={isSaveCard}
                        onChange={() => setIsSaveCard(!isSaveCard)}
                      />
                    )}
                    label={(
                      <Typography
                        variant="xs"
                        inlineBlock
                        label={Translation.save_card_for_future_auto_payments}
                      />
                    )}
                  />
                </FormGroup>
              </div>
            )}
          </div>
        </>
      )}

      {paymentType === 'paypal' && (
        <>
          <Typography
            variant="s"
            label={Translation.paypal_payment_description}
            lineHeight={PX_TO_REM['20']}
          />
          {/* eslint-disable-next-line react/no-danger */}
          <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: unpaidData.paypal_form }} />
        </>
      )}

      {paymentType === 'giropay' && (
        <>
          <div className="giropay-description">
            <Typography
              label={Translation.giropay_description}
              variant="s"
            />
          </div>
          <div className="form-inputs">
            <Input
              label={Translation.iban}
              name="iban"
              onChange={handleIbanChange}
              onBlur={handleIbanBlur}
              type="text"
              value={iban}
              error={formErrors.iban}
              isError={isFormErrors.iban}
            />
            <Input
              label={Translation.bic}
              name="bic"
              onChange={handleBicChange}
              pattern={FIELDS_PATTERNS.bicCode}
              type="text"
              value={bic}
              error={formErrors.bic}
              isError={isFormErrors.bic}
            />
          </div>
        </>
      )}

      {paymentType === 'sepa' && isCheckout && (
        <Typography variant="s">
          {Translation.please_go_to_your}
          {' '}
          <Link
            variant="s"
            underline
            href={BILLING_PAGE}
            onClick={handleBillingPageClick}
            label={Translation.billing_page}
          />
          {' '}
          {Translation.and_add_sepa}
        </Typography>
      )}

      {paymentType === 'sepa' && !isCheckout && (
        <div className="form-inputs scrollbar-overflow">
          <Input
            label={Translation.iban}
            name="iban"
            onChange={handleIbanChange}
            onBlur={handleIbanBlur}
            type="text"
            value={iban}
            error={formErrors.iban}
            isError={isFormErrors.iban}
          />
          <Input
            label={Translation.bic}
            name="bic"
            onChange={handleBicChange}
            pattern={FIELDS_PATTERNS.bicCode}
            type="text"
            value={bic}
            error={formErrors.bic}
            isError={isFormErrors.bic}
          />
          {bankName && (
            <div className="bank-name">
              <Typography
                variant="xs"
                label="Bank"
              />
              <Typography
                variant="xs"
                theme="dark"
                label={bankName}
              />
            </div>
          )}
          <FormGroup>
            <FormControlLabel
              className="save-card-checkbox"
              control={(
                <Checkbox
                  checked={isDifferentAddress}
                  onChange={() => setIsDifferentAddress(!isDifferentAddress)}
                />
              )}
              label={(
                <Typography
                  variant="xs"
                  inlineBlock
                  label={Translation.bank_account_private}
                />
              )}
            />
          </FormGroup>

          {isDifferentAddress && (
            <>
              <div className="form-row">
                <Select
                  name="title"
                  label={Translation.title}
                  placeholder={Translation.title}
                  value={title}
                  values={Translation.titles_for_sepa}
                  onChange={(e) => handleSelectChange(e, setTitle, 'title')}
                  error={formErrors.title}
                  isError={isFormErrors.title}
                />
                <Select
                  name="salutation"
                  label={Translation.title}
                  placeholder={Translation.title}
                  value={salutation}
                  values={Translation.salutations}
                  onChange={(e) => handleSelectChange(e, setSalutation, 'salutation')}
                  error={formErrors.salutation}
                  isError={isFormErrors.salutation}
                />
              </div>
              <div className="form-row">
                <Input
                  label={Translation.firstname}
                  name="firstname"
                  onChange={(e) => handleValueChange(e, setFirstname)}
                  type="text"
                  value={firstname}
                  error={formErrors.firstname}
                  isError={isFormErrors.firstname}
                />
                <Input
                  label={Translation.lastname}
                  name="lastname"
                  onChange={(e) => handleValueChange(e, setLastname)}
                  type="text"
                  value={lastname}
                  error={formErrors.lastname}
                  isError={isFormErrors.lastname}
                />
              </div>
              <div className="form-row">
                <Input
                  label={Translation.address}
                  name="address"
                  onChange={(e) => handleValueChange(e, setAddress)}
                  type="text"
                  value={address}
                  error={formErrors.address}
                  isError={isFormErrors.address}
                />
              </div>
              <div className="form-row">
                <Input
                  label={Translation.zip_code}
                  name="zip"
                  onChange={(e) => handleValueChange(e, setZip)}
                  type="text"
                  value={zip}
                  error={formErrors.zip}
                  isError={isFormErrors.zip}
                />
                <Input
                  label={Translation.city}
                  name="city"
                  onChange={(e) => handleValueChange(e, setCity)}
                  type="text"
                  value={city}
                  error={formErrors.city}
                  isError={isFormErrors.city}
                />
              </div>
              <div className="form-row">
                <Select
                  isEmptyOption={false}
                  label={Translation.country}
                  value={country}
                  values={getCountries()}
                  onChange={(e) => handleSelectChange(e, setCountry, 'country')}
                  error={formErrors.country}
                  isError={isFormErrors.country}
                />
              </div>
            </>
          )}
        </div>
      )}

      {paymentType === 'sofort' && (
        <Typography
          variant="s"
          label={Translation.sofort_description}
          lineHeight={PX_TO_REM['20']}
        />
      )}

      {paymentType === 'file' && (
        <>
          <div className="transfer-desc-row">
            <Typography
              variant="s"
              label="Commerzbank"
            />
          </div>
          <div className="transfer-desc-row">
            <Typography
              variant="s"
              inline
              label={`${Translation.recipient}: `}
            />
            <Typography
              variant="s"
              inline
              font="semibold"
              label="Doopic GmbH"
            />
          </div>
          <div className="transfer-desc-row">
            <Typography
              variant="s"
              inline
              label="IBAN: "
            />
            <Typography
              variant="s"
              inline
              font="semibold"
              label="DE03 1004 0048 0172 0531 00"
            />
          </div>
          <div className="transfer-desc-row">
            <Typography
              variant="s"
              inline
              label="BIC: "
            />
            <Typography
              variant="s"
              inline
              font="semibold"
              label="COBADEFFXXX"
            />
          </div>
          <div className="transfer-desc-row">
            <Typography
              variant="s"
              inline
              label={`${Translation.reference}: `}
            />
            <Typography
              variant="s"
              inline
              font="semibold"
              label="RE 56646, RE 56677"
            />
          </div>
          <div className="transfer-desc-row">
            <Typography
              variant="s"
              lineHeight={PX_TO_REM['20']}
              label={Translation.transfer_money_description}
            />
          </div>
          <Typography
            variant="s"
          >
            {Translation.you_can_create_the_invoice_1}
            {' '}
            <Link
              to="/"
              variant="s"
              label={Translation.here}
              onClick={handleCreateInvoice}
              underline
            />
            {' '}
            {Translation.you_can_create_the_invoice_2}
          </Typography>
        </>
      )}

      <div className="form-actions">
        {!!paymentMethods.length && isCheckout && (
          <button
            type="button"
            className="use-different-payment-btn"
            onClick={() => setPaymentModalState(PAYMENT_MODAL_TYPE.paymentList)}
          >
            <Typography
              variant="xs"
              font="semibold"
              label={`← ${Translation.back_to_saved_payment_methods}`}
            />
          </button>
        )}

        <Button
          type="contained"
          onClick={handleModalClose}
          label={paymentMethods.length > 0 ? Translation.back : Translation.cancel}
          width={PX_TO_REM['116']}
        />

        {isCheckout && (
          <Button
            onClick={handleMakePayment}
            label={(
              // eslint-disable-next-line no-nested-ternary
              paymentType === 'sepa'
                ? Translation.ok
                : (paymentType === 'file' ? Translation.close : Translation.make_payment)
            )}
            width={isCheckout ? '14.230769rem' : 'auto'}
          />
        )}

        {!isCheckout && (
          <Button
            onClick={handleSaveMethod}
            label={Translation.save_payment_method}
            width="auto"
          />
        )}
      </div>
    </div>
  )
}

PaymentForm.propTypes = {
  closeModal: PropTypes.func,
  isFormChanged: PropTypes.bool,
  setIsFormChanged: PropTypes.func,
  setNavigateTo: PropTypes.func,
  setLeaveDialogOpen: PropTypes.func,
  setPaymentModalState: PropTypes.func,
  isCheckout: PropTypes.bool,
}

PaymentForm.defaultProps = {
  closeModal: () => { },
  isFormChanged: false,
  setIsFormChanged: () => { },
  setNavigateTo: () => { },
  setLeaveDialogOpen: () => { },
  setPaymentModalState: () => { },
  isCheckout: false,
}

export default PaymentForm
