/* eslint-disable max-classes-per-file */
const cardTypes = [
  {
    name: 'amex',
    range: '34, 37',
    valid_length: [15],
  },
  {
    name: 'diners_club_carte_blanche',
    range: '300-305',
    valid_length: [14],
  },
  {
    name: 'diners_club_international',
    range: '36',
    valid_length: [14],
  },
  {
    name: 'jcb',
    range: '3088, 3528-3589',
    valid_length: [16],
  },
  {
    name: 'laser',
    range: '6304, 6706, 6709, 6771',
    valid_length: [16, 17, 18, 19],
  },
  {
    name: 'visa_electron',
    range: '4026, 417500, 4508, 4844, 4913, 4917',
    valid_length: [16],
  },
  {
    name: 'visa',
    range: '4',
    valid_length: [13, 14, 15, 16, 17, 18, 19],
  },
  {
    name: 'mastercard',
    range: '51-55, 2221-2720',
    valid_length: [16],
  },
  {
    name: 'discover',
    range: '6011, 622126-622925, 644-649, 65',
    valid_length: [16],
  },
  {
    name: 'dankort',
    range: '5019',
    valid_length: [16],
  },
  {
    name: 'maestro',
    range: '50, 56-69',
    valid_length: [12, 13, 14, 15, 16, 17, 18, 19],
  },
  {
    name: 'uatp',
    range: '1',
    valid_length: [15],
  },
]

class Trie {
  constructor() {
    this.trie = {}
  }

  push(value) {
    let char
    let obj = this.trie
    // eslint-disable-next-line no-param-reassign
    value = value.toString().split('')

    for (let i = 0; i < value.length; i += 1) {
      char = value[i]

      if (typeof obj[char] === 'undefined') {
        if (i === (value.length - 1)) {
          obj[char] = null
        } else {
          obj[char] = {}
        }
      }

      obj = obj[char]
    }

    return obj
  }

  find(value) {
    let char
    let obj = this.trie
    // eslint-disable-next-line no-param-reassign
    value = value.toString().split('')

    for (let i = 0; i < value.length; i += 1) {
      char = value[i]

      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(char)) {
        if (obj[char] === null) {
          return true
        }
      } else {
        return false
      }

      obj = obj[char]
    }
    return false
  }
}

class Range {
  constructor(trie) {
    this.trie = trie

    if (!(trie instanceof Trie)) {
      throw Error('Range constructor requires a Trie parameter')
    }
  }

  static rangeWithString(ranges) {
    if (typeof ranges !== 'string') {
      throw Error('rangeWithString requires a string parameter')
    }

    // eslint-disable-next-line no-param-reassign
    ranges = ranges.replace(/ /g, '').split(',')
    const trie = new Trie()

    for (let i = 0; i < ranges.length; i += 1) {
      const range = ranges[i]
      const match = range.match(/^(\d+)-(\d+)$/)

      if (match) {
        for (let n = parseInt(match[1], 10), end = parseInt(match[2], 10); n <= end; n += 1) {
          trie.push(n)
        }
      } else if (range.match(/^\d+$/)) {
        trie.push(range)
      } else {
        throw Error(`Invalid range '${range}'`)
      }
    }

    return new Range(trie)
  }

  match(number) {
    return this.trie.find(number)
  }
}

export const luhn10 = (identifier) => {
  let sum = 0
  let alt = false
  let i = identifier.length - 1
  let num

  while (i >= 0) {
    num = parseInt(identifier.charAt(i), 10)

    if (alt) {
      num *= 2
      if (num > 9) {
        num = (num % 10) + 1
      }
    }

    alt = !alt
    sum += num
    i -= 1
  }

  return sum % 10 === 0
}

export const getCardType = (val) => {
  for (let i = 0; i < cardTypes.length; i += 1) {
    const r = Range.rangeWithString(cardTypes[i].range)
    if (r.match(val)) {
      return cardTypes[i].name
    }
  }
  return undefined
}
