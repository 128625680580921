import React, { useEffect, useState, useRef } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { Tooltip } from '@mui/material'

import { Translation } from '../../../../helpers/Translation'
import {
  DEFAULT_PAGE,
  DESKTOP_PX_TO_REM,
  IMAGE_SEARCH_PAGE,
  IMAGE_TYPE_IN,
  IMAGE_TYPE_OUT,
  PX_TO_REM,
} from '../../../../helpers/Constants'
import { getGalleryListUrl, getGalleryUrl } from '../../../../helpers/Utils'

import Typography from '../../../../components/Typography'
import Table from '../../../../components/Table'
import Checkbox from '../../../../components/Checkbox'
import Link from '../../../../components/Link'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import DotAnimation from '../../../../layouts/Common/DotAnimation'

import { ReactComponent as FilesSvg } from '../../../../svg/files.svg'
import { ReactComponent as ImageFolderSvg } from '../../../../svg/image_folder.svg'
import { ReactComponent as ArrowUpDownSvg } from '../../../../svg/arrow_up_down.svg'
import { ReactComponent as CalendarSvg } from '../../../../svg/calendar.svg'
import { ReactComponent as OneTwoThreeSvg } from '../../../../svg/123.svg'
import { ReactComponent as NewTabSvg } from '../../../../svg/new_tab.svg'

import './index.scss'

const ImageSearchBox = () => {
  const getOrderSearchImages = useStoreActions((actions) => actions.order.getOrderSearchImages)
  const setOrderSearchImages = useStoreActions((actions) => actions.order.setOrderSearchImages)
  const orderSearchImages = useStoreState((state) => state.order.orderSearchImages)

  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isShowWarning, setIsShowWarning] = useState(false)
  const [transformedData, setTransformedData] = useState([])
  const [imageSearchData, setImageSearchData] = useState({
    id_search: '',
    name_search: '',
    folder_search: '',
    filter_input: true,
    filter_output: false,
    filter_3m_older: false,
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const searchDataShallow = { ...imageSearchData }
    if (searchDataShallow.name_search || searchDataShallow.folder_search || searchDataShallow.id_search) {
      setIsSubmit(true)
      setIsLoadingSearch(true)
      setOrderSearchImages([])

      if (searchDataShallow.name_search?.startsWith('%') || searchDataShallow.name_search?.endsWith('%')) {
        searchDataShallow.name_search = searchDataShallow.name_search.replace('%', ';%;')
      }

      await getOrderSearchImages(searchDataShallow)
      setIsLoadingSearch(false)
    } else {
      setIsShowWarning(true)
      setTimeout(() => {
        setIsShowWarning(false)
      }, 3000)
    }
  }

  const checkEnterKeyEvent = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e).then(() => { })
    }
  }

  const openImageSearch = () => {
    window.open(IMAGE_SEARCH_PAGE, '_blank').focus()
  }

  useEffect(() => {
    if (!imageSearchData.filter_input) {
      setImageSearchData((prev) => ({ ...prev, filter_output: true }))
    }
  }, [imageSearchData.filter_input])

  useEffect(() => {
    if (!imageSearchData.filter_output) {
      setImageSearchData((prev) => ({ ...prev, filter_input: true }))
    }
  }, [imageSearchData.filter_output])

  const colWidth = [68, 64, 202, 192, 97]

  const columns = [
    {
      id: 'order_id',
      render: 'jsx',
      label: Translation.order_id,
      width: colWidth[0] / DESKTOP_PX_TO_REM,
    },
    { id: 'images', label: Translation.images, width: colWidth[1] / DESKTOP_PX_TO_REM },
    { id: 'filename', label: Translation.file_name, width: colWidth[2] / DESKTOP_PX_TO_REM },
    { id: 'folder', label: Translation.order_name, width: colWidth[3] / DESKTOP_PX_TO_REM },
    {
      id: 'date',
      render: 'jsx',
      label: Translation.date,
      width: colWidth[4] / DESKTOP_PX_TO_REM,
    },
  ]

  useEffect(() => {
    const tempData = []

    orderSearchImages.map((item) => (
      tempData.push({
        order_id: (
          <Link
            href={`${DEFAULT_PAGE}?order=${item.order_id}`}
            label={(item.order_id).toString()}
            underline
            isNewTab
          />
        ),
        images: (
          <Link
            href={getGalleryUrl(item.order_id, item.type === IMAGE_TYPE_OUT)}
            label={item.type === IMAGE_TYPE_IN ? Translation.input : Translation.output}
            underline
            isNewTab
          />
        ),
        filename: (
          <Link
            href={getGalleryListUrl(item.order_id, item.image_id, item.type === IMAGE_TYPE_OUT)}
            label={item.file}
            underline
            isNewTab
          />),
        folder: (
          <div>
            {
              item.template_name && (
                <div className="order-template-name">
                  {item.template_name}
                </div>
              )
            }
            <div className="order-name">
              {item.order_name}
            </div>
          </div>
        ),
        date: (
          <Typography
            fontSize={PX_TO_REM['10']}
            noWrap
            label={`
              ${new Date(item.timestamp_received).toLocaleDateString('en-GB').replace(/\//g, '.')} 
              ${new Date(item.timestamp_received).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
            `}
          />
        ),
      })
    ))

    setTransformedData(tempData)
  }, [orderSearchImages])

  const divRef = useRef(null)

  useEffect(() => {
    const divElement = divRef.current

    const handleScroll = () => {
      if (divElement.scrollHeight - divElement.scrollTop === divElement.clientHeight) {
        document.getElementById('image-search-box-table-bottom').style.display = 'none'
      } else {
        document.getElementById('image-search-box-table-bottom').style.display = 'block'
      }
    }

    if (divElement && orderSearchImages.length) {
      divElement.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (divElement && orderSearchImages.length) {
        divElement.removeEventListener('scroll', handleScroll)
      }
    }
  }, [transformedData.length])

  return (
    <div className="image-search-box">
      <form className={`box-options ${isSubmit ? 'isSubmit' : ''}`} onSubmit={handleSubmit}>
        <div className="box-options-title">
          <Typography
            variant="xs-narrow"
            label={Translation.image_search}
            font="semibold"
          />
          <Tooltip
            className="info-tooltip"
            PopperProps={{
              disablePortal: true,
            }}
            title={Translation.open_image_search_in_new_window}
            placement="right"
            arrow
          >
            <NewTabSvg onClick={openImageSearch} />
          </Tooltip>
        </div>
        <div className="box-options-row">
          <label htmlFor="image-search-filename" className="row-titleWrapper">
            <FilesSvg />
            <Typography
              variant="xs-narrow"
              label={Translation.file_name}
              font="medium"
            />
          </label>
          <div className="inputWrapper">
            <Input
              type="text"
              id="image-search-filename"
              value={imageSearchData.name_search}
              onChange={
                (e) => setImageSearchData((prev) => ({ ...prev, name_search: `${e.target.value}` }))
              }
              onKeyUp={(e) => checkEnterKeyEvent(e)}
            />
          </div>
        </div>
        <div className="box-options-row">
          <label htmlFor="image-search-folder" className="row-titleWrapper">
            <ImageFolderSvg />
            <Typography
              variant="xs-narrow"
              label={Translation.folder}
              font="medium"
            />
          </label>
          <div className="inputWrapper">
            <Input
              type="text"
              id="image-search-folder"
              value={imageSearchData.folder_search}
              onChange={(e) => setImageSearchData((prev) => ({ ...prev, folder_search: e.target.value }))}
              onKeyUp={(e) => checkEnterKeyEvent(e)}
            />
          </div>
        </div>
        <div className="box-options-row">
          <label htmlFor="image-search-order" className="row-titleWrapper">
            <OneTwoThreeSvg />
            <Typography
              variant="xs-narrow"
              label={Translation.order_id}
              font="medium"
            />
          </label>
          <div className="inputWrapper">
            <Input
              type="text"
              id="image-search-order"
              value={imageSearchData.id_search}
              onChange={(e) => setImageSearchData((prev) => ({ ...prev, id_search: e.target.value }))}
              onKeyUp={(e) => checkEnterKeyEvent(e)}
            />
          </div>
        </div>
        {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
        <label htmlFor="image-search-input" className="box-options-checkRow">
          <div className="checkRow-titleWrapper">
            <ArrowUpDownSvg />
            <Typography
              variant="xs-narrow"
              label={Translation.input}
              font="medium"
            />
          </div>
          <Checkbox
            id="image-search-input"
            onChange={(e) => setImageSearchData((prev) => ({ ...prev, filter_input: e.target.checked }))}
            checked={imageSearchData.filter_input}
          />
        </label>
        <label htmlFor="image-search-output" className="box-options-checkRow">
          <div className="checkRow-titleWrapper">
            <ArrowUpDownSvg />
            <Typography
              variant="xs-narrow"
              label={Translation.output}
              font="medium"
            />
          </div>
          <Checkbox
            id="image-search-output"
            onChange={(e) => setImageSearchData((prev) => ({ ...prev, filter_output: e.target.checked }))}
            checked={imageSearchData.filter_output}
          />
        </label>
        <label htmlFor="image-search-than-months" className="box-options-checkRow">
          <div className="checkRow-titleWrapper">
            <CalendarSvg />
            <Typography
              variant="xs-narrow"
              label={Translation.older_than_3_months}
              font="medium"
            />
          </div>
          <Checkbox
            id="image-search-than-months"
            onChange={(e) => setImageSearchData((prev) => ({ ...prev, filter_3m_older: e.target.checked }))}
            checked={imageSearchData.filter_3m_older}
          />
        </label>
        <div className="box-options-button">
          <Button type="fit-content" onClick={handleSubmit} label={Translation.search} />
        </div>
        {
          isShowWarning ? (
            <div className="box-warning">
              <Typography variant="body1" label={Translation.image_search_warning_text} />
            </div>
          ) : null
        }
      </form>
      {
        isLoadingSearch ? (
          <div className="box-table-loading">
            <DotAnimation />
          </div>
        ) : null
      }
      {
        isSubmit && !isLoadingSearch ? (
          <div className="box-table">
            <Table
              columns={columns}
              cellHeight="small"
              data={transformedData}
              showPagination={false}
              showRowsPerPage={false}
              divRef={divRef}
              count={transformedData.length}
              emptyDataText="no_data_with_filters"
            />
            <div id="image-search-box-table-bottom" className="box-table-bottom" />
          </div>
        ) : null
      }
    </div>
  )
}

export default ImageSearchBox
