import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../../helpers/Translation'
import {
  IMAGE_GENERATED_OFFSET, IMAGE_PREVIEW_MAX_HEIGHT, PX_TO_REM,
} from '../../../../../helpers/Constants'

import Typography from '../../../../../components/Typography'

import './index.scss'

// eslint-disable-next-line import/no-cycle
import {
  getPreviewSizes,
  getPreviewOrientationClass,
} from '../index'

const OrderDetailsImage = ({ image, receivedOffset }) => {
  const [error, setError] = useState(false)

  const handleImageError = () => {
    setError(true)
  }

  return (
    (error) ? (
      <img
        src={(receivedOffset <= IMAGE_GENERATED_OFFSET) ? Translation.image_generated : Translation.image_not_found_img}
        style={{ height: IMAGE_PREVIEW_MAX_HEIGHT }}
        alt=""
      />
    ) : (
      <>
        <img
          src={image.url}
          onError={handleImageError}
          onLoad={(e) => e.currentTarget.classList.remove('skeleton')}
          className={`${getPreviewOrientationClass(image)} skeleton`}
          style={getPreviewSizes(image)}
          alt=""
        />
        {
          image.is_test_image === 1 && (
            <div className="image-test-badge">
              <Typography fontSize={PX_TO_REM['10']} label={Translation.test_image} />
            </div>
          )
        }
      </>
    )
  )
}

OrderDetailsImage.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    width_preview: PropTypes.number,
    height_preview: PropTypes.number,
    is_test_image: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
  }),
  receivedOffset: PropTypes.number,
}

OrderDetailsImage.defaultProps = {
  image: {
    url: '',
    is_test_image: false,
  },
  receivedOffset: 6,
}

export default OrderDetailsImage
