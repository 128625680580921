import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroupMui from '@mui/material/RadioGroup'
import PropTypes from 'prop-types'

import { ReactComponent as RadioCheckedSvg } from '../../svg/radio_checked.svg'
import { ReactComponent as RadioCheckedGraySvg } from '../../svg/radio_checked_gray.svg'
import { ReactComponent as RadioUncheckedSvg } from '../../svg/radio_unchecked.svg'

import './index.scss'

const theme = createTheme({
  typography: {
    fontFamily: 'roobert, Helvetica, Arial, sans-serif',
  },
})

const RadioGroup = ({
  name,
  value,
  values,
  row,
  onChange,
  color,
  disabledData,
  allDisabled,
}) => (
  <ThemeProvider theme={theme}>
    <RadioGroupMui
      name={name}
      row={row}
      value={value}
      onChange={onChange}
    >
      {
        Object.keys(values).map((key) => (
          <FormControlLabel
            key={key}
            value={key}
            label={values[key]}
            className="doo-label"
            disabled={disabledData.includes(key) || allDisabled}
            control={(
              <Radio
                disableRipple
                className="doo-radio"
                icon={<RadioUncheckedSvg />}
                checkedIcon={color !== 'gray' ? <RadioCheckedSvg /> : <RadioCheckedGraySvg />}
              />
            )}
          />
        ))
      }
    </RadioGroupMui>
  </ThemeProvider>
)

RadioGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.node)]),
  row: PropTypes.bool,
  onChange: PropTypes.func,
  color: PropTypes.oneOf(['', '#3A3183', 'gray']),
  disabledData: PropTypes.arrayOf(PropTypes.string) || PropTypes.arrayOf(PropTypes.number),
  allDisabled: PropTypes.bool,
}

RadioGroup.defaultProps = {
  name: '',
  value: '',
  values: [],
  row: false,
  onChange: () => { },
  color: '',
  disabledData: [],
  allDisabled: false,
}

export default RadioGroup
