import React from 'react'
import PropTypes from 'prop-types'

import { Translation } from '../../../../helpers/Translation'
import { FIELDS_MIN_LENGTH, FIELDS_PATTERNS } from '../../../../helpers/Constants'

import Select from '../../../../components/Select'
import Input from '../../../../components/Input'

import './index.scss'

const UserInformation = ({
  email,
  emailDisabled,
  handleEmailChange,
  title,
  handleTitleChange,
  firstname,
  handleFirstnameChange,
  lastname,
  handleLastnameChange,
  telephone,
  handleTelephoneChange,
  formErrors,
  isFormErrors,
}) => (
  <div className="profile-form">
    <div className="title-firstname-lastname">
      <div className="title-select-container">
        <Select
          isEmptyOption={false}
          label={Translation.title}
          values={Translation.titles}
          value={title}
          onChange={handleTitleChange}
        />
      </div>
      <Input
        type="text"
        name="firstname"
        label={`${Translation.firstname}*`}
        error={formErrors.firstname}
        isError={isFormErrors.firstname}
        minLength={FIELDS_MIN_LENGTH.default}
        pattern={FIELDS_PATTERNS.default}
        placeholder={Translation.firstname}
        value={firstname}
        onChange={handleFirstnameChange}
      />
      <Input
        type="text"
        name="lastname"
        label={`${Translation.lastname}*`}
        error={formErrors.lastname}
        isError={isFormErrors.lastname}
        minLength={FIELDS_MIN_LENGTH.default}
        pattern={FIELDS_PATTERNS.default}
        placeholder={Translation.lastname}
        value={lastname}
        onChange={handleLastnameChange}
      />
    </div>

    <div className="email-phonenumber">
      <Input
        type="email"
        name="email"
        label={`${Translation.email}*`}
        error={formErrors.email}
        isError={isFormErrors.email}
        pattern={FIELDS_PATTERNS.email}
        placeholder={Translation.email}
        value={email}
        onChange={handleEmailChange}
        disabled={emailDisabled}
      />
      <Input
        label={Translation.phone_number}
        placeholder={Translation.phone_number}
        error={formErrors.telephone}
        isError={isFormErrors.telephone}
        pattern={FIELDS_PATTERNS.telephone}
        name="telephone"
        value={telephone}
        onChange={handleTelephoneChange}
      />
    </div>
  </div>
)

UserInformation.propTypes = {
  email: PropTypes.string,
  emailDisabled: PropTypes.bool,
  handleEmailChange: PropTypes.func,
  title: PropTypes.string,
  handleTitleChange: PropTypes.func,
  firstname: PropTypes.string,
  handleFirstnameChange: PropTypes.func,
  lastname: PropTypes.string,
  handleLastnameChange: PropTypes.func,
  telephone: PropTypes.string,
  handleTelephoneChange: PropTypes.func,
  formErrors: PropTypes.objectOf(PropTypes.string),
  isFormErrors: PropTypes.objectOf(PropTypes.bool),
}

UserInformation.defaultProps = {
  email: '',
  emailDisabled: true,
  handleEmailChange: () => { },
  title: '',
  handleTitleChange: () => { },
  firstname: '',
  handleFirstnameChange: () => { },
  lastname: '',
  handleLastnameChange: () => { },
  telephone: '',
  handleTelephoneChange: () => { },
  formErrors: {},
  isFormErrors: {},
}

export default UserInformation
