export const isLocal = (window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf('local.') !== -1)

const getCookieExpireDate = (days = 30) => {
  const expireDate = new Date()
  expireDate.setTime(expireDate.getTime() + (days * 24 * 60 * 60 * 1000))
  return expireDate.toUTCString()
}

const cookieExpireDate = getCookieExpireDate()

export const Cookie = {
  getDomain() {
    if (isLocal) return 'localhost'

    if (window.location.href.indexOf('127.0.0.1') !== -1) return '127.0.0.1'

    const hostnameArr = window.location.hostname.split('.')

    return `.${hostnameArr[hostnameArr.length - 2]}.${hostnameArr[hostnameArr.length - 1]}`
  },

  setCookie(name, value, expiresDays = 0) {
    let expires = cookieExpireDate
    if (expiresDays) expires = getCookieExpireDate(expiresDays)
    document.cookie = `${name}=${value};expires=${expires};path=/;domain=${Cookie.getDomain()}`
  },

  getCookie(name, isReturnIntegerIfNull = false) {
    const nameRegExp = new RegExp(`${name}=[^;]+`, 'i')

    let value = null
    if (isReturnIntegerIfNull) value = 0
    const matchResult = document.cookie.match(nameRegExp)
    if (matchResult && matchResult.length > 0) {
      [, value] = document.cookie.match(nameRegExp)[0].split('=')
    }

    return value
  },

  deleteCookie(name) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${Cookie.getDomain()}`
  },
}
