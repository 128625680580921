import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStoreActions } from 'easy-peasy'

import { Translation } from '../../../../helpers/Translation'

import Typography from '../../../../components/Typography'
import Textarea from '../../../../components/Textarea'
import Input from '../../../../components/Input'

import './index.scss'

const InternalRemark = ({ newOrderTemplate }) => {
  const updateTemplateValue = useStoreActions((actions) => actions.order.updateTemplateValue)
  const [internalRemark, setInternalRemark] = useState('')
  const [guideLink, setGuideLink] = useState('')
  const [spEmailText, setSpEmailText] = useState('')

  useEffect(() => {
    if (newOrderTemplate) {
      setInternalRemark(newOrderTemplate.remark_internal || '')
      setGuideLink(newOrderTemplate.guide_link)
      setSpEmailText(newOrderTemplate.remark_sp || '')
    }
  }, [newOrderTemplate])

  return (
    <div className="internal-remark">
      <div className="internal-remark-title">
        <Typography variant="subtitle" font="semibold" label={Translation.internal_remarks} />
      </div>
      <div className="internal-remark-inputs">
        <Textarea
          width="100%"
          height={internalRemark ? '7.6923076rem' : ''}
          placeholder={Translation.qa_remark}
          value={internalRemark}
          onChange={(e) => setInternalRemark(e.target.value)}
          onBlur={() => {
            updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'remark_internal', value: internalRemark })
          }}
        />
        <Input
          placeholder={Translation.guide_link}
          value={guideLink}
          onChange={(e) => setGuideLink(e.target.value)}
          onBlur={() => {
            updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'guide_link', value: guideLink })
          }}
        />
        <Textarea
          width="100%"
          height={spEmailText ? '7.6923076rem' : ''}
          placeholder={Translation.sp_email_text}
          value={spEmailText}
          onChange={(e) => setSpEmailText(e.target.value)}
          onBlur={() => {
            updateTemplateValue({ template_id: newOrderTemplate?.template_id, id: 'remark_sp', value: spEmailText })
          }}
        />
      </div>
    </div>
  )
}

InternalRemark.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newOrderTemplate: PropTypes.objectOf(PropTypes.any),
}

InternalRemark.defaultProps = {
  newOrderTemplate: {},
}

export default InternalRemark
