import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const getClassName = (size = '') => {
  let className = 'input--wrap'
  if (size) className += ` input--${size}--wrap`
  return className
}

const getInputClassName = (isError = false, type = '', endAdornment = '') => {
  let className = 'input'
  if (type === 'number' && !endAdornment) className += ' input-number-no-padding'
  if (isError) className += ' input--error'
  return className
}

const Input = ({
  type,
  name,
  size,
  label,
  error,
  minLength,
  maxLength,
  isError,
  pattern,
  placeholder,
  value,
  inputRef,
  width,
  onChange,
  onBlur,
  onKeyUp,
  onKeyDown,
  disabled,
  disableAutocomplete,
  startAdornment,
  endAdornment,
}) => (
  <div
    className={getClassName(size)}
    style={{
      width: /^\d+(\.\d+)?$/.test(width) ? `${width}rem` : width,
    }}
  >
    {
      (label) ? (
        <div className="input__label">
          {label}
        </div>
      ) : null
    }
    <div className="input-container">
      {
        (startAdornment) ? (
          <div className="input__start-adornment">
            {startAdornment}
          </div>
        ) : null
      }
      <input
        type={type}
        title=""
        name={name}
        pattern={pattern}
        placeholder={placeholder}
        value={value}
        className={getInputClassName(isError, type, endAdornment)}
        maxLength={maxLength}
        ref={inputRef}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        disabled={disabled}
        {...(type === 'password' && disableAutocomplete && { autoComplete: 'one-time-code' })}
        {...(type === 'number' && { min: 0, max: 9999999 })}
        style={{ textIndent: (startAdornment) ? '2.076923rem' : '0' }}
      />
      {
        (endAdornment) ? (
          <div className="input__end-adornment">
            {endAdornment}
          </div>
        ) : null
      }
    </div>
    {
      (isError && error) ? (
        <div className="input__error">
          {
            error.replace(/%MIN_LENGTH%/g, (minLength) ? `${minLength}` : '')
          }
        </div>
      ) : null
    }
  </div>
)

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  isError: PropTypes.bool,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  disableAutocomplete: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
}

Input.defaultProps = {
  type: 'text',
  name: '',
  size: '',
  label: '',
  error: '',
  minLength: 0,
  maxLength: null,
  isError: false,
  pattern: '',
  placeholder: '',
  value: '',
  inputRef: null,
  width: '',
  onChange: () => { },
  onBlur: () => { },
  onKeyUp: () => { },
  onKeyDown: () => { },
  disabled: false,
  disableAutocomplete: false,
  startAdornment: null,
  endAdornment: null,
}

export default Input
