import React, { useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useNavigate } from 'react-router-dom'

import { Translation } from '../../helpers/Translation'
import {
  DEFAULT_CURRENCY, INTERVAL_30_SECONDS, ORDER_SUCCESS_PAGE, TEMPLATE_PAGE, PX_TO_REM,
} from '../../helpers/Constants'
import { isLocal } from '../../helpers/Cookie'

import MobileView from '../../layouts/Common/MobileView'

import Button from '../../components/Button'
import Typography from '../../components/Typography'
import Checkbox from '../../components/Checkbox'
import Link from '../../components/Link'
import Dialog from '../../components/Dialog'

import { ReactComponent as RefreshIcon } from '../../svg/repeat.svg'
import { ReactComponent as FolderIcon } from '../../svg/folder.svg'
import { ReactComponent as FolderBlackIcon } from '../../svg/folder_black.svg'
import { ReactComponent as ClockSvg } from '../../svg/clock.svg'

import './index.scss'

const ApiNewOrder = () => {
  const navigate = useNavigate()

  const isMobile = useStoreState((state) => state.layout.isMobile)
  const apiErrors = useStoreState((state) => state.ftp.apiErrors)
  const orderFolders = useStoreState((state) => state.ftp.orderFolders)
  const isAnyOrderInPreparation = useStoreState((state) => state.ftp.isAnyOrderInPreparation)
  const getOrderFtpFolders = useStoreActions((state) => state.ftp.getOrderFtpFolders)
  const createOrderFtp = useStoreActions((state) => state.ftp.createOrderFtp)
  const setCreatedOrderId = useStoreActions((actions) => actions.order.setCreatedOrderId)
  const user = useStoreState((state) => state.user.user)

  const [selectedFolders, setSelectedFolders] = useState([])
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      getOrderFtpFolders('api_order')
    }, INTERVAL_30_SECONDS)
    getOrderFtpFolders('api_order')

    return () => clearInterval(interval)
  }, [])

  const onFolderSelect = (folder) => {
    if (selectedFolders.includes(folder)) {
      setSelectedFolders(selectedFolders.filter(
        (selectedFolder) => (selectedFolder.folder_id !== folder.folder_id || selectedFolder.subfolder !== folder.subfolder),
      ))
    } else {
      setSelectedFolders([...selectedFolders, folder])
    }
  }

  const createOrder = async () => {
    const liveApiTemplates = selectedFolders.filter((f) => f.is_remote !== 1).map((folder) => ({
      template_id: folder.template_id,
      subfolder: folder.subfolder,
    }))
    const remoteApiTemplates = selectedFolders.filter((f) => f.is_remote === 1).map((folder) => ({
      template_id: folder.template_id,
      subfolder: folder.subfolder,
    }))
    const res = await createOrderFtp({
      live_api_templates: liveApiTemplates,
      remote_api_templates: remoteApiTemplates,
    })
    if (res) {
      const orderIds = res.order_id.map((orderId) => orderId).join(', ')
      setCreatedOrderId(orderIds)

      // send conversion to AdWords
      if (!isLocal && typeof gtag !== 'undefined') {
        // eslint-disable-next-line no-undef
        gtag(
          'event',
          'conversion',
          {
            send_to: process.env.REACT_APP_AW_NEW_ORDER_CONVERSION,
            value: 1.0,
            currency: user.currency || DEFAULT_CURRENCY,
            transaction_id: '',
          },
        )
      }

      navigate(ORDER_SUCCESS_PAGE)
    } else {
      setIsErrorModalOpen(true)
    }
  }

  if (isMobile) {
    return (
      <MobileView
        label={Translation.ftp_order_mobile_message}
      />
    )
  }

  if (orderFolders === null) return null

  return (
    <div className="api-order-page">
      <div className="box">
        <div className="left">
          <Typography
            variant="s"
            theme="dark"
            font="semibold"
            lineHeight={PX_TO_REM['20']}
            label={Translation.following_orders}
          />
          <Typography variant="xs" label={Translation.images_will_be_taken} />
          {orderFolders.some((folder) => folder.update_offset !== 0) && (
            <div className="warning-message">
              <Typography variant="xs" label={Translation.folder_recently_updated} />
            </div>
          )}
          <div className="folder-list">
            {orderFolders.map((folder) => (
              <div key={folder.folder_id + folder.subfolder} className="folder-item">
                <Checkbox
                  checked={selectedFolders.some((f) => (f.folder_id === folder.folder_id && f.subfolder === folder.subfolder))}
                  onChange={() => onFolderSelect(folder)}
                  disabled={folder.image_count === 0}
                />
                {
                  (folder.image_count)
                    ? (<FolderBlackIcon className="folder-icon" />) : (<FolderIcon className="folder-icon" />)
                }

                <Typography variant="xs" inline label={folder.input_folder_name} font={folder.image_count ? 'semibold' : ''} />

                {folder.subfolder && (
                  <Typography variant="xs" inline label={`/${folder.subfolder}`} font={folder.image_count ? 'semibold' : ''} />
                )}

                &nbsp;
                <Typography
                  variant="xs"
                  inline
                  font={folder.image_count ? 'semibold' : ''}
                >
                  (
                  {folder.image_count}
                  {' '}
                  {Translation.images}
                  {' '}
                  /
                  {' '}
                  <Link
                    variant="xs"
                    font="semibold"
                    label={Translation.workflow}
                    href={`${TEMPLATE_PAGE}/${folder.template_id}`}
                    isNewTab
                  />
                  )
                </Typography>
                {folder.update_offset !== 0 && (
                  <>
                    &nbsp;
                    <div className="inline warning-message">
                      <Typography
                        variant="xs"
                        label={Translation.recently_updated.replace('%VALUE%', folder.update_offset)}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="right">
          <Button
            type="fit-content"
            onClick={() => getOrderFtpFolders('api_order')}
            label={(
              <div className="refresh-btn">
                <RefreshIcon className="refresh-icon" />
                <Typography variant="xs" font="semibold" label={Translation.ftp} />
              </div>
            )}
          />
        </div>
      </div>
      {
        isAnyOrderInPreparation ? (
          <div className="previous-order-desc">
            <div>
              <ClockSvg />
            </div>
            <div>
              <Typography variant="xs" label={Translation.order_in_process} />
            </div>
          </div>
        ) : (
          <div className="create-order-btn">
            <Button
              type="fit-content"
              disabled={selectedFolders.length === 0}
              onClick={createOrder}
              label={Translation.create_ftp_order_now}
            />
          </div>
        )
      }
      {isErrorModalOpen && (
        <Dialog
          isShown={isErrorModalOpen}
          title={`${Translation.attention}!`}
          content={(
            <Typography
              variant="xs"
              containsHtml
              label={apiErrors}
            />
          )}
          yesLabel={Translation.ok}
          onClickCancel={() => setIsErrorModalOpen(false)}
          onClickYes={() => setIsErrorModalOpen(false)}
          hasOnlyYes
          isWarning
        />
      )}
    </div>
  )
}

export default ApiNewOrder
