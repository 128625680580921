import React from 'react'

import './index.scss'

const DotAnimation = () => (
  <div className="loading-dots">
    <div className="loading-dots--dot" />
    <div className="loading-dots--dot" />
    <div className="loading-dots--dot" />
  </div>
)

export default DotAnimation
