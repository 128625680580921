import React from 'react'
import PropTypes from 'prop-types'
import { useStoreState } from 'easy-peasy'
import { useNavigate, useLocation } from 'react-router-dom'

import { Translation } from '../../helpers/Translation'
import { CHECKOUT_PAGE, PAYMENT_SUCCESS_PAGE, PX_TO_REM } from '../../helpers/Constants'

import Typography from '../../components/Typography'
import Button from '../../components/Button'

import LangMenu from '../LangMenu'
import SupportMenu from '../SupportMenu'
import ProfileMenu from '../ProfileMenu'

import './index.scss'

const Header = ({ title, isShadow }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useStoreState((state) => state.layout.isMobile)
  const unpaidDetails = useStoreState((state) => state.invoice.unpaidDetails)

  return (
    <div className={`header ${isShadow ? 'header--shadow' : ''}`}>
      <div className="header--wrap">
        <div className="header--title">
          <Typography
            variant="h6"
            font="semibold"
            theme="dark"
            label={title}
          />
        </div>

        {!isMobile
          && unpaidDetails
          && unpaidDetails?.final_price_no_format !== 0
          && location.pathname !== CHECKOUT_PAGE
          && location.pathname !== PAYMENT_SUCCESS_PAGE
          && (
            <div className="outstanding-balance-container">
              <div className="outstanding-balance-info">
                <Typography
                  fontSize={PX_TO_REM['9']}
                  lineHeight={PX_TO_REM['9']}
                  noWrap
                  font="semibold"
                  label={Translation.outstanding_balance}
                />
                <Typography
                  fontSize={PX_TO_REM['19']}
                  lineHeight={PX_TO_REM['22']}
                  noWrap
                  font="semibold"
                  label={`${unpaidDetails.final_price}${unpaidDetails.currency_sign}`}
                />
              </div>
              <Button
                isWarning
                label={Translation.pay_now}
                onClick={() => navigate(CHECKOUT_PAGE)}
                width="auto"
              />
            </div>
          )}

        <div className="header--menu--wrap">
          <div>
            <SupportMenu />
          </div>

          <div>
            <LangMenu />
          </div>

          <div className="avatar-logout--wrap">
            <ProfileMenu />
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  isShadow: PropTypes.bool,
}

Header.defaultProps = {
  title: '',
  isShadow: false,
}

export default Header
